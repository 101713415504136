import styled from '@emotion/styled';
import { Col, Container, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { INPUT_BORDER } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const parameters = "0px solid"


export const StyledRadioButtonCol = styled (Row)`
display: flex;
justify-content: flex-start;
align-items: center;
width: 30%;
height: 100%;
white-space: nowrap;

border: ${parameters} green;

@media (max-width: ${breakpoints.xxl}) {
        /* width: 100px; */
        /* flex-direction: column; */
        flex-wrap: no-wrap;
        justify-content: flex-start;
        align-items: center;
        flex-basis: 50%;
        margin-left: -8px; 
        white-space: wrap;
        /* max-height: px; */
      }

`;

export const StyledRadio2ButtonCol = styled (Col)`
display: flex;
/* flex-wrap: wrap; */
justify-content: flex-start;
/* flex-basis: 70%; */
/* margin-right: 400px; */
/* padding-right: 7px;
margin-left: -30px; */
border: ${parameters} yellowgreen;
@media (width: ${breakpoints.xxl}) {
        /* width: 100px; */
        flex-basis: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

`;
export const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;
  /* border: ${parameters} purple; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const UserUpdateWrapper = styled(Row)`
  margin-top: 20px;
  .user-fields-container {
    margin-top: 20px;
  }
`;

export const WorkerUpdateWrapper = styled(Row)`
  margin-top: 20px;
  .worker-fields-container {
    display: flex;
   
    justify-content: space-between;
    margin-top: 20px;
    border: ${parameters} pink;

    .remove-row-button {
      margin-top: 10px;

      span {
        margin: 0;
      }
    }

    .check-box {
      /* transform: scale(2); */
      zoom: 2;
      display: flex;
     
      /* align-content: space-around; */
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      overflow: hidden;
      /* width:80%; */

      border: ${parameters} yellow;
      @media (max-width: ${breakpoints.lg}) {
        /* width: 100px; */
        /* transform: scale(1.5); */
        /* flex-direction: column;
        justify-content: flex-start;
        align-items: center; */
        zoom: 1;
        /* flex-direction: column; */
          
      }
    }

    label {
      padding-right: 100px;
    } 

    input[type='checkbox'] {
      position: relative;
      appearance: none;
      width: 50px;
      height: 20px;
      background: #ccc;
      border-radius: 50px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type='checkbox'] {
      background: #F24E4F;
    }

    input[type='checkbox']::after {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
      transition: 0.4s;
    }

    input:checked[type='checkbox']::after {
      left: 50%;
    }
  }

  .worker-education-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 855px;
    padding: 20px 0 0;

    .add-button {
      cursor: pointer;
    }
  }

  .worker-image-container {
    .image-preview {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 5px;

      .image-preview-hover {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .image-preview-hover:hover {
        opacity: 1;
        background: rgba(203, 206, 215, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin-right: 40px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }

    .image-ref {
      height: 0;
      width: 0;
      input {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  /* border: ${parameters} red; */

  @media (max-width: ${breakpoints.md}) {
    margin: 10px auto;
  }
`;

// export const StyledDatePicker = ({ locale, ...props }) => {
//   console.log("here is locale ====>>>>", locale)
//   return <DatePicker locale={locale} {...props} />;
// };

export const StyledDatePickerField = styled(DatePicker)`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
`;

export const StyledSelectField = styled('select')`
  font-size: 16px;
  padding: 5px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
`;

export const SectionTitle = styled('h5')`
  margin: 20px 0;
`;

export const StyledTextareaField = styled('textarea')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  resize: none;
`;

export const SubmitActionWrapper = styled(Col)`
  button {
    margin-top: 15px;
  }
`;
