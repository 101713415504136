import { createAsyncThunk } from '@reduxjs/toolkit';

import { contactUrl } from 'utils/apis';
import http from 'utils/http';

const contact = createAsyncThunk('contact', async payload => {
  const { data } = await http.post(contactUrl, payload);
console.log("here is data ===>>>", data)
  return data;
});

export { contact };
