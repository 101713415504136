import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Editor from 'components/QuillEditor';
import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { showToast } from 'redux/modules/toast/reducer';
import { fetchProject, updateProject, createProject } from 'redux/modules/projects/asyncThunks';
import { jobsSelector } from 'redux/modules/jobs/selectors';
import { authUserSelector } from 'redux/modules/auth/selectors';

import {
  StyledProjectsContentWrapper,
  SectionTitle,
  StyledInputField,
  StyledSelectField,
  StyledTextareaField,
  SubmitActionWrapper,
  WorkerUpdateWrapper,
  StyledContainer,
  StyledDatePickerField
} from './styles';
import CustomButton from '../../../components/CustomButton';
import { WHITE } from '../../../constants/colors';
import { formatISODateFromShortDate } from '../../../utils/helpers';

// eslint-disable-next-line complexity
const EditProject = ({ history }) => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const [project, setProject] = useState(null);
  const userInfo = useSelector(authUserSelector);
  const jobList = useSelector(jobsSelector);
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();

  const {
    register: registerProjectInputFields,
    setValue: setProjectInputFields,
    watch: projectInputFieldsWatch,
    handleSubmit: handleSubmitProjectInputFields,
    formState: { errors: errorsProjectInputFields }
  } = useForm();

  useEffect(() => {
    if (routeParams?.projectId) {
      try {
        (async () => {
          const jobRes = await dispatch(fetchProject(routeParams.projectId)).unwrap();
          setProject(jobRes);
          setSelectedValue(jobRes.job.id);
        })();
      } catch (e) {
        dispatch(
          showToast({
            type: 'danger',
            message: e.message || 'error',
            delay: 5000
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const setPredefinedUserValues = () => {
      setProjectInputFields('title', project?.title);
      setProjectInputFields('description', project?.description);
      setProjectInputFields('deadline', formatISODateFromShortDate(project?.deadline, true));
      setProjectInputFields('status', project?.status || 'open');
      setProjectInputFields('job', project?.job);
      setProjectInputFields('shortDescription', project?.shortDescription);
    };

    setPredefinedUserValues();
  }, [setProjectInputFields, project?.title, project?.description, project?.deadline, project?.status, project?.shortDescription, project?.job]);

  // const handleProjectUpdate = async formData => {
  //   console.log('=====>>> userinfo', userInfo);
  //   const data = {
  //     ...formData,
  //     company: userInfo?.id,
  //     locale: localStorage.getItem('i18nextLng') || 'en'
  //   };

  //   if (!routeParams?.projectId) {
  //     await dispatch(createProject(data)).unwrap();
  //     history.go(-1);
  //   } else {
  //     await dispatch(updateProject({ projectId: Number(routeParams.projectId), payload: data })).unwrap();
  //   }
  // };

  const handleProjectUpdate = async formData => {
    const { projectId } = routeParams;
    const userId = userInfo?.id ?? '';
    const locale = localStorage.getItem('i18nextLng') ?? 'en';
    const projectData = { ...formData, company: userId, locale };

    try {
      if (!projectId) {
        await dispatch(createProject(projectData)).unwrap();
        history.go(-1);
      } else {
        await dispatch(updateProject({ projectId: Number(projectId), payload: projectData })).unwrap(),
          dispatch(showToast({ type: 'success', message: t('MESSAGES.updateProfile.success') })),
          history.go(-1);
      }
    } catch (error) {
      console.error(error);
      // Handle error appropriately (e.g. show error message to user)
    }
  };

  const deadlineWatch = projectInputFieldsWatch('deadline');
  const descriptionWatch = projectInputFieldsWatch('description');

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={project?.title || t('FORM.fields.ProjektTitle')} role={USER_TYPE.COMPANY}>
      <StyledProjectsContentWrapper>
        <StyledContainer fluid>
          <WorkerUpdateWrapper>
            <form onSubmit={handleSubmitProjectInputFields(handleProjectUpdate)}>
              <SectionTitle>{`${t('FORM.fields.general')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12} md={3}>
                  <StyledInputField placeholder={t('FORM.fields.title')} type="text" {...registerProjectInputFields('title', { required: true })} />
                  {errorsProjectInputFields.title && <span>{t('FORM.validation.requiredField')}</span>}
                </Col>
                <Col sm={12} md={3}>
                  <StyledDatePickerField
                    selected={deadlineWatch}
                    onChange={date => setProjectInputFields('deadline', date)}
                    placeholderText={t('FORM.fields.selectDate')}
                    isClearable
                    dateFormat="dd.MM.yyyy"
                    required
                  />
                  {errorsProjectInputFields.deadline && <span>{t('FORM.validation.requiredField')}</span>}
                </Col>
                <Col sm={12} md={3}>
                  <StyledSelectField placeholder={t('FORM.fields.status')} type="text" {...registerProjectInputFields('status', { required: true })}>
                    <option value="open">{t('FORM.fields.open')}</option>
                    {/* <option value="canceled">{t('FORM.fields.canceled')}</option>
                    <option value="ongoing">{t('FORM.fields.ongoing')}</option> */}
                    <option value="finished">{t('FORM.fields.finished')}</option>
                  </StyledSelectField>
                </Col>
                {/* <Col sm={12} md={3}>
                  <StyledSelectField placeholder={t('FORM.fields.status')} type="text" value={selectedValue} {...registerProjectInputFields('job', { required: true })}>
                    {jobList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </StyledSelectField>
                  {errorsProjectInputFields.job && <span>{t('FORM.validation.requiredField')}</span>}
                </Col> */}
              </Row>
              <SectionTitle>{`${t('OFFERCARD.description')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12}>
                  <Editor value={descriptionWatch} onChange={content => setProjectInputFields('description', content)} placeholder={t('OFFERCARD.description')} />

                  {errorsProjectInputFields.description && <span>{t('FORM.validation.requiredField')}</span>}
                </Col>
              </Row>
              {/* <Row className="worker-fields-container">
                <Col sm={12}>
                  <StyledTextareaField
                    placeholder={t('FORM.fields.shortDescription')}
                    type="text"
                    rows="3"
                    cols="60"
                    {...registerProjectInputFields('shortDescription')}
                  />
                  {errorsProjectInputFields.shortDescription && <span>{t('FORM.validation.requiredField')}</span>}
                </Col>
              </Row> */}
              <SubmitActionWrapper>
                <CustomButton type="submit" text={t('BUTTON.updateWorkersInfo')} customTextColor={WHITE} />
              </SubmitActionWrapper>
            </form>
          </WorkerUpdateWrapper>
        </StyledContainer>
      </StyledProjectsContentWrapper>
    </ProfilePageContainer>
  );
};

export default withTranslations(EditProject, 'EditProject');
