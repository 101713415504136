export const loginUrl = '/auth/local';
export const forgetPassword = '/auth/forgot-password';
export const resetPass = '/auth/reset-password';
export const registerUrl = '/auth/local/register';
export const userMeUrl = '/me';
export const userDetailsUrl = '/profile';
export const updateUserUrl = userId => `/users/${userId}`;
export const updateWorkerUrl = workerId => `/workers/${workerId}`;
export const workerProfileUrl = workerId => `/workers/${workerId}`;

export const updateCompanyUrl = companyId => `/companies/${companyId}`;
export const updateBlacklistUrl = companyId => `/companies/${companyId}`;
export const companyProfileUrl = companyId => `/companies/${companyId}`;

export const applicationsUrl = '/applications';
export const applicationUrl = applicationId => `/applications/${applicationId}`;

export const companyOffersUrl = companyId => `/job-offers?company=${companyId}`;
export const workerOffersUrl = workerId => `/job-offers?worker=${workerId}`;
export const workerOfferUpdateUrl = jobOfferId => `/job-offers/${jobOfferId}`;
export const sendOfferCompanyUrl = '/job-offers';

export const getWorkersUrl = '/workers';

export const jobsUrl = '/jobs';
export const jobUrl = jobId => `/jobs/${jobId}`;
export const countJobsUrl = '/jobs/count';
export const jobIdUrl = id => `/jobs/${id}`;
export const workersUrl = '/workers';
export const workersCountUrl = '/workers/count';

export const activateHash = hash => `/activate/${hash}`;
export const resetPasswordRequest = '/reset';
export const resetPassword = '/reset-password';

// Industries Apis
export const industryList = '/industries';
export const industryOccupations = '/industy-occupations';

// uploads Apis

export const deleteDoc = docId => `/upload/files/${docId}`;

// projects Apis

export const projectsUrl = '/projects';

export const countProjectsUrl = '/projects/count';

export const projectUrl = projectId => `/projects/${projectId}`;

// contact api

export const contactUrl = '/contacts';

// fetch Company login user info

export const companyInfo = '/companies';
