export const projectsSelector = ({ projectsList }) => {
  const { projects } = projectsList;

  return projects;
};

export const projectSelector = ({ projectsList }, projectId) => {
  const { projects } = projectsList;

  return (projects || []).find(project => project.id === projectId);
};

export const countProjectsSelector = ({ projectsList }) => {
  const { projectsCount } = projectsList;

  return projectsCount;
};
