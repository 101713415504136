import axios from 'axios';
import Qs from 'qs';

export const AUTH = 'auth';
export const TOKEN = 'token';
export const TOKEN_TYPE = 'Bearer';
const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const resolveApiUrl = `${API_PROTOCOL}://${API_DOMAIN}`;

const http = axios.create({
  baseURL: resolveApiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer(parameters) {
    return Qs.stringify(parameters, { arrayFormat: 'repeat' });
  }
});

export default http;
