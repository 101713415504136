import { createAsyncThunk } from '@reduxjs/toolkit';

import { companyOffersUrl, workerOffersUrl, workerOfferUpdateUrl } from 'utils/apis/index';
import http from 'utils/http';

const companyOffers = createAsyncThunk('offers/company', async companyId => {
  const { data } = await http.get(companyOffersUrl(companyId));

  return data;
});
const workerOffers = createAsyncThunk('offers/worker', async workerId => {
  const { data } = await http.get(workerOffersUrl(workerId));

  return data;
});
const updateJobOffers = createAsyncThunk(
  'jobs/update-job-offers',
  async ({ jobOfferId, payload }) => {
    console.log("here is updatejoboffers in thunk", jobOfferId, payload);
    const { data } = await http.put(workerOfferUpdateUrl(jobOfferId), payload);
console.log("here is data in updateJobOffers ===>>>", data)
    return data;
  }
);



export {
  companyOffers,
  workerOffers,
  updateJobOffers
};
