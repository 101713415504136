/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  StyledCustomDialog,
  StyledCustomDialogWrapper,
  StyledCustomDialogLeftSide,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogButtonActions,
  StyledInputField,
  StyledCloseIcon
} from 'components/ForgetPassDiaglog/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';
import { getWorkerProfile, getCompanyProfile } from 'redux/modules/auth/asyncThunks';
import { closeDialog, openDialog } from 'redux/modules/dialogs/reducer';
import { ErrorMessage } from 'components/RegisterDialog/styles';
import { showToast } from 'redux/modules/toast/reducer';
import { forgetPassword } from 'utils/apis';
import { loginUser } from 'redux/modules/auth/reducer';
// import { AUTH } from 'utils/http';

const ForgetPassDialog = ({ history }) => {
  const { forgetPassDialogOpened } = useSelector(dialogSelector);
  const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const resolveApiUrl = `${API_PROTOCOL}://${API_DOMAIN}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (forgetPassDialogOpened) {
      document.body.style.overflow = 'hidden';
    }
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, [forgetPassDialogOpened]);

  const handleSendLink = async loginPayload => {
    try {
      // const responseData = await dispatch(login(loginPayload))?.unwrap();
      let response = await fetch(resolveApiUrl + forgetPassword, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginPayload)
      });
      response = await response.json();
      if (response?.ok) {
        dispatch(
          showToast({
            type: 'success',
            message: t('FORGOT_PASSWORD_DIALOG.successMessage')
          })
        );
        dispatch(
          closeDialog({
            dialogName: 'forgetPassDialog'
          })
        );
        return;
      }
    } catch (err) {
      console.log(err, 'error is not complete'); // I want the full error response from the backend
      setErrMsg(t('FORM.validation.failedLogin'));
    }
  };

  const handleCloseForgetPassDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'forgetPassDialog'
      })
    );
  };

  return forgetPassDialogOpened ? (
    <>
      <StyledCustomDialog onClick={handleCloseForgetPassDialog} />
      <StyledCustomDialogWrapper>
        <StyledCloseIcon onClick={handleCloseForgetPassDialog} />
        <StyledCustomDialogLeftSide>
          <StyledDialogTitle>{t('FORGOT_PASSWORD_DIALOG.title')}</StyledDialogTitle>
          <form onSubmit={handleSubmit(handleSendLink)}>
            <StyledDialogContent>
              <StyledInputField placeholder={t('FORM.fields.email')} type="text" {...register('email', { required: true })} />
              {errors.identifier && <span>{t('FORM.validation.requiredField')}</span>}
            </StyledDialogContent>

            <StyledDialogButtonActions>
              <CustomButton
                text={t('FORGOT_PASSWORD_DIALOG.button')}
                customFontSize="18px"
                customWidth="300px"
                customHeight="50px"
                customTextColor={WHITE}
                customBorderColor={APP_RED}
                type="submit"
              />
            </StyledDialogButtonActions>
          </form>
        </StyledCustomDialogLeftSide>
      </StyledCustomDialogWrapper>
    </>
  ) : null;
};

export default ForgetPassDialog;
