import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Editor from 'components/QuillEditor';
import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { showToast } from 'redux/modules/toast/reducer';
import http from 'utils/http';
import { industryList, industryOccupations } from 'utils/apis';
import { languageSelector } from 'redux/modules/language/selectors';
import CustomTable from 'components/CustomTableVacancy';
import { getPropByValue } from 'utils/helpers';

import {
  StyledProjectsContentWrapper,
  SectionTitle,
  StyledInputField,
  StyledSelectField,
  StyledTextareaField,
  SubmitActionWrapper,
  WorkerUpdateWrapper,
  StyledContainer,
  StyledDatePickerField,
  MultiSelector,
  StyledEditorLong
} from './styles';
import CustomButton from '../../../components/CustomButton';
import { WHITE } from '../../../constants/colors';
import { authProfileSelector } from '../../../redux/modules/auth/selectors';
import { createJob, fetchJob, updateJob } from '../../../redux/modules/jobs/asyncThunks';
import { formatISODateFromShortDate } from '../../../utils/helpers';
import { isNull } from 'lodash';

// eslint-disable-next-line complexity
const EditVacancy = ({ history }) => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const company = useSelector(authProfileSelector);
  const language = useSelector(languageSelector);
  const [job, setJob] = useState(null);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([]);
  const [preSelectedLevel, setPreSelectedLevel] = useState('');
  const [industries, setIndustries] = useState();
  const [industryOCP, setIndustryOCP] = useState([]);
  const [selectedOCP, setSelectedOCP] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectorError, setSelectorError] = useState(0); // 1=> Industry error 2=> industries Occupations
  const [selectedStartPlan, setSelectedStartPlan] = useState(null);
  const [hourlyFlag, setHourlyFlag] = useState(false);
  const [monthlyFlag, setMonthlyFlag] = useState(false);
  const [monthlyOrHourlyIsFilled, setMonthlyOrHourlyIsFilled] = useState(false);
  const [plz, setPlz] = useState();
  const [isPlzFilled, setIsPlzFilled] = useState(false);
  const [hourlyRate, setHourlyRate] = useState();
  const [monthlyRate, setMonthlyRate] = useState();

  // const specificDate = new Date('Thu Jan 01 1970');
  // Convert deadlineWatch to an ISO string
// const deadlineWatchISO = deadlineWatch.toISOString();

// // Convert specificDate to an ISO string
// const specificDateISO = specificDate.toISOString();

  const handlerPlz = e => {
    e.preventDefault;
    console.log('hey from e.target .value', e.target.value);
    setPlz(e.target.value);
    if (e.target.value.length >= 1) {
      setIsPlzFilled(true);
    } else {
      setIsPlzFilled(false);
    }
  };
  // console.log('here is Plz', plz);
  const handleStartPlanChange = event => {
    setSelectedStartPlan(event.target.value);
  };

  const handlerHourly = e => {
    e.preventDefault;
    console.log('hey from e.target .value handlerHourly', e.target.value);
    setHourlyRate(e.target.value);
    const { value } = e.target;
    // console.log('Rate field has been filled:', value);
    if (value.length >= 1) {
      setHourlyFlag(true);
    } else {
      setHourlyFlag(false);
    }
  };

  const handlerMonthly = e => {
    e.preventDefault;
    console.log('hey from e.target .value handlerHourly', e.target.value);
    setMonthlyRate(e.target.value);
    const { value } = e.target;
    // console.log('Rate field has been filled:', value);
    if (value.length >= 1) {
      setMonthlyFlag(true);
    } else {
      setMonthlyFlag(false);
    }
  };

  const {
    register: registerJobInputFields,
    setValue: setJobInputFields,
    watch: jobInputFieldsWatch,
    handleSubmit: handleSubmitJobInputFields,
    formState: { errors: errorsJobInputFields }
  } = useForm();

  function convertUTCToTimezoneOffset(utcTimestamp) {
    const utcDate = new Date(utcTimestamp);
    const offsetMilliseconds = 2 * 60 * 60 * 1000;
    const localTimestamp = new Date(utcDate.getTime() + offsetMilliseconds);
    return localTimestamp.toISOString();
  }
  let timeConverted 

  useEffect(() => {
    if (routeParams?.jobId) {
      try {
        (async () => {
          const jobRes = await dispatch(fetchJob(routeParams.jobId)).unwrap();
          timeConverted = convertUTCToTimezoneOffset(jobRes?.deadline);

          // console.log("here is time converted", timeConverted)

          if (timeConverted === '1970-01-01T02:00:00.000Z') {
            setJob({ ...jobRes, deadline: null });
          } else {
            setJob({ ...jobRes, deadline: timeConverted });
          }


  // if (deadlineWatch === specificDate ) {
  //   setJob({ ...jobRes, deadline: null });
  // } else {
  //   setJob({...jobRes, deadline: timeConverted})
  // }

          // const jobWithConvertedTime = { ...jobRes, deadline: timeConverted };
          // console.log('here is jobRes==>>', jobWithConvertedTime);
          // setJob(jobWithConvertedTime);
        })();
      } catch (e) {
        dispatch(
          showToast({
            type: 'danger',
            message: e.message || 'error',
            delay: 5000
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const setPredefinedUserValues = () => {
      setJobInputFields('title', job?.title);
      setJobInputFields('description', job?.description);
      setJobInputFields('deadline', formatISODateFromShortDate(job?.deadline, true));
      // setJobInputFields('rate', job?.rate);
      setHourlyRate(job?.rate);
      // setJobInputFields('monthlyPayroll', job?.monthlyPayroll);
      setMonthlyRate(job?.monthlyPayroll);
      setJobInputFields('status', job?.status || 'open');
      setJobInputFields('startP', job?.startP || selectedStartPlan);
      setSelectedStartPlan(job?.startP || '');
      setJobInputFields('descriptionCompany', job?.descriptionCompany);
      setJobInputFields('contactInformation', job?.contactInformation);
      setJobInputFields('address', job?.address);
      setJobInputFields('city', job?.city);
      // setJobInputFields('postCode', job?.postCode);
      setPlz(job?.postCode);

      setJobInputFields('state', job?.state);
      // console.log('here is job dejjt====>>>>', job);

      setPreSelectedLevel(job?.skillLevel);

      http.get(`${industryList}?_locale=${language}`).then(res => {
        setIndustries(() =>
          res?.data.map(item => ({
            value: item?.id || '',
            label: item?.name || '',
            locale: item?.locale || '',
            localizations: item?.localizations || []
          }))
        );
        setSelectedOption(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          if (job) {
            let found;
            for (let i = 0; i < res?.data.length; i++) {
              const industry = res?.data[i];
              if (language !== job.industry.locale) {
                const temp = industry.localizations.find(locObj => locObj.id === job.industry.id);
                if (temp) {
                  found = industry;
                  break;
                }
              }
            }
            if (language === job.industry.locale) {
              unTranslatedSelectedOption.push({ value: job.industry.id, label: job.industry.name, locale: job.industry.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.id, label: found.name, locale: found.locale });
            }
          }
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
      });
    };

    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    }

    setPredefinedUserValues();
  }, [
    setJobInputFields,
    job?.title,
    job?.description,
    job?.deadline,
    job?.rate,
    job?.monthlyPayroll,
    job?.status,
    job?.address,
    job?.city,
    job?.postCode,
    job?.state,
    job?.descriptionCompany,
    job?.contactInformation,
    job?.skillLevel,
    job,
    language
  ]);
  console.log('here is getPropByValue(selectedLevel[0].label)', selectedLevel && selectedLevel[0].label);
  let formContent = {};
  const handleJobUpdate = async formData => {
    if (selectedOption.length < 1) {
      setSelectorError(1);
    } else if (selectedOCP.length < 1) {
      setSelectorError(2);
    } else {
      const data = {
        ...formData,
        company: company.id,
        industry: selectedOption[0].value,
        industy_occupations: selectedOCP.map(ele => ele.value),
        skillLevel: (selectedLevel && selectedLevel[0].label) || '',
        locale: localStorage.getItem('i18nextLng') || 'en',
        postCode: plz,
        rate: hourlyRate,
        monthlyPayroll: monthlyRate
        // deadline: new Date(formData.deadline).toISOString()
      };
      console.log('here is formData', data);
      if (!routeParams?.jobId) {
        await dispatch(createJob(data)).unwrap();
        dispatch(showToast({ type: 'success', message: t('MESSAGES.updateVacancy.success') }));
        history.go(-1);
      } else {
        await dispatch(updateJob({ jobId: routeParams.jobId, payload: data })).unwrap(),
          dispatch(showToast({ type: 'success', message: t('MESSAGES.updateVacancy.success') }));
      }
    }
  };

  const deadlineWatch = jobInputFieldsWatch('deadline');
  const descriptionWatch = jobInputFieldsWatch('description');
  const descriptionCompanyWatch = jobInputFieldsWatch('descriptionCompany');
  const contactInformationWatch = jobInputFieldsWatch('contactInformation');
  const rateInformationWatch = jobInputFieldsWatch('rate');
  const countryInformationWatch = jobInputFieldsWatch('state');
  const addressInformationWatch = jobInputFieldsWatch('address');
  const cityInformationWatch = jobInputFieldsWatch('city');
  const titleInformationWatch = jobInputFieldsWatch('title');
  console.log('here is deadlinewatch', deadlineWatch);
  const fetchIndustriesOcp = async () => {
    if (selectedOption.length > 0) {
      let filter = '';
      selectedOption.length > 0 &&
        selectedOption.forEach((item, index) => {
          if (index === 0) {
            filter = `?industry=${item.value}&_locale=${language}`;
          } else {
            filter = `${filter}&industry=${item.value}`;
          }
        });
      await http.get(industryOccupations + filter).then(res => {
        const ocpList = res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || '',
          locale: item?.locale || '',
          localizations: item?.localizations || []
        }));
        setSelectedOCP(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          if (job) {
            job.industy_occupations.forEach(sele => {
              let found;
              for (let i = 0; i < ocpList.length; i++) {
                const industryOcc = ocpList[i];
                if (language !== sele.locale) {
                  const temp = industryOcc.localizations.find(locObj => locObj.id === sele.id);
                  if (temp) {
                    found = industryOcc;
                    break;
                  }
                }
              }
              if (language === sele.locale) {
                unTranslatedSelectedOption.push({ value: sele.id, label: sele.name, locale: sele.locale });
              }
              if (found) {
                translatedSelectedOption.push({ value: found.value, label: found.label, locale: found.locale });
              }
            });
            return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
          }
        });
        setIndustryOCP(ocpList);
      });
    } else {
      setIndustryOCP([]);
    }
  };

  useEffect(() => {
    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    }
    fetchIndustriesOcp();
  }, [selectedOption]);
  // console.log('here is selectedLevel====>>>', selectedLevel)

  let handleDate = date => {
    //  let newDate = new Date(date)
    //  newDate.setDate(date.getDate() +1)
    // console.log("here is formattedDateString in handleDatefunction ==>>", formattedDateString)
    // console.log("here is date in handleDatefunction ==>>", correctDate)

    setJobInputFields('deadline', date);
  };

  //   console.log('here is errorsJobInputFields.contactInformation==>>', errorsJobInputFields.contactInformation);
  //   useEffect(()=> {
  // if (registerJobInputFields.rate) {
  //   setHourlyFlag(true)
  // }

  //   }, [registerJobInputFields])

  // const handleRateChange = event => {
  //   const { value } = event.target;
  //   // console.log('Rate field has been filled:', value);
  //   if (value.length >= 1) {
  //     setHourlyFlag(true);
  //   } else {
  //     setHourlyFlag(false);
  //   }
  // };

  // const handleMonthlyPayrollChange = event => {
  //   const { value } = event.target;
  //   // console.log('Rate field has been filled:', value);
  //   if (value.length >= 1) {
  //     setMonthlyFlag(true);
  //   } else {
  //     setMonthlyFlag(false);
  //   }
  // };

  // useEffect(() => {
  //   if (monthlyRate > 0) {
  //     setMonthlyFlag(true)
  //     setMonthlyOrHourlyIsFilled(true)
  //   }
  //   if (hourlyRate > 0) {
  //     setMonthlyFlag(true)
  //     setMonthlyOrHourlyIsFilled(true)
  //   }
  // }, [])

  useEffect(() => {
    if (hourlyFlag || monthlyFlag) {
      setMonthlyOrHourlyIsFilled(false);
    } else {
      setMonthlyOrHourlyIsFilled(true);
    }
  }, [hourlyFlag, monthlyFlag]);



  // const updatedDeadline = deadlineWatch.getDate()

  // console.log("here is updatedDeadline", updatedDeadline);

  //   console.log('here is monthlyOrHourlyIsFilled', monthlyOrHourlyIsFilled);
  // console.log("here is countryInformationWatch", countryInformationWatch)
  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={job?.title || t('FORM.fields.JobTitle')} role={USER_TYPE.COMPANY}>
      <StyledProjectsContentWrapper>
        <StyledContainer fluid>
          <WorkerUpdateWrapper>
            <form onSubmit={handleSubmitJobInputFields(handleJobUpdate)}>
              <SectionTitle>{`${t('FORM.fields.general')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12} md={4}>
                  <StyledInputField placeholder={t('FORM.fields.title')} type="text" {...registerJobInputFields('title', { required: true })} />
                  <Col style={{ height: '25px' }}>
                    {' '}
                    {!titleInformationWatch && (
                      <span style={{ color: 'black', paddingLeft: '10px', fontSize: '14px', fontStyle: 'italic' }}>{t('FORM.validation.required')}</span>
                    )}
                  </Col>

                  {/* {errorsJobInputFields.title && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
                <Col sm={12} md={3}>
                  <StyledSelectField type="text" {...registerJobInputFields('startP')} value={selectedStartPlan} onChange={handleStartPlanChange}>
                    <option value="">{t('FORM.fields.startPlan')}</option>
                    <option value="immediately">{t('FORM.fields.immediately')}</option>
                    <option value="byAgreement">{t('FORM.fields.byAgreement')}</option>
                  </StyledSelectField>
                </Col>
                <Col md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {t('FORM.fields.or')}
                </Col>
                <Col sm={12} md={3}>
                  <StyledDatePickerField
                    selected={deadlineWatch}
                    
                    onChange={date => handleDate(date)}
                    placeholderText={t('FORM.fields.selectDate')}
                    dateFormat="dd.MM.yyyy"
                    isClearable
                  />
                  {/* {errorsJobInputFields.deadline && <span>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
              </Row>
              <SectionTitle>{`${t('FORM.fields.descriptionCompany')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12}>
                  <StyledEditorLong>
                    <Editor
                      value={descriptionCompanyWatch}
                      onChange={content => setJobInputFields('descriptionCompany', content)}
                      placeholder={t('FORM.fields.descriptionVacancy')}
                      // {...registerJobInputFields('descriptionCompany', {required: true})}
                    />
                  </StyledEditorLong>
                  {/* {errorsJobInputFields.descriptionCompany && <span style={{ color: 'red', paddingLeft: "10px" }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
              </Row>
              <SectionTitle>{`${t('FORM.fields.description')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12}>
                  <StyledEditorLong>
                    <Editor
                      value={descriptionWatch}
                      onChange={content => setJobInputFields('description', content)}
                      placeholder={t('FORM.fields.descriptionVacancy')}
                      //  {...registerJobInputFields('description', {required: true})}
                    />
                  </StyledEditorLong>
                  {/* {errorsJobInputFields.description && <span style={{ color: 'red', paddingLeft: "10px" }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
              </Row>
              <SectionTitle>{`${t('FORM.fields.descriptionContact')}`}</SectionTitle>
              <Row className="worker-fields-container">
                <Col sm={12}>
                  <StyledEditorLong>
                    <Editor
                      value={contactInformationWatch}
                      onChange={content => setJobInputFields('contactInformation', content)}
                      placeholder={t('FORM.fields.descriptionVacancy')}
                      // {...registerJobInputFields('contactInformation', { required: true })}
                    />
                  </StyledEditorLong>
                  {/* {errorsJobInputFields.contactInformation && <span style={{ color: 'red', paddingLeft: "10px" }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
              </Row>
              <SectionTitle>{`${t('FORM.fields.details')}`}</SectionTitle>
              <Row>
                <Col xxl={3}>
                  <StyledInputField
                    value={hourlyRate}
                    //  onChange={cont => setJobInputFields('rate', cont)}
                    placeholder={t('FORM.fields.onlyNumbersHourly')}
                    // type="text"
                    // {...registerJobInputFields('rate')}
                    // onChange={...registerJobInputFields('rate')}
                    onChange={handlerHourly}
                  />
                  {/* <StyledInputField
                    placeholder={t('FORM.fields.onlyNumbersHourly')}
                    type="text"
                    {...registerJobInputFields('rate', { required: false })}
                    onChange={content => set}
                  /> */}
                  {/* {errorsJobInputFields.rate && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
                <Col xxl={3}>
                  <StyledInputField
                    value={monthlyRate}
                    placeholder={t('FORM.fields.onlyNumbersMonthly')}
                    // {...registerJobInputFields('monthlyPayroll')}
                    // onChange={handleMonthlyPayrollChange}
                    onChange={handlerMonthly}
                  />
                </Col>
                <Row>
                  <Col style={{ height: '20px' }}>
                    {monthlyOrHourlyIsFilled && (
                      <span style={{ color: 'black', paddingLeft: '10px', marginTop: '50px', fontSize: '14px', fontStyle: 'italic' }}>
                        {t('FORM.validation.monthlyOrHourlyRequired')}
                      </span>
                    )}
                  </Col>
                </Row>
              </Row>
              <Row style={{ paddingTop: '30px' }}>
                <Col xxl={3}>
                  <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...registerJobInputFields('address', { required: true })} />
                  <Col style={{ height: '25px' }}>
                    {' '}
                    {!addressInformationWatch && (
                      <span style={{ color: 'black', paddingLeft: '10px', fontSize: '14px', fontStyle: 'italic' }}>{t('FORM.validation.required')}</span>
                    )}
                  </Col>
                  {/* {errorsJobInputFields.address && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
                <Col xxl={3}>
                  <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...registerJobInputFields('city', { required: true })} />
                  <Col style={{ height: '25px' }}>
                    {' '}
                    {!cityInformationWatch && (
                      <span style={{ color: 'black', paddingLeft: '10px', fontSize: '14px', fontStyle: 'italic' }}>{t('FORM.validation.required')}</span>
                    )}
                  </Col>
                  {/* {errorsJobInputFields.city && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>} */}
                </Col>
              </Row>
              <Row style={{ paddingTop: '10px' }}>
                <Col xxl={3}>
                  <StyledInputField value={plz} placeholder={t('FORM.fields.postcode')} onChange={handlerPlz} />
                  {!isPlzFilled ? (
                    <span style={{ color: 'black', paddingLeft: '10px', fontSize: '14px', fontStyle: 'italic' }}>{t('FORM.validation.required')}</span>
                  ) : (
                    ''
                  )}
                </Col>
                <Col xxl={3}>
                  <StyledInputField placeholder={t('FORM.fields.state')} type="text" {...registerJobInputFields('state', { required: true })} />
                  <Col style={{ height: '25px' }}>
                    {' '}
                    {!countryInformationWatch && (
                      <span style={{ color: 'black', paddingLeft: '10px', fontSize: '14px', fontStyle: 'italic' }}>{t('FORM.validation.required')}</span>
                    )}
                  </Col>
                  {/* //  <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span> */}
                </Col>
              </Row>

              <Row>
                <Col>
                  <MultiSelector
                    isMulti
                    placeholder={t('FORM.fields.selectIndustries')}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={industries}
                    isOptionDisabled={() => selectedOption.length >= 1}
                  />
                  {selectorError === 1 && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>}
                </Col>
                <Col>
                  {selectedOption?.length > 0 && (
                    <MultiSelector
                      isMulti
                      placeholder={t('FORM.fields.selectOccupations')}
                      value={selectedOCP}
                      onChange={setSelectedOCP}
                      options={industryOCP}
                      // isOptionDisabled={() => selectedOCP.length >= 1}
                    />
                  )}
                  {selectorError === 2 && <span style={{ color: 'red', paddingLeft: '10px' }}>{t('FORM.validation.requiredField')}</span>}
                </Col>
              </Row>
              <Row>
                <Row>
                  <Col>
                    {selectedOCP && selectedOCP.length > 0 && (
                      <CustomTable predefinedValue={preSelectedLevel} selectedOCP={selectedOCP} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />
                    )}
                  </Col>
                </Row>
              </Row>
              <SubmitActionWrapper>
                <CustomButton
                  type="submit"
                  text={!routeParams?.jobId ? t('BUTTON.create') : t('BUTTON.updateWorkersInfo')}
                  customTextColor={WHITE}
                  // disabled={monthlyOrHourlyIsFilled === true ? true : false}
                />
              </SubmitActionWrapper>
            </form>
          </WorkerUpdateWrapper>
        </StyledContainer>
      </StyledProjectsContentWrapper>
    </ProfilePageContainer>
  );
};

export default withTranslations(EditVacancy, 'EditVacancy');
