import React, { useState } from 'react';

import { InputStyles, StyledInput } from 'components/FormFields/CustomInput/styles';

const CustomInput = ({ name, onInputChange, placeholder }) => {
  const [inputValue, setInputValue] = useState('');

  const handleOnInputChange = ({ target }) => {
    const { value } = target;
    setInputValue(value);
    onInputChange(value);
  };

  return (
    <InputStyles>
      {/* <label>{placeholder}</label> */}
      <StyledInput name={name} aria-describedby={name} onChange={handleOnInputChange} value={inputValue} placeholder={placeholder} />
    </InputStyles>
  );
};

export default CustomInput;
