import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import {
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faQuestionCircle,
  faBan,
  faPen,
  faCircle,
  faPlusCircle,
  faStar,
  faCaretDown,
  faPlus,
  faPowerOff,
  faGlobe,
  faCloudUploadAlt,
  faTrashAlt,
  faTrash,
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import {
 faStar as farStart,
  faFileAlt,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  fab,
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faPlusCircle,
  faCircle,
  faQuestionCircle,
  faBan,
  faPen,
  faStar,
  farStart,
  faCaretDown,
  faPlus,
  faPowerOff,
  faGlobe,
  faCloudUploadAlt,
  faTrashAlt,
  faTrash,
  faChevronCircleLeft,
  faChevronCircleRight,
  faFileAlt,
);
