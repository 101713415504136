/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FarStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as FaStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import { APP_RED, WHITE } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderBottomImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledCardHeaderDetailsTop
} from 'components/CustomCarOffers/styles';

// eslint-disable-next-line complexity
const CustomCard = ({
  headerDescriptionEmail,
  headerDescriptionPhone,
  favCompanyDescription,
  profileImage,
  sectionTitle,
  sectionSubtitle,
  handleActiveDeactivateJob,
  upperButtonText,
  lowerButtonText,
  handleDeleteJob,
  handleEditJob,
  editButtonText,
  headerDescriptionFirst,
  headerDescriptionSecond,
  headerDescriptionThird,
  headerDescriptionFourth,
  headerDescriptionFifth,
  headerDescriptionExtra,
  headerDescriptionFirstRight,
  headerDescriptionSecondRight,
  headerDescriptionThirdRight,
  footerDescription,
  profileClick,
  favoriteIcon,
  isFavorite,
  onFavoriteClick,
  isActive,
  click,
  hasActions,
  handleOnItemClick,
  handleOnItemClickReject,
  acceptText,
  rejectText,
  onApplyClick,
  onAddRemoveClick,
  plusMinusSign,
  footerMinHeight,
  deadline,
  headerDescriptionPayrollMonthly,
  headerDescriptionFourthFrontPage,
  postCode

  // onApplyButton
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  // console.log(footerDescription);

  // const footerMinHeight = {
  //   minHeight: '6rem'
  // };

  return (
    <StyledCard onClick={click} active={isActive}>
      {/* ///////// */}
      {/* //// Flex Column start */}
      <StyledCardHeader>
        {/* //// Flex Column end */}
        {/* /////// */}
        {/* ///////// */}
        {/* //// set to felx only start */}

        <StyledIconWrapper>
          {favoriteIcon &&
            (isFavorite ? (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FaStar} color={APP_RED} fontSize="19px" />
            ) : (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FarStar} color={APP_RED} fontSize="19px" />
            ))}
        </StyledIconWrapper>

        <StyledCardHeaderImageWrapper>
          {/* //// set to felx only end */}
          {/* /////// */}
          {/* ///////// */}

          {/* //// set to felx only start */}
          {/* /// orange */}
          {/* <StyledImageDescriptionWrapper> */}
          {/* //// set to felx only end */}
          <StyledCardHeaderImageDescription>
            <StyledCardHeaderImage onClick={profileClick} src={profileImage} />{' '}
          </StyledCardHeaderImageDescription>
          <StyledCardRowWrapperCol>
            {/* <StyledCardHeaderImageDescription> */}
            {/* 
              /// yellow */}
            <StyledCardHeaderImageDescriptionFirst>
              {/* <CardName> */}
              {width > 1700 && sectionTitle ? sectionTitle.slice(0, 32) : ''}
              {width > 1700 && sectionTitle && sectionTitle.length > 31 ? '...' : ''}
              {width > 900 && width < 1700 && sectionTitle ? sectionTitle.slice(0, 28) : ''}
              {width > 900 && width < 1700 && sectionTitle && sectionTitle.length > 27 ? '...' : ''}
              {width <= 900 && sectionTitle ? sectionTitle.slice(0, 23) : ''}
              {width <= 900 && sectionTitle && sectionTitle.length > 22 ? '...' : ''}
              {/* </CardName> */}
            </StyledCardHeaderImageDescriptionFirst>
            <StyledCardHeaderDetailsTop>
              <span onClick={profileClick}>
                {sectionSubtitle && sectionSubtitle.slice(0, 25)}
                {sectionSubtitle && sectionSubtitle.length > 25 ? '...' : ''}
              </span>
              <span>
                {headerDescriptionThird && headerDescriptionThird.slice(0, 20) + `\u0020`}
                {headerDescriptionThird && headerDescriptionThird.toString().length > 20 ? '...' : ''}
                {headerDescriptionFifth && headerDescriptionFifth.toString().slice(0, 6)}
                {headerDescriptionFifth && headerDescriptionFifth.toString().length > 6 ? '...' : ''}
                {headerDescriptionFifth && <br />}
                {headerDescriptionFirst && headerDescriptionFirst.slice(0, 40)}
                {headerDescriptionFirst && headerDescriptionFirst.length > 40 ? '...' : ''} {headerDescriptionFirst && <br />}
                {headerDescriptionSecond && headerDescriptionSecond.slice(0, 20)} {headerDescriptionSecond && headerDescriptionSecond.length > 20 ? '...' : ''} &nbsp;{' '}
                {postCode && postCode}
              </span>
              <p>{headerDescriptionExtra && headerDescriptionExtra}</p>&nbsp;
              {/* {headerDescriptionThird && headerDescriptionThird.slice(0, 10)} {headerDescriptionThird && headerDescriptionThird.length > 10 ? '...' : ''} */}
              {headerDescriptionPhone && <span>Phone: {headerDescriptionPhone}, &nbsp;</span>}
              {headerDescriptionEmail && <span>Email: {headerDescriptionEmail}&nbsp;</span>}
              {/* </StyledCardHeaderImageDescription> */}
            </StyledCardHeaderDetailsTop>
            {(headerDescriptionFirstRight || headerDescriptionSecondRight || headerDescriptionThirdRight) && (
              <StyledCardHeaderBottomImageDescription>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionFirstRight}</span>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionSecondRight}</span>
                {headerDescriptionThirdRight && <span style={{ whiteSpace: 'nowrap' }}>Kat. {headerDescriptionThirdRight}</span>}
              </StyledCardHeaderBottomImageDescription>
            )}
            {headerDescriptionPayrollMonthly && (
              <StyledCardHeaderImageDescription>
                <StyledCardFrontPage>
                  <span>
                    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}
                  </span>
                  {deadline && (
                    <p style={footerMinHeight}>
                      {t('OFFERCARD.startDate')}: {deadline}
                    </p>
                  )}
                </StyledCardFrontPage>
              </StyledCardHeaderImageDescription>
            )}
          </StyledCardRowWrapperCol>
        </StyledCardHeaderImageWrapper>
      </StyledCardHeader>
      {headerDescriptionFourthFrontPage && (
        <StyledCardFrontBottom>
          <span>{headerDescriptionFourthFrontPage}</span>
        </StyledCardFrontBottom>
      )}
      {headerDescriptionFourth && (
        <StyledCardHeaderBellowImage>
          <span>{headerDescriptionFourth}</span>
        </StyledCardHeaderBellowImage>
      )}
      {/* <StyledCardFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {favCompanyDescription && <p style={footerMinHeight}>{favCompanyDescription}</p>}
      </StyledCardFooter> */}
      {/* <StyledFooterBottom style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {footerDescription && <p style={footerMinHeight}>{footerDescription}</p>}
      </StyledFooterBottom> */}
      {hasActions && (
        <>
          {/* <StyledProjectsButtonRow>
            {onApplyClick && <CustomButton text={t('BUTTON.applyNow')} customTextColor={WHITE} customWidth="120px" handleOnClick={onApplyClick} />}
          </StyledProjectsButtonRow> */}
          {/* <StyledProjectsButtonRow>
            {onAddRemoveClick && <CustomButton text={plusMinusSign} customTextColor={WHITE} customWidth="120px" handleOnClick={onAddRemoveClick} />}
          </StyledProjectsButtonRow> */}
          {/* <StyledCardAppFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {(handleOnItemClick || handleOnItemClickReject) && (
              <div className="actions-wrapper">
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClickReject}>
                    {rejectText || 'Close'}
                  </button>
                )}
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClick}>
                    {acceptText || 'Open'}
                  </button>
                )}
              </div>
            )}
          </StyledCardAppFooter> */}
        </>
      )}
      <Row>
        <Col>
          <StyledCardBodyRight>
            {upperButtonText && (
              <button className="edit-button" type="button" onClick={handleActiveDeactivateJob}>
                {upperButtonText}
              </button>
            )}
            {lowerButtonText && (
              <button className="delete-button" type="button" onClick={handleDeleteJob}>
                {lowerButtonText}
              </button>
            )}
            {editButtonText && (
              <button className="edit-button" type="button" onClick={handleEditJob}>
                {editButtonText}
              </button>
            )}
          </StyledCardBodyRight>
        </Col>
      </Row>
    </StyledCard>
  );
};

CustomCard.defaultProps = {
  profileImage: null,
  sectionTitle: '',
  headerDescriptionFirst: null,
  headerDescriptionSecond: null,
  hasActions: false,
  sectionSubtitle: null,
  isActive: false
};

CustomCard.propTypes = {
  profileImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  headerDescriptionFirst: PropTypes.string,
  headerDescriptionSecond: PropTypes.string,
  hasActions: PropTypes.bool,
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CustomCard;
