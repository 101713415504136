export const companyOfferSelector = ({ offerList }) => {
  const { offers } = offerList;

  return offers;
};


export const WorkerOfferSelector = ({ offerList }) => {
  const { offers } = offerList;

  return offers;
};
