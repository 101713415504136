import styled from '@emotion/styled';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';

import { APP_RED, DARK_BLUE_FOOTER, WHITE } from 'constants/colors';

const StyledTopHeaderWrapper = styled('div')`
  width: 100%;
`;

const StyledActionBarWrapper = styled(Dropdown)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;

  img {
    width: 32px;
    height: 32px;
    border: 1px solid ${APP_RED};
    border-radius: 50%;
    margin-right: 10px;
  }

  p {
    text-align: center;
    font: normal normal medium 14px/28px Lato;
    color: ${WHITE};
    margin: 0 10px 0 0;
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 32px;
  background-color: ${DARK_BLUE_FOOTER};
  color: ${WHITE};

  :hover {
    color: ${WHITE};
    background-color: ${DARK_BLUE_FOOTER};
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &.dropdown-menu.show {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: ${DARK_BLUE_FOOTER};
    color: ${WHITE};
    padding: 15px 15px;

    & a {
      text-decoration: none;
      color: ${WHITE};
      margin: 5px 0;

      :hover {
        color: ${APP_RED};
      }
    }
  }
`;

const SignOutMenuItem = styled('div')`
  cursor: pointer;

  :hover {
    color: ${APP_RED};
  }
`;

const StyledLine = styled('div')`
  width: 100%;
  height: 1px;
  background: rgb(212 212 216 / 30%);
  position: relative;

  & > div {
    position: absolute;
    width: 37px;
    height: 4px;
    background: ${APP_RED};
    left: 25px;
    top: -2px;
  }
`;

const StyledTitle = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 25px;

  h1 {
    text-align: left;
    font: normal normal 700 34px/53px Lato;
    letter-spacing: 0px;
    color: #26282d;
    margin: 0;
    margin-top: 16px;
  }
`;

export {
  StyledTopHeaderWrapper,
  StyledActionBarWrapper,
  StyledDropdownToggle,
  StyledDropdownMenu,
  SignOutMenuItem,
  StyledLine,
  StyledTitle
};
