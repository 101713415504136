import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { useSearchParams } from "react-router-dom";
import RegisterPrivacyPolicy from './components/RegisterPrivacyPolicy'
import RegisterAGBs from './components/RegisterAGBs'
import EditProject from 'views/companyPage/EditProject';
import EditVacancy from 'views/companyPage/EditVacancy';
import PrivateRoute from 'components/PrivateRoute';
import Profile from 'views/employeePage/Profile';
import CompanyProfile from 'views/companyPage/Profile';
import EditProfile from 'views/employeePage/EditProfile';
import EditCompanyProfile from 'views/companyPage/EditProfile';
import Employment from 'views/employeePage/Employment';
import ApplicationsEmployee from 'views/employeePage/Applications';
import ApplicationsCompany from 'views/companyPage/Applications';
import FavoritesEmployee from 'views/employeePage/Favorites';
import FavoritesCompany from 'views/companyPage/Favorites';
import Blacklist from 'views/companyPage/Blacklist';
import WeeklyReportEmployee from 'views/employeePage/WeeklyReports';
import WeeklyReportCompany from 'views/companyPage/WeeklyReports';
import LoginDialog from 'components/LoginDialog';
import RegisterDialog from 'components/RegisterDialog';
import SearchEmployee from 'views/employeePage/Search';
import SearchCompany from 'views/companyPage/Search';
import Loading from 'components/Loading';
import Subscription from 'views/companyPage/Subscription';
import Projects from 'views/companyPage/Projects';
import Vacancies from 'views/companyPage/Vacancies';
import { authSelector } from 'redux/modules/auth/selectors';
import { loaderSelector } from 'redux/modules/loader/selectors';
import 'assets/fontawesome/index';
import SuspenseSpinner from 'components/SuspenseSpinner';
import CustomToast from 'components/CustomToast';
import { toastSelector } from 'redux/modules/toast/selector';
import { resetToast } from 'redux/modules/toast/reducer';
import Offers from 'views/companyPage/Offers';
import WorkerOffers from 'views/employeePage/Offers';
import ForgetPassDialog from 'components/ForgetPassDiaglog';
import ResetPassDialog from 'components/ResetPassDialog';
import { openDialog } from 'redux/modules/dialogs/reducer';

const HomePage = lazy(() => import('views/landingPage/Main'));
const AboutPage = lazy(() => import('views/landingPage/About'));
const ContactUsPage = lazy(() => import('views/landingPage/ContactUs'));
const FunctionPage = lazy(() => import('views/landingPage/Function'));
const IndustriesPage = lazy(() => import('views/landingPage/Industries'));
const PricingPage = lazy(() => import('views/landingPage/Pricing'));
const AGBPage = lazy(() => import('views/landingPage/AGB'));
const CareerPage = lazy(() => import('views/landingPage/Career'));
const ImpressumPage = lazy(() => import('views/landingPage/Impressum'));

const App = ({ history }) => {
  const dispatch = useDispatch();
  const isAuthenticatedSelector = Boolean(useSelector(authSelector));
  const isLoadingSelector = useSelector(loaderSelector);
  const toastState = useSelector(toastSelector);
  // const [searchParams] = useSearchParams();
  useEffect(() => {
    const url = history.location.search;
    const query = new URLSearchParams(url);
    // console.log('here is query', query);
    const isCode = query.get('code');
    // console.log('here is isCode', isCode);
    if (isCode) {
      dispatch(
        openDialog({
          dialogName: 'resetPassDialog'
        })
      );
    }
  }, [history?.location]);

  return (
    <ConnectedRouter history={history}>
      <Loading isLoading={isLoadingSelector} />
      <CustomToast isOpen={toastState.isOpen} type={toastState.type} message={toastState.message} delay={toastState.delay} closeToast={() => dispatch(resetToast())} />
      <Suspense fallback={<SuspenseSpinner />}>
        <Switch>
          <Route exact path="/" component={HomePage} key="LANDING_MAIN_PAGE" />
          <Route exact path="/about" component={AboutPage} key="LANDING_ABOUT_PAGE" />
          <Route exact path="/contact-us" component={ContactUsPage} key="LANDING_CONTACT_US_PAGE" />
          <Route exact path="/function" component={FunctionPage} key="LANDING_FUNCTION_PAGE" />
          <Route exact path="/all-industries" component={IndustriesPage} key="LANDING_INDUSTRIES_PAGE" />
          <Route exact path="/pricing" component={PricingPage} key="LANDING_PRICING_PAGE" />
          <Route exact path="/agb" component={AGBPage} key="LANDING_AGB_PAGE" />
          <Route exact path="/career" component={CareerPage} key="LANDING_CAREER_PAGE" />
          <Route exact path="/impressum" component={ImpressumPage} key="LANDING_IMPRESSUM_PAGE" />

          <PrivateRoute exact path="/worker/search" component={SearchEmployee} key="EMPLOYEE_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/profile" component={Profile} key="PROFILE_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/:workerId/profile/:user" component={EditProfile} key="PROFILE_PAGE_EDIT" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/employment" component={Employment} key="EMPLOYMENT_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/offers" component={WorkerOffers} key="EMPLOYMENT_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/applications" component={ApplicationsEmployee} key="APPLICATIONS_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/favorites" component={FavoritesEmployee} key="FAVORITES_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/worker/weekly-report" component={WeeklyReportEmployee} key="WEEKLY_REPORT_PAGE" isAuthenticated={isAuthenticatedSelector} />

          <PrivateRoute exact path="/company/search" component={SearchCompany} key="COMPANY_SEARCH_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/favorites" component={FavoritesCompany} key="COMPANY_FAVORITES_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/weekly-report" component={WeeklyReportCompany} key="COMPANY_WEEKLY_REPORT_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/applications" component={ApplicationsCompany} key="COMPANY_APPLICATIONS_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/offers" component={Offers} key="COMPANY_OFFERS_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/subscription" component={Subscription} key="COMPANY_SUBSCRIPTION_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/profile" component={CompanyProfile} key="COMPANY_PROFILE_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/blacklist" component={Blacklist} key="COMPANY_PROFILE_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute
            exact
            path="/company/:companyId/profile/:user"
            component={EditCompanyProfile}
            key="COMPANY_EDIT_PROFILE_PAGE"
            isAuthenticated={isAuthenticatedSelector}
          />
          <PrivateRoute exact path="/company/projects" component={Projects} key="COMPANY_PROJECTS_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/vancancies/edit/:jobId" component={EditVacancy} key="COMPANY_VACANCIES_EDIT_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/vancancies/new" component={EditVacancy} key="COMPANY_VACANCIES_NEW_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/projects/edit/:projectId" component={EditProject} key="COMPANY_PROJECTS_EDIT_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/projects/new" component={EditProject} key="COMPANY_PROJECTS_NEW_PAGE" isAuthenticated={isAuthenticatedSelector} />
          <PrivateRoute exact path="/company/vacancies" component={Vacancies} key="COMPANY_VACANCIES_PAGE" isAuthenticated={isAuthenticatedSelector} />
        </Switch>
        <LoginDialog history={history} />
        <ForgetPassDialog history={history} />
        <ResetPassDialog history={history} />
        <RegisterDialog />
        <RegisterPrivacyPolicy />
        <RegisterAGBs />
      </Suspense>
    </ConnectedRouter>
  );
};

export default App;
