import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';

import { INPUT_TEXT_DARK_BLUE, SUBTLE_RED, INPUT_BORDER, WHITE, LIGHT_BLUE_PAGE_BG, APP_RED, DARK_BLUE, DARK_BUTTON, LIGHTER_GRAY } from 'constants/colors';

const parameters = 'solid 0px';

const StyledButtonFirst = styled.div`
  padding-bottom: 0px;
`;

const StyledButtonSecond = styled.div`
  padding-bottom: 5px;
`;

const StyledRowButtons = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1400px) {
    /* flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px; */
  }
  @media (max-width: ${breakpoints.md}) {
    /* flex-direction: row;
  justify-content: center;
  padding-bottom: 10px; */
  }
`;

const StyledCard = styled(Card)`
  background-color: ${WHITE};
  color: ${INPUT_TEXT_DARK_BLUE};
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;
  /* padding-top: 10px; */
  border: ${parameters} red;
  /* ${({ active }) => (active ? `border: 2px solid ${APP_RED}` : '')} */
  @media (min-width: 1600px) {
    height: 300px;
  }
  @media (max-width: 1440px) {
    height: 380px;
    padding-top: 30px;
  }
  /* @media (max-width: 900px) {
    height: 450x;
    padding-left: 10px;
padding-right: 10px;
  } */
  // before 1400px
  /* @media (min-width: 768px) and (max-width: 1440px) {
    height: 330px;
  }
  @media (min-width: 420px) and (max-width: 1000px) {
    height: 330px;
    padding-top: 5px;

  } */
  /* @media (min-width: 1px) and (max-width: 419px) {
    height: 300px;
    padding-top: 5px;
  } */
`;

const StyledProjectsButtonRow = styled('div')`
  display: flex;
  justify-content: center;
  border: ${parameters} brown;
`;

const StyledFooterBottom = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 120px;
  border: ${parameters} green;
  /* max-height: 100px; */
  /* green  border: 3px solid light; */
  /* ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`} */

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  p {
    /* overflow-y: scroll; */
    overflow-x: hidden;
    box-sizing: border-box;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      height: 120px;
    }
    /* scroll bar vertical height */
    display: -webkit-box;
    -webkit-line-clamp: 50;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};

    @media (max-width: 1390px) {
      -webkit-line-clamp: 5;
    }

    /* @media (min-width: 1441px) {
      -webkit-line-clamp: 4;
    } */
    /* @media (min-width: 800px) {
      -webkit-line-clamp: 3;
    } */
  }
`;

const StyledBottomCardDescription = styled('div')`
  display: flex;
  padding-top: 10px;
  border: ${parameters} darkred;
`;

const StyledCardRowWrapperCol = styled('div')`
  display: flex;
  max-width: 350px;

  border: ${parameters} darkred;
  flex-direction: column;
  @media (max-width: 1840px) {
    flex-direction: column;
    max-height: 220px;
  }

  @media (max-width: 900px) {
    padding-left: 10px;
  }
  @media (min-width: 1700px) {
    margin-top: 20px;
  }
`;

const StyledCardBodyRight = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    border: none;
    background: #fff;
    box-sizing: border-box;
    font-size: 80%;
    padding: 1px 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: box-shadow 0.2s ease-in-out;
    width: 80%;
  }

  button {
    border-radius: 4px;
  }

  .delete-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .edit-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }
  /* border-top: 1px solid ${INPUT_BORDER}; */
`;

// grey horizontal line border
const StyledCardHeader = styled(Card.Header)`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  min-height: 150px;
  height: 220px;
  /* max-height: 200px; */
  /* flex-wrap: wrap; */
  /* margin-top: 40px; */
  justify-content: center;

  /* border: ${parameters} purple; */
  /*   
  border: ${parameters} purple; */
  @media (min-width: 1440px) {
    height: 360px;
    padding-top: 20px;
    margin-top: -20px;
  }
  @media (max-width: ${breakpoints.xxl}) {
    max-height: 320px;
    padding-top: 20px;
  }
  @media (max-width: 1000px) {
    height: 320px;
    padding-top: 0px;
  }
`;

const StyledCardHeaderImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  font-family: 'Lato Regular';
  border: ${parameters} green;

  @media (max-width: 1440px) {
    flex-direction: column;
  }
  @media (min-width: 1900px) {
    flex-direction: row;
  }

  /* @media (max-height: 1440px) {
  flex-direction:column;
  
  }
  
  @media (max-height: 900px) {
height: 300px;
  } */
`;

const StyledImageDescriptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  border: ${parameters} orange;
  /* overflow-y: hidden; */
  /* overflow-x: scroll; */

  gap: 1rem;
  font-family: 'Lato Regular';
  /* &::-webkit-scrollbar {
    height: 0.3rem;
    background-color: transparent;
    border-radius: 5px;
  } */
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    // height: 2px;
    // width: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: horizontal;
`;

const CardName = styled.div`
  max-width: 300px;
  display: flex;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  /* height: 100px; */
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* border: ${parameters} lightgreen; */
  @media (max-width: 750px) {
    display: flex;
    justify-content: center;
  }
`;

const StyledCardHeaderImage = styled(Card.Img)`
  border-radius: 50%;
  /* width: 70px;
  height: 70px; */
  width: 90px;
  height: 90px;

  background: ${LIGHT_BLUE_PAGE_BG};
  border: ${parameters} brown;
  @media (max-width: 750px) {
    margin-right: 0px;
  }
  @media (min-width: 1700px) {
    margin-right: 20px;
  }
`;

// const StyledCardHeaderImageDescription = styled('div')`
//   display: flex;
//   font-size: 15px;
//   flex-direction: column;
// `;

const StyledCardHeaderImageDescription = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  border: ${parameters} pink;
  @media (max-width: ${breakpoints.lg}) {
    /* flex-direction: row;
    flex-wrap: wrap; */
    justify-content: flex-start;
    /* align-items: center; */
  }
`;

const StyledCardHeaderMiddle = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  height: 150px;
  border: ${parameters} orange;
  @media (max-width: ${breakpoints.lg}) {
    /* flex-direction: row; */
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 140px;

    /* align-items: center; */
  }
`;

const StyledCardHeaderBottomImageDescription = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-top: 5px;
  border: ${parameters} green;
  /* margin-bottom: 100px; */

  @media (max-width: 1440px) {
    /* flex-direction: row;
    flex-wrap: wrap; */
    padding-top: 0cqw;
    padding-bottom: 20px;
  }

  @media (max-width: 767px) {
    /* flex-direction: row;
    flex-wrap: wrap; */
    align-items: center;
  }
`;

const StyledCardHeaderImageDescriptionRight = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-bottom: 10px;
  border: ${parameters} grey;
`;

const StyledCardHeaderImageDescriptionRightFrontPage = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-top: 25px;
`;

// yellow
const StyledCardHeaderImageDescriptionFirst = styled('span')`
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 1000;
  /* max-height: 200px; */
  width: 300px;
  border: ${parameters} yellow;
  overflow-wrap: break-word;
  height: 30px;
  @media (max-width: 768px) {
    align-items: flex-start;
    max-height: none;
    max-width: 230px;
  }

  @media (max-width: 1440px) {
    /* align-items: center; */
    /* max-height: none; */
    width: 300px;
  }
`;

const StyledCardHeaderCol = styled('div')`
  display: flex;
  border: ${parameters} brown;
`;

const StyledCardHeaderBellowImage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
`;

const StyledCardFrontPage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
  border: ${parameters} lightgreen;
  @media (max-width: 750px) {
    padding-top: 0px;
    padding-left: 30px;
    align-items: center;
  }
`;

const StyledCardFrontBottom = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
  border: ${parameters} darkgreen;
  @media (max-width: ${breakpoints.xxl}) {
    padding-top: 0px;
    padding-bottom: 10px;
    align-items: center;
  }
`;
const StyledCardAppFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 15px;

  border: ${parameters} lightgreen;
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}
  @media (max-width: ${breakpoints.lg}) {
    padding-bottom: 0px;
  }

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  button:nth-of-type(1) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
  }

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding-bottom: 25px; */
  border: ${parameters} lightgreen;
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  /* button:nth-of-type(1) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
  } */

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }

  p {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      height: 120px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};
  }
`;

const StyledIconWrapper = styled('div')`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  /* margin-top: -40px; */
  /* justify-content: flex-end; */
  /* bottom: 28px; */
  /* align-self: flex-start; */
  /* padding-top: 30px; */
  top: 17px;
  right: 17px;

  border: ${parameters} red;
  @media (max-width: 1440px) {
    /* margin-top: -10px; */
    /* margin-bottom: 10px; */
  }
`;

const StyledSmallText = styled('div')`
  display: flex;
  padding-top: 10px;
`;

export {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardHeaderImageDescriptionRightFrontPage,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardHeaderBottomImageDescription,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledRowButtons,
  StyledButtonFirst,
  StyledButtonSecond,
  StyledCardHeaderMiddle,
  StyledSmallText
};
