export default [
  {
    code: 'en',
    name: 'English',
    coutry_code: 'gb'
  },
  {
    code: 'de',
    name: 'Deutsch',
    coutry_code: 'de'
  },
  {
    code: 'fr',
    name: 'Français',
    coutry_code: 'fr'
  },
  {
    code: 'it',
    name: 'Italiano',
    coutry_code: 'it'
  }
];
