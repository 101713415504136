import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';
import {
  companyApplications,
  companyJobs,
  countJobs,
  createJob,
  fetchJobsList,
  updateApplication,
  updateJob,
  deleteJob,
  workerApplications
} from 'redux/modules/jobs/asyncThunks';

const initialState = {
  jobs: [],
  applications: [],
  jobsCount: 0,
  status: ASYNC_STATUS.IDLE
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: {
    [fetchJobsList.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [fetchJobsList.fulfilled]: (state, { payload }) => {
      state.jobs = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [countJobs.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [countJobs.fulfilled]: (state, { payload }) => {
      state.jobsCount = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [companyJobs.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [companyJobs.fulfilled]: state => {
      state.status = ASYNC_STATUS.IDLE;
    },
    [workerApplications.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [workerApplications.fulfilled]: state => {
      state.status = ASYNC_STATUS.IDLE;
    },
    [companyApplications.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [companyApplications.fulfilled]: (state, { payload }) => {
      state.applications = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateJob.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateJob.fulfilled]: (state, { payload }) => {
      console.log('updateJob.fulfilled action dispatched with payload:');
      const existIndex = state.jobs.findIndex(j => j.id === payload.id);
      if (existIndex > -1) {
        state.jobs.splice(existIndex, 1);
        state.jobs = [payload, ...state.jobs];
      } else {
        state.jobs = [payload, ...state.jobs];
      }
      state.status = ASYNC_STATUS.IDLE;
    },
    [deleteJob.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [deleteJob.fulfilled]: (state, { payload }) => {
      state.jobs = state.jobs.filter(job => job.id !== payload.id);
      state.applications = state.applications.filter(apps => apps.job.id !== payload.id);
      state.status = ASYNC_STATUS.IDLE;
    },
    [createJob.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [createJob.fulfilled]: (state, { payload }) => {
      state.jobs = [payload, ...state.jobs];
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateApplication.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },

    // [updateApplication.fulfilled]: (state, { payload }) => {
    //   state.applications = state.applications.map(application =>
    //     application.id === payload.id ? payload : application
    //   );
    //   state.status = ASYNC_STATUS.IDLE;
    // }
    
    [updateApplication.fulfilled]: (state, { payload }) => {
      const existIndex = state.applications.findIndex(a => a.id === payload.id);

      if (existIndex > -1) {
        const freshApplicationsList = [...state.applications];
        freshApplicationsList.splice(existIndex, 1);
        state.applications = [payload, ...freshApplicationsList];
      } else {
        state.applications = [payload, ...state.applications];
      }
      state.status = ASYNC_STATUS.IDLE;
    }
  }
});

export default jobsSlice.reducer;
