/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FarStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as FaStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import { APP_RED, WHITE, DARK_BUTTON, DARK_BLUE } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderBottomImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledImageWrapper,
  StyledTopSmallTextContainer,
  StyledTopSmallTextBottom,
  StyledButtonLeftSpace,
  StyledButtonRow,
  StyledWrapperBottons
} from 'components/CustomCarApplications/styles';

// eslint-disable-next-line complexity
const CustomCarApplications = ({
  headerDescriptionEmail,
  headerDescriptionPhone,
  favCompanyDescription,
  profileImage,
  sectionTitle,
  sectionSubtitle,
  handleActiveDeactivateJob,
  upperButtonText,
  lowerButtonText,
  handleDeleteJob,
  handleEditJob,
  editButtonText,
  headerDescriptionFirst,
  headerDescriptionSecond,
  headerDescriptionThird,
  headerDescriptionFourth,
  headerDescriptionFifth,
  headerDescriptionExtra,
  headerDescriptionFirstRight,
  headerDescriptionSecondRight,
  headerDescriptionThirdRight,
  footerDescription,
  profileClick,
  favoriteIcon,
  isFavorite,
  onFavoriteClick,
  isActive = 'true',
  click,
  hasActions,
  handleOnItemClick,
  handleOnItemClickReject,
  handleOnItemClickSeeJob,
  acceptText,
  rejectText,
  onApplyClick,
  onAddRemoveClick,
  plusMinusSign,
  footerMinHeight,
  deadline,
  headerDescriptionPayrollMonthly,
  headerDescriptionFourthFrontPage,
  viewJobText

  // onApplyButton
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  // console.log(footerDescription);

  // const footerMinHeight = {
  //   minHeight: '6rem'
  // };

  return (
    <StyledCard onClick={click} active={isActive}>
      <StyledCardHeader>
        <StyledIconWrapper>
          {favoriteIcon &&
            (isFavorite ? (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FaStar} color={APP_RED} fontSize="19px" />
            ) : (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FarStar} color={APP_RED} fontSize="19px" />
            ))}
        </StyledIconWrapper>
        <StyledCardHeaderImageWrapper>
          <StyledImageWrapper>
            <StyledCardHeaderImage onClick={profileClick} src={profileImage} />{' '}
          </StyledImageWrapper>
          {/* <StyledCardRowWrapperCol> */}

          {/* 
              /// yellow */}
          <StyledTopSmallTextContainer>
            <StyledCardHeaderImageDescriptionFirst>
              {/* <CardName> */}
              {sectionTitle.slice(0, 25)}
              {sectionTitle.length > 25 ? '...' : ''}
              {/* </CardName> */}
            </StyledCardHeaderImageDescriptionFirst>
            <StyledTopSmallTextBottom>
              {/* <StyledCardHeaderImageDescription> */}
              <span onClick={profileClick}>
                {sectionSubtitle && sectionSubtitle.slice(0, 30)}
                {sectionSubtitle && sectionSubtitle.length > 29? '...' : ''}
              </span>
              {headerDescriptionThird && headerDescriptionThird.slice(0, 32)}
              {headerDescriptionThird && headerDescriptionThird.toString().length > 32 ? '...' : ''}
              <br></br>
              {headerDescriptionFifth && headerDescriptionFifth.toString().slice(0, 6)}
              {headerDescriptionFifth && headerDescriptionFifth.toString().length > 6 ? '...' : ''}
              {headerDescriptionFirst && headerDescriptionFirst.slice(0, 31)}
              {headerDescriptionFirst && headerDescriptionFirst.length > 31 ? '...' : ''}
              {headerDescriptionSecond && headerDescriptionSecond.slice(0, 15)} {headerDescriptionSecond && headerDescriptionSecond.length > 15 ? '...' : ''}
              {headerDescriptionExtra && headerDescriptionExtra}
              {/* {headerDescriptionThird && headerDescriptionThird.slice(0, 10)} {headerDescriptionThird && headerDescriptionThird.length > 10 ? '...' : ''} */}
              {headerDescriptionPhone && <span>Phone: {headerDescriptionPhone}, &nbsp;</span>}
              {headerDescriptionEmail && <span>Email: {headerDescriptionEmail}</span>}
              {/* </StyledCardHeaderImageDescription> */}
              {(headerDescriptionFirstRight || headerDescriptionSecondRight || headerDescriptionThirdRight) && (
                <div>
                  <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionFirstRight}</span>
                  <br></br>
                  <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionSecondRight}</span>
                  {headerDescriptionThirdRight && <span style={{ whiteSpace: 'nowrap' }}>, Kat. {headerDescriptionThirdRight}</span>}
                </div>
              )}
            </StyledTopSmallTextBottom>
          </StyledTopSmallTextContainer>
          {headerDescriptionPayrollMonthly && (
            <StyledCardHeaderImageDescription>
              <StyledCardFrontPage>
                <span>
                  {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}
                </span>
                {deadline && (
                  <p style={footerMinHeight}>
                    {t('OFFERCARD.startDate')}: {deadline}
                  </p>
                )}
              </StyledCardFrontPage>
            </StyledCardHeaderImageDescription>
          )}
          {/* </StyledCardRowWrapperCol> */}
        </StyledCardHeaderImageWrapper>
      </StyledCardHeader>
      {headerDescriptionFourthFrontPage && (
        <StyledCardFrontBottom>
          <span>{headerDescriptionFourthFrontPage}</span>
        </StyledCardFrontBottom>
      )}
      {headerDescriptionFourth && (
        <StyledCardHeaderBellowImage>
          <span>{headerDescriptionFourth}</span>
        </StyledCardHeaderBellowImage>
      )}
      {/* <StyledCardFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {favCompanyDescription && <p style={footerMinHeight}>{favCompanyDescription}</p>}
      </StyledCardFooter> */}
      {/* <StyledFooterBottom style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {footerDescription && <p style={footerMinHeight}>{footerDescription}</p>}
      </StyledFooterBottom> */}
      {hasActions && (
        <>
          <StyledProjectsButtonRow>
            {onApplyClick && <CustomButton text={t('BUTTON.applyNow')} customTextColor={WHITE} customWidth="120px" handleOnClick={onApplyClick} />}
          </StyledProjectsButtonRow>
          <StyledProjectsButtonRow>
            {onAddRemoveClick && <CustomButton text={plusMinusSign} customTextColor={WHITE} customWidth="120px" handleOnClick={onAddRemoveClick} />}
          </StyledProjectsButtonRow>
          <StyledCardAppFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {(handleOnItemClick || handleOnItemClickReject) && (
              <div>
                {' '}
               {width > 900 ? <StyledWrapperBottons>
                  <StyledButtonRow>
                    <CustomButton
                      text="Ablehnen"
                      custombuttoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={DARK_BLUE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClickReject}
                    />
                    <div style={{ width: '10px' }}></div>
                    <CustomButton
                      text="Job Details"
                      custombuttoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={DARK_BLUE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClickSeeJob}
                    />
                    <div style={{ width: '10px' }}></div>
                    <CustomButton
                      text="Annehmen"
                      customButtoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={WHITE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClick}
                    />
                  </StyledButtonRow>
                </StyledWrapperBottons>: ""}
                {width < 900 ? <StyledWrapperBottons>
                  <StyledButtonRow>
                    <CustomButton
                      text="Ablehnen"
                      custombuttoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={DARK_BLUE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClickReject}
                    />
                    {/* <div style={{ width: '3px' }}></div> */}
                    <CustomButton
                      text="Job Details"
                      custombuttoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={DARK_BLUE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClickSeeJob}
                    />
                    {/* <div style={{ width: '3px' }}></div> */}
                    <CustomButton
                      text="Annehmen"
                      customButtoncolor={WHITE}
                      customHeight="45px"
                      customWidth="100px"
                      customTextColor={WHITE}
                      custombordercolor={APP_RED}
                      //  hasIcon
                      //  icon="pen"
                      iconFontSize="15px"
                      customFontSize="14px"
                      handleOnClick={handleOnItemClick}
                    />
                  </StyledButtonRow>
                </StyledWrapperBottons>: ""}
              </div>

              // <div className="actions-wrapper">
              //   {handleOnItemClick && (
              //      <div className='first-button'>
              //     <button type="button" onClick={handleOnItemClickReject}>
              //       {rejectText || 'Close'}
              //     </button>
              //     </div>
              //   )}
              //   {handleOnItemClick && (
              //      <div className='second-button'>
              //     <button type="button" onClick={handleOnItemClick}>
              //       {acceptText || 'Open'}
              //     </button>
              //     </div>
              //   )}
              //     <div className='third-button'>
              //     <button type="button" onClick={handleOnItemClickSeeJob}>
              //       {viewJobText}
              //     </button>
              //     </div>
              // </div>
            )}
          </StyledCardAppFooter>
        </>
      )}
      <Row>
        <Col>
          <StyledCardBodyRight>
            {upperButtonText && (
              <button className="edit-button" type="button" onClick={handleActiveDeactivateJob}>
                {upperButtonText}
              </button>
            )}
            {lowerButtonText && (
              <button className="delete-button" type="button" onClick={handleDeleteJob}>
                {lowerButtonText}
              </button>
            )}
            {editButtonText && (
              <button className="edit-button" type="button" onClick={handleEditJob}>
                {editButtonText}
              </button>
            )}
          </StyledCardBodyRight>
        </Col>
      </Row>
    </StyledCard>
  );
};

CustomCarApplications.defaultProps = {
  profileImage: null,
  sectionTitle: '',
  headerDescriptionFirst: null,
  headerDescriptionSecond: null,
  hasActions: false,
  sectionSubtitle: null,
  isActive: false
};

CustomCarApplications.propTypes = {
  profileImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  headerDescriptionFirst: PropTypes.string,
  headerDescriptionSecond: PropTypes.string,
  hasActions: PropTypes.bool,
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CustomCarApplications;
