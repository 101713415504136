import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageHeaderTitle from 'components/LandingPageHeaderTitle';
import { Modal, Button } from 'react-bootstrap';

import { closeDialog } from 'redux/modules/dialogs/reducer';
import { openDialog } from 'redux/modules/dialogs/reducer';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import CustomButton from 'components/CustomButton';

import { WHITE } from 'constants/colors';
import { Footer, RegisterDialogContainer, RegisterDialogWrapper, StyledPricingPlanWrapper, Title, List, ListItem, SubList, SubListItem, StyledCloseIcon } from './styles';

const RegisterAGBs = () => {
  const { registerAGBsOpened } = useSelector(dialogSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeDialogAGBs = () => {
    dispatch(closeDialog({ dialogName: 'registerAGBs' }));
    handleOpenRegisterDialog();
  };

  const handleOpenRegisterDialog = () => {
    dispatch(
      openDialog({
        dialogName: 'registerDialog'
      })
    );
  };

  const renderAGBTop = () => (
    <>
      <Title>Bossasto GmbH - Inhaltsverzeichnis</Title>
      <List>
        <ListItem>
          <a href="#geltungsbereich">Geltungsbereich</a>
        </ListItem>
        <ListItem>
          <a href="#zustimmung-agb">Zustimmung zu den AGB</a>
        </ListItem>
        <ListItem>
          <a href="#bossasto-system">Das Bossasto-System</a>
        </ListItem>
        <ListItem>
          <a href="#datenschutz-cookie-richtlinie">Datenschutz und Cookie Richtlinie</a>
        </ListItem>
        <ListItem>
          <a href="#vertragsinhalt">Vertragsinhalt</a>
          <SubList>
            <SubListItem>
              <a href="#registrierung-bossasto-usern">Registrierung von Bossasto-Usern</a>
            </SubListItem>
            <SubListItem>
              <a href="#vertragliche-leistungen">Vertragliche Leistungen</a>
              <SubList>
                <SubListItem>
                  <a href="#arbeitnehmer">Arbeitnehmer</a>
                </SubListItem>
                <SubListItem>
                  <a href="#arbeitgeber">Arbeitgeber</a>
                </SubListItem>
              </SubList>
            </SubListItem>
            <SubListItem>
              <a href="#gewährleistungsausschluss">Gewährleistungsausschluss</a>
            </SubListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <a href="#verweise-links">Verweise und Links zu anderen Webseiten</a>
        </ListItem>
        <ListItem>
          <a href="#sperrung-zugriff">Sperrung des Zugriffs auf die Webseite oder die App</a>
        </ListItem>
        <ListItem>
          <a href="#preise-zahlungsbedingungen">Preise und Zahlungsbedingungen</a>
          <SubList>
            <SubListItem>
              <a href="#arbeitnehmer-preise">Arbeitnehmer</a>
            </SubListItem>
            <SubListItem>
              <a href="#arbeitgeber-preise">Arbeitgeber</a>
            </SubListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <a href="#Laufzeit-links">Laufzeit und Kündigung</a>
          <SubList>
            <SubListItem>
              <a href="#arbeitnehmer">Arbeitnehmer</a>
            </SubListItem>
            <SubListItem>
              <a href="#arbeitgeber">Arbeitgeber</a>
            </SubListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <a href="#dienstleistungserbringung">Dienstleistungserbringung</a>
        </ListItem>
        <ListItem>
          <a href="#veröffentlichen-von-inhalten-und-kontaktieren-von-anderen-benutzern">Veröffentlichen von Inhalten und Kontaktieren von anderen Benutzern</a>
        </ListItem>
        <ListItem>
          <a href="#zulässige-nutzung-allgemein-für-arbeitgeber-und-arbeitnehmer">Zulässige Nutzung (allgemein für Arbeitgeber und Arbeitnehmer)</a>
        </ListItem>
        <ListItem>
          <a href="#informationspflicht">Informationspflicht</a>
        </ListItem>
        <ListItem>
          <a href="#registrierung-und-geheimhaltung">Registrierung und Geheimhaltung</a>
        </ListItem>

        <ListItem>
          <a href="#datenschutz">Datenschutz</a>
        </ListItem>
        <ListItem>
          <a href="#immaterialgüterrechte">Immaterialgüterrechte</a>
        </ListItem>
        <ListItem>
          <a href="#cookie-richtlinie-von-bossasto">COOKIE-RICHTLINIE VON BOSSASTO</a>
        </ListItem>
        <ListItem>
          <a href="#zustimmung">ZUSTIMMUNG</a>
        </ListItem>
        <ListItem>
          <a href="#cookies-deaktivieren">COOKIES DEAKTIVIEREN</a>
        </ListItem>
        <ListItem>
          <a href="#vertragsänderungen">Vertragsänderungen</a>
        </ListItem>
        <ListItem>
          <a href="#abtretung">Abtretung</a>
        </ListItem>
        <ListItem>
          <a href="#salvatorische-klausel">Salvatorische Klausel</a>
        </ListItem>
        <ListItem>
          <a href="#vertraulichkeit">Vertraulichkeit</a>
        </ListItem>
        <ListItem>
          <a href="#schriftform">Schriftform</a>
        </ListItem>
        <ListItem>
          <a href="#anwendbares-recht">Anwendbares Recht</a>
        </ListItem>
        <ListItem>
          <a href="#gerichtsstand">Gerichtsstand</a>
        </ListItem>
      </List>

      <h3 id="geltungsbereich">1. Geltungsbereich</h3>
      <p>
        Diese Allgemeinen Geschäftsbedingungen (nachfolgend “AGB“) gelten für den gesamten Geschäftsbereich der Bossasto GmbH sowie der mit ihren verbundenen
        Gesellschaften und Zweigniederlassungen (nachfolgend “Bossasto“). Bossasto erstellt den ersten Kontakt zwischen Bewerber (nachfolgend “Arbeitnehmer“) und
        Unternehmen (nachfolgend “Arbeitgeber“) in der Schweiz und bietet den Arbeitnehmern kostenlose sowie unverbindliche Unterstützungsleistungen an. Bossasto ist eine
        online Karriereplattform für private Nutzer („Arbeitnehmer“) und für Firmen („Arbeitgeber“). Gilt eine Regelung für beide Parteien, so wird „Bossasto-User“
        erwähnt.
      </p>
      <p id="zustimmung-agb">
        Gegenstand dieser AGB ist das Rechtsverhältnis zwischen Bossasto GmbH („Bossasto“) und den Bossasto-Usern, welche die von Bossasto angebotene Dienstleistung auf
        der Plattforme nutzen. Die Plattform von Bossasto lautet <a href="https://www.bossasto.ch/">www.bossasto.ch</a> oder{' '}
        <a href="https://www.bossasto.com/">www.bossasto.com</a>. Nachfolgend werden sämtliche Dienstleistungen und Produkte von Bossasto für Arbeitgeber und für
        Arbeitnehmer gemeinsam „Bossasto User“ genannt. Diese AGB gilt für jede Nutzung der Bossasto-Dienste. Die Arbeitnehmer sind nicht bei Bossasto GmbH angestellt,
        auch wenn Sie ein Angebot von den Arbeitgebern annehmen. Die Arbeitnehmer müssen mit den Arbeitgebern direkt einen Arbeitsvertrag vereinbaren und sind dadurch bei
        den Arbeitgebern direkt angestellt (Stundenlohn oder Monatslohn).
      </p>
      <h3 id="bossasto-system">2. Zustimmung zu den AGB</h3>
      <p>
        Mit der Nutzung der Bossasto-Dienste via Bossasto-Web erklärt sich der Bossasto-User mit der Geltung der nachfolgender AGB einverstanden. Bossasto-User können
        zusätzlich bei Nutzung der Bossasto-Dienste von Bossasto aufgefordert werden, ihre Zustimmung zu dieser AGB mittels Aktivierens eines entsprechenden Eingabefeldes
        zu wieder anzunehmen.
      </p>
      <h3>3. Das Bossasto-System</h3>
      <p>
        Bossasto ist eine Arbeitsvermittlungsplattform und verfügt über die notwendige Arbeitsvermittlerbewilligung. Über die von Bossasto betriebene Webseite
        www.bossasto.ch (nachfolgend "bosssato.ch") sowie die Bossasto App (nachfolgend "Bossasto-App") bringt Bossasto Arbeitnehmer (der Einfachheit halber wird jeweils
        nur die männliche Form verwendet; die weibliche ist darin eingeschlossen) und Arbeitgeber zusammen. Das Zusammenführen von Arbeitnehmern mit Arbeitgebern erfolgt
        weitgehend automatisiert. Die Nutzung von bossasto.ch oder der Bossasto-App ist für Arbeitnehmer kostenlos. Jedoch stehen für die Arbeitgeber 3 Monatsabos zur
        Auswahl. Diese werden per Kreditkarte abgebucht aber die Abos erden nicht automatisch verlängert. Bevor ein Arbeitgeber überhaupt bossasto.ch oder die
        Bossasto-App nutzen kann, muss Bossasto selbst den Arbeitgeber freischalten und ihm die Erlaubnis geben, die Funktionen von bossasto.ch und die von der
        Bossasto-App zu nutzen.
      </p>
      <p id="datenschutz-cookie-richtlinie">
        Mit dem Zugriff auf bossasto.ch oder die Bossasto-App gelten diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") vom Bossasto-User als anerkannt. Mit
        Anklicken des dafür vorgesehenen Kästchens bei der Registrierung als Arbeitnehmer oder Arbeitgeber, anerkennt der Bossasto-User ausdrücklich diese AGB. Die
        Bestimmungen der AGB gelten nicht nur für die Benutzung der Webseite oder der Bossasto-App, sondern bilden integrierenden Bestandteil jeglicher vertraglichen
        Beziehung mit Bossasto.{' '}
      </p>
      <h3>4. Datenschutz und Cookie Richtlinie</h3>
      <p id="vertragsinhalt">
        Die Nutzung von bossasto.ch oder der Bossasto-App unterliegt den folgenden zusätzlichen Bestimmungen: Unserer Datenschutzrichtlinie (separat in der
        Datenschutzerklärung erklärt), diese enthält die Bestimmungen, gemäss denen wir personenbezogene Daten, welcher wir über die Benutzer, Arbeitnehmer oder
        Einsatzbetriebe erfassen, verarbeiten. Mit der Nutzung von bossasto.ch oder der Bossasto-App erklären sich alle Beteiligte mit dieser Verarbeitung einverstanden
        und gewährleisten, dass sämtliche von ihnen bereitgestellten Daten richtig sind. Unserer Cookie-Richtlinie (Punkt 18, 19 und 20 sowie separat in der
        Datenschutzerklärung erklärt), diese enthält die Hinweise zu den Cookies auf bossasto.ch oder der Bossasto-App. Mit der Nutzung von bossasto.ch oder der
        Bossasto-App bestätigen die Bossasto-User, dass sie die Bestimmungen dieser Richtlinie einhalten.
      </p>
      <p>
        <h3 id="registrierung-bossasto-usern"> Vertragsinhalt</h3>
      </p>
      <p>
        <h4>5.1 Registrierung von Bossasto-Usern</h4>
      </p>
      <p>
        Um die Dienste von bossasto.ch und/oder der Bossasto-App nutzen zu können, muss sich jeder Bossasto-User zuerst auf bossasto.ch oder der Bossasto-App
        registrieren. Die Bossasto-User dürfen über die von ihnen erfasste E-Mail sowie das eigene kreierte Passwort, welche sie für jedes Einloggen auf bossasto.ch oder
        der Bossasto-App zwecks Identifikation verwenden muss. Die Login-Daten sind sorgfältig aufzubewahren und keinem Dritten bekannt zu geben. Allfällige Schäden
        resultierend aus dem Verlust/Bekanntwerden Dritter, der Login-Daten hat der Bossasto-User alleine zu tragen. Jegliche Haftung seitens Bossasto ist ausgeschlossen.
      </p>
      <p id="vertragliche-leistungen">
        Bossasto kann die gesamte bossasto.ch oder einen Teil davon und/oder die Bossasto-App ohne vorherige Ankündigung sperren, zurückziehen, einstellen oder ändern.
        Bossasto lehnt jegliche Haftung ab, wenn bossasto.ch oder die App aus irgendeinem Grund zu irgendeinem Zeitpunkt oder für einen bestimmten Zeitraum nicht
        verfügbar ist. Bossasto haftet ebenfalls nicht für Schäden, die durch allfällige Viren oder andere schädliche Komponenten infolge der Benutzung von bossasto.ch,
        der Bossasto-App oder des Servers entstanden sind. Die Bossasto-User sind dafür verantwortlich, alle notwendigen Vorkehrungen zu treffen, damit sie Zugriff auf
        bossasto.ch und die Bossasto-App haben. Sie sind auch dafür verantwortlich, dass alle Personen, die über ihre Internetverbindung auf bossasto.ch oder die
        Bossasto-App zugreifen, Kenntnis von diesen Nutzungsbedingungen sowie anderen anwendbaren Geschäftsbedingungen haben und diese auch einhalten. Die Arbeitgeber
        können die auf bossasto.ch oder Bossasto-App gefundenen Arbeitnehmer über Payroll anstellen und der Bossasto eine Anfrage senden. Bossasto entscheidet über
        welchen Partner das Payroll ausgeführt wird. Die Bossasto-Partner müssen eine gültige Personalverleih- sowie Personalvermittlungsbewilligung besitzen. Sollte der
        Arbeitgeber keine Arbeitnehmer finden, kann der Arbeitgeber einen Festvermittlungsauftrag der Bossasto anbieten. Die Arbeitgeber müssen für Festvermittlung mit
        Mehrkosten rechnen. Finden Arbeitnehmer keinen Arbeitnehmer über bossasto.ch oder die Bossasto-App, so kann der Arbeitnehmer Bossasto anfragen aktiv nach
        potentiellen Arbeitgebern zu suchen. Für Arbeitnehmer ist auch diese Dienstleistung kostenlos jedoch für die Arbeitgeber wird die Festvermittlung verrechnet.
      </p>

      <h4 id="arbeitnehmer">5.2 Vertragliche Leistungen</h4>
      <p>
        Der Leistungsumfang der aktuellen Bossasto-Dienste sind jeweils auf bossasto.ch oder der Bossasto-App beschrieben. Im Wesentlichen sind es folgende
        Bossasto-Dienste:
      </p>
      <h5>A. für Arbeitnehmer</h5>
      <p id="arbeitgeber">
        Durch der Registrierung und Eingabe seiner Daten auf bossasto.ch oder der Bossasto-App nimmt der Arbeitnehmer die für ihn kostenlosen
        Arbeitsvermittlungs-Dienstleistungen durch Bossasto in Anspruch, womit der Vertragsabschluss mit Bossasto durch die Eingabe der Daten sowie das Akzeptieren der
        AGB und der Datenschutzerklärung zustande kommt. Die Arbeitsuchenden haben die Möglichkeit auf bossasto.ch oder der Bossasto-App ein Profil zu erstellen. Mit dem
        Profil kann aktiv nach Arbeit gesucht werden oder die Arbeitgeber machen den Arbeitnehmern direkt ein Angebot. Je nach Profil sind diverse Jobinserate sichtbar.
        Jedoch versichert Bossasto dem Arbeitnehmer keine neue Arbeit oder Jobinserate nach seinen Suchkriterien und garantiert nicht zum Vertragsabschluss. Bossasto.ch
        oder die Bossasto-App kann nicht ohne die Registration und Anmeldung genutzt werden. Bossasto stellt den Arbeitnehmern bossasto.ch und Bossasto-App zur Verfügung,
        mit welcher er sein Profil pflegen kann und die ihm passende Jobmöglichkeiten liefert. Möchte der Arbeitnehmer dies nicht, so hat er die Möglichkeit sein Profil
        auf Anonym zu stellen.
      </p>
      <h5>B. für Arbeitgeber</h5>
      <p id="gewährleistungsausschluss">
        Bossasto stellt dem Arbeitgeber bossasto.ch und die Bossasto-App zur Verfügung, welche es dem Arbeitgeber erlaubt, Stellenanzeigen zu erfassen und geeignete
        Arbeitnehmer für ihre offenen Stellen zu finden oder aktiv nach geeigneten Arbeitnehmern zu suchen und direkt zu kontaktieren. Durch die Registrierung und Eingabe
        der Daten auf bossasto.ch oder der Bossasto-App nimmt der Arbeitgeber die für ihn kostenpflichtigen Arbeitsvermittlungs-Dienstleistungen durch Bossasto in
        Anspruch, womit der Vertragsabschluss mit Bossasto durch die Eingabe der Daten sowie das Akzeptieren der AGB und der Datenschutzerklärung zustande kommt. Nach der
        Registration kann der Arbeitgeber die vollen Funktionen von bossasto.ch oder Bossasto-App erst nach der Zahlung eines der drei Abbos mit der Kreditkarte nutzen.
        Bevor ein Arbeitgeber überhaupt bossasto.ch oder die Bossasto-App nutzen kann, muss Bossasto selbst den Arbeitgeber freischalten und ihm die Erlaubnis geben, die
        Funktionen von bossasto.ch und die von der Bossasto-App zu nutzen. Bossasto bietet dem Arbeitgeber eine Plattform zum Erfassen von Stelleninserate an und hat auch
        die Möglichkeit aktiv nach geeigneten Mitarbeitern zu suchen. Mit der Erstellung einer neuen Stellenanzeige auf bossasto.ch oder der Bossasto-App erteilt der
        Arbeitgeber sein Einverständnis für die Publikation der jeweiligen Stellenanzeige und die angegebenen Firmeninformationen sowie Logos auf bossasto.ch sowie auf
        die Bossasto-App. Ist das Inserat auf Bossasto publiziert, können sich die Arbeitsuchende auf die Inserate bewerben. Bossasto sichert den Arbeitgeber nicht zu,
        dass in jedem Fall ein passender Arbeitnehmer sich auf die Inserate melden wird oder gefunden werden kann und übernimmt auch keine Gewähr für den Erfolg einer
        erfassten Stellenanzeige. Bossasto.ch oder die Bossasto- App kann nicht ohne die Registration und Anmeldung genutzt werden. Bossasto stellt den Arbeitgebern
        bossasto.ch und Bossasto-App zur Verfügung, mit welcher er sein Profil pflegen kann und die ihm passende Arbeitnehmer zur Auswahl stellt. Auch kann der
        Arbeitgeber Bossasto Festvermittlungsaufträge weiterleiten, jedoch kommt im Bereich Festvermittlung weiter zusätzliche Kosten. Sollte der Arbeitgeber einen
        Arbeitnehmer gefunden haben, jedoch möchte er ihn nicht direkt anstellen wollen, so kann der Arbeitgeber der Bossasto einen Payroll-Auftrag anbieten. Bossasto
        wird einen Partner anfragen, über welchen das Payroll abgeschlossen und ausgeführt werden kann. Die Arbeitnehmer werden auch bei Payroll nicht über Bossasto
        angestellt. Bossasto ist nicht verpflichtet die Payroll’s sowie Festanstellungsaufträge anzunehmen.
      </p>
      <h4>5.3 Gewährleistungsausschlus</h4>
      <p id="verweise-links">
        Bossasto haftet nicht dafür, dass die auf bossasto.ch oder der Bossasto-App angebotenen Funktionen ohne technische Unterbrüche fehlerfrei zur Verfügung stehen.
        Bossasto haftet ebenfalls nicht für Schäden, die durch allfällige Viren oder andere schädliche Komponenten infolge der Benutzung von bossasto.ch, der Bossasto-App
        oder des Servers entstanden sind. Bossasto schliesst jede Haftung für Schäden oder Folgeschäden infolge der Nutzung bzw. Nichtbenutzung von bossasto.ch oder der
        Bossasto-App aus. Bossasto haftet ebenfalls nicht für Manipulationen, die dem Bossasto-User durch die Nutzung des Internets am EDV-System entstehen. Im Übrigen
        schliesst Bossasto jegliche Haftung, soweit gesetzlich zulässig, aus.
      </p>
      <h3>6. Verweise und Links zu anderen Webseiten</h3>
      <p id="sperrung-zugriff">
        Auf bossasto.ch und der Bossasto-App befinden sich Links zu anderen Webseiten. Bossasto hat keinen Einfluss auf den Inhalt dieser Webseiten und ist damit nicht
        für deren Inhalt verantwortlich. Das Herstellen von Verbindungen zu diesen Webseiten erfolgt damit auf eigene Gefahr.
      </p>
      <h3>7. Sperrung des Zugriffs auf bossasto.ch oder die Bossasto-App</h3>
      <p>
        Die Bossasto-User sind verpflichtet, bei der Registrierung als Arbeitnehmer oder Arbeitgeber alle Fragen wahrheitsgemäss zu beantworten und ausschliesslich
        richtige Angaben zu machen. Bossasto behält sich das Recht vor, Stichproben betreffend Richtigkeit zu machen.
      </p>
      <p>
        Sollte Bossasto herausfinden, dass wahrheitswidrige oder falsche Angaben gemacht wurden, wird der Zugriff des Benutzers auf bossasto.ch und die Bossasto-App
        gesperrt und die vertragliche Beziehung gilt als auf den nächstmöglichen Zeitpunkt gekündigt. Bossasto behält sich überdies vor, bei wahrheitswidrigen oder
        falschen Angaben, rechtliche Schritte, inkl. Strafanzeige, einzuleiten. Die Bossasto-User werden vom Bossasto-System sowohl anlässlich der Registrierung als auch
        danach verschiedentlich dazu aufgefordert, Angaben zu machen oder Dokumente hochzuladen/einzureichen. Die Benutzer sind verpflichtet, diesen Aufforderungen innert
        der angegebenen Frist oder, falls keine Frist angegeben wurde, innert drei Arbeitstagen, Folge zu leisten. Leisten die Bossasto-User nach zweimaliger Aufforderung
        keine Folge, berechtigt dies Bossasto zur Sperrung des Zugriffs auf bossasto.ch und die Bossasto-App. Die Sperrung gilt gleichzeitig als Kündigung der
        vertraglichen Beziehung auf den nächstmöglichen Zeitpunkt.
      </p>
      <p id="preise-zahlungsbedingungen">
        Die Sperrung und Kündigung erfolgt allerdings erst nach Anhörung aller Beteiligten durch Bossasto. Überdies behält sich Bossasto das Recht vor, jederzeit und nach
        freiem Ermessen einem Bossasto-User den Zugriff auf bossast.ch und die Bossasto-App zu sperren, namentlich dann z.B., wenn die Reputation von Bossasto gefährdet
        ist. Schadensersatzansprüche eines Bossasto-Users dessen Zugriff auf bossasto.ch und die Bossasto-App gesperrt wurde, sind vollumfänglich wegbedungen.
      </p>
      <p>
        <h3 id="arbeitnehmer-preise">8. Preise und Zahlungsbedingungen</h3>
      </p>
      <p>
        <h4>A. Arbeitnehmer</h4>
      </p>
      <p id="arbeitgeber-preise">
        Für die Arbeitsuchende ist die Nutzung von bossasto.ch sowie die Bossasto-App und die Erstellung des Profils sowie die aktive Bewerbung kostenlos.
      </p>
      <p>
        <h4>B. Arbeitgeber</h4>
      </p>
      <p>
        Die Veröffentlichung von Stellenanzeigen auf bossasto.ch oder über die Bossasto-App erfordert eine Gebühr für Arbeitgeber. Die für den Arbeitgeber bindenden und
        maßgeblichen Preise für die Nutzung von bossasto.ch sowie die Bossasto-App sind vor Abschluss des Vertrags für den Arbeitgeber ersichtlich und akzeptiert durch
        die Registrierung und Annahme der AGBs. Der Arbeitgeber hat die Möglichkeit, nach der Registrierung ein Abonnement auszuwählen und die Zahlung per Kreditkarte
        oder die von Bossasto zur Verfügung gestellten Zahlungsdiensten durchzuführen.
      </p>
      <p id="Laufzeit-links">
        Ohne bezahltes Abonnement ist es dem Arbeitgeber nicht möglich, Jobinserate zu veröffentlichen oder aktiv Arbeitsuchende zu kontaktieren. Der Zugang bleibt
        solange aktiv, wie das Abonnement bezahlt wurde. Sollte eine automatische Zahlung per Kreditkarte nicht ausreichend gedeckt sein, wird der Arbeitgeber per E-Mail
        informiert und hat fünf Wochentage Zeit, um das Konto aufzuladen. Andernfalls werden die Funktionen für die Veröffentlichung von Stelleninseraten und die Suche
        deaktiviert.
      </p>
      <p>
        <h3>9. Laufzeit und Kündigung</h3>
      </p>
      <p>
        <h4>A. Arbeitnehmer</h4>
      </p>
      <p>
        Nach der Registrierung vom Arbeitnehmer, akzeptiert er die AGB sowie die Datenschutzrichtlinien und gilt als gültiger Vertrag zwischen Arbeitnehmer und Bossasto.
        Die Nutzung von bossasto.ch sowie die Bossasto-App gilt als Vertrag auf unbestimmte Dauer. Der Arbeitnehmer kann sein Profil au Anonym stellen und die Löschung
        seines Profils der Bossasto mitteilen. Das Profil wird so schnell wie möglich von Bossasto gelöscht. Der Arbeitnehmer hat jederzeit die Möglichkeit, die Löschung
        seines Profils durch eine Anfrage per E-Mail an info@bossasto.ch zu beantragen.
      </p>
      <p>
        <h4>B. Arbeitgeber</h4>
      </p>
      <p>
        Arbeitgeber, die sich auf bossasto.ch sowie die Bossasto-App registrieren, haben die Möglichkeit, aus drei verschiedenen Abonnement zu wählen. Diese umfassen
        Laufzeiten von 1 Monat, 6 Monaten oder 12 Monaten für ein Profil. Die Preise für das Nutzen von bossasto.ch sowie die von der Bossasto-App sind nach der
        Registration sowie Freischaltung des Profils seitens Bossasto per Kreditkarte zu bezahlen.
      </p>
      <p>
        <h4>C. Allgemeines</h4>
      </p>
      <p id="dienstleistungserbringung">
        Alle Bossasto-User, sind verpflichtet, Bossasto ausschließlich für den Zweck der Vermittlung von Arbeitsplätzen zu nutzen. Sollte Bossasto für andere Zwecke
        genutzt werden, wie zum Beispiel zur Gewinnung von Kontakten für Kryptohandel, Aktienverkauf, Krankenkassenverkauf, Finanzberatungsangebote, Datenverkauf oder
        andere unseriöse Geschäfte, wird der Zugang des Benutzers sofort gesperrt und eine Strafe in Höhe von CHF 10'000.- (pro Fall) erhoben. Kündigungen durch Bossasto
        erfolgen schriftlich an die vom Bossasto-User angegebene Post- oder E-Mail-Adresse. Sollten diese Kontaktangaben des Bossasto-Users nicht mehr korrekt sein und
        Bossasto nicht über eine Veränderung informiert worden sein, gilt die Kündigung als rechtswirksam, sobald sie an die bekannte Post- oder E-Mail-Adresse versandt
        wurde.
      </p>
      <p>
        <h3>10. Dienstleistungserbringung</h3>
      </p>
      <p>
        Bossasto erfüllt ihre Verpflichtungen durch die Bereitstellung von bossasto.ch oder die Bossasto-App, vorbehaltlich anderslautender Vereinbarungen. Sofern keine
        weiteren Bedingungen vereinbart werden, gilt der Sitz von Bossasto als Ort der Erfüllung. Bossasto verpflichtet sich, die Dienstleistungen sorgfältig und
        zuverlässig durchzuführen, jedoch garantiert sie keinen Erfolg. Bossasto handelt als unabhängiger Unternehmer und ist nicht exklusiv an den Kunden oder Bewerber
        gebunden. Es besteht keine Partnerschaft im Sinne des schweizerischen Obligationenrechts zwischen Bossasto und den Arbeitnehmern oder Arbeitgebern, auch steht
        Bossasto mit den Arbeitnehmern nicht in einem Arbeitsverhältnis. Mit dieser Erklärung bestätigt Bossasto, dass sie über alle erforderlichen Genehmigungen für die
        Arbeitsvermittlung verfügt.
      </p>
      <p>
        Bossasto erbringt ihre Dienstleistungen sorgfältig und fachgerecht, jedoch kann sie keine Gewähr für die ständige Verfügbarkeit und Fehlerfreiheit von bossasto.ch
        sowie der Bossasto-App übernehmen. Insbesondere haftet Bossasto nicht für eventuelle Ausfälle und technische Störungen sowie deren Auswirkungen auf die Dienste
        von Bossasto. Die auf bossasto.ch und der Bossasto-App verfügbaren Jobangebote stammen von Arbeitgebern, die diese Anzeigen veröffentlicht haben. Bossasto
        übernimmt keine Gewähr für die Richtigkeit oder Vollständigkeit dieser Daten. Bossasto bemüht sich, eine unbefugte Nutzung der auf bossasto.ch sowie der
        Bossasto-App veröffentlichten Daten durch Dritte zu verhindern. Jegliche Haftung von Bossasto im Falle einer unbefugten Verwendung durch Dritte ist jedoch
        ausdrücklich ausgeschlossen. Bossasto übernimmt auch keine Gewähr für den Erfolg einer veröffentlichten Stellenanzeige.
      </p>
      <p>
        Bossasto gibt keine Verantwortung für das Nichterscheinen oder fehlerhafte Erscheinen einer Stellenanzeige oder eines Profils und dass Bossasto-User nicht das
        Recht haben, Ansprüche geltend zu machen. Allerdings sind Bossasto-User berechtigt, die korrekte Veröffentlichung des Inserats oder Profils zu verlangen. Es wird
        auch darauf hingewiesen, dass die Haftung von Bossasto auf Fälle von vorsätzlichem oder grob fahrlässigem Handeln beschränkt ist, und dass es keine Haftung für
        Schäden aufgrund leichter Fahrlässigkeit, indirekte Schäden oder Folgeschäden, entgangenen Gewinn, Schäden durch vertrags-, rechts- oder zweckwidrige Nutzung von
        bossasto.ch oder der Bossasto-App durch Bossasto-User oder andere Dritte oder Schäden, die auf höhere Gewalt zurückzuführen sind.
      </p>
      <p>
        <h4>10.1 Dienstleistungen von Dritten / Payroll mit Partnerfirma</h4>
      </p>
      <p id="veröffentlichen-von-inhalten-und-kontaktieren-von-anderen-benutzern">
        Im Zusammenhang mit der Nutzung von bossasto.ch sowie die Bossasto-App kann es vorkommen, dass der Arbeitgeber Leistungen von Drittanbietern in Anspruch nimmt.
        Wenn ein Arbeitgeber einen Arbeitnehmer findet und diesen vorübergehend auf Stundenbasis testen möchte, hat er die Möglichkeit, diese Leistungen mit unserem
        Partner zu vereinbaren. Der Stundensatz und die Dauer des Vertrags werden direkt mit dem Partner vereinbart. In diesem Fall schließt der Arbeitgeber direkt einen
        Vertrag mit dem Drittanbieter ab. Bossasto übernimmt keinerlei Verantwortung für die Erbringung dieser Dienstleistungen durch den Drittanbieter.
      </p>
      <p>
        <h3>11. Veröffentlichen von Inhalten und Kontaktieren von anderen Benutzern</h3>
      </p>
      <p id="zulässige-nutzung-allgemein-für-arbeitgeber-und-arbeitnehmer">
        Die Bossasto-User tragen die Verantwortung für jeglichen Schaden und jegliche Kosten, die durch eine Verletzung dieser Allgemeinen Geschäftsbedingungen oder
        anderer Vertragsbestandteile entstehen und stellen Bossasto von jeglichen Ansprüchen Dritter frei, die im Zusammenhang mit der Nutzung von bossasto.ch sowie der
        Bossasto-App durch Bossasto-User gegen Bossasto geltend gemacht werden. Der Schaden, der ersetzt werden muss, beinhaltet auch angemessene Anwaltskosten. Bossasto
        behält sich das Recht vor, den Vertrag mit einem Arbeitgeber, bei dessen Nutzung vertragswidrige Praktiken festgestellt werden, unverzüglich und ohne
        Rückerstattung irgendwelcher Kosten zu kündigen und/oder die weitere Nutzung der Leistungen zu verweigern sowie das Blockieren des Profils und die
        Neuregistrierung von anderen Angestellten des gleichen Arbeitgeber- Firma nicht freizuschalten.
      </p>
      <p>
        <h3>12. Zulässige Nutzung, allgemein für Arbeitgeber und Arbeitnehmer</h3>
      </p>
      <p>
        Die Bossasto-User sind verantwortlich für die rechts- und vertragskonforme Verwendung von bossasto.ch sowie der Bossasto-App und müssen die schweizerischen
        Gesetze, insbesondere das Strafrecht, das Datenschutzgesetz sowie die immateriellen Eigentumsrechte und anwendbares ausländisches Recht während der Nutzung stets
        einhalten. Jeder Bossasto-User haftet ausschließlich und persönlich für die Rechtmäßigkeit, Richtigkeit und Vollständigkeit der von ihm zur Veröffentlichung auf
        bossasto.ch sowie der Bossasto-App bereitgestellten oder eingegebenen Inhalte. Jeder Bossasto-User ist allein und persönlich verantwortlich für die sorgfältige
        und vertrauliche Aufbewahrung seiner Logindaten (insbesondere E-Mail, Passwörter und Dokumente) und für alle Aktivitäten, die über seinen Zugang ausgeführt
        werden.
      </p>
      <p>
        Die Bossasto-User dürfen keine Daten in ihren Stellenanzeigen oder Profil eingeben, die geistiges Eigentum Dritter, Persönlichkeitsrechte oder andere Rechte
        Dritter verletzen. Die Bossasto-User tragen die alleinige Verantwortung für den Inhalt ihrer Stellenprofile (Arbeitgeber) bzw. den Inhalt ihres Profils
        (Arbeitnehmer und Arbeitgeber) und gewährleisten die Richtigkeit und Aktualität der darin enthaltenen Informationen. Jeder Bossasto-User verpflichtet sich, auf
        bossasto.ch und der Bossasto-App keine rechtswidrigen Inhalte, insbesondere solche mit rassistischem, pornographischem oder persönlichkeitsverletzendem Charakter,
        zu veröffentlichen
      </p>
      <p>Unzulässige Inhalte sind insbesondere:</p>
      <p id="informationspflicht">
        Stellenanzeigen mit jeglicher Art von Pornografischen Titeln oder Inhalt; Stellenanzeigen mit ungenügender Angabe von Identität/Adresse. Jede veröffentlichte
        Stellenanzeige muss sich auf eine tatsächlich vakante Stelle beziehen. Bossasto behält sich das Recht vor, Stellenanzeigen oder Profile, die diesen Anforderungen
        nicht entsprechen, ohne Vorwarnung und Begründung aus ihrem Angebot zu entfernen und den Zugang des Nutzers zu sperren.
      </p>
      <p>
        <h3>13. Informationspflicht</h3>
      </p>
      <p id="registrierung-und-geheimhaltung">
        Die Bossasto-User verpflichten sich, über Änderungen seiner aktuellen Daten (Name, Postadresse, Email-Adresse etc.) und andere vertragsrelevante Informationen im
        Profil zu ändern sowie aktuell zu halten.
      </p>
      <p>
        <h3>14. Registrierung und Geheimhaltung</h3>
      </p>
      <p id="datenschutz">
        Um bossasto.ch sowie die Bossasto-App nutzen zu können, muss sich der Arbeitgeber registrieren. Der Arbeitgeber ist verpflichtet, bei der Registrierung korrekte
        und wahrheitsgetreue Angaben („Benutzerdaten“) zu machen und diese bei Änderungen aktualisieren. Der Arbeitgeber trägt die Verantwortung für die Geheimhaltung
        seiner Logindaten und sorgt dafür, dass sie vor unberechtigtem Zugriff Dritter geschützt sind. Sollte ein Arbeitgeber eine unberechtigte Nutzung seiner Logindaten
        vermuten, muss er dies unverzüglich Bossasto melden(info@Bossasto.com). Bei Missbrauch der Benutzerdaten, ob durch den Arbeitgeber oder Dritte, behält sich
        Bossasto das Recht vor, das entsprechende Profil unverzüglich zu sperren oder zu löschen und den Vertrag mit dem Arbeitgeber sofort zu kündigen.
      </p>
      <p>
        <h3>15. Datenschutz</h3>
      </p>
      <p>
        Bossasto verpflichtet sich, Personendaten der Bossasto-User, insbesondere Registrierungsdaten, sorgfältig und in Übereinstimmung mit dem schweizerischen
        Datenschutzgesetz zu bearbeiten. Bossasto bearbeitet die Daten gemäss den Datenschutzerklärung von Bossasto. Die Datenschutzerklärung von Bossasto ist
        integrierender Bestandteil dieser AGBs und damit des Vertrages zwischen Bossasto und den Bossasto-Usern. Bossasto darf die im Rahmen des Vertragsschlusses
        aufgenommenen Daten zur Erfüllung der Verpflichtungen aus dem Vertrag sowie zu anderen bekanntgegebenen Zwecken bearbeiten und verwenden. Bossasto ergreift die
        notwendigen und angemessenen Massnahmen, welche zur Sicherung der Daten gemäss den anwendbaren gesetzlichen Vorschriften erforderlich sind.
      </p>
      <p id="immaterialgüterrechte">
        Der Arbeitgeber und der Arbeitnehmer erklären sich damit einverstanden, dass ihre Daten von Bossasto gespeichert, gemäß den Vertragsbedingungen verwendet und
        bearbeitet werden. Sie sind sich bewusst, dass Bossasto, auf Anordnung von Gerichten oder Behörden, verpflichtet und berechtigt ist, allfällige Informationen, die
        den Arbeitgebern oder Arbeitnehmern betreffen, an diesen oder Dritte weiterzugeben. Mit der Annahme der vorliegenden AGB erklären sich der Arbeitgeber und der
        Arbeitnehmer damit einverstanden, dass Bossasto die Daten bis auf Widerruf für Marketingzwecke verwenden darf. Zudem können die zur Leistungserfüllung notwendigen
        Daten an beauftragte Dienstleistungspartner oder andere Dritte weitergegeben werden. Der Arbeitgeber trägt die Verantwortung dafür, dass die Bestimmungen zum
        Datenschutz in Bezug auf die vermittelten Arbeitnehmer eingehalten werden. Bossasto ist berechtigt, die im Zusammenhang mit dem Vertragsabschluss erhobenen Daten
        zur Erfüllung der vertraglichen Pflichten und für andere bekannt gegebene Zwecke zu verarbeiten und zu verwenden. Bossasto ergreift die notwendigen und
        angemessenen Schutzmaßnahmen, um die Daten gemäß den geltenden gesetzlichen Vorschriften zu sichern.
      </p>
      <p>
        <h3>16. Immaterialgüterrechte</h3>
      </p>
      <p>
        Alle Rechte an von Bossasto verwendeten oder angebotenen Produkten, Dienstleistungen und eventuellen Marken liegen bei Bossasto oder es ist berechtigt, diese zu
        verwenden. Weder diese AGB noch zugehörige Individualvereinbarungen beinhalten die Übertragung von Rechten an geistigem Eigentum, es sei denn, dies wird
        ausdrücklich vereinbart. Der Kunde und der Bewerber ist jegliche Weiterverwendung, Veröffentlichung und Verfügbarmachung von Informationen, Bildern, Texten oder
        anderen geistigen Gütern, die ihm von Bossasto zur Verfügung gestellt werden, untersagt, es sei denn, dies wird von Bossasto ausdrücklich genehmigt. Falls sie
        Bossasto-User im Zusammenhang mit der Erfüllung der Verpflichtungen aus dem Vertrag Informationen, Bilder, Texte oder andere geistige Güter verwendet, die ihm
        durch Bossasto zugänglich gemacht werden und an denen Dritte ein Schutzrecht haben, haben die Bossasto-User sicherzustellen, dass keine Schutzrechte Dritter
        verletzt werden. Der Arbeitgeber stimmt zu, dass Bossasto das Firmenlogo des Kunden für eigene Marketingzwecke (z.B. im Rahmen von Kundenlisten usw., die auf
        bossasto.ch und der Bossasto-App veröffentlicht werden) verwenden darf.
      </p>
      <p id="cookie-richtlinie-von-bossasto">
        Die von Bossasto entwickelte Software sowie der gesamte Inhalt der Bossasto-Dienste und -Plattformen ist urheberrechtlich geschützt und gehört entweder Bossasto
        selbst und darf ohne Zustimmung von Bossasto nicht kopiert, bearbeitet, verbreitet oder sonst wie verändert werden. Dies gilt auch für Wort-, Bild- und/oder
        akustische Marken und Logos, die auf bossasto.ch oder der BOSSASTO APP dargestellt werden. Für die Dauer des Vertrags gewährt Bossasto den Bossasto-Usern ein
        nicht übertragbares und nicht ausschließliches Recht zur Verwendung und Nutzung der geistigen Eigentumsrechte, die im Rahmen der Bossasto-Dienste zur Verfügung
        gestellt werden. Alle entsprechenden Rechte an bestehendem oder bei Vertragserfüllung entstehendem geistigen Eigentum oder anderen geistigen Eigentumsrechten
        verbleiben vollständig bei Bossasto. Sollte Bossasto durch von Bossasto-Usern verursachte Verletzungen von geistigen Eigentumsrechten Dritter in Anspruch genommen
        werden, verpflichtet sich der Bossasto-User hiermit ausdrücklich, Bossasto vollständig schadlos zu halten.
      </p>
      <p>
        <h3>17. Cookie-Richtlinie von Bossasto</h3>
      </p>
      <p>
        Bossasto.ch und die Bossasto-App verwenden Cookies, um Sie von anderen Benutzern unserer Website oder App zu unterscheiden. Dies ermöglicht es uns, Ihnen einen
        angenehmen Besuch auf bossasto.ch und die Bossasto-App zu gewährleisten und diese weiter zu verbessern.
      </p>
      <p>
        <h4>17.1 Änderungen unserer Datenschutz- oder Cookie-Richtlinie</h4>
      </p>
      <p id="zustimmung">
        Wir behalten uns das Recht vor, den Inhalt von bossasto.ch, der Bossasto-App oder der Dienstleistungen jederzeit ohne vorherige Ankündigung zu ändern.
        Dementsprechend kann sich auch diese Datenschutzrichtlinie und/oder Cookie-Richtlinie jederzeit ändern. Wir empfehlen Ihnen daher, diese regelmäßig zu überprüfen
        und sich über die Verwendung personenbezogener Daten auf dem Laufenden zu halten.
      </p>
      <p>
        <h3>18. Zustimmung</h3>
      </p>
      <p id="cookies-deaktivieren">
        Durch die Nutzung von bossasto.ch und der Bossasto-App erklären die Bossasto-User sich mit der Verwendung von Cookies gemäß dieser Cookie-Richtlinie
        einverstanden. Bei Ihrem ersten Besuch auf bosssasto.ch und der Bossasto-App wurde Ihnen ein entsprechendes Pop-up-Fenster angezeigt. Wenn Sie bei zukünftigen
        Besuchen dieses Fenster nicht mehr sehen, können Sie Ihre Zustimmung jederzeit widerrufen.
      </p>
      <p>
        <h3>19. COOKIES DEAKTIVIEREN</h3>
      </p>
      <p>
        Wenn Sie der Verwendung dieser Cookies nicht zustimmen, deaktivieren Sie diese bitte, indem Sie die Anweisungen unter http://allaboutcookies.org für Ihren Browser
        befolgen oder, sofern verfügbar, die unten angegebenen automatischen Deaktivierungs-Tools verwenden. Bitte beachten Sie, dass einige Dienste möglicherweise nicht
        ordnungsgemäß funktionieren, wenn Cookies deaktiviert sind.
      </p>
      <p id="vertragsänderungen">
        Wenn Sie nicht möchten, dass Ihr Browserverlauf nachverfolgt wird, können Sie dies in einigen Browsern entsprechend einstellen. Das Deaktivieren dieser
        Nachverfolgung kann die Nutzung von bossasto.ch oder der Bossasto-App und der bereitgestellten Dienste beeinträchtigen. Es kann vorkommen, dass wir nach ihrem
        ersten Besuch auf bossasto.ch oder der Bossasto-App die von uns verwendeten Cookies ändern. Durch diese Cookie- Richtlinie sind Sie immer über die von uns
        platzierten Cookies und deren Zweck sowie über die Möglichkeiten, diese zu deaktivieren, im Bilde. Daher sollten Sie die Cookie-Richtlinie regelmäßig überprüfen.
      </p>
      <p>
        <h3>20. Vertragsänderungen</h3>
      </p>
      <p id="abtretung">
        Diese AGB kann von Bossasto jederzeit geändert werden. Die zum Zeitpunkt des Vertragsabschlusses geltende Version der AGB ist grundsätzlich maßgeblich. Es sei
        denn, die Bossasto-User haben eine neuere Version der AGB schriftlich zugestimmt. Bossasto behält sich das Recht vor, Vertragsleistungen, Preise und alle anderen
        Vertragsvereinbarungen, einschließlich dieser AGB, jederzeit zu ändern. Bereits abgeschlossene Bossasto-Abos sind von Preis- und Leistungsänderungen innerhalb der
        gewählten Laufzeit nicht betroffen, sofern die Preis- und Leistungsänderungen für den Arbeitgeber keine Mehrbelastung oder Verschlechterung bedeuten. Allfällige
        Änderungen an diesen AGBs müssen dem Arbeitgeber in angemessener Weise mitgeteilt werden. Wenn der Arbeitgeber die Bossasto-Dienste nach Bekanntgabe der
        Änderungen weiterhin in Anspruch nimmt, akzeptiert er damit die Änderungen.
      </p>
      <p>
        <h3 id="salvatorische-klausel">21. Abtretung</h3>
      </p>
      <p>Die Übertragung von Rechten und Pflichten des Bossasto-User aus diesem Vertrag erfordert die ausdrückliche schriftliche Zustimmung von Bossasto.</p>
      <p>
        <h3>22. Verrechnungsausschluss</h3>
      </p>
      <p>
        Durch den Abschluss des Vertrags verzichtet der Arbeitgeber auf sein Recht auf Verrechnung von Forderungen, die er gegenüber Bossasto hat oder die er für geltend
        zu machen glaubt.
      </p>
      <p>
        <h3>23. Salvatorische Klausel</h3>
      </p>
      <p id="vertraulichkeit">
        Sollte eine Bestimmung dieser AGB ungültig oder undurchführbar sein, bleibt die Gültigkeit des Vertrages im Übrigen unberührt. Die betroffenen Vertragsparteien
        werden die ungültige Bestimmung durch eine gültige ersetzen, die dem ursprünglichen wirtschaftlichen Zweck am nächsten kommt. Dasselbe gilt für eventuelle Lücken
        im Vertrag. Sollte eine Bestimmung nichtig oder ungültig sein, bleibt der Rest der AGB davon unberührt. Nichtige oder ungültige Bestimmungen werden durch solche
        ersetzt, die wirksam sind und dem ursprünglichen Zweck am nächsten kommen. In gleicher Weise ist zu verfahren, falls sich eine Lücke im Vertrag ergibt oder eine
        Bestimmung sich als undurchführbar erweist.
      </p>
      <p>
        <h3 id="schriftform">24. Vertraulichkeit</h3>
      </p>
      <p id="anwendbares-recht">
        Die Bossasto-User verpflichten sich, alle Informationen, die im Zusammenhang mit den Vertragsleistungen bekannt werden, vertraulich zu behandeln und weder für
        eigene Zwecke außerhalb des Vertrages zu verwerten noch Dritten zugänglich zu machen, es sei denn, dies ist zur Erfüllung des Vertrages erforderlich. Diese
        Geheimhaltungspflicht umfasst auch Kenntnisse, die nicht allgemein bekannt sind und bleibt auch nach Beendigung des Vertrages bestehen.
      </p>
      <p>
        <h3 id="gerichtsstand">25. Schriftform</h3>
      </p>
      <p>
        Alle Änderungen oder Ergänzungen des Vertrags zwischen Bossasto und den Bossasto-Usern, einschließlich dieser AGBs, erfordern die Schriftform, sofern nicht anders
        vereinbart oder in diesen AGBs festgelegt ist. Diese Pflicht unterliegt auch die Zustimmung zu Preis- und Leistungsänderungen, sofern diese für den Arbeitgeber
        eine Mehrbelastung oder Verschlechterung darstellen.
      </p>
      <p>
        <h3>26. Anwendbares Recht</h3>
      </p>
      <p>
        Alle Vertragsbeziehungen zwischen Bossasto und de Bossasto-Usern unterliegen schweizerischem Recht, unter Ausschluss des internationalen Privatrechts und des
        Übereinkommens der Vereinten Nationen. Änderungen oder Ergänzungen des Vertrags, einschließlich dieser AGBs, erfordern die Schriftform, es sei denn, es gibt
        abweichende vertragliche Vereinbarungen oder Bestimmungen in den AGBs.
      </p>
      <p>
        <h3>27. Gerichtsstand</h3>
      </p>
      <p>
        Der ausschließliche Gerichtsstand für alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesem Vertragsverhältnis ergeben, ist Zürich, unter Vorbehalt
        allfälliger zwingender gesetzlicher Gerichtsstände. Sollten keine zwingenden gesetzlichen Bestimmungen vorliegen, sind ausschließlich die Gerichte am Sitz der
        Bossasto GmbH in der Schweiz zuständig, um Streitigkeiten aus diesen AGB und der darauf basierenden Vertragsbeziehung zu lösen.
      </p>
    </>
  );

  return registerAGBsOpened ? (
    <React.Fragment>
      <RegisterDialogWrapper onClick={closeDialogAGBs}></RegisterDialogWrapper>
      <RegisterDialogContainer>
        <StyledCloseIcon onClick={closeDialogAGBs} />
        <Row ><PageHeaderTitle style={{paddingLeft: "100px"}} title={t('LANDING_PAGE_FOOTER.agbs')} /></Row>
        <StyledPricingPlanWrapper>{renderAGBTop()}</StyledPricingPlanWrapper>
        <Footer style={{ justifyContent: 'center', paddingTop: '15px', paddingBottom: '15px' }}>
          <CustomButton text={t('BUTTON.cancel')} customHeight="50px" customTextColor={WHITE} handleOnClick={closeDialogAGBs} />
        </Footer>
      </RegisterDialogContainer>
    </React.Fragment>
  ) : null;
};

export default RegisterAGBs;
