/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { Alert, Col, Row } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';
import { formatDate } from 'utils/helpers';

import withTranslations from 'translations';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import Filters from 'components/Filters';
import CustomCarSearch from 'components/CustomCarSearch';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import { USER_TYPE } from 'constants/userTypes';
import Pager from 'components/Pager';
import SendOffersDialog from 'components/SendOffersDialog';
import { StyledDialogButtonActions, StyledInputField } from 'components/LoginDialog/styles';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';
import { showToast } from 'redux/modules/toast/reducer';
import DefaultProfileImage from 'assets/images/default.jpeg';

import { StyledSearchContentWrapper, StyledSearchResultContent, StyledCardWrapper, StyledColWrapper} from './styles';
import { countWorkersSelector, workersSelector } from '../../../redux/modules/workers/selectors';
import { authProfileSelector, favoritesProfileSelector, blacklistSelector } from '../../../redux/modules/auth/selectors';
import { countWorkers, fetchWorkersList } from '../../../redux/modules/workers/asyncThunks';
import { closeDialog, openDialog } from '../../../redux/modules/dialogs/reducer';
import { sendOfferCompany, updateFavoritesCompany, updateBlacklist, getCompanyProfile } from '../../../redux/modules/auth/asyncThunks';
import PreviewDialog from '../../../components/PreviewDialog';
import {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledPreviewDialogFooterInfo
} from '../Blacklist/styles';

const Blacklist = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [workerCount, setWorkerCount] = useState(null);
  const workersListSelector = useSelector(workersSelector);
  const workersCountSelector = useSelector(countWorkersSelector);
  const favorites = useSelector(favoritesProfileSelector);
  const blacklisted = useSelector(blacklistSelector);
  const company = useSelector(authProfileSelector);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const BE_URL = ``;
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const PER_PAGE = 50;
  const defaultParams = {
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
  };
  const { register, handleSubmit } = useForm();
  useEffect(async() => {
    const countList = await dispatch(fetchWorkersList(defaultParams));
    setWorkerCount(blacklisted.length)
    dispatch(countWorkers());
    dispatch(getCompanyProfile(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  const handleOnSearch = (inputValue, page) => {
    const params = {
      ...defaultParams,
      _start: page ? (page - 1) * PER_PAGE : (activePage - 1) * PER_PAGE
    };

    if (inputValue) {
      if (!searchTerm) {
        // eslint-disable-next-line no-underscore-dangle
        params._start = 0;
        setActivePage(1);
      }
      // eslint-disable-next-line no-underscore-dangle
      // params['industy_occupations.name_contains'] = inputValue;
      // params['address_contains'] = inputValue;
      // params['city_contains'] = inputValue;
      // params['state_contains'] = inputValue;
      // params['jobTitle_contains'] = inputValue;
      // params['jobDescription_contains'] = inputValue;
      // params['user[0].username_contains'] = inputValue;
      // params['user[0].name_contains'] = inputValue;
      setSearchTerm(inputValue);
      // eslint-disable-next-line no-underscore-dangle
      params._q = inputValue;
      // console.log(params._q);
    } else {
      setSearchTerm('');
    }

    dispatch(fetchWorkersList({ ...params }));
    dispatch(countWorkers({ ...params }));
  };

  const debouncedOnInputChange = _.debounce(handleOnSearch, 400);

  const handleSendWorkerOffer = worker => {
    setPreviewWorker({ id: worker });
    dispatch(
      closeDialog({
        dialogName: 'previewDialog'
      })
    );
    dispatch(
      openDialog({
        dialogName: 'sendOfferDialog'
      })
    );
  };

  const handleOpenWorkerDetails = worker => {
    // setPreviewWorker(worker);
        setPreviewWorker({
          originalData: worker,
          title: worker?.name,
          subtitle: worker?.jobTitle,
          workerJobTitle: worker?.jobTitle,
          workerName: worker?.name,
          workerStreet: worker?.address,
          workerCity: worker?.city,
          workerPostcode: worker?.rate,
          workerCountry: worker?.state,
          workerEmail: worker?.email,
          workerCitizenship: worker?.citizenship,
          workerBirthdate: worker?.birthdate,
          workerDriverLicenses: worker?.driverLicences,
          workerHourlyRate: worker?.hourlyRate,
          workerMonthlyRate: worker?.monthlySalary,
          workerMobilePhone: worker?.mobilePhone,
          workerBio: worker?.jobDescription,
          workerFiles: worker?.files,
          workerEducation: worker?.education,
          workerExperience: worker?.workExperience,
          logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
        });
    setPreviewDetails({
      originalData: worker,
      workerJobTitle: worker?.jobTitle,
      id: worker?.id,
      title: worker?.name,
      subtitle: worker?.jobTitle,
      address: worker?.jobDescription,
      // isBlacklisted: blacklisted && blacklisted?.find(workerBlacklisted => workerBlacklisted?.id === worker?.id) || null,
    //   offerStatus: worker?.offerStatus || null,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null,
    //   onSendOffer: handleSendWorkerOffer,
    //   onSendToBlacklist: handleToBlacklist,
      onSendRemoveFromBlacklist: handleRemoveFromBlacklist
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  const changePage = page => {
    setActivePage(page);
    handleOnSearch(searchTerm, page);
  };

//   const handleToBlacklist = async workerId => {
//     let updatedBlacklist = [];
//     const alreadyBlacklisted = blacklisted.find(blacklistWorker => blacklistWorker.id === workerId);
//     if (alreadyBlacklisted) {
//       return blacklisted;
//     } else {
//       updatedBlacklist = [...blacklisted.map(blacklistWorker => blacklistWorker), workerId];
//     }

//     try {
//       const isBSent = await dispatch(updateBlacklist({ companyId: company.id, payload: { blacklists: updatedBlacklist } }));
//     } catch (error) {
//       console.log('error updating blacklist', error);
//     }
//   };

  const handleRemoveFromBlacklist = async workerId => {
    let updatedBlacklist = [];
    updatedBlacklist = blacklisted.filter(worker => worker.id !== workerId);
    try {
      const isRemoved = await dispatch(updateBlacklist({ companyId: company.id, payload: { blacklists: updatedBlacklist } }));
      // console.log('here is isRemoved ===>>>>', isRemoved);
      if (isRemoved) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.removedFromBlacklist.success') }));
        dispatch(
            closeDialog({
              dialogName: 'previewDialog'
            }))
      }
    } catch (error) {
      console.log('there was as error trying to remove a worker from blacklist', error);
    }
  };

  const handleSendOffer = async payload => {
    try {
      // console.log('previewWorker', previewWorker);
      payload = {
        ...payload,
        worker: previewWorker.id,
        company: company.id,
        status: 'Pending',
        address: {
          city: payload.city,
          streatAddress: payload.streatAddress,
          country: payload.country
        }
      };
      const isOfferSent = await dispatch(sendOfferCompany({ payload }));
      if (isOfferSent && isOfferSent.payload) {
        dispatch(fetchWorkersList(defaultParams));
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.sendOffer.success')
          })
        );
        dispatch(
          closeDialog({
            dialogName: 'sendOfferDialog'
          })
        );
      }
    } catch (err) {
      if (err) {
        console.log('ERROR while sending offer', err);
      }
    }
  };
  const footerMinHeightVar = {
    // minHeight: '6rem'
  };
  function convertDateFormat(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const originalDate = previewWorker?.birthdate;
  const formattedDate = convertDateFormat(originalDate);

  let blacklistIds = blacklisted.map(worker => worker.id);

  // console.log("here are blacklisted ===>>>", blacklisted.includes(blacklistIds))
  // console.log("here is blacklistIds ==>>", blacklistIds)
  // console.log("here is workersListSelector ==>>", workersListSelector.entities)
  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.blacklist.pageTitle')} role={USER_TYPE.COMPANY}>
      <>
        <StyledSearchContentWrapper>
          <Filters onInputChange={debouncedOnInputChange} placeholder={t('SIDE_NAVIGATION.blacklist.pageTitle')} />
          <StyledSearchResultContent xs={12}>
            {workersListSelector
              ? workersListSelector
                  .filter(worker => blacklistIds.includes(worker.id))
                  .map(worker => (
                    <StyledCardWrapper key={`worker-${worker.id}`} sm={12} md={5} xl={4}>
                      <CustomCarSearch
                        footerMinHeight={footerMinHeightVar}
                        profileImage={worker.image?.url ? `${BE_URL}${worker.image?.url}` : DefaultProfileImage}
                        sectionTitle={worker?.name}
                        sectionSubtitle={worker?.industy_occupations.map(ele => ele.name).join(', ')}
                        // headerDescriptionFirst={worker.citizenship}
                        headerDescriptionSecond={worker.city}
                        headerDescriptionThird={worker.state}
                        headerDescriptionFifth={worker.rate}
                        headerDescriptionFirstRight={`CHF ${worker.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                        headerDescriptionSecondRight={`CHF ${worker.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                        headerDescriptionThirdRight={worker.driverLicences}
                        footerDescription={worker.jobDescription}
                        // hasActions
                        favoriteIcon
                        isFavorite={(favorites || []).find(fav => fav.id === worker.id)}
                        onFavoriteClick={$event => favoriteClick($event, worker.id)}
                        click={() => handleOpenWorkerDetails(worker)}
                      />
                    </StyledCardWrapper>
                  ))
              : null}
          </StyledSearchResultContent>
          <Pager active={activePage} jobsCount={workerCount} perPage={PER_PAGE} changePage={changePage} />
        </StyledSearchContentWrapper>
      
        <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{paddingTop: "20px"}}>
              {previewWorker?.workerStreet && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>  {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>{previewWorker?.workerStreet}
                </p>
              )}
              {previewWorker?.workerCity && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>  {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}:</span>{previewWorker?.workerCity}
                </p>
              )}
              {previewWorker?.workerPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>   {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>{previewWorker?.workerPostcode}
                </p>
              )}
              {previewWorker?.workerCountry && (
                <p>
                <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>{previewWorker?.workerCountry}
                </p>
              )}
              {previewWorker?.workerBirthdate && (
                <p>
                <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>{formatDate(new Date(previewWorker?.workerBirthdate))}
                </p>
              )}
              {previewWorker?.workerCitizenship && (
                <p>
               <span style={{ fontWeight: 'bold' }}>      {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>{previewWorker?.workerCitizenship}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{paddingTop: "20px"}}>
              {previewWorker?.workerMobilePhone && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>   {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>{previewWorker?.workerMobilePhone}
                </p>
              )}
              {previewWorker?.workerEmail && (
                <p>
                <span style={{ fontWeight: 'bold' }}>      {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>{previewWorker?.workerEmail}
                </p>
              )}
              {previewWorker?.workerDriverLicenses && (
                <p>
                 <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>{previewWorker?.workerDriverLicenses}
                </p>
              )}
              {previewWorker?.workerHourlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>    {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>{previewWorker?.workerHourlyRate}.-
                </p>
              )}
              {previewWorker?.workerMonthlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>{previewWorker?.workerMonthlyRate}.-
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <StyledWorkerDocumentsWrapper>
          <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
          <StyledDocumentsWrapper>
              {(previewWorker?.workerFiles || []).map(file => (
                <StyledDocumentLine key={`file-${file.id}`}>
                  <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                    <span>
                      <FontAwesomeIcon icon={faFileAlt} />
                    </span>
                    {file.name}
                  </p>
                </StyledDocumentLine>
              ))}
            </StyledDocumentsWrapper>
        </StyledWorkerDocumentsWrapper>
        <Row>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
            {previewWorker?.workerExperience && previewWorker?.workerExperience?.length ? (
              previewWorker?.workerExperience.map(
                work =>
                  work.companyName &&
                  work.position && (
                    <>
                      <strong>
                        {work.companyName}, {work.position}
                      </strong>
                      <p>
                        {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                      </p>
                    </>
                  )
              )
            ) : (
              <></>
            )}
          </StyledColWrapper>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
            {previewWorker?.workerEducation?.length ? (
              previewWorker?.workerEducation.map(work => (
                <>
                  <strong>
                    {work.name}, {work.type}
                  </strong>
                  <p>
                    {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                  </p>
                </>
              ))
            ) : (
              <></>
            )}
          </StyledColWrapper>
        </Row>
        <Row>
          {' '}
         {previewWorker?.workerBio && <StyledColWrapper style={{ paddingTop: '20px' }}>
            <h4>{t('FORM.fields.aboutMe')}</h4>
            <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>{' '}
          </StyledColWrapper>}
        </Row>
        </PreviewDialog>
      </>
    </ProfilePageContainer>
  );
};

export default withTranslations(Blacklist, 'Blacklist');
