import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';
import { Row } from 'react-bootstrap';

import { INPUT_TEXT_DARK_BLUE, SUBTLE_RED, INPUT_BORDER, WHITE, LIGHT_BLUE_PAGE_BG, APP_RED, DARK_BLUE, DARK_BUTTON, LIGHTER_GRAY } from 'constants/colors';

const parameters = 'solid 0px';

const StyledWrapperBottons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  border: ${parameters} blue;
`;

const StyledButtonRow = styled(Row)`
  /* margin-left: auto; */

  /* justify-content: flex-start; */
  border: ${parameters} green;
  @media (max-width: 768px) {
    width: 370px;
    /* justify-content: flex-start;
    align-items: flex-start; */
  }
`;

const StyledTopSmallTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  border: ${parameters} brown;

  @media (max-width: 1440px) {
    max-width: 500px;
    margin-right: -20px;
  }
`;

const StyledTopSmallTextBottom = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: ${parameters} lightblue;
`;

const StyledImageWrapper = styled('div')`
  display: flex;
  border: ${parameters} orange;
`;

const StyledCard = styled(Card)`
  background-color: ${WHITE};
  color: ${INPUT_TEXT_DARK_BLUE};
  width: 100%;
  height: 280px;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  justify-content: space-between;
  border: ${parameters} red;
  ${({ active }) => (active ? `border: 2px solid ${APP_RED}` : '')}

  @media (max-width: ${breakpoints.xl}) {
    height: 100%;

    /* padding-top: 10px; */
  }
`;

const StyledProjectsButtonRow = styled('div')``;


const StyledFooterBottom = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100px;
  border: ${parameters} green;
  /* max-height: 100px; */

  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  p {
    /* overflow-y: scroll; */
    overflow-x: hidden;
    box-sizing: border-box;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      height: 120px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};

    @media (max-width: ${breakpoints.lg}) {
      -webkit-line-clamp: 5;
    }

    /* @media (min-width: 1441px) {
      -webkit-line-clamp: 4;
    } */
    /* @media (min-width: 800px) {
      -webkit-line-clamp: 3;
    } */
  }
`;

const StyledBottomCardDescription = styled('div')`
  display: flex;
  padding-top: 10px;
  /* border: ${parameters} darkred; */
`;

const StyledCardRowWrapperCol = styled('div')`
  display: flex;
  border: ${parameters} darkred;
  @media (max-width: 1840px) {
    flex-direction: column;
    max-height: 130px;
  }
`;

const StyledCardBodyRight = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: ${parameters}purple; */

  button {
    border: none;
    background: #fff;
    box-sizing: border-box;
    font-size: 80%;
    padding: 1px 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: box-shadow 0.2s ease-in-out;
    width: 80%;
  }

  button {
    border-radius: 4px;
  }

  .delete-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .edit-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }

  /* @media(max-width: 768px) {
  align-items: flex-start;
} */
  /* border-top: 1px solid ${INPUT_BORDER}; */
`;

// const StyledCardHeader = styled(Card.Header)`
// border: ${parameters} green;

// `;

// grey line
const StyledCardHeader = styled(Card.Header)`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
height: 220px;
  /* height: 200px; */
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  /* padding-top: 30px; */
  /* border: ${parameters}purple; */
  @media (max-width: ${breakpoints.xxl}) {
    max-height: 100%;
  }
  @media (max-width: 768px) {
    height: 260px;
  }
`;

const StyledCardHeaderImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'Lato Regular';
  border: ${parameters} yellowgreen;
  padding-left: 20px;
  /* margin-bottom: 20px; */
  @media (max-width: ${breakpoints.lg}) {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-top: 8px;
  }
`;

const StyledImageDescriptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  border: ${parameters} orange;
  /* overflow-y: hidden; */
  /* overflow-x: scroll; */
  gap: 1rem;
  font-family: 'Lato Regular';
  /* &::-webkit-scrollbar {
    height: 0.3rem;
    background-color: transparent;
    border-radius: 5px;
  } */
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    // height: 2px;
    // width: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: horizontal;
`;

const CardName = styled.div`
  /* max-width: 200px;
  display: flex;
  justify-content: flex-start;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: ${parameters} blue;
  @media (max-width: 750px) {
    display: flex;
    justify-content: center;

  } */
`;

const StyledCardHeaderImage = styled(Card.Img)`
  border-radius: 50%;
  /* width: 70px;
  height: 70px; */
  width: 90px;
  height: 90px;
  margin-right: 20px;
  background: ${LIGHT_BLUE_PAGE_BG};
  @media (max-width: 750px) {
    margin-right: 0px;
  }
`;

// const StyledCardHeaderImageDescription = styled('div')`
//   display: flex;
//   font-size: 15px;
//   flex-direction: column;
// `;

const StyledCardHeaderImageDescription = styled('div')`
  /* display: flex;
  font-size: 15px;
  flex-direction: column;


  border: ${parameters} pink;
  @media (max-width: ${breakpoints.lg}) {

    justify-content: flex-start;
    align-items: center;
  } */
`;

const StyledCardHeaderBottomImageDescription = styled('div')`
  display: flex;
  font-size: 15px;
  justify-content: center;
  flex-direction: column;
  border: ${parameters} green;

  @media (max-width: ${breakpoints.lg}) {
    /* flex-direction: row;
    flex-wrap: wrap; */
    /* align-items: center; */
    justify-content: flex-start;
  }
`;

const StyledCardHeaderImageDescriptionRight = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-bottom: 10px;
  border: ${parameters} grey;
`;

const StyledCardHeaderImageDescriptionRightFrontPage = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-top: 25px;
`;

// yellow
const StyledCardHeaderImageDescriptionFirst = styled('span')`
  display: flex;
  font-size: 20px;
  font-weight: 1000;
  padding-top: 5px;
  border: ${parameters} yellow;
  @media (max-width: ${breakpoints.lg}) {
    align-items: center;
    padding-top: 0px;
  }
`;

const StyledCardHeaderCol = styled('div')`
  display: flex;
  /* border: ${parameters} brown; */
  justify-content: flex-start;
`;

const StyledCardHeaderBellowImage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
`;

const StyledCardFrontPage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;

  border: ${parameters} darkgreen;
  @media (max-width: ${breakpoints.lg}) {
    padding-top: 0px;
    padding-left: 0px;
    align-items: center;
  }
`;

const StyledCardFrontBottom = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
  /* border: ${parameters} darkgreen; */
  @media (max-width: 750px) {
    padding-top: 60px;
    padding-bottom: 10px;
    align-items: center;
  }
`;

const StyledCardAppFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;

  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 40px;
  /* border: ${parameters} orange; */

  /* padding-bottom: 25px; */
  /* border: ${parameters} blue; */
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  @media (max-width: ${breakpoints.lg}) {
    height: 100%;
    padding-bottom: 0px;
  }

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    padding-top: 30px;
    @media (max-width: ${breakpoints.xxl}) {
      flex-direction: column;

      justify-content: center;
      align-items: center;
    }
    @media (max-width: ${breakpoints.md}) {
      flex-direction: row;

      justify-content: center;
      align-items: center;
    }
    @media (max-width: ${breakpoints.sm}) {
      flex-direction: column;
      padding-top: 0px;
      justify-content: center;
      align-items: center;
    }
  }

  .first-button {
    outline: 0;
    width: 140px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
    @media (max-width: ${breakpoints.md}) {
      width: 200px;
    }
  }

  .second-button {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid red;
    background: #f95050;
    color: ${WHITE};
  }

  .third-button {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }

  /* button:nth-of-type(1) {
    outline: 0;
    width: 140px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
    @media (max-width: ${breakpoints.md}) {
      width: 200px;
    }
  }

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid red;
    background: #f95050;
    color: ${WHITE};
  }
  button:nth-of-type(3) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  } */
  .buttonsAcceptRejectView {
    padding-bottom: 20px;
  }
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding-bottom: 25px; */
  border: ${parameters} lightgreen;
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  /* button:nth-of-type(1) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
  } */

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }

  p {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      height: 120px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};
  }
`;

const StyledIconWrapper = styled('div')`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  top: 10px;
  right: 10px;

  /* justify-content: flex-end; */
  /* bottom: 28px; */
  /* align-self: flex-start; */
  /* padding-top: 30px; */
`;

const StyledButtonLeftSpace = styled('div')`
  padding-right: 1px;
`;

export {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardHeaderImageDescriptionRightFrontPage,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardHeaderBottomImageDescription,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledImageWrapper,
  StyledTopSmallTextContainer,
  StyledTopSmallTextBottom,
  StyledButtonLeftSpace,
  StyledButtonRow,
  StyledWrapperBottons
};
