import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from 'utils/helpers';

import withTranslations from 'translations';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import CustomCard from 'components/CustomCard';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { USER_TYPE } from 'constants/userTypes';
import { openDialog } from 'redux/modules/dialogs/reducer';

import {
  StyledFavoritesContentWrapper,
  StyledFavoritesCardsWrapper,
  StyledCardWrapper,
  StyledBlockTitle,
  StyledPreviewDetails,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo
} from './styles';
import { StyledInfoLine, StyledInfoWrapper, StyledWorkerInfoPreviewWrapper } from 'views/companyPage/Projects/styles';
import { applicationsProfileSelector, authUserSelector, favoritesProfileSelector } from '../../../redux/modules/auth/selectors';
import { companyJobs } from '../../../redux/modules/jobs/asyncThunks';
import Sanitizer from '../../../components/Sanitizer';
import PreviewDialog from '../../../components/PreviewDialog';
import http from '../../../utils/http';
import { applicationsUrl } from '../../../utils/apis';
import { prepareApplicationData } from '../Search/config';
import { getWorkerProfile, updateFavorites } from '../../../redux/modules/auth/asyncThunks';

const Favorites = () => {
  const { t } = useTranslation();
  const [activeCard, setActiveCard] = useState('');
  const [previewDetails, setPreviewDetails] = useState({});
  const favorites = useSelector(favoritesProfileSelector);
  const applications = useSelector(applicationsProfileSelector);
  const { worker } = useSelector(authUserSelector);
  const dispatch = useDispatch();
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  const [favoriteCompanyJobs, setFavoriteCompanyJobs] = useState([]);

  const getCompanyJobs = async companyId => {
    // console.log('here is setFavoriteCompanyJobs', favoriteCompanyJobs);
if (favoriteCompanyJobs.length === 0 ){
    try {
    //   console.log('trying ===>>>');
      const data = await dispatch(companyJobs(companyId)).unwrap();
      setFavoriteCompanyJobs(data);
    //   console.log('here is setFavoriteCompanyJobs agia', favoriteCompanyJobs);
    } catch (e) {
      throw Error(e);
    }} else {
        setFavoriteCompanyJobs([])
    }
  };

//   console.log("here is favoriteCompanyJobs outside ===>>>", favoriteCompanyJobs.length)
  // console.log('here is Favorites ===>>>', favorites);
  const handleOnApply = async job => {
    await http.post(applicationsUrl, prepareApplicationData({ worker, job, company: job?.company }));
    dispatch(getWorkerProfile(worker.id));
  };

  const handleOpenJobDetails = job => {
    // console.log('here is job in favorites ===>>>', job);
    setPreviewDetails({
      jobStreet: job?.address,
      jobCity: job?.city,
      jobPostCode: job?.postCode,
      jobCountry: job?.state,
      jobDescription: job?.description,
      jobDescriptionCompany: job?.descriptionCompany,
      jobContactInformation: job?.contactInformation,
      jobStartPlan: job?.startP,
      // offerSalaryAmount: offer?.salary,
      // offerSalaryType: offer?.salaryType,
      jobMonthlySalary: job?.monthlyPayroll, // check this
      jobHourly: job?.rate, // check this
      jobStartDate: job?.deadline,
      jobJobTitle: job?.title,
      companyStreet: job?.company?.address,
      companyCity: job?.company?.city,
      companyPostcode: job?.company?.postCode,
      companyCountry: job?.company?.state,
      companyMobilePhone: job?.company?.mobilePhone,
      companyPhone: job?.company?.phoneNumber,
      companyWebsite: job?.company?.website,
      companyName: job?.company?.companyName,
      companyEmail: job?.company?.contactEmail,
      companyDescription: job?.company?.description,
      logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,

      originalData: job,
      title: job?.title,
      rate: job?.rate,
      monthlyRate: job?.monthlyPayroll,
      deadline: job?.deadline,
      descriptionCompany: job?.descriptionCompany,
      description: job?.description,
      contactInformation: job?.contactInformation,
      contact: job?.company?.contactEmail,
      address: `${job?.address}, ${job?.city}, ${job.state}`,
      logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,

      // customButtonText: foundJobApplied && addTwoWeeks(foundJobApplied?.created_at) > new Date().toISOString() ? t('BUTTON.alreadyApplied') : t('BUTTON.applyNow'),
      // originalData: job,
      // title: job?.title,
      // description: job?.description,
      // contact: job?.company?.contactEmail,
      // address: job?.company?.address,
      // customButtonText: 'Apply',
      // logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,
      onApplyClick: () => handleOnApply(job),
      disabledApply: applications.find(application => application.job === job.id),
      customButtonFavText: 'Apply'
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };
  const footerMinHeightVar = {
    minHeight: '6rem'
  };

  const favoriteClick = (event, companyId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!companyId) return;

    const alreadyExist = favorites.find(fav => fav.id === companyId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), companyId];
    }

    dispatch(updateFavorites({ workerId: worker.id, payload: { favorites: updatedFavorites } }));
  };

  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('SIDE_NAVIGATION.favorites.pageTitle')} role={USER_TYPE.WORKER}>
      <StyledFavoritesContentWrapper fluid>
        <StyledFavoritesCardsWrapper sm={12} md={4}>
          {favorites.map(favorite => (
            <StyledCardWrapper key={`favorite-company-${favorite.id}`} sm={12} md={6}>
              <CustomCard
                footerMinHeight={footerMinHeightVar}
                profileImage={favorite.image?.url ? `${BE_URL}${favorite.image.url}` : DefaultProfileImage}
                sectionTitle={favorite.companyName}
                sectionSubtitle={favorite.address}
                headerDescriptionFirst={favorite.city}
                headerDescriptionSecond={favorite.state}
                headerDescriptionPhone={favorite.mobilePhone}
                headerDescriptionEmail={favorite.contactEmail}
                favCompanyDescription={favorite?.description && favorite?.description.replace(/<[^>]+>/g, '')}
                footerDescription={favorite?.description && favorite?.description.replace(/<[^>]+>/g, '')}
                isActive={activeCard === `favorite-company-${favorite.id}` ? 'true' : undefined}
                favoriteIcon
                isFavorite={(favorites || []).find(fav => fav.id === favorite.id)}
                onFavoriteClick={$event => favoriteClick($event, favorite.id)}
                click={() => {
                  setActiveCard(`favorite-company-${favorite.id}`);
                  getCompanyJobs(favorite.id);
                }}
              />
            </StyledCardWrapper>
          ))}
        </StyledFavoritesCardsWrapper>
        {favoriteCompanyJobs && favoriteCompanyJobs.length > 0 && (
          <>
            <StyledBlockTitle style={{ paddingTop: '20px' }}>{t('SIDE_NAVIGATION.favorites.jobsPosted')} </StyledBlockTitle>
            <StyledFavoritesCardsWrapper>
              {favoriteCompanyJobs.map(favoriteCompanyJob => (
                <StyledCardWrapper key={`job-${favoriteCompanyJob.id}`} sm={12} md={6}>
                  <CustomCard
                    footerMinHeight={footerMinHeightVar}
                    profileImage={favoriteCompanyJob.company?.image?.url ? `${BE_URL}${favoriteCompanyJob.company?.image?.url}` : DefaultProfileImage}
                    sectionTitle={favoriteCompanyJob.title}
                    sectionSubtitle={favoriteCompanyJob.company?.companyName}
                    headerDescriptionFirst={favoriteCompanyJob.city}
                    headerDescriptionSecond={favoriteCompanyJob.state}
                    footerDescription={favoriteCompanyJob?.description && favoriteCompanyJob?.description.replace(/<[^>]+>/g, '')}
                    hasActions
                    click={() => handleOpenJobDetails(favoriteCompanyJob)}
                  />
                </StyledCardWrapper>
              ))}
            </StyledFavoritesCardsWrapper>
          </>
        )}
      </StyledFavoritesContentWrapper>
      <PreviewDialog {...previewDetails}>
      
        <StyledPreviewDetails>
          {previewDetails?.descriptionCompany ? (
            <>
              <strong>
                <h4>{t('FORM.fields.aboutUs')}</h4>{' '}
              </strong>
              <Sanitizer review={previewDetails?.descriptionCompany} />{' '}
            </>
          ) : null}
          {previewDetails?.description ? (
            <>
              <strong>
                <h4>{t('FORM.fields.jobDescription')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.description} />{' '}
            </>
          ) : null}
          {previewDetails?.contactInformation ? (
            <>
              <strong>
                <h4>{t('FORM.fields.descriptionContact')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.contactInformation} />{' '}
            </>
          ) : null}
          <Row>
            {previewDetails?.rate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.rate')}: </strong>CHF {previewDetails?.rate}.-
              </Col>
            )}
            {previewDetails?.monthlyRate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.ratePerMonth')}:</strong> CHF {previewDetails?.monthlyRate}.-
              </Col>
            )}
            {previewDetails?.deadline && formatDate(new Date(previewDetails?.deadline)) !== ('01/01/1970') ? (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.startDate')}:</strong> {formatDate(new Date(previewDetails?.deadline))}
              </Col>
            ): ""}
             {previewDetails?.jobStartPlan && (
              <Col sm={12} md={12} xl={12} style={{ paddingBottom: '10px' }}>
                <strong>{t('OFFERCARD.startDate')}:</strong> {t(`FORM.fields.${previewDetails?.jobStartPlan}`)}
              </Col>
            )}
            {previewDetails?.address && (
              <div style={{ paddingTop: '10px' }}>
                <h4>{t('FORM.fields.address')}</h4>
                <StyledPreviewDialogFooterInfo>{previewDetails?.address}</StyledPreviewDialogFooterInfo>
              </div>
            )}
          </Row>
        </StyledPreviewDetails>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Favorites, 'Favorites');
