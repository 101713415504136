/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FarStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as FaStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import Sanitizer from '../../components/Sanitizer';
import { useWindowDimensions } from 'utils/helpers';

import { APP_RED, WHITE, DARK_BLUE, DARK_BUTTON } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderBottomImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledRowButtons,
  StyledButtonFirst,
  StyledButtonSecond
} from 'components/CustomCardApplicationsWorker/styles';

// eslint-disable-next-line complexity
const CustomCard = ({
  headerDescriptionEmail,
  headerDescriptionPhone,
  favCompanyDescription,
  profileImage,
  sectionTitle,
  sectionSubtitle,
  handleActiveDeactivateJob,
  upperButtonText,
  lowerButtonText,
  handleDeleteJob,
  handleEditJob,
  editButtonText,
  headerDescriptionFirst,
  headerDescriptionSecond,
  headerDescriptionThird,
  headerDescriptionFourth,
  headerDescriptionFifth,
  headerDescriptionExtra,
  headerDescriptionFirstRight,
  headerDescriptionSecondRight,
  headerDescriptionThirdRight,
  footerDescription,
  profileClick,
  favoriteIcon,
  isFavorite,
  onFavoriteClick,
  isActive,
  click,
  hasActions,
  handleOnItemClick,
  handleOnItemClickReject,
  acceptText,
  rejectText,
  onApplyClick,
  onAddRemoveClick,
  plusMinusSign,
  footerMinHeight,
  deadline,
  headerDescriptionPayrollMonthly,
  headerDescriptionFourthFrontPage,
  termination,
  handleOnClick,
  headerDescriptionHourly,
  startPlan,
  postCode

  // onApplyButton
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  // console.log(footerDescription);

  // const footerMinHeight = {
  //   minHeight: '6rem'
  // };
  console.log('here is startPlan==>>', startPlan);
  console.log('here is deadline==>>', deadline);
  return (
    <StyledCard onClick={click} active={isActive ? 'true' : 'false'}>
      <StyledCardHeader>
        <StyledIconWrapper>
          {favoriteIcon &&
            (isFavorite ? (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FaStar} color={APP_RED} fontSize="19px" />
            ) : (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FarStar} color={APP_RED} fontSize="19px" />
            ))}
        </StyledIconWrapper>
        <StyledCardHeaderImageWrapper>
          <StyledCardHeaderImageDescription>
            <StyledCardHeaderImage onClick={profileClick} src={profileImage} />{' '}
          </StyledCardHeaderImageDescription>
          <StyledCardRowWrapperCol>
            <StyledCardHeaderImageDescription>
              <StyledCardHeaderImageDescriptionFirst>
                {/* <CardName> */}
                {width > 1700 && sectionTitle ? sectionTitle.slice(0, 38): ""}
                  {width > 1700 && sectionTitle && sectionTitle.length > 37 ? '...' : ''}
                  {width > 900 && width < 1700 && sectionTitle ? sectionTitle.slice(0, 24): ""}
                  {width > 900 && width < 1700 && sectionTitle && sectionTitle.length > 23? '...' : ''}
                  {width <= 900 && sectionTitle ? sectionTitle.slice(0, 23): ""}
                  {width <= 900 && sectionTitle && sectionTitle.length > 22 ? '...' : ''}
                {/* </CardName> */}
              </StyledCardHeaderImageDescriptionFirst>
              <span onClick={profileClick}>
                {/* {sectionSubtitle && sectionSubtitle.slice(0, 15)}
                {sectionSubtitle && sectionSubtitle.length > 15 ? '...' : ''} */}
                 {width > 1700 && sectionSubtitle ? sectionSubtitle.slice(0, 45): ""}
                  {width > 1700 && sectionSubtitle && sectionSubtitle.length > 44 ? '...' : ''}
                  {width > 900 && width < 1700 && sectionSubtitle ? sectionSubtitle.slice(0, 34): ""}
                  {width > 900 && width < 1700 && sectionSubtitle && sectionSubtitle.length > 33? '...' : ''}
                  {width <= 900 && sectionSubtitle ? sectionSubtitle.slice(0, 31): ""}
                  {width <= 900 && sectionSubtitle && sectionSubtitle.length > 30 ? '...' : ''}
              </span>
              <span>
                {headerDescriptionThird && headerDescriptionThird.slice(0, 20) + `\u0020`}
                {headerDescriptionThird && headerDescriptionThird.toString().length > 20 ? '...' : ''}
                {headerDescriptionFifth && headerDescriptionFifth.toString().slice(0, 6)}
                {headerDescriptionFifth && headerDescriptionFifth.toString().length > 6 ? '...' : ''}
                {headerDescriptionFirst && headerDescriptionFirst.slice(0, 31)}
                {headerDescriptionFirst && headerDescriptionFirst.length > 31 ? '...' : ''} {postCode && postCode}
                {headerDescriptionSecond && <span>,&nbsp;</span>}
                {width > 800 ? headerDescriptionSecond && headerDescriptionSecond.slice(0, 15) : headerDescriptionSecond && headerDescriptionSecond.slice(0, 8)}{' '}
                {headerDescriptionSecond && headerDescriptionSecond.length > 15 ? '...' : ''} &nbsp;
              </span>{' '}
              {deadline && startPlan && startPlan !== 'immediately' ? t('OFFERCARD.startDate') : ''} {deadline !== '01/01/1970' ? deadline : ''}
              {`\u0020`}
              {startPlan && t(`FORM.fields.${startPlan}`)}
              {headerDescriptionHourly && (
                <span>
                  {t('OFFERCARD.salaryDisplay.perHour')}: CHF {headerDescriptionHourly}.-
                </span>
              )}
              {headerDescriptionPayrollMonthly && (
                <span>
                  {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}.-
                </span>
              )}
              <p>{headerDescriptionExtra && headerDescriptionExtra}</p>
              {/* {headerDescriptionThird && headerDescriptionThird.slice(0, 10)} {headerDescriptionThird && headerDescriptionThird.length > 10 ? '...' : ''} */}
              {headerDescriptionPhone && <span>Phone: {headerDescriptionPhone}, &nbsp;</span>}
              {headerDescriptionEmail && <span>Email: {headerDescriptionEmail}</span>}
            </StyledCardHeaderImageDescription>
            {(headerDescriptionFirstRight || headerDescriptionSecondRight || headerDescriptionThirdRight) && (
              <StyledCardHeaderBottomImageDescription>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionFirstRight}</span>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionSecondRight}</span>
                {headerDescriptionThirdRight && <span style={{ whiteSpace: 'nowrap' }}>Kat. {headerDescriptionThirdRight}</span>}
              </StyledCardHeaderBottomImageDescription>
            )}
           
              <StyledCardHeaderImageDescription>
                {/* <StyledCardFrontPage> */}
                  {/* <span>
                    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}
                  </span> */}
                  {deadline && (
                    <p style={footerMinHeight}>
                      {t('OFFERCARD.startDate')}: {deadline}
                    </p>
                  )}
                {/* </StyledCardFrontPage> */}
              </StyledCardHeaderImageDescription>
            
          </StyledCardRowWrapperCol>
        </StyledCardHeaderImageWrapper>
      </StyledCardHeader>
      {headerDescriptionFourthFrontPage && (
        <StyledCardFrontBottom>
          <span>{headerDescriptionFourthFrontPage}</span>
        </StyledCardFrontBottom>
      )}
      {headerDescriptionFourth && (
        <StyledCardHeaderBellowImage>
          <span>{headerDescriptionFourth}</span>
        </StyledCardHeaderBellowImage>
      )}
      {/* <StyledCardFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {favCompanyDescription && <p style={footerMinHeight}>{favCompanyDescription}</p>}
      </StyledCardFooter> */}
      <StyledFooterBottom style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {/* {footerDescription && (
          <p style={footerMinHeight}>
            <Sanitizer review={footerDescription} />
          </p>
        )} */}
      </StyledFooterBottom>
      {termination && (
        <CustomButton
          text="Kündigen"
          customTextColor={WHITE}
          customButtonColor={APP_RED}
          customWidth="120px"
          handleOnClick={() => onCompletedClick(application.job.id)}
        />
      )}
      {hasActions && (
        <>
          <StyledProjectsButtonRow>
            {onApplyClick && <CustomButton text={t('BUTTON.applyNow')} customTextColor={WHITE} customWidth="120px" handleOnClick={onApplyClick} />}
          </StyledProjectsButtonRow>
          <StyledProjectsButtonRow>
            {onAddRemoveClick && <CustomButton text={plusMinusSign} customTextColor={WHITE} customWidth="120px" handleOnClick={onApplyClick} />}
          </StyledProjectsButtonRow>
          <StyledCardAppFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {(handleOnItemClick || handleOnItemClickReject) && (
              <div className="actions-wrapper">
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClickReject}>
                    {rejectText || 'Close'}
                  </button>
                )}
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClick}>
                    {acceptText || 'Open'}
                  </button>
                )}
              </div>
            )}
          </StyledCardAppFooter>
        </>
      )}

      {/* <Col> */}
      {/* <StyledCardBodyRight> */}
      {upperButtonText && (
        <div style={{ backgroundColor: 'white', paddingTop: '10px' }}>
          <StyledRowButtons>
            <StyledButtonFirst>
              <CustomButton
                text={upperButtonText}
                custombuttoncolor={WHITE}
                customHeight="30px"
                customWidth="80px"
                customTextColor={DARK_BLUE}
                custombordercolor={APP_RED}
                //  hasIcon
                //  icon="pen"
                iconFontSize="15px"
                customFontSize="12px"
                handleOnClick={handleActiveDeactivateJob}
              />
            </StyledButtonFirst>
            <div style={{ width: '5px' }}></div>
            <StyledButtonSecond>
              <CustomButton
                text={lowerButtonText}
                custombuttoncolor={WHITE}
                customHeight="30px"
                customWidth="65px"
                customTextColor={DARK_BLUE}
                custombordercolor={APP_RED}
                //  hasIcon
                //  icon="pen"
                iconFontSize="15px"
                customFontSize="12px"
                handleOnClick={handleDeleteJob}
              />
            </StyledButtonSecond>
            <div style={{ width: '5px' }}></div>
            <CustomButton
              text={editButtonText}
              customButtonColor={WHITE}
              customHeight="30px"
              customWidth="75px"
              customTextColor={WHITE}
              custombordercolor={APP_RED}
              //  hasIcon
              //  icon="pen"
              iconFontSize="15px"
              customFontSize="12px"
              handleOnClick={handleEditJob}
            />
          </StyledRowButtons>
        </div>
      )}
      <Row>
        {/* {upperButtonText && (
              <button className="edit-button" type="button" onClick={handleActiveDeactivateJob}>
                {upperButtonText}
              </button>
            )}
            {lowerButtonText && (
              <button className="delete-button" type="button" onClick={handleDeleteJob}>
                {lowerButtonText}
              </button>
            )}
            {editButtonText && (
              <button className="edit-button" type="button" onClick={handleEditJob}>
                {editButtonText}
              </button>
            )} */}
        {/* </StyledCardBodyRight>
        </Col> */}
      </Row>
    </StyledCard>
  );
};

CustomCard.defaultProps = {
  profileImage: null,
  sectionTitle: '',
  headerDescriptionFirst: null,
  headerDescriptionSecond: null,
  hasActions: false,
  sectionSubtitle: null,
  isActive: false
};

CustomCard.propTypes = {
  profileImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  headerDescriptionFirst: PropTypes.string,
  headerDescriptionSecond: PropTypes.string,
  hasActions: PropTypes.bool,
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CustomCard;
