export const jobsSelector = ({ jobsList }) => {
  const { jobs } = jobsList;

  return jobs;
};

export const jobsByCompanySelector = ({ jobsList }) => {
  const { jobsCompany } = jobsList;

  return jobsCompany;
};

export const jobSelector = ({ jobsList }, jobId) => {
  const { jobs } = jobsList;

  return (jobs || []).find(job => job.id === jobId);
};

export const countJobsSelector = ({ jobsList }) => {
  const { jobsCount } = jobsList;

  return jobsCount;
};

export const applicationsSelector = ({ jobsList }) => {
  const { applications } = jobsList;

  return applications;
};
