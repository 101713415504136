import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/helpers';

import { showToast } from 'redux/modules/toast/reducer';
import Sanitizer from 'components/Sanitizer';
import withTranslations from 'translations';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import CustomCard from 'components/CustomCarWorkerEmploy';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { USER_TYPE } from 'constants/userTypes';
import { APP_RED, GRAY, WHITE } from 'constants/colors';
import Filters from 'components/Filters';
import { openDialog, closeDialog } from '../../../redux/modules/dialogs/reducer';
import PreviewDialog from '../../../components/PreviewDialog';
import PreviewTerminate from '../../../components/PreviewTerminate';
import { jobsSelector } from '../../../redux/modules/jobs/selectors';
import { updateJobOffers, workerOffers } from '../../../redux/modules/offers/asyncThunks';
import { fetchProjectsList, updateProject, updateProjekts, fetchProjectsByWorkerList, updateProjectByWorker } from 'redux/modules/projects/asyncThunks';
import { useWindowDimensions } from 'utils/helpers';

import {
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledOngoingEmploymentColWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledOngoingInfoWrapper,
  StyledOngoingInfoColumnWrapper,
  StyledFieldWrapper,
  StyledFieldTitle,
  StyledFieldValue,
  StyledDescription,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledDescriptionTitle,
  StyledTextareaField,
  StyledDatePickerField,
  StyledColLeft,
  StyledColRight,
  StyledRow,
  StyledRowButtons,
  StyledReason,
  StyledRowDate,
  StyledPreviewDetails,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo
} from './styles';
import { StyledInfoLine, StyledInfoWrapper, StyledWorkerInfoPreviewWrapper } from 'views/companyPage/Projects/styles';
import CustomButton from 'components/CustomButton';
import { authProfileSelector, authUserSelector } from '../../../redux/modules/auth/selectors';
import { workerApplications, updateJob, updateApplication } from '../../../redux/modules/jobs/asyncThunks';
import { WorkerOfferSelector } from 'redux/modules/offers/selectors';
import { projectsSelector } from 'redux/modules/projects/selectors';
import { countWorkersSelector, workersSelector } from '../../../redux/modules/workers/selectors';
import { formatShortDate } from '../../../utils/helpers';
import http from '../../../utils/http';
import { applicationsUrl, projectsUrl } from '../../../utils/apis';
import { applicationsSelector } from '../../../redux/modules/jobs/selectors';

const Employment = () => {
  const { t } = useTranslation();
  const offers = useSelector(WorkerOfferSelector);
  const worker = useSelector(authProfileSelector);
  const workersList = useSelector(workersSelector);

  const dispatch = useDispatch();
  const userInfo = useSelector(authUserSelector);
  const [role, setRole] = useState(userInfo?.role.type);
  const projectList = useSelector(projectsSelector);
  const jobList = useSelector(jobsSelector);
  const [applications, setApplications] = useState([]);
  const [previewWorker, setPreviewWorker] = useState(null);
  const [previewOffer, setPreviewOffer] = useState(null);
  const [activatePopUp, setActivatePopUp] = useState(false);
  const [previewJobDetails, setPreviewJobDetails] = useState([]);
  const [previewApplication, setPreviewApplication] = useState(null);
  const [finishedApplications, setFinishedApplications] = useState([]);
  const [terminationReason, setTerminationReason] = useState('');
  const [terminationDate, setTerminationDate] = useState(null);
  const [previewDetails, setPreviewDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [tab, setTab] = useState('open');
  const [allWorkerApplications, setAllWorkerApplications] = useState([]);
  const applicationList = useSelector(applicationsSelector);
  const { width } = useWindowDimensions();

  const BE_URL = ``;
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;

  const footerMinHeightVar = {
    minHeight: '0.1rem'
  };
  let userIdOfCompany;
  // GET ALL WORKER APPLICATIONS TO UPDATE THE APPLICATION WHEN TERMINATED
  useEffect(() => {
    (async () => {
      try {
        const { data } = await http.get(applicationsUrl);
        setAllWorkerApplications(data);
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, []);
  // console.log('here is jobList ppp', jobList);
  useEffect(() => {
    dispatch(workerApplications(worker.id));
    // console.log('here is applications inside useEffect');
  }, [applicationList]);
  // console.log('here is allWorkerApplications ====>>>>', allWorkerApplications);
  // console.log('here is worker.id ===>>>', worker.id);
  // console.log('here is applicationList ===>>>', applicationList);

  useEffect(async () => {
    dispatch(workerOffers(worker.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // console.log('Here is offers ====>>>>', offers);

  useEffect(() => {
    try {
      (async () => {
        const applicationsResponse = await dispatch(workerApplications(worker.id)).unwrap();
        setApplications(applicationsResponse.filter(apps => apps?.status === 'accepted'));
        setFinishedApplications(applicationsResponse.filter(apps => apps?.status === 'accepted' && apps?.job?.status === 'finished'));
      })();
    } catch (e) {
      throw new Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobList, dispatch, applicationList]);

  const onCompletedClick = async jobId => {
    try {
      const updateThisJob = await dispatch(updateJob({ jobId: jobId, payload: { status: 'finished' } }));
      if (updateThisJob) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.userTerminated.success') }));
      }
    } catch (error) {
      console.log('error occured while trying to terminate contract', error);
    }
  };
  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const onTerminateClick = async (jobId, workerId) => {
    let userId = workerId.toString();
    // console.log("here is companyId ---==>>", companyId)
    // userIdOfCompany = applications.filter()
    // console.log('here is userId ===>>>', userId);
    try {
      // console.log('Here is data stuff', jobId, userId, terminationDate, terminationReason);
      const terminateJob = await dispatch(
        updateJob({
          jobId: jobId,
          payload: {
            // workerId: workerId,
            terminatedBy: userId,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason,
            status: 'finished'
          }
        })
      ).unwrap();
      if (terminateJob) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.userTerminated.success') }));
      } else {
        dispatch(showToast({ type: 'failed', message: t('MESSAGES.userTerminated.failed') }));
      }
      dispatch(
        closeDialog({
          dialogName: 'previewTerminate'
        })
      );
    } catch (error) {
      console.log('an error occured', error);
    }
    // UPDATE THE WORKER APPLICATION WITH TERMINATION INFO - find application ID and update it with termination content - check job for company id, then fetch users which have company id, then fetch projects from that user, then remove worker from all projects of the user
    let applicationFull = await allWorkerApplications.find(application => jobId === application.job.id);
    // console.log("here is applicationFull ==-->>", applicationFull)
    if (applicationFull) {
      let companyId = applicationFull.company.id;
      // console.log('here is companyId ====>>>>', companyId);
      let applicationId = applicationFull.id;
      let idOfWorker = userInfo.worker.id;
      dispatch(
        updateApplication({
          applicationId,
          payload: {
            workerId: idOfWorker,
            companyId: companyId,

            terminatedBy: userId,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason
          }
        })
      );
      if (companyId) {
        // Object.assign(params, { company: companyId });
        //  await dispatch(fetchProjectsByWorkerList({ ...params }));

        const { data } = await http.get(projectsUrl);
        // console.log('here is data of projects ==>>', data);

        const targetCompanyId = companyId; // Change this to your desired companyId
// console.log("her eis this companyId", companyId)
        // The workerId yocu want to remove
        const targetWorkerId = idOfWorker; // Change this to your desired workerId
        // console.log("her eis this idOfWorker", idOfWorker)
        // Update projects: filter by companyId, then remove specific worker from matching projects
        const updatedProjectList = data.map(project => {
          
          if (project.company.company === targetCompanyId) {
            const updatedWorkers = project.workers.filter(worker => worker?.id !== targetWorkerId);
            return { ...project, workers: updatedWorkers };
          }
          return project;
          
        });
        dispatch(updateProjekts(updatedProjectList));
        // console.log("here is updatedProjects==>>", updatedProjectList)
        
      } else {
        console.log('couldnt get projects line 214 in employment');
      }
      // console.log("here is projectList ss==>>", projectList)
      // const updatedProjectList = projectList.map(project => {
      //   const updatedProject = { ...project };
      //   // console.log('here is updatedProject ===>>>', updatedProject);
      //   updatedProject.workers = updatedProject.workers.filter(worker => worker.id !== idOfWorker);
      //   // console.log('here is updatedProjectList ===>>>', updatedProjectList);
      //   return updatedProject;
      // });
    } else {
      console.log('No application found for the given jobId.');
    }
    setActivatePopUp(false);
  };

  // useEffect(() => {
  //   if (companyId) {
  //     Object.assign(params, { company: userInfo?.id });
  //     dispatch(fetchProjectsByWorkerList({ ...params }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userInfo]);
  // console.log('here is applications ya know', applications);
  // console.log('here is userInfo ===>>>', userInfo);
  // jobId below is offer id and workerId is the user id
  const onTerminateOfferClick = async (jobId, workerId, realWorkerId) => {
    let userId = workerId.toString();
    try {
      const terminateOffer = await dispatch(
        updateJobOffers({
          jobOfferId: jobId,
          payload: {
            terminatedBy: userId,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason,
            activated: false
          }
        })
      );
      dispatch(workerOffers(worker.id));
      // console.log("here is terminateOffer ===>>>", terminateOffer)
      if (terminateOffer) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.userTerminated.success') }));
      } else {
        dispatch(showToast({ type: 'failed', message: t('MESSAGES.userTerminated.failed') }));
      }
      dispatch(
        closeDialog({
          dialogName: 'previewTerminate'
        })
      );
    } catch (error) {
      console.log('====error occured while terminating offer====', error);
    }

    const updatedProjectList = projectList.map(project => {
      const updatedProject = { ...project };
      updatedProject.workers = updatedProject.workers.filter(worker => worker.id !== realWorkerId);
      return updatedProject;
    });
    // console.log('here is updatedProjectList ===>>>', updatedProjectList);
    dispatch(updateProjekts(updatedProjectList));
    setActivatePopUp(false);
  };

  const openPopUpTerminateJob = (e, application) => {
    // console.log('hellofrom application ====>>>>', application);
    e.stopPropagation();
    e.preventDefault();
    setPreviewJobDetails({
      originalData: application,
      companyId: application?.company?.id,
      companyName: application?.company?.company,
      title: application?.company.companyName,
      jobId: application?.job?.id,
      workerId: application?.worker.user,
      subtitle: application?.job.title,
      logoUrl: application?.company.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      isOffer: false
    });

    dispatch(
      openDialog({
        dialogName: 'previewTerminate'
      })
    );
  };

  const handleTerminateClick = (e, application) => {
    e.stopPropagation();
    e.preventDefault();
    // alert("hi")
    openPopUpTerminateJob(e, application);
  };
  ///////////// offer
  const openPopUpTerminateOffer = (e, application) => {
    e.stopPropagation();
    e.preventDefault();
    // console.log('hello from  openPopUpTerminateOffer application====>>>>', application);
    setPreviewJobDetails({
      originalData: application,
      companyName: application?.company?.companyName,
      title: application?.company?.companyName,
      jobId: application?.id,
      workerId: application?.worker?.user?.id,
      subtitle: application?.jobTitle,
      logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      isOffer: true,
      realWorkerId: application?.worker?.id
    });

    dispatch(
      openDialog({
        dialogName: 'previewTerminate'
      })
    );
  };

  const handleTerminateOfferClick = (e, application) => {
    e.stopPropagation();
    e.preventDefault();
    openPopUpTerminateOffer(e, application);
    // console.log('hello from  handleTerminateOfferClick====>>>>', application);
  };

  const handleOpenJobDetails = offer => {
    // console.log('hello from handleOpenJobDetails ===>>>');
    // const employeeFullProfile = (workersList || []).find(employee => employee.id === worker.id);
    // console.log('here is job =====>>>>', offer);
    setPreviewApplication(offer);
    setPreviewWorker({
      jobStreet: offer?.job?.address,
      jobCity: offer?.job?.city,
      jobPostCode: offer?.job?.postCode,
      jobCountry: offer?.job?.state,
      jobDescription: offer?.job?.description,
      jobDescriptionCompany: offer?.job?.descriptionCompany,
      jobContactInformation: offer?.job?.contactInformation,
      jobStartPlan: offer?.job?.startP,
      // offerSalaryAmount: offer?.salary,
      // offerSalaryType: offer?.salaryType,
      jobMonthlySalary: offer?.job?.monthlyPayroll, // check this
      jobHourly: offer?.job?.rate, // check this
      jobStartDate: offer?.job?.deadline,
      jobJobTitle: offer?.job?.title,
      companyStreet: offer?.company?.address,
      companyCity: offer?.company?.city,
      companyPostcode: offer?.company?.postCode,
      companyCountry: offer?.company?.state,
      companyMobilePhone: offer?.company?.mobilePhone,
      companyPhone: offer?.company?.phoneNumber,
      companyWebsite: offer?.company?.website,
      companyName: offer?.company?.companyName,
      companyEmail: offer?.company?.contactEmail,
      companyDescription: offer?.company?.description,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    setPreviewDetails({
      // originalData: job,
      // title: job?.job?.title,

      // rate: job?.job?.rate,
      // monthlyRate: job?.job?.monthlyPayroll,
      // deadline: job?.job?.deadline,
      // descriptionCompany: job?.job?.descriptionCompany,
      // description: job?.job?.description,
      // contactInformation: job?.job?.contactInformation,
      // contact: job?.company?.contactEmail,
      // address: `${job?.job?.address}, ${job?.job?.city}, ${job.job?.state}`,
      // logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null

      originalData: offer,
      title: offer?.company?.companyName,
      // subtitle: offer?.job.title,
      workerJobTitle: offer?.job?.title,
      // workerName: offer?.worker?.user?.name,
      // workerStreet: offer?.worker?.description,
      // workerCity: offer?.worker?.city,
      // workerPostcode: offer?.worker?.rate,
      // workerCountry: offer?.worker?.state,
      // workerEmail: offer?.worker?.user?.email,
      // workerCitizenship: offer?.worker?.citizenship,
      // workerBirthdate: offer?.worker?.birthdate,
      // workerDriverLicenses: offer?.worker?.driverLicenses,
      // workerHourlyRate: offer?.worker?.hourlyRate,
      // workerMonthlyRate: offer?.worker?.monthlySalary,
      // workerMobilePhone: offer?.worker?.mobilePhone,
      // workerBio: offer?.worker?.jobDescription,
      offerStreet: offer?.address?.streatAddress,
      offerCity: offer?.address?.city,
      offerPostCode: offer?.address?.postCode,
      offerCountry: offer?.address?.country,
      offerDescription: offer?.description,
      offerSalaryAmount: offer?.salary,
      offerSalaryType: offer?.salaryType,
      offerStartDate: offer?.startDate,
      offerJobTitle: offer?.jobTitle,
      companyStreet: offer?.company?.address,
      companyCity: offer?.company?.city,
      companyPostcode: offer?.company?.postCode,
      companyCountry: offer?.company?.state,
      companyMobilePhone: offer?.company?.mobilePhone,
      companyPhone: offer?.company?.phoneNumber,
      companyWebsite: offer?.company?.website,
      companyName: offer?.company?.companyName,
      companyEmail: offer?.company?.contactEmail,
      companyDescription: offer?.company?.description,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const handleOpenJobOfferDetails = offer => {
    // console.log('hello from handleOpenJobDetails ===>>>');
    // const employeeFullProfile = (workersList || []).find(employee => employee.id === worker.id);
    // console.log('here is joboffer =====>>>>', offer);
    setPreviewApplication(offer);
    setPreviewOffer({
      offerStreet: offer?.address?.streatAddress,
      offerCity: offer?.address?.city,
      offerPostCode: offer?.address?.postCode,
      offerCountry: offer?.address?.country,
      offerDescription: offer?.description,
      offerSalaryAmount: offer?.salary,
      offerSalaryType: offer?.salaryType,
      offerStartDate: offer?.startDate,
      offerJobTitle: offer?.jobTitle
    });

    setPreviewWorker({
      companyStreet: offer?.company?.address,
      companyCity: offer?.company?.city,
      companyPostcode: offer?.company?.postCode,
      companyCountry: offer?.company?.state,
      companyMobilePhone: offer?.company?.mobilePhone,
      companyPhone: offer?.company?.phoneNumber,
      companyWebsite: offer?.company?.website,
      companyName: offer?.company?.companyName,
      companyEmail: offer?.company?.contactEmail,
      companyDescription: offer?.company?.description,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    setPreviewDetails({
      title: offer?.company?.companyName,
      // subtitle: offer?.job.title,
      workerJobTitle: offer?.jobTitle,
      originalData: offer,
      // title: offer?.jobTitle,
      // children : '<div style={display: flex}><h3> CHF 20</h3> </div>',
      rate: offer?.salary,
      monthlyRate: offer?.job?.monthlyPayroll,
      deadline: offer?.job?.deadline,
      descriptionCompany: offer?.company.description,
      description: offer?.description,
      contactInformation: offer?.job?.contactInformation,
      contact: offer?.company?.contactEmail,
      address: `${offer?.address?.streatAddress}, ${offer?.address?.city}, ${offer.address?.postCode}`,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const filterBySearch = application =>
    (application?.job?.address && application?.job?.address.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.city && application?.job?.city.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.postCode && application?.job?.postCode.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.state && application?.job?.state.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.company?.companyName && application?.company?.companyName.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.description && application?.job?.description.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.state && application?.state?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.shortDescription && application?.job?.shortDescription?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.title && application?.job?.title.toLowerCase().includes(searchTerm?.toLowerCase()));

  const filterByTab = offer => {
    if (tab === 'finished') {
      return offer.activated === false;
    } else if (tab === 'open') {
      return offer.activated === true && offer.status === 'Accepted';
    }
  };

  // console.log('here is tab', tab);

  console.log(
    'here issss applications ===>>>',
    applications
  );

  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('SIDE_NAVIGATION.myEmployment.pageTitle')} role={USER_TYPE.WORKER}>
      <StyledEmploymentContentWrapper>
        {width >= 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} /> : ""}
        <StyledEmploymentInfoWrapper>
          <StyledCompletedEmploymentColWrapper xs={12} >
          {width >= 900  ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'open'} onClick={() => setTab('open')}>
                {t('SIDE_NAVIGATION.myEmployment.ongoingEmployments')} (
                {(applications || []).filter(a => a.job.status === 'open' && !a.terminatedBy)?.length +
                  (offers || []).filter(offer => offer?.status === 'Accepted' && offer?.activated === true)?.length || 0}
                )
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'finished'} onClick={() => setTab('finished')}>
                {t('SIDE_NAVIGATION.myEmployment.completedEmployments')} (
                {(applications || []).filter(a => a.job.status === 'open' && a.terminatedBy)?.length +
                  (offers || []).filter(offer => offer?.status === 'Accepted' && offer?.activated === false)?.length || 0}
                )
              </StyledTitleBox>
              {/* <StyledTitleBox active={tab === 'rejected'} onClick={() => setTab('rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(applications || []).filter(a => a.status === 'rejected')?.length || 0})
              </StyledTitleBox> */}
            </StyledTitleBoxWrapper>: "" }
            <StyledEmploymentCardWrapper>
            {width < 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} /> : ""}
            {width < 900  ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'open'} onClick={() => setTab('open')}>
                {t('SIDE_NAVIGATION.myEmployment.ongoingEmployments')} (
                {(applications || []).filter(a => a.job.status === 'open' && !a.terminatedBy)?.length +
                  (offers || []).filter(offer => offer?.status === 'Accepted' && offer?.activated === true)?.length || 0}
                )
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'finished'} onClick={() => setTab('finished')}>
                {t('SIDE_NAVIGATION.myEmployment.completedEmployments')} (
                {(applications || []).filter(a => a.job.status === 'open' && a.terminatedBy)?.length +
                  (offers || []).filter(offer => offer?.status === 'Accepted' && offer?.activated === false)?.length || 0}
                )
              </StyledTitleBox>
              {/* <StyledTitleBox active={tab === 'rejected'} onClick={() => setTab('rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(applications || []).filter(a => a.status === 'rejected')?.length || 0})
              </StyledTitleBox> */}
            </StyledTitleBoxWrapper>: "" }
              <StyledSearchResultContent xs={12} xxl={4}>
                {applications
                  // .filter(filteredByDate)
                  // .filter(application => application.job.status === tab)
                  .filter(application =>
                    tab === 'finished'
                      ? application.job.status === 'open' && application.terminatedBy
                      : tab === 'open'
                      ? application.job.status === 'open' && !application.terminatedBy
                      : null
                  )
                  .filter(filterBySearch)
                  .map((application, index) => (
                    <StyledCardWrapper key={`worker-${application?.company?.id}-${index}`} sm={12} md={6} lg={5} xxl={4}>
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        profileImage={
                          userInfo?.role?.type === 'company'
                            ? application?.worker?.image?.url
                              ? `${BE_URL}${application.worker?.image?.url}`
                              : DefaultProfileImage
                            : application?.company?.image?.url
                            ? `${BE_URL}${application?.company?.image?.url}`
                            : DefaultProfileImage
                        }
                        notArbeitsStelle
                        isCompleted={application?.terminatedBy && true}
                        sectionTitle={application?.company?.companyName}
                        sectionSubtitle={application?.job?.title}
                        // headerDescriptionFirst={worker.citizenship}
                        headerDescriptionThird={application?.job?.state}
                        headerDescriptionSecond={application?.job?.city}
                        // headerDescriptionFifth={application?.job?.rate}
                        headerDescriptionFirstRight={`CHF ${application?.job?.monthlyPayroll || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                        headerDescriptionSecondRight={`CHF ${application.job?.rate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                        headerDescriptionThirdRight={application?.worker?.driverLicences}
                        footerDescription={<Sanitizer review={application?.job?.description} />}
                        // hasActions
                        favoriteIcon
                        isNotOffer
                        handleTerminateClick={e => handleTerminateClick(e, application)}
                        // isFavorite={(favorites || []).find(fav => fav.id === worker.id)}
                        // onFavoriteClick={$event => favoriteClick($event, worker.id)}
                        click={() => handleOpenJobDetails(application)}
                      />
                    </StyledCardWrapper>
                  ))}
                {offers
                  // .filter(filteredByDate)
                  .filter(filterByTab)
                  .filter(filterBySearch)
                  .map(application => (
                    <StyledCardWrapper key={`worker-${application.company?.id}`} sm={12} md={6} lg={5}>
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        isCompleted={application.terminatedBy && true}
                        profileImage={
                          userInfo?.role?.type === 'company'
                            ? application?.worker?.image?.url
                              ? `${BE_URL}${application.worker?.image?.url}`
                              : DefaultProfileImage
                            : application?.company?.image?.url
                            ? `${BE_URL}${application?.company?.image?.url}`
                            : DefaultProfileImage
                        }
                        sectionTitle={application?.company?.companyName}
                        sectionSubtitle={application?.jobTitle}
                        headerDescriptionFirst={application?.address?.city}
                        postCode={application?.address?.postCode}
                        headerDescriptionPayrollMonthly={application?.monthlyPayroll}
                        headerDescriptionHourly={application?.salary}
                        // headerDescriptionFirst={`${t('OFFERCARD.salaryAmount')}: CHF ${application.salary || 0} ${
                        //   application.salaryType === 'Hourly' ? t('OFFERCARD.salaryDropDown.perHour') : t('OFFERCARD.salaryDropDown.perMonth')
                        // }`}
                        headerDescriptionThird={application?.address?.country}
                        headerDescriptionFirstRight={
                          application?.salaryType === 'Monthly'
                            ? `CHF ${application?.salaryType === 'Monthly' ? application?.salary : ''}.- ${t(
                                application?.salaryType === 'Monthly' ? 'EMPLOYEE_PROFILE.MY_PROFILE.perMonth' : ''
                              )}`
                            : ''
                        }
                        headerDescriptionSecondRight={
                          application.salaryType === 'Hourly'
                            ? `CHF ${application.salaryType === 'Hourly' ? application?.salary : ''}.- ${t(
                                application?.salaryType === 'Hourly' ? 'EMPLOYEE_PROFILE.MY_PROFILE.perHour' : ''
                              )}`
                            : ''
                        }
                        // headerDescriptionThird={`${t('OFFERCARD.status')}: ${
                        //   application.status === 'Pending'
                        //     ? t('SIDE_NAVIGATION.offers.openOffers')
                        //     : application.status === 'Accepted'
                        //     ? t('SIDE_NAVIGATION.applications.accepted')
                        //     : t('SIDE_NAVIGATION.applications.declined')
                        // }`}
                        favoriteIcon
                        // headerDescriptionExtra={`${t('OFFERCARD.startDate')}: ${formatDate(new Date(application.startDate))}`}
                        footerDescription={application?.description}
                        hasActions
                        // handleOnItemClick={application.status === 'Pending' ? $event => acceptApplication($event, application.id, application) : undefined}
                        // handleOnItemClickReject={application.status === 'Pending' ? $event => rejectApplication($event, application.id) : undefined}
                        // acceptText={application.status === 'Pending' ? t('BUTTON.applicationAccept') : undefined}
                        // rejectText={application.status === 'Pending' ? t('BUTTON.applicationReject') : undefined}
                        isOffer
                        handleTerminateOfferClick={e => handleTerminateOfferClick(e, application)}
                        // click={() => handleOpenWorkerDetails(application)}
                        click={() => handleOpenJobOfferDetails(application)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledSearchResultContent>
            </StyledEmploymentCardWrapper>
          </StyledCompletedEmploymentColWrapper>
        </StyledEmploymentInfoWrapper>
      </StyledEmploymentContentWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.companyName && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.company')}: </span>
                  {previewWorker?.companyName}
                </p>
              )}
              {previewWorker?.companyStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewWorker?.companyStreet}
                </p>
              )}
              {previewWorker?.companyCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.companyCity}
                </p>
              )}
              {previewWorker?.companyPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.companyPostcode}
                </p>
              )}
              {previewWorker?.companyCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewWorker?.companyCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.companyMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.companyMobilePhone}
                </p>
              )}
              {previewWorker?.companyPhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.companyPhone}
                </p>
              )}
              {previewWorker?.companyEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewWorker?.companyEmail}
                </p>
              )}
              {previewWorker?.companyWebsite && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewWorker?.companyWebsite}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <Row>
          {' '}
          {previewWorker?.companyDescription && (
            <StyledColWrapper style={{ paddingTop: '20px', marginBottom: '50px' }}>
              <h4>{t('FORM.fields.aboutUs')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.companyDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        {previewWorker?.jobJobTitle && (
          <>
            {' '}
            <StyledWorkerInfoBottomWrapper>
              <h4>
                <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.jobDescription')}</span>
              </h4>
            </StyledWorkerInfoBottomWrapper>
            <StyledWorkerInfoLineWrapper>
              <StyledLineWrapper />
            </StyledWorkerInfoLineWrapper>
          </>
        )}
        {/* ///////////////////////// JOB ///////////////////////////////// */}
        {previewWorker?.jobJobTitle && (
          <StyledWorkerInfoPreviewWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewWorker?.jobJobTitle && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                    {previewWorker?.jobJobTitle}
                  </p>
                )}
                {previewWorker?.jobStreet && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewWorker?.jobStreet}
                  </p>
                )}
                {previewWorker?.jobCity && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                    {previewWorker?.jobCity}
                  </p>
                )}
                {previewWorker?.jobPostCode && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                    {previewWorker?.jobPostCode}
                  </p>
                )}
                {previewWorker?.jobCountry && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewWorker?.jobCountry}
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewWorker?.jobStartDate && formatDate(new Date(previewWorker?.jobStartDate)) !== ('01/01/1970') || previewWorker?.jobStartPlan === 'byAgreement' || previewWorker?.jobStartPlan === 'immediately' ?  (<>
                    <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {previewWorker?.jobStartDate && formatDate(new Date(previewWorker?.jobStartDate)) !== ('01/01/1970')? formatDate(new Date(previewWorker?.jobStartDate)): ""}  </>): ""}
                    {previewWorker?.jobStartPlan && (
                      <>
                        <span>
                          {' '}
                          {previewWorker?.jobStartDate &&  formatDate(new Date(previewWorker?.jobStartDate)) !== ('01/01/1970')?  t('FORM.fields.or'): ""} {previewWorker?.jobStartPlan}
                        </span>
                      </>
                    )}
                
                
                

                {/* {previewWorker?.offerSalaryAmount &&
                (previewWorker?.offerSalaryType === 'Hourly' ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ))}
              {previewWorker?.offerHourly && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.offerHourly}.-
                </p>
              )}
              {previewWorker?.offerMonthlySalary && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.offerMonthlySalary}.-
                </p>
              )} */}
                {previewWorker?.jobHourly && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.jobHourly}.-
                  </p>
                )}
                {previewWorker?.jobMonthlySalary && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.jobMonthlySalary}.-
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
          </StyledWorkerInfoPreviewWrapper>
        )}
        <Row>
          {' '}
          {previewWorker?.jobDescriptionCompany && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.companyDescription')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.jobDescriptionCompany.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        <Row>
          {' '}
          {previewWorker?.jobDescription && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.requirements')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.jobDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        <Row>
          {' '}
          {previewWorker?.jobContactInformation && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.descriptionContact')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.jobContactInformation.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        {/* ////////////////////////// END JOB //////////////////////////// */}
        {previewOffer?.offerJobTitle && (
          <StyledWorkerInfoBottomWrapper>
            <h4>
              {' '}
              <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.offer')}</span>
            </h4>
          </StyledWorkerInfoBottomWrapper>
        )}
        {previewOffer?.offerJobTitle && (
          <StyledWorkerInfoLineWrapper>
            <StyledLineWrapper />
          </StyledWorkerInfoLineWrapper>
        )}

        {previewOffer?.offerJobTitle && (
          <StyledWorkerInfoPreviewWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewOffer?.offerJobTitle && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                    {previewOffer?.offerJobTitle}
                  </p>
                )}
                {previewOffer?.offerStreet && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewOffer?.offerStreet}
                  </p>
                )}
                {previewOffer?.offerCity && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                    {previewOffer?.offerCity}
                  </p>
                )}
                {previewOffer?.offerPostCode && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                    {previewOffer?.offerPostCode}
                  </p>
                )}
                {previewOffer?.offerCountry && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewOffer?.offerCountry}
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewOffer?.offerStartDate && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {formatDate(new Date(previewOffer?.offerStartDate))}
                  </p>
                )}
                {previewOffer?.offerSalaryAmount &&
                  (previewOffer?.offerSalaryType === 'Hourly' ? (
                    <p>
                      <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewOffer?.offerSalaryAmount}.-
                    </p>
                  ) : (
                    <p>
                      <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewOffer?.offerSalaryAmount}.-
                    </p>
                  ))}
              </StyledInfoLine>
            </StyledInfoWrapper>
          </StyledWorkerInfoPreviewWrapper>
        )}
        {previewOffer?.offerJobTitle && (
          <Row>
            {' '}
            {previewOffer?.offerDescription && (
              <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
                <h4>{t('OFFERCARD.description')}</h4>
                <StyledPreviewDialogFooterInfo>{previewOffer?.offerDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
              </StyledColWrapper>
            )}
          </Row>
        )}
      </PreviewDialog>
      <PreviewTerminate {...previewJobDetails} onTerminateClick={onTerminateClick} onTerminateOfferClick={onTerminateOfferClick} isOffer={previewJobDetails.isOffer}>
        <StyledInfoLine>
          <StyledRow>
            <p>
              <strong> {t('FORM.fields.JobTitle')}: </strong>
              {previewJobDetails?.subtitle}
            </p>

            <p>
              <strong>{t('FORM.fields.addressOfJob')}:</strong> {previewJobDetails?.originalData?.job?.address}
            </p>
            <p>
              <strong> {t('FORM.fields.cityOfJob')}: </strong> {previewJobDetails?.originalData?.job?.city}
            </p>
            <p>
              <strong> {t('FORM.fields.hourlyRate')}: </strong>CHF {previewJobDetails?.originalData?.job?.rate}
            </p>
            <p>
              <strong> {t('FORM.fields.monthlyPayroll')}:</strong> CHF {previewJobDetails?.originalData?.job?.monthlyPayroll}
            </p>

            <p>{/* {t('EMPLOYEE_PROFILE.MY_PROFILE.state')}: {previewWorker?.state} */}</p>
          </StyledRow>
        </StyledInfoLine>
        <StyledRow>
          <StyledReason>
            {' '}
            <p>{t('FORM.fields.terminationReason')}:</p>
          </StyledReason>
          <label>
            <StyledTextareaField required value={terminationReason} type="text" onChange={e => setTerminationReason(e.target.value)} />
            {/* <input type="text" value={terminationReason} onChange={e => setTerminationReason(e.target.value)} /> */}
          </label>
        </StyledRow>
        <StyledRowDate>
          {' '}
          <StyledReason>
            {' '}
            <p>{t('FORM.fields.terminationEffectiveDate')}</p>
          </StyledReason>
          <label>
            <StyledDatePickerField
              dateFormat="dd.MM.yyyy"
              required
              placeholderText={t('FORM.fields.toDate')}
              value={terminationDate}
              selected={terminationDate}
              onChange={date => setTerminationDate(date, 'toDate')}
            />

            {/* <input type="date" value={terminationDate} onChange={e => setTerminationDate(e.target.value)} /> */}
          </label>
        </StyledRowDate>
      </PreviewTerminate>
    </ProfilePageContainer>
  );
};

export default withTranslations(Employment, 'Employment');
