import { useEffect, useState } from 'react';

import { SkillLevel } from 'constants/skillLevels';
import { SkillLevelV2 } from 'constants/skillLevels';

export const isEmpty = obj => {
  if (obj === null || obj === undefined) return true;
  if (typeof obj === 'number' || typeof obj === 'boolean') return false;
  if (obj.length !== undefined) return obj.length === 0;
  return Object.keys(obj).length === 0;
};

export const isObject = value =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isArray = value => Array.isArray(value);

export const arrayToDict = array => array.reduce((acc, el) => ({ ...acc, [el]: el }), {});

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const replaceSpaceWithUnderscore = text => text && text.split(' ').join('_');

export const replaceUnderscoreWithSpace = text => text && text.split('_').join(' ');

export const getBlobUrl = (file, type, subType, name) =>
  window.URL.createObjectURL(
    new Blob(
      [file],
      {
        type: `${type}/${subType}`
      },
      name
    )
  );

export const formatedArrayOfStrings = arr => {
  if (isEmpty(arr)) {
    return '/';
  }

  return arr.join(', ');
};

export const formatDateISOString = date => {
  const myDate = new Date(date);
  return myDate.toISOString();
};

export const truncateText = (str, truncateAfter) =>
  str.length > truncateAfter ? `${str.substring(0, truncateAfter).trimEnd().concat('...')}` : str;

export const removeLastItemFromArray = array => array.slice(0, array.length - 1);

export const formatShortDate = date => {
  if (!date || date.split('-').length < 3) return date;

  const year = date.split('-')[0];
  const month = date.split('-')[1];
  const day = date.split('-')[2];

  return `${day}/${month}/${year}`;
};

export const formatISODateFromShortDate = (date, minusMonth = false) => {
  if (!date || date.split('-').length < 3) return date;

  const year = parseInt(date.split('-')[0], 10);
  const month = parseInt(date.split('-')[1], 10);
  const day = parseInt(date.split('-')[2], 10);

  const dateObject = new Date();
  dateObject.setFullYear(year);
  dateObject.setUTCMonth(minusMonth ? month - 1 : month);
  dateObject.setDate(day);

  return dateObject;
};

export const formatShortDateFromISODate = date => {
  if (!date || !(date instanceof Date)) return date;

  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${year}-${month}-${day}`;
};

export const formatISODate = date => {
  if (!date || !(date instanceof Date)) return date;

  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${day}/${month}/${year}`;
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};


export const getPropByValue = (val) => {
  if (val) {
    const entry = Object.entries(SkillLevel).find(([key, value]) => value === val.label);
    if (entry) {
      return entry[0].trim();
    }
  }
};

export const getPropByValueV2 = (val) => {
  if (val) {
  
          if (
            val.label === 'CH gelernt' ||
            val.label === 'Appris en Suisse' ||
            val.label === 'Appreso in Svizzera' ||
            val.label === 'CH learned'
          ) {
            val.label = 'CH learned';
          } else if (
            val.label === 'Im Ausland gelernt' ||
            val.label === "Appris à l'étranger" ||
            val.label === "Appreso all'estero" ||
            val.label === 'Learned abroad'
          ) {
            val.label = 'Learned abroad';
          } else if (
            val.label === 'Semiqualifiziert' ||
            val.label === 'Semi-qualifié' ||
            val.label === 'Semi-qualificato' ||
            val.label === 'Semi-skilled'
          ) {
            val.label = 'Semi-skilled';
          } else if (
            val.label === 'Unqualifiziert' ||
            val.label === 'Non qualifié' ||
            val.label === 'Non qualificato' ||
            val.label === 'Unskilled'
          ) {
            val.label = 'Unskilled';
          }
          const entry = Object.entries(SkillLevel).find(([key, value]) => value === val.label);
          if (entry) {
            return entry[0].trim();
          }
        }
   
};


export const addTwoWeeks = (dateString) => {
  if (dateString) {
    let date;
    try {
      date = new Date(dateString);
    } catch (error){
      return "Invalid date format, Please provide date in format 'yyyy-mm-ddTHH:mm:ss.sssZ' "
    }
    date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
    return date.toISOString();
  }
  return false;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderQuoteMap = ({ quoteMap, source, destination }) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered
    };
    return {
      quoteMap: result
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next
  };

  return {
    quoteMap: result
  };
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}
