import styled from '@emotion/styled';

export const SuspenseSpinnerContainer = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
