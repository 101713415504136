export const workersSelector = ({ workersList }) => {
  const { workers } = workersList;

  return workers;
};

export const countWorkersSelector = ({ workersList }) => {
  const { workersCount } = workersList;

  return workersCount;
};
