import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { LIGHT_GRAY_BACKGROUND, LIGHT_BLUE_PAGE_BG, APP_RED } from 'constants/colors';

const parameters = "solid 0px"

const StyledFavoritesContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${parameters} orange;
`;

const StyledFavoritesWrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 0px 6px 6px 6px;
  margin-top: 20px;
  border: ${parameters} red;
`;

const StyledFavoritesCardsWrapper = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  margin-top: 16px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: ${parameters} blue;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
  border: ${parameters} purple;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

export {
  StyledFavoritesContentWrapper,
  StyledFavoritesWrapper,
  StyledFavoritesCardsWrapper,
  StyledCardWrapper,
  StyledPreviewDialogFooterInfo
};
