import styled from '@emotion/styled';

import breakpoints from 'constants/breakpoints';
import { APP_RED, DARK_BLUE } from 'constants/colors';

const colorsOn = 'solid 0px';

const StyledImageContainer = styled('div')`
  display: flex;
  text-align: left;
  font: normal normal 700 34px/53px Lato;
  letter-spacing: 0px;
  color: #26282d;
  margin: 0;
  margin-top: 16px;
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
  }
`;

const StyledProfileContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 15px 30px 10px;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  border: ${colorsOn} orange;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${breakpoints.lg}) {
    padding: 30px 10px 30px 10px;
  }
`;

const StyledDescriptionDocumentsRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: ${colorsOn} pink;
  height: 100%;
  padding-top: 20px;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    flex-wrap: unset;
    padding-top: 20px;
    padding-bottom: 10px;
  }
`;

const StyledDescription = styled('div')`
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  border: ${colorsOn} lightblue;
  width: 50%;
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const StyledProfileWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border: ${colorsOn} blue; */
`;

const StyledInfoInnerWrapper = styled('div')`
  border: 1px solid #282e36;
  border-radius: 6px;
  padding: 10px;
  margin: 5px 0;
  border: ${colorsOn} lightgreen;
  p {
    margin: 0;

    span {
      text-transform: capitalize;
    }
  }

  p:first-of-type {
    font-weight: 500;
  }

  p {
    font-weight: 100;
  }

  p:last-child {
    font-weight: 500;
  }
`;

const StyledProfileInfoWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
  border: ${colorsOn} red;
  /* @media (max-width: ${breakpoints.md}) {

  } */
`;

const StyledProfileInfo = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${colorsOn} blue;
`;

const StyledProfileDescriptionWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 50%;
  justify-content: space-between;
  width: 100%;
  border: ${colorsOn} purple;
  img {
    height: 200px;
    margin-right: 40px;
    box-shadow: 0px 3px 6px #00000029;
  }

  /* @media (max-width: ${breakpoints.md}) {
    justify-content: center;
    img {
      width: 100%;
    }
    h1,
    h3 {
      text-align: center;
      margin-top: 10px;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    img {
      margin: 0;
    }
    h1 {
      margin-top: 10px;
    }
  } */
`;

const StyledProfileDescriptionLeftSide = styled('div')`
  border: ${colorsOn} yellow;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    align-items: center;
    /* padding-left: 10px; */
  }
`;

const StyledProfileImage = styled('div')`
  width: fit-content;
  height: fit-content;
  display: flex;
  @media (max-width: ${breakpoints.lg}) {
    /* justify-content: center;
    align-items: center; */
    padding-left: 40px;
  }
`;

const StyledProfileDescription = styled('div')`
  width: 100%;
  justify-content: flex-start;
  padding-top: 30px;

  border: ${colorsOn} green;
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    /* margin-bottom: 40px; */
  }

  p {
    font: normal normal normal 18px/28px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};
  }

  & > div {
    button {
      width: 168px;
      height: 50px;
      background: #474a4f 0% 0% no-repeat padding-box;
      border-radius: 5px;
      font: normal normal 16px Lato;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      box-icon {
        margin-right: 5px;
      }
    }

    h1 {
      font: normal normal 600 28px/1 Lato;
      letter-spacing: 0px;
      color: ${DARK_BLUE};
      text-transform: capitalize;
      margin-bottom: 16px;
    }

    h3 {
      font: normal normal 400 24px/32px Lato;
      letter-spacing: 0px;
      color: #474a4f;
      margin-bottom: 16px;
    }
  }
`;

const StyledInfo = styled('div')`
  border: ${colorsOn} brown;

  h4 {
    font: normal normal 600 18px/28px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};
    margin-bottom: 0;
    margin-top: 30px;
  }

  p {
    text-align: left;
    font: normal normal normal 16px/24px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};
    margin-top: 10px;
    margin-bottom: 0;
  }

  .invisible {
    visibility: hidden;
  }
`;

const StyledDocs = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* position: absolute; */
  /* right: 30px; */
  border: ${colorsOn} yellowgreen;
  h4 {
    font: normal normal 900 18px/28px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};
    margin-top: 24px;
    margin-bottom: 10px;
  }
  /* @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  } */
`;

const StyledDoc = styled('p')`
  color: ${APP_RED};
  /* border: ${colorsOn} green; */
  font: normal normal medium 16px/24px Lato;
  letter-spacing: 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  box-icon {
    margin-left: 5px;
  }
`;

const StyledProfileActions = styled('div')`
  border: ${colorsOn} darkred;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 5px;
  /* align-items: center; */

  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
  }

  button {
    width: 168px;
    height: 50px;
    letter-spacing: 0px;
    font: normal normal 16px Lato;
  }

  button:nth-of-type(1) {
    border: 1px solid #282e36;
    border-radius: 6px;
    background: white;
    color: #2f3332;
    margin-bottom: 15px;
  }

  button:nth-of-type(2) {
    background: #f95050 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    color: #ffffff;
  }

  form {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
    justify-content: center;
  }
`;

export {
  StyledProfileWrapper,
  StyledProfileDescriptionWrapper,
  StyledProfileDescriptionLeftSide,
  StyledProfileInfoWrapper,
  StyledProfileDescription,
  StyledProfileInfo,
  StyledInfo,
  StyledDocs,
  StyledDoc,
  StyledProfileActions,
  StyledProfileContentWrapper,
  StyledInfoInnerWrapper,
  StyledProfileImage,
  StyledDescription,
  StyledDescriptionDocumentsRow,
  StyledImageContainer
};
