import styled from '@emotion/styled';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import { APP_RED, WHITE, LIGHT_BLUE_PAGE_BG, DARK_BLUE_FOOTER } from 'constants/colors';

const StyledSideNavigation = styled(Nav)`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  background-color: ${DARK_BLUE_FOOTER};
  height: 100vh;
  overflow: auto;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledLogoWrapper = styled('div')`
  border-bottom: 1px solid rgb(212 212 216 / 30%);
  padding: 18px 0;
  margin-bottom: 50px;
  margin-left: 50px;

  img {
    height: 25px;
    padding-left: 10px;
  }
`;

const StyledUser = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  padding-left: 50px;
  max-width: 95%;

  img {
    width: 72px;
    border: 1px solid ${WHITE};
    border-radius: 50%;
    margin-right: 15px;
  }

  div {
    overflow: hidden;

    p {
      text-align: left;
      letter-spacing: 0px;
      color: ${LIGHT_BLUE_PAGE_BG};
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p:nth-of-type(1) {
      font: normal normal 600 16px/26px 'Lato Regular';
    }

    p:nth-of-type(2),
    p:nth-of-type(3) {
      font-family: 'Lato', sans-serif;
      font-weight: 100;
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const LinksSignOutWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledLinks = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  cursor: pointer;
  padding-left: 50px;

  .links {
    text-decoration: none;
  }
`;

const StyledSignOut = styled('div')`
  display: fixed;
  padding-left: 50px;
  margin-bottom: 30px;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font: normal normal 200 18px 'Lato Regular';
    letter-spacing: 0px;
    color: ${LIGHT_BLUE_PAGE_BG};
    :hover {
      color: ${APP_RED};
    }

    svg {
      margin-right: 10px;
    }
  }
`;

const StyledLink = styled(NavLink)`
  text-align: left;
  font: normal normal 18px/50px Lato;
  letter-spacing: 0px;
  color: ${LIGHT_BLUE_PAGE_BG};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;

  @keyframes example {
    0% {
      background-color: red;
    }
    25% {
      background-color: yellow;
    }
    50% {
      background-color: blue;
    }
    100% {
      background-color: green;
    }
  }

  &:hover {
    color: ${WHITE};
  }

  &:hover svg {
    right: 54px;
  }

  svg {
    right: -20px;
  }

  &.selected-item {
    span {
      width: 7px;
      height: 7px;
      display: block;
      border-radius: 50%;
      background: ${APP_RED};
      margin-right: 10px;
    }

    font-weight: 700;
  }
`;

const StyledLinkArrow = styled(FontAwesomeIcon)`
  position: absolute;
  right: 54px;
  font-size: 12px;
  transition: 0.4s;
`;

export {
  StyledSideNavigation,
  StyledLogoWrapper,
  StyledUser,
  LinksSignOutWrapper,
  StyledLinks,
  StyledLink,
  StyledSignOut,
  StyledLinkArrow
};
