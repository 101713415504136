import styled from '@emotion/styled';

import { Card, Row, Col, Container } from 'react-bootstrap';

import Select from 'react-select';
import { ReactComponent as CloseIcon } from 'assets/icons/x_icon.svg';
import PricingBackground from 'assets/images/pricing-background.png';

import { APP_RED, APP_RED_HOVER, BLACK, INPUT_BORDER, WHITE, DARK_BLUE, LIGHT_GRAY_BLUE, GRAY, GRAY_DISABLED, INPUT_TEXT_DARK_BLUE } from 'constants/colors';

export const RegisterDialogWrapper = styled('div')`
  display: flex;
  justify-content: center;
  background-color: ${BLACK}; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 9998; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
`;

export const RegisterDialogContainer = styled(Card)`
  display: flex;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  border: 1px solid ${INPUT_BORDER};
  opacity: 1;
  margin: auto; /* Auto margin according to the element width */
  border-radius: 5px;
`;
export const StyledFrom = styled('form')`
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    height: 120px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 22px;
  font-family: 'Lato';
  color: ${INPUT_TEXT_DARK_BLUE};
`;

export const MultiSelector = styled(Select)`
  font-size: 16px;
  padding: 15px 10px;
  // border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 5px;
  // width: 100%;
  // max-height: 18rem;
  max-width: 18rem;
`;
export const ErrorMessage = styled('span')`
  color: ${APP_RED};
  font-size: 12px;
  font-family: 'Lato Bold';
  font-style: italic;
`;

export const StyledCheckboxWrapper = styled('div')`
  width: 100%;
  text-align: left;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: flex-start;

  label {
    text-align: left;
    font: normal normal normal 18px/28px 'Lato Regular';
    letter-spacing: 0px;
    color: ${DARK_BLUE};
    opacity: 1;
    padding-right: 15px;
  }

  input[type='checkbox'] + label {
    display: block;
    cursor: pointer;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '';
    border-radius: 0.2em;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    vertical-align: sub;
    color: transparent;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: ${WHITE} 0% 0% no-repeat content-box;
    border: 1px solid ${LIGHT_GRAY_BLUE};
    border-radius: 6px;
    opacity: 1;
  }

  input[type='checkbox'] + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    background-color: ${APP_RED};
    border-color: ${LIGHT_GRAY_BLUE};
    color: ${WHITE};
  }

  input[type='checkbox']:disabled + label:before {
    transform: scale(1);
    border-color: ${GRAY};
  }

  input[type='checkbox']:checked:disabled + label:before {
    transform: scale(1);
    background-color: ${GRAY_DISABLED};
    border-color: ${GRAY_DISABLED};
  }
`;

export const Footer = styled(Card.Footer)`
  display: flex;
  align-items: center;

  button:first-of-type {
    margin-right: 10px;
  }
`;

export const FooterLeft = styled('div')`
  margin-left: 12px;
  display: flex;
`;

export const FooterRight = styled('div')`
  display: flex;
  margin-right: 10px;
  margin-left: auto;
`;

export const HeaderContent = styled('div')`
  display: flex;
  justify-content: center;
  font-weight: bold;

  .header-content-description {
    padding: 5px 10px;
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
`;

export const FormChangeHandler = styled('div')`
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${APP_RED};
  padding: 5px 10px;
  color: ${WHITE};
  border-radius: 5px;

  &:hover {
    background-color: ${APP_RED_HOVER};
  }
`;

export const StyledDescriptionWrapperIndustry = styled('div')`
  padding-top: 58px;
  padding-left: 20px;
  padding-bottom: 5px;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE};
  opacity: 1;
`;

export const FormWrapper = styled(Row)`
  .row-container {
    display: flex;
    justify-content: space-between;

    .remove-row-button {
      margin-top: 10px;

      span {
        margin: 0;
      }
    }

    .check-box {
      gap: 5px;
      transform: scale(2);
      display: flex;
      align-content: space-around;
      justify-content: space-evenly;
      align-items: center;
      height: 50px;
      overflow: hidden;
    }

    input[type='checkbox'] {
      position: relative;
      appearance: none;
      width: 35px;
      height: 20px;
      background: #ccc;
      border-radius: 50px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type='checkbox'] {
      background: #f24e4f;
    }

    input[type='checkbox']::after {
      position: absolute;
      content: '';
      width: 14px;
      height: 13px;
      top: 0;
      left: 0;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
      transition: 0.4s;
    }

    input:checked[type='checkbox']::after {
      left: 50%;
    }
  }
`;

export const StyledDescriptionWrapperOccupation = styled('div')`
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 5px;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE};
  opacity: 1;
`;

export const StyledTitle = styled.h1`
  font-size: 32px;
  color: ${DARK_BLUE};
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const StyledSectionTitle = styled.h2`
  font-size: 28px;
  color: ${APP_RED};
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const StyledSubsectionTitle = styled.h3`
  font-size: 24px;
  color: ${APP_RED};
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const StyledLink = styled.a`
  color: ${APP_RED};
  text-decoration: none;
`;
export const StyledDiv = styled.div`
  overflow-y: scroll;
`;

export const StyledMainWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background-image: url(${PricingBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  padding-bottom: 50px;
`;

export const StyledDescriptionBox = styled(Container)`
  max-width: 625px;
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 45px;

  p {
    margin-bottom: 38px;
  }
`;

export const StyledSwitcher = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSwitcherBackground = styled('div')`
  width: 428px;
  height: 54px;
  background: #caced8 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #16376434;
  border-radius: 10px;
  padding: 5px;
`;

export const StyledSwitcherTexts = styled('div')`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  position: relative;
`;

export const StyledSwitcherText = styled('div')`
  width: 33.33%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: #26282d;
  position: relative;
  z-index: 200;
  cursor: pointer;
`;

export const StyledSwitcherFloatingButton = styled('div')`
  position: absolute;
  height: 100%;
  width: 32%;
  z-index: 190;
  background: #f8fbff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #16376434;
  border-radius: 10px;
  opacity: 1;
  left: ${props => (props.position === 'left' ? '0' : 'unset')};
  right: ${props => (props.position === 'right' ? '0' : 'unset')};
  transition: 2s;
  cursor: pointer;
`;

export const StyledPricingPlanWrapper = styled(Col)`
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '74px')};
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 30px;
  max-height: 50vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    border: 2px solid transparent;
    height: 80px;
  }
`;

export const StyledPricingPlan = styled(Col)`
  width: 346px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 18px/28px 'Lato Bold';
  box-shadow: 0px 5px 15px #16376434;
  border-radius: 15px;
  opacity: 1;
  padding: 38px 29px;
`;

export const StyledPricingPlanTitle = styled('h1')`
  font: normal normal 900 24px/32px Lato;
  letter-spacing: 0px;
  color: #192432;
  opacity: 1;
`;

export const StyledPricingPlanRabat = styled('h2')`
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: #f95050;
  opacity: 1;
  margin: 0;
`;

export const StyledPricingPlanDescription = styled('ul')`
  list-style: none;
  padding: 0;
`;

export const StyledPricingPlanDescriptionElement = styled('li')`
  text-align: center;
  font: normal normal normal 16px/36px Lato;
  letter-spacing: 0px;
  color: #474a4f;
`;

export const StyledSeparator = styled('hr')`
  border: 1px solid #707070;
  width: 90%;
  opacity: 0.4;
`;

export const StyledPricingPlanPrice = styled('h1')`
  font: normal normal 900 36px/48px 'Lato Black';
  letter-spacing: -0.72px;
  color: #f95050;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const StyledDescriptionBlockBottom = styled(Container)`
  padding-top: 117px;

  p {
    text-align: center;
    text-align: center;
    font: normal normal normal 18px/28px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};

    &:nth-of-type(2) {
      margin-top: 45px;
      margin-bottom: 45px;
      ::after {
        content: ''; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 30px; /* Change this to whatever width you want. */
        padding-top: 30px; /* This creates some space between the element and the border. */
        border-bottom: 4px solid ${APP_RED}; /* This creates the border. Replace black with whatever color you want. */
      }
    }

    &:nth-of-type(3) {
      font: normal normal normal 18px/28px 'Lato Bold';
    }
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  padding-left: 85px;
  padding-bottom: 40px;
`;

export const List = styled.ol`
  list-style-type: decimal;
  margin-left: 2rem;
`;

export const ListItem = styled.li`
  margin-bottom: 0.5rem;

  & > a {
    color: #333;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SubList = styled.ol`
  list-style-type: lower-roman;
  margin-left: 2rem;
`;

export const SubListItem = styled.li`
  margin-bottom: 0.5rem;

  & > a {
    color: #666;
    text-decoration: none;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledAboutPage = styled(Container)`
  justify-content: center;
  align-items: center;
  background-image: url(${PricingBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
