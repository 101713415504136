import React from 'react';
import PropTypes from 'prop-types';

import { StyledHeaderWrapper } from 'components/LandingPageHeaderTitle/styles';

const LandingPageHeaderTitle = ({ title, customHeaderWrapperClass }) => (
  <StyledHeaderWrapper fluid className={customHeaderWrapperClass}>
    <h1>{title}</h1>
  </StyledHeaderWrapper>
);

LandingPageHeaderTitle.defaultProps = {
  customHeaderWrapperClass: null
};

LandingPageHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  customHeaderWrapperClass: PropTypes.string
};

export default LandingPageHeaderTitle;
