/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const Sanitizer = ({ review }) => (
  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(review) }} />
);

Sanitizer.defaultProps = {
  review: ''
};

Sanitizer.propTypes = {
  review: PropTypes.string
};

export default Sanitizer;
