import styled from '@emotion/styled';
import { Col, Container, Row } from 'react-bootstrap';

import breakpoints from 'constants/breakpoints';
import PricingBackground from 'assets/images/pricing-background.png';
import { APP_RED, DARK_BLUE } from 'constants/colors';

const StyledMainWrapper = styled(Container)`
  background-image: url(${PricingBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  padding-bottom: 50px;
`;

const StyledDescriptionBox = styled(Container)`
  max-width: 625px;
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 45px;

  p {
    margin-bottom: 38px;
  }
`;

const StyledSwitcher = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSwitcherBackground = styled('div')`
  width: 428px;
  height: 54px;
  background: #caced8 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #16376434;
  border-radius: 10px;
  padding: 5px;
`;

const StyledSwitcherTexts = styled('div')`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  position: relative;
`;

const StyledSwitcherText = styled('div')`
  width: 33.33%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: #26282d;
  position: relative;
  z-index: 200;
  cursor: pointer;
`;

const StyledSwitcherFloatingButton = styled('div')`
  position: absolute;
  height: 100%;
  width: 32%;
  z-index: 190;
  background: #f8fbff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 15px #16376434;
  border-radius: 10px;
  opacity: 1;
  left: ${props => (props.position === 'left' ? '0' : 'unset')};
  right: ${props => (props.position === 'right' ? '0' : 'unset')};
  transition: 2s;
  cursor: pointer;
`;

const StyledPricingPlanWrapper = styled(Row)`
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '74px')};
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  @media (max-width: ${breakpoints.xl}) {
     margin-top: -100px;
     margin-top: -100px;
     div {
      padding-bottom: 25px;
     }
    }
`;

const StyledPricingPlan = styled(Col)`
  width: 346px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 18px/28px 'Lato Bold';
  box-shadow: 0px 5px 15px #16376434;
  border-radius: 15px;
  opacity: 1;
  padding: 38px 29px;
@media (max-width: ${breakpoints.lg}) {
margin-bottom: 25px;
}
`;

const StyledPricingPlanTitle = styled('h1')`
  font: normal normal 900 24px/32px Lato;
  letter-spacing: 0px;
  color: #192432;
  opacity: 1;
`;

const StyledPricingPlanRabat = styled('h2')`
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: #f95050;
  opacity: 1;
  margin: 0;
`;

const StyledPricingPlanDescription = styled('ul')`
  list-style: none;
  padding: 0;
`;

const StyledPricingPlanDescriptionElement = styled('li')`
  text-align: center;
  font: normal normal normal 16px/36px Lato;
  letter-spacing: 0px;
  color: #474a4f;
`;

const StyledSeparator = styled('hr')`
  border: 1px solid #707070;
  width: 90%;
  opacity: 0.4;
`;

// const StyledPricingPlanPrice = styled('h1')`
//   font: normal normal 900 36px/48px 'Lato Black';
//   letter-spacing: -0.72px;
//   color: #f95050;
//   opacity: 1;
//   margin-top: 16px;
//   margin-bottom: 0;
// `;
const StyledPricingPlanPrice = styled('h1')`
  font: normal normal 900 36px/48px 'Lato Black';
  letter-spacing: -0.72px;
  color: #f95050;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 0;
  position: relative; /* Ensure the pseudo-element is positioned relative to this element */
  &::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #f95050; /* Adjust thickness and color as needed */
    transform-origin: bottom left; /* Set the rotation origin to bottom left */
    transform: rotate(-15deg); /* Rotate the line */
  }
`;


const StyledDescriptionBlockBottom = styled(Container)`
  padding-top: 117px;

  @media (max-width: ${breakpoints.lg}) {
    padding-top: 50px;
}

  p {
    text-align: center;
    text-align: center;
    font: normal normal normal 18px/28px Lato;
    letter-spacing: 0px;
    color: ${DARK_BLUE};

    &:nth-of-type(2) {
      margin-top: 45px;
      margin-bottom: 45px;
      ::after {
        content: ''; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 30px; /* Change this to whatever width you want. */
        padding-top: 30px; /* This creates some space between the element and the border. */
        border-bottom: 4px solid ${APP_RED}; /* This creates the border. Replace black with whatever color you want. */
      }
    }

    &:nth-of-type(3) {
      font: normal normal normal 18px/28px 'Lato Bold';
    }
  }
`;

export {
  StyledMainWrapper,
  StyledDescriptionBox,
  StyledSwitcher,
  StyledSwitcherBackground,
  StyledSwitcherTexts,
  StyledSwitcherText,
  StyledSwitcherFloatingButton,
  StyledPricingPlanWrapper,
  StyledPricingPlan,
  StyledPricingPlanTitle,
  StyledPricingPlanRabat,
  StyledPricingPlanDescription,
  StyledPricingPlanDescriptionElement,
  StyledSeparator,
  StyledPricingPlanPrice,
  StyledDescriptionBlockBottom
};
