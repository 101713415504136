import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';

import { LIGHT_GRAY_BACKGROUND, INPUT_BORDER, APP_RED, DISABLED, LIGHT_BLUE_PAGE_BG } from 'constants/colors';

const StyledSearchContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;

const StyledWorkerInfoPreviewWrapper = styled('div')`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: flex-start;
  gap: 70px;
  padding: 0 30px;
  @media (max-width: ${breakpoints.lg}) {
      flex-direction: column;
      padding: 0 0px;
      gap: 10px;
  }
`;

export const StyledTextareaField = styled('textarea')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  resize: none;
  width: 86%;
  margin-top: 20px;
`;
const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  margin-top: 16px;
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSelectField = styled('select')`
  font-size: 16px;
  padding: 5px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 56px;
  margin: 0 5px;
`;

const StyledWorkerDocumentsWrapper = styled('div')`
  padding: 20px 30px 0;
  h4 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
`;
const StyledInfoWrapper = styled('div')`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 3px solid orange; */
`;

 const StyledTextCol = styled('Col')`
  display: flex;
  border: 3px solid blue;
  padding-top: 30px;
  /* justify-content: center;
  align-items: center; */
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    /* align-items: center; */
    /* padding-top: 30px; */
  }
`;

const StyledInfoLine = styled('div')`
  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    line-height: 28px;
  }
`;
const StyledDocumentsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  & > :nth-of-type(even) {
    position: relative;
    left: 40px;
  }
`;

const StyledDocumentLine = styled('div')`
  width: 50%;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: ${APP_RED};
    cursor: pointer;

    span {
      padding-right: 10px;
      color: ${DISABLED};
    }
  }
`;

export {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledSearchContentWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledColWrapper,
  StyledSelectField,
  StyledTextCol,
  StyledPreviewDialogFooterInfo
};
