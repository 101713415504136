import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { formatDate } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Sanitizer from 'components/Sanitizer';
import { useWindowDimensions } from 'utils/helpers';

import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import Filters from 'components/Filters';
import CustomCard from 'components/CustomCarApplications';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { USER_TYPE } from 'constants/userTypes';
import { workersSelector } from 'redux/modules/workers/selectors';

import {
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledPreviewDetails
} from './styles';
import { applicationsSelector } from '../../../redux/modules/jobs/selectors';
import { openDialog } from '../../../redux/modules/dialogs/reducer';
import PreviewDialog from '../../../components/PreviewDialog';
import PreviewDiaApplicationJob from '../../../components/PreviewDiaApplicationJob';
import { authProfileSelector, favoritesProfileSelector } from '../../../redux/modules/auth/selectors';
import { updateFavoritesCompany, updateWorker, updateWorkerApplication } from '../../../redux/modules/auth/asyncThunks';
import { companyApplications, updateApplication } from '../../../redux/modules/jobs/asyncThunks';

import {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper
} from '../Projects/styles';
import { StyledColWrapper, StyledPreviewDialogFooterInfo } from '../Search/styles';

const Employment = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const [previewJob, setPreviewJob] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const favorites = useSelector(favoritesProfileSelector);
  const company = useSelector(authProfileSelector);
  const workersList = useSelector(workersSelector);
  const dispatch = useDispatch();
  const [tab, setTab] = useState('pending');
  const applications = useSelector(applicationsSelector);
  const { width } = useWindowDimensions();
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const params = {
    _sort: 'createdAt:DESC'
  };
  const BE_URL = ``;

  useEffect(() => {
    dispatch(companyApplications(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const handleOpenWorkerDetails = async worker => {
    console.log('here is worker===>>>', worker);
    // setPreviewWorker(worker);
    setPreviewWorker({
      originalData: worker,
      title: worker?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.jobTitle,
      workerName: worker?.name,
      workerStreet: worker?.address,
      workerCity: worker?.city,
      workerPostcode: worker?.rate,
      workerCountry: worker?.state,
      workerEmail: worker?.user?.email,
      workerCitizenship: worker?.citizenship,
      workerBirthdate: worker?.birthdate,
      workerDriverLicenses: worker?.driverLicences,
      workerHourlyRate: worker?.hourlyRate,
      workerMonthlyRate: worker?.monthlySalary,
      workerMobilePhone: worker?.mobilePhone,
      workerBio: worker?.jobDescription,
      workerFiles: worker?.files,
      workerEducation: worker?.education,
      workerExperience: worker?.workExperience,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    setPreviewDetails({
      originalData: worker,
      title: worker?.user?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.jobTitle,
      descriptionOfWorker: worker?.jobDescription,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  const filterApplications = application => application?.status === tab;
  const filterBySearch = application =>
    application.worker?.user?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.jobTitle?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.job?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    workersList
      .find(user => user.id === application.worker?.id)
      ?.industy_occupations.map(el => el.name)
      .join()
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());

  // const acceptApplication = (event, applicationId) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   dispatch(updateApplication({ applicationId, payload: { status: 'accepted' } }));
  // };

  const acceptApplication = async (event, applicationId, workerId) => {
    event.stopPropagation();
    event.preventDefault();
  
    const application = applications.find(a => a.id === applicationId);

    console.log("here is the applicationId ===>>>", applicationId);
    dispatch(updateWorkerApplication({ workerId, payload: { isActive: false } }));

    dispatch(updateApplication({ applicationId, payload: { status: 'accepted', workerId: workerId } }));
  };

  const rejectApplication = (event, applicationId) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(updateApplication({ applicationId, payload: { status: 'rejected' } }));
  };

  const footerMinHeight = {
    minHeight: '3rem'
  };

  const onClickViewJob = (event, application) => {
    event.stopPropagation();
    event.preventDefault();
    console.log('here is application===>>', application);
    setPreviewJob(application);
    setPreviewDetails({

      title: application?.job?.title,
      jobStreet: application?.job?.address,
      jobCity: application?.job?.city,
      jobPostCode: application?.job?.postCode,
      jobCountry: application?.job?.state,
      jobDescription: application?.job?.description,
      jobDescriptionCompany: application?.descriptionCompany,
      jobContactInformation: application?.contactInformation,
      jobStartPlan: application?.startP,


      // offerSalaryAmount: offer?.salary,
      // offerSalaryType: offer?.salaryType,


      // jobMonthlySalary: job?.monthlyPayroll, // check this
      // jobHourly: job?.rate, // check this
      // jobStartDate: job?.deadline,
      // jobJobTitle: job?.title,
      // companyStreet: job?.company?.address,
      // companyCity: job?.company?.city,
      // companyPostcode: job?.company?.postCode,
      // companyCountry: job?.company?.state,
      // companyMobilePhone: job?.company?.mobilePhone,
      // companyPhone: job?.company?.phoneNumber,
      // companyWebsite: job?.company?.website,
      // companyName: job?.company?.companyName,
      // companyEmail: job?.company?.contactEmail,
      // companyDescription: job?.company?.description,
      // logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,
      // workerJobTitle: job?.title,

      originalData: application?.job,
      startPlan: application?.job?.startP,
      rate: application?.job?.rate,
      monthlyRate: application?.job?.monthlyPayroll,
      deadline: application?.job?.deadline,
      descriptionCompany: application?.job?.descriptionCompany,
      description: application?.job?.description,
      contactInformation: application?.job?.contactInformation,
      contact: application?.job?.company?.contactEmail,
      address: `${application?.job?.address}, ${application?.job?.city}, ${application?.job?.state}`,
      logoUrl: application?.job?.company?.image?.url ? `${BE_URL}${application?.job?.company?.image?.url}` : null,

      //////
      // originalData: application,
      // title: application?.job?.title,
      // company: application?.company?.companyName,
      // rate: application?.job?.rate,
      // monthlyRate: application?.job?.monthlyPayroll,
      // deadline: application?.job?.deadline,
      // descriptionCompany: application?.job?.descriptionCompany,
      // contactInformation: application?.job?.contactInformation,
      // description: application?.job?.description,
      // contact: application?.company?.contactEmail,
      // address: `${application?.job?.address}, ${application?.job?.city}, ${application.job?.state}`,
      // logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      // mobilePhone: application?.company?.mobilePhone,
      // phone: application?.company?.phoneNumber,
      // name: application?.company?.name

    /////
      // originalData: application,
      // title: application?.company?.companyName,
      // subtitle: application?.job?.title,
      // logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      // address: application?.job?.address,
      // rate: application?.job?.rate,
      // postCode: application?.job?.postCode,
      // monthlyRate: application?.job?.monthlyPayroll,
      // deadline: application?.job?.deadline,
      // description: application?.job?.description,
      // address: `${application?.job?.address}, ${application?.job?.city}, ${application?.job?.postCode}, ${application?.job.state}`
    });
    dispatch(
      openDialog({
        dialogName: 'previewJobDialog'
      })
    );
  };
  const applicationKey = application => `${application.worker.id}-${application.job.id}`;

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.applications.pageTitle')} role={USER_TYPE.COMPANY}>
      <StyledEmploymentContentWrapper>
        {width >= 900 ? <Filters placeholder={t('SIDE_NAVIGATION.applications.searchApplications')} onInputChange={debouncedOnInputChange} />: ""}
        <StyledEmploymentInfoWrapper>
          <StyledCompletedEmploymentColWrapper xs={12}>
            {width >= 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'pending'} onClick={() => setTab('pending')}>
                {t('SIDE_NAVIGATION.applications.openApplications')} ({(applications || []).filter(a => a.status === 'pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'accepted'} onClick={() => setTab('accepted')}>
                {t('SIDE_NAVIGATION.applications.accepted')} ({(applications || []).filter(a => a.status === 'accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'rejected'} onClick={() => setTab('rejected')}>
                {t('SIDE_NAVIGATION.applications.declined')} ({(applications || []).filter(a => a.status === 'rejected')?.length || 0})
              </StyledTitleBox>
            </StyledTitleBoxWrapper>: ""}
            <StyledEmploymentCardWrapper>
            {width < 900 ? <Filters placeholder={t('SIDE_NAVIGATION.applications.searchApplications')} onInputChange={debouncedOnInputChange} />: ""}
            {width < 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'pending'} onClick={() => setTab('pending')}>
                {t('SIDE_NAVIGATION.applications.openApplications')} ({(applications || []).filter(a => a.status === 'pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'accepted'} onClick={() => setTab('accepted')}>
                {t('SIDE_NAVIGATION.applications.accepted')} ({(applications || []).filter(a => a.status === 'accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'rejected'} onClick={() => setTab('rejected')}>
                {t('SIDE_NAVIGATION.applications.declined')} ({(applications || []).filter(a => a.status === 'rejected')?.length || 0})
              </StyledTitleBox>
            </StyledTitleBoxWrapper>: ""}
              <StyledSearchResultContent xs={12}>
                {applications
                  .filter(filterApplications)
                  .filter(filterBySearch)
                  .map(application => (
                    <StyledCardWrapper key={`worker-${applicationKey(application)}`} sm={12} md={6} lg={5}>
                      <CustomCard
                        footerMinHeight={footerMinHeight}
                        profileImage={application.worker?.image?.url ? `${BE_URL}${application.worker?.image?.url}` : DefaultProfileImage}
                        sectionTitle={application.worker?.user?.name}
                        sectionSubtitle={workersList
                          .find(user => user.id === application?.worker?.id)
                          ?.industy_occupations.map(ele => ele.name)
                          .join()}
                        // headerDescriptionFirst={application.worker?.citizenship}
                        headerDescriptionSecond={application.worker?.city}
                        headerDescriptionThird={` ${application.job?.title}`}
                        headerDescriptionFirstRight={`CHF ${application.worker.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                        headerDescriptionSecondRight={`CHF ${application.worker.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                        headerDescriptionThirdRight={application.worker.driverLicences}
                        footerDescription={application.worker?.jobDescription}
                        hasActions={true}
                        handleOnItemClick={application.status === 'pending' ? $event => acceptApplication($event, application.id, application.worker.id) : undefined}
                        handleOnItemClickReject={application.status === 'pending' ? $event => rejectApplication($event, application.id) : undefined}
                        handleOnItemClickSeeJob={application ? $event => onClickViewJob($event, application) : undefined}
                        acceptText={application.status === 'pending' ? t('BUTTON.applicationAccept') : undefined}
                        viewJobText={t('BUTTON.viewJob')}
                        rejectText={application.status === 'pending' ? t('BUTTON.applicationReject') : undefined}
                        favoriteIcon
                        isFavorite={(favorites || []).find(fav => fav.id === application.worker?.id)}
                        onFavoriteClick={$event => favoriteClick($event, application.worker?.id)}
                        click={() => handleOpenWorkerDetails(application.worker)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledSearchResultContent>
            </StyledEmploymentCardWrapper>
          </StyledCompletedEmploymentColWrapper>
        </StyledEmploymentInfoWrapper>
      </StyledEmploymentContentWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewWorker?.workerStreet}
                </p>
              )}
              {previewWorker?.workerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.workerCity}
                </p>
              )}
              {previewWorker?.workerPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.workerPostcode}
                </p>
              )}
              {previewWorker?.workerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewWorker?.workerCountry}
                </p>
              )}
              {previewWorker?.workerBirthdate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>
                  {formatDate(new Date(previewWorker?.workerBirthdate))}
                </p>
              )}
              {previewWorker?.workerCitizenship && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>
                  {previewWorker?.workerCitizenship}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.workerMobilePhone}
                </p>
              )}
              {previewWorker?.workerEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewWorker?.workerEmail}
                </p>
              )}
              {previewWorker?.workerDriverLicenses && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewWorker?.workerDriverLicenses}
                </p>
              )}
              {previewWorker?.workerHourlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>
                  {previewWorker?.workerHourlyRate}.-
                </p>
              )}
              {previewWorker?.workerMonthlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>
                  {previewWorker?.workerMonthlyRate}.-
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <StyledWorkerDocumentsWrapper>
          <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
          <StyledDocumentsWrapper>
            {(previewWorker?.workerFiles || []).map(file => (
              <StyledDocumentLine key={`file-${file.id}`}>
                <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                  <span>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </span>
                  {file.name}
                </p>
              </StyledDocumentLine>
            ))}
          </StyledDocumentsWrapper>
        </StyledWorkerDocumentsWrapper>

        <Row>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
            {previewWorker?.workerExperience && previewWorker?.workerExperience?.length ? (
              previewWorker?.workerExperience.map(
                work =>
                  work.companyName &&
                  work.position && (
                    <>
                      <strong>
                        {work.companyName}, {work.position}
                      </strong>
                      <p>
                      {new Date(work.startDate).toLocaleDateString('en-GB') === '01/01/1970' ? null: `${new Date(work.startDate).toLocaleDateString('en-GB')} |`} {new Date(work.endDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : new Date(work.endDate).toLocaleDateString('en-GB')}
                      </p>
                    </>
                  )
              )
            ) : (
              <></>
            )}
          </StyledColWrapper>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
            {previewWorker?.workerEducation?.length ? (
              previewWorker?.workerEducation.map(work => (
                <>
                  <strong>
                    {work.name}, {work.type}
                  </strong>
                  <p>
                    {new Date(work.startDate).toLocaleDateString('en-GB') === '01/01/1970' ? null: `${new Date(work.startDate).toLocaleDateString('en-GB')} |`} {new Date(work.endDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : new Date(work.endDate).toLocaleDateString('en-GB')}
                  </p>
                </>
              ))
            ) : (
              <></>
            )}
          </StyledColWrapper>
        </Row>
        <Row>
          {previewWorker?.workerBio && (
            <StyledColWrapper style={{ paddingTop: '20px' }}>
              <h4>{t('FORM.fields.aboutMe')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
      </PreviewDialog>

      <PreviewDiaApplicationJob {...previewDetails}>
      <StyledPreviewDetails>
          {previewDetails?.descriptionCompany ? (
            <>
              <strong>
                <h4>{t('FORM.fields.aboutUs')}</h4>{' '}
              </strong>
              <Sanitizer review={previewDetails?.descriptionCompany} />{' '}
            </>
          ) : null}
          {previewDetails?.description ? (
            <>
              <strong>
                <h4>{t('FORM.fields.jobDescription')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.description} />{' '}
            </>
          ) : null}
          {previewDetails?.contactInformation ? (
            <>
              <strong>
                <h4>{t('FORM.fields.descriptionContact')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.contactInformation} />{' '}
            </>
          ) : null}
          <Row>
            {previewDetails?.rate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.rate')}: </strong>CHF {previewDetails?.rate}.-
              </Col>
            )}
            {previewDetails?.monthlyRate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.ratePerMonth')}:</strong> CHF {previewDetails?.monthlyRate}.-
              </Col>
            )}
            {previewDetails?.deadline && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.startDate')}:</strong> {formatDate(new Date(previewDetails?.deadline ))}
              </Col>
            )}
              {previewDetails?.address && <div style={{paddingTop: "10px"}}>
            <h4>{t('FORM.fields.address')}</h4>
            <StyledPreviewDialogFooterInfo>{previewDetails?.address}</StyledPreviewDialogFooterInfo>
     </div>}
          </Row>
        </StyledPreviewDetails>
      </PreviewDiaApplicationJob>
    </ProfilePageContainer>
  );
};

export default withTranslations(Employment, 'Employment');
