import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: {
    loginDialogOpened: false,
    previewDialogOpened: false,
    sendOfferDialogOpened: false,
    forgetPassDialogOpened: false,
    resetPassDialogOpened: false,
    registerAGBsOpened: false,
    registerPrivacyPolicyOpened: false,
    previewJobDialogOpened: false,
    previewTerminateOpenend: false,
  }
};

const dialogsSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (_, { payload }) => ({
      type: {
        [`${payload.dialogName}Opened`]: true
      }
    }),
    closeDialog: (_, { payload }) => ({
      type: {
        [`${payload.dialogName}Opened`]: false
      }
    })
  }
});

export const { openDialog, closeDialog } = dialogsSlice.actions;

export default dialogsSlice.reducer;
