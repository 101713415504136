import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import en from './en';

const translations = {
  en
};

export const translation = (name, trans = translations) => trans.en[name];

export default (TranslatedComponent, name, trans = translations) => {
  const Component = ({ forwardedRef, ...props }) => <TranslatedComponent {...props} ref={forwardedRef} translations={trans.en[name]} />;

  Component.defaultProps = {
    forwardedRef: null
  };

  Component.propTypes = {
    forwardedRef: PropTypes.shape({})
  };

  return forwardRef((props, ref) => <Component {...props} forwardedRef={ref} />);
};
