import styled from '@emotion/styled';

import { APP_RED, INPUT_TEXT_DARK_BLUE, WHITE } from 'constants/colors';

const StyledButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato Medium';
  cursor: pointer;
  outline: none;
  /* border-radius: 6px; */
  ${({ customradiustopleft }) => (customradiustopleft ? `border-top-left-radius: ${customradiustopleft};` : `border-radius: 6px;`)}
  ${({ customradiustopright }) => (customradiustopright ? `border-top-right-radius: ${customradiustopright};` : `none`)}
  /* border-top-left-radius: 6px; */
  position: relative;
  background-color: ${APP_RED};
  border: none;
  padding: 5px 20px;
  color: ${WHITE};

  ${({ customtextcolor }) => (customtextcolor ? `color: ${customtextcolor}` : `color: ${INPUT_TEXT_DARK_BLUE}`)};

  ${({ custombordercolor }) => (custombordercolor ? `border: 1px solid ${custombordercolor}` : `none`)};

  ${({ customheight }) => (customheight ? `height: ${customheight}` : 'height: auto')};

  ${({ customwidth }) => (customwidth ? `width: ${customwidth}` : 'width: auto')};

  ${({ customfontsize }) => (customfontsize ? `font-size: ${customfontsize}` : 'font-size: inherit')};

  ${({ custombuttoncolor }) => (custombuttoncolor ? `background-color: ${custombuttoncolor}` : `background-color: ${APP_RED}`)};

  :hover {
    border: ${APP_RED};
    ${({ custombuttoncolor }) => (custombuttoncolor ? `background-color: ${custombuttoncolor}` : `background-color: ${APP_RED}`)};
    ${({ custombordercolor }) => (custombordercolor ? `border: 1px solid ${custombordercolor}` : `none`)};
  }

  :active {
    ${({ custombuttoncolor }) => (custombuttoncolor ? `background-color: ${custombuttoncolor}` : `background-color: ${APP_RED}`)};
  }

  :focus {
    ${({ custombuttoncolor }) => (custombuttoncolor ? `background-color: ${custombuttoncolor}` : `background-color: ${APP_RED}`)};
  }
`;

const StyledButtonIcon = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
   ${({ customMarginLeft}) => (customMarginLeft ? `margin-left: ${customMarginLeft}`: `margin-left: 5px`)};

  }
`;

export { StyledButton, StyledButtonIcon };
