/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import {
  StyledImage,
  StyledImageWrapper,
  StyledPreviewDialog,
  StyledPreviewDialogBody,
  StyledPreviewDialogCloseIcon,
  StyledPreviewDialogFooter,
  StyledPreviewDialogFooterInfo,
  StyledPreviewDialogHeader,
  StyledPreviewDialogTitle,
  StyledPreviewDialogWrapper,
  StyledHalfBorder,
  StyledPreviewDialogSubtitle,
  StyledVerticalLine,
  StyledPreviewMainContent,
  StyledImageRow,
  StyledImageTextRow,
  StyledButtonCol,
  StyledButtonRow
} from 'components/PreviewDialog/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import UserImageRounded from 'assets/images/user-image.jpeg';
import CustomButton from 'components/CustomButton';
import { APP_RED, GRAY, WHITE } from 'constants/colors';
import { truncateText } from 'utils/helpers';

const PreviewDialog = ({
  title,
  subtitle,
  logoUrl,
  children,
  contact,
  address,
  originalData,
  customButtonText,
  onApplyClick,
  disabledApply = false,
  // isOfferSent,
  offerStatus,
  onSendOffer,
  id,
  descriptionTop,
  isCompany,
  workerBio,
  workerJobTitle,
  onSendToBlacklist,
  isBlacklisted,
  onSendRemoveFromBlacklist,
  descriptionOfWorker,
  customButtonFavText,
  isOfferActive
}) => {
  const { t } = useTranslation();
  const { previewDialogOpened } = useSelector(dialogSelector);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const handleCloseDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const handleClickOutside = e => {
    if (previewDialogOpened && !e.target.closest('.styled-preview-dialog-wrapper')) {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [previewDialogOpened]);

  // console.log('here is title ===>>>', title);
  // console.log('here is isOfferActive ===>>>', isOfferActive);

  return previewDialogOpened ? (
    <StyledPreviewDialog>
      <StyledPreviewDialogWrapper>
        <StyledPreviewDialogHeader>
          <StyledPreviewDialogCloseIcon onClick={handleCloseDialog} />
          <StyledImageWrapper>
            <StyledImageRow>
              <StyledHalfBorder />
              <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
            </StyledImageRow>  
            </StyledImageWrapper>
            <StyledImageTextRow>
              <StyledPreviewDialogTitle>{title && title.slice(0, 66)}{title && title.length > 65 ? "...": ""}</StyledPreviewDialogTitle>
              {width < 400 ? workerJobTitle && <StyledPreviewDialogSubtitle>{workerJobTitle.slice(0, 50)}{workerJobTitle && workerJobTitle.length > 49 ? "...": ""}</StyledPreviewDialogSubtitle> : workerJobTitle && <StyledPreviewDialogSubtitle>{workerJobTitle.slice(0, 100)}{workerJobTitle && workerJobTitle.length > 99 ? "...": ""}</StyledPreviewDialogSubtitle>} 
  
            </StyledImageTextRow>
        
        </StyledPreviewDialogHeader>
        <StyledPreviewDialogBody>
          {/* <hr /> */}
          <StyledPreviewMainContent>
            {/* <StyledVerticalLine /> */}
            {children}
          </StyledPreviewMainContent>
          {/* {workerBio && <StyledPreviewDialogFooterInfo>{workerBio}</StyledPreviewDialogFooterInfo>} */}
        </StyledPreviewDialogBody>
        {/* <StyledPreviewDialogFooter>
          <StyledPreviewDialogFooterInfo>
            <a href={`mailto:${contact}`}>{contact}</a>
            <div>{address}</div>
          </StyledPreviewDialogFooterInfo> */}
        <StyledButtonRow>
          {onSendToBlacklist && (
            <StyledButtonCol>
              <CustomButton
                text={t('BUTTON.addToBlacklist')}
                customTextColor={WHITE}
                custombuttoncolor={disabledApply ? GRAY : APP_RED}
                disabled={disabledApply}
                customWidth="140px"
                customHeight="50px"
                handleOnClick={e => {
                  e.stopPropagation();
                  onSendToBlacklist(id);
                }}
              />
            </StyledButtonCol>
          )}
          {onSendRemoveFromBlacklist && (
            <StyledButtonCol>
              {' '}
              <CustomButton
                text={t('BUTTON.removeFromBlacklist')}
                customTextColor={WHITE}
                custombuttoncolor={disabledApply ? GRAY : APP_RED}
                disabled={disabledApply}
                customWidth="160px"
                customHeight="50px"
                handleOnClick={e => {
                  e.stopPropagation();
                  onSendRemoveFromBlacklist(id);
                }}
              />
            </StyledButtonCol>
          )}

          {customButtonText && (
            <StyledButtonCol>
              <CustomButton
                text={customButtonText}
                customTextColor={WHITE}
                customButtonColor={disabledApply ? GRAY : APP_RED}
                disabled={disabledApply}
                customWidth="120px"
                handleOnClick={onApplyClick}
              />
            </StyledButtonCol>
          )}
          {offerStatus && (
            <StyledButtonCol>
              <CustomButton
                text={
                isOfferActive === false
                    ? t('BUTTON.offerStatus.sent')
                    :  t('BUTTON.SendOffer') 
                    // offerStatus === 'Pending'
                    // ? t('BUTTON.offerStatus.sent')
                    // : offerStatus === 'Accepted' && isOfferActive === false
                    // ? t('BUTTON.offerStatus.accepted')
                    // : offerStatus === 'Rejected'
                    // ? t('BUTTON.offerStatus.rejected')
                    // : offerStatus === 'Accepted' && isOfferActive === true 
                    // ? t('BUTTON.SendOffer'): "hello"
                }
                customTextColor={WHITE}
                custombuttoncolor={isOfferActive === false? GRAY : APP_RED}
                disabled={['Pending', 'Rejected'].includes(offerStatus) || isOfferActive === false}
                customWidth="140px"
                customHeight="50px"
                handleOnClick={
                  // !['Pending', 'Accepted', 'Rejected'].includes(offerStatus) && 
                  isOfferActive === true
                  ? e => {
                        e.stopPropagation();
                        onSendOffer(id);
                      }
                    : null
                }
              />
            </StyledButtonCol>
          )}
          {/* </StyledPreviewDialogFooter> */}
        </StyledButtonRow>
      </StyledPreviewDialogWrapper>
    </StyledPreviewDialog>
  ) : null;
};

PreviewDialog.defaultProps = {
  onApplyClick: () => {}
};

PreviewDialog.defaultProps = {
  title: '',
  contact: '',
  address: ''
};

PreviewDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  contact: PropTypes.string,
  address: PropTypes.string,
  onApplyClick: PropTypes.func
};

export default PreviewDialog;
