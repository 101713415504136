import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import breakpoints from 'constants/breakpoints';

import { WHITE, APP_RED, LIGHT_GRAY_BACKGROUND, DARK_BLUE_FOOTER, LIGHT_BLACK, DARK_BUTTON, DARK_BLUE_TEXT, BAN_RED, INPUT_BORDER, LIGHT_BLUE_PAGE_BG } from 'constants/colors';

const borderTemp = '0px';

const StyledBlueBackground = styled('div')`
  /* display: flex; */
  padding: 12px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  border-radius: 6px;
`;

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;
`;

const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledDatePickerField = styled(DatePicker)`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
`;

const StyledApplicationsContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledApplicationsInfoWrapper = styled(Row)`
  margin-top: 12px;
  height: 100%;
  overflow: hidden;
`;

const StyledOpenApplicationsColWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCancelledApplicationsColWrapper = styled(Col)`
  padding-left: 7px;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitleBoxWrapper = styled('div')`
  display: flex;
  border-bottom: 2px solid red;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

// const StyledTitleBoxWrapper = styled(Row)``;

const StyledTitleBox = styled('div')`
  background: ${({ active }) => (active ? '#f95050 0% 0% no-repeat padding-box' : '#F5F5F5')};
  border-radius: 6px 6px 0 0;
  text-align: center;
  font: normal normal bold 14px/48px Lato;
  letter-spacing: 0px;
  color: ${({ active }) => (active ? WHITE : '#474A4F')};
  border-top: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-left: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-right: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  padding: 0 20px;
  cursor: pointer;
  margin-right: 6px;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 5px;
    width: 100%;
  }
`;

const StyledApplicationsCardWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background: ${LIGHT_GRAY_BACKGROUND} 0% 0% no-repeat padding-box;
  border-radius: 0 6px 6px 6px;
  padding: 16px;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledOpenApplicationSingleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(212, 212, 216, 0.3);
  padding: 20px 0;
  ${({ isFirst }) => (isFirst ? 'padding-top: 0;' : '')}
`;

const StyledOpenApplicationsInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledOpenApplicationsColumnWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledFieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledFieldTitle = styled('p')`
  margin-bottom: 8px;
  text-align: left;
  font: normal normal normal 14px/17px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledFieldValue = styled('p')`
  margin: 0;
  text-align: left;
  font: normal normal bold 18px/28px 'Lato';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledDescription = styled('p')`
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
  margin-top: 7px;
  margin-bottom: 12px;
  max-height: 250px;
  overflow-y: auto;
`;

const StyledDescriptionTitle = styled('h4')`
  text-align: left;
  font-size: 18px;
  font: 'Lato Bold', sans-serif;
  font-weight: 900;
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
  margin-top: 7px;
`;

const StyledApplicationFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 24px;
  }
`;

const StyledCancelledApplicationSingleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(212, 212, 216, 0.3);
  padding: 10px 0;
  ${({ isFirst }) => (isFirst ? 'padding-top: 0;' : '')}
`;

const StyledCancelledApplicationsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    text-align: left;
    font: normal normal 900 24px/32px Lato;
    letter-spacing: 0px;
    color: ${LIGHT_BLACK};
    margin: 0;
  }

  p {
    text-align: right;
    font: normal normal 900 18px/32px Lato;
    letter-spacing: 0px;
    color: ${DARK_BUTTON};
    margin: 0;
  }
`;

const StyledCancelledApplicationsBody = styled('p')`
  margin-top: 8px;
  margin-bottom: 10px;
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: ${DARK_BLUE_TEXT};
  max-width: 500px;
`;

const StyledCancelledApplicationsFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    font-size: 24px;
    color: ${BAN_RED};
  }
`;

const StyledDateOfCancellation = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    text-align: left;
    font: normal normal normal 14px/17px 'Lato Regular';
    letter-spacing: 0;
    color: ${DARK_BLUE_FOOTER};
    margin-bottom: 4px;
  }

  h4 {
    text-align: left;
    font: normal normal bold 16px/28px Lato;
    letter-spacing: 0;
    color: ${DARK_BLUE_TEXT};
    margin: 0;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledEmploymentContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledEmploymentInfoWrapper = styled(Row)`
  margin-top: 12px;
  height: 100%;
  overflow: hidden;
`;

const StyledCompletedEmploymentColWrapper = styled(Col)`
  padding-left: 7px;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledEmploymentCardWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background: ${LIGHT_GRAY_BACKGROUND} 0% 0% no-repeat padding-box;
  border-radius: 0 6px 6px 6px;
  padding: 16px;
  overflow-y: auto;
`;


const StyledWorkerInfoBottomWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
padding-top: 20px;
font-weight: bold;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledWorkerInfoLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 70px;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledLineWrapper = styled('hr')`
  width: 75vw; /* Set width to 70% of viewport width */
  height: 1px; /* Set desired height */
  background-color: black; /* Set desired color */
  border: none; /* Remove default border */
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

export {
  StyledEmploymentCardWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledEmploymentInfoWrapper,
  StyledApplicationsContentWrapper,
  StyledApplicationsInfoWrapper,
  StyledOpenApplicationsColWrapper,
  StyledCancelledApplicationsColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledApplicationsCardWrapper,
  StyledOpenApplicationSingleWrapper,
  StyledOpenApplicationsInfoWrapper,
  StyledOpenApplicationsColumnWrapper,
  StyledFieldWrapper,
  StyledFieldTitle,
  StyledFieldValue,
  StyledDescription,
  StyledApplicationFooter,
  StyledCancelledApplicationSingleWrapper,
  StyledCancelledApplicationsHeader,
  StyledCancelledApplicationsBody,
  StyledCancelledApplicationsFooter,
  StyledDateOfCancellation,
  StyledDescriptionTitle,
  StyledDatePickerField,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledEmploymentContentWrapper,
  StyledPreviewDetails,
  StyledBlueBackground,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo
};
