import styled from '@emotion/styled';
import LoadingBar from 'react-top-loading-bar';

import { APP_RED } from 'constants/colors';

const LoaderWrapper = styled('div')`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  z-index: 10000;
`;

const StyledProgressBar = styled(LoadingBar)`
  background-color: ${APP_RED};
  position: absolute;
  width: 100%;
  height: 3px;
  z-index: 10000;
`;

export { LoaderWrapper, StyledProgressBar };
