/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import breakpoints from 'constants/breakpoints';
import makeAnimated from 'react-select/animated';
// import Popup from 'react-popup';

import CustomTable from 'components/CustomTable';
import CustomButtonRegister from 'components/CustomButtonRegister';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import { openDialog } from 'redux/modules/dialogs/reducer';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import { APP_RED, APP_RED_HOVER, INPUT_BORDER, WHITE, LIGHT_BLACK } from 'constants/colors';
import {
  Footer,
  FooterEmployee,
  FooterLeft,
  FooterRight,
  HeaderContent,
  RegisterDialogContainer,
  RegisterDialogWrapper,
  FormChangeHandler,
  StyledInputField,
  ErrorMessage,
  MultiSelector,
  StyledCheckboxWrapper,
  StyledDescriptionWrapperIndustry,
  StyledDescriptionWrapperOccupation,
  FormWrapper,
  StyledFrom,
  StyledIndOccBox,
  StyledIndOccColumn,
  StyledWrapperHeadingLeft,
  StyledWrapperHeadingRight,
  FormChangeHandlerWorker
} from 'components/RegisterDialog/styles';
import { USER_TYPE } from 'constants/userTypes';
import { payloadBody } from 'components/RegisterDialog/config';
import http from 'utils/http';
import { industryList, industryOccupations, registerUrl } from 'utils/apis';
import { showToast } from 'redux/modules/toast/reducer';
import { languageSelector } from 'redux/modules/language/selectors';
import { getPropByValue } from 'utils/helpers';
import { getPropByValueV2 } from 'utils/helpers';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const Dialog = () => {
  const { registerDialogOpened } = useSelector(dialogSelector);
  const language = useSelector(languageSelector);
  const [isWorker, setIsWorker] = useState(true);
  const [role, setRole] = useState(USER_TYPE.WORKER);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState([]);
  const [matchedPassword, setMatchedPassword] = useState(true);
  const [industries, setIndustries] = useState();
  const [industryOCP, setIndustryOCP] = useState([]);
  const [selectedOCP, setSelectedOCP] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectorError, setSelectorError] = useState(0); // 1=> Industry error 2=> industries Occupations
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [refreshBrowser, setRefreshBrowser] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const animatedComponents = makeAnimated();

  const selectStyles = {
    singleValue: prevStyles => ({
      ...prevStyles,
      // border: 'none',
      border: `1px solid ${INPUT_BORDER}`
    }),
    placeholder: prevStyles => ({
      ...prevStyles,
      fontSize: '12px',
      color: '#B2B2B2',
      fontWeight: 500,
      letterSpacing: '0.2px'
    }),
    indicatorSeparator: prevStyles => ({
      ...prevStyles
    }),
    menuList: prevStyles => ({
      ...prevStyles,
      maxHeight: '8rem',
      color: '#8c8c8c',
      fontSize: '12px'
    }),
    container: prevStyle => ({
      ...prevStyle,
      color: APP_RED,
      ':focus': {
        border: '1px solid #6495ED'
      }
    }),
    control: (prevStyle, state) => ({
      ...prevStyle,
      border: `1px solid ${INPUT_BORDER}`,
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none'
    }),
    valueContainer: prevStyle => ({
      ...prevStyle,
      flexWrap: 'noWrap',
      padding: '1px 16px',
      gap: '0rem 0.5rem',
      overflowX: 'auto',
      width: '100%',
      // border: 'none',
      // border: `1px solid ${INPUT_BORDER}`,
      '::-webkit-scrollbar': {
        height: '3px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        // background: '#DCDCDC',
        backgroundColor: APP_RED
      },
      '::-webkit-scrollbar-thumb:hover': {
        // background: '#AEAEAE',
        backgroundColor: APP_RED_HOVER
      }
    }),
    multiValueRemove: prevStyles => ({
      ...prevStyles,
      padding: '2px 0px',
      color: '#6785DD',
      position: 'absolute',
      top: '-4px',
      left: '94%',
      background: '#fff',
      ':hover': {
        background: '#fff'
      }
    }),
    multiValue: prevStyle => ({
      ...prevStyle,
      border: `0.5px solid  ${APP_RED}`,
      backgroundColor: 'transparent',
      fontWeight: '400',
      letterSpacing: '.5px',
      padding: '0px 8px',
      flexWrap: 'nowrap',
      borderRadius: '3px',
      position: 'relative',
      minWidth: '8rem'
    }),
    multiValueLabel: prevStyle => ({
      ...prevStyle,
      // color: '',
      padding: '0px 3px',
      fontWeight: 600
    }),
    input: prevStyle => ({
      ...prevStyle,
      border: '0 !important',
      outline: '0 !important',
      ':focus': {
        outline: 'none',
        boxShadow: 'none !important'
      }
    })
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm();

  console.log(watch('rate'));
  useEffect(() => {
    if (isWorker) {
      setAcceptedTermsAndConditions(false);
      setAcceptedPrivacyPolicy(false);
      reset();
      setSelectedOption([]);
      setSelectedOCP([]);
      setSelectedLevel([]);
      setRole('Worker');
    } else {
      setAcceptedTermsAndConditions(false);
      setAcceptedPrivacyPolicy(false);
      reset();
      setSelectedOption([]);
      setSelectedOCP([]);
      setSelectedLevel([]);
      setRole('Company');
    }
  }, [isWorker]);

  const toggleIsWorker = () => {
    setIsWorker(!isWorker);
  };

  const renderRegisterType = () => (
    <HeaderContent>
      <FooterLeft>
        <StyledWrapperHeadingLeft>
          <FormChangeHandler onClick={isWorker ? toggleIsWorker : null} color={!isWorker ? APP_RED : WHITE} colorText={!isWorker ? WHITE : LIGHT_BLACK}>
            {t('REGISTER_DIALOG.headerToggleCompany')}
          </FormChangeHandler>
        </StyledWrapperHeadingLeft>
        <div style={{ fontSize: '26px', paddingTop: '5px' }}>/</div>
        <StyledWrapperHeadingRight>
          <FormChangeHandlerWorker onClick={!isWorker ? toggleIsWorker : null} color={isWorker ? APP_RED : WHITE} colorText={isWorker ? WHITE : LIGHT_BLACK}>
            {t('REGISTER_DIALOG.headerToggleEmployee')}
          </FormChangeHandlerWorker>
        </StyledWrapperHeadingRight>
      </FooterLeft>
      {/* <div className="header-content-description">{isWorker ? t('REGISTER_DIALOG.headerDescription.worker') : t('REGISTER_DIALOG.headerDescription.company')}</div> */}
    </HeaderContent>
  );

  const closeRegisterDialog = () => {
    reset();
    setSelectedOption([]);
    setSelectedOCP([]);
    setSelectedLevel([]);
    setAcceptedTermsAndConditions(false);
    setAcceptedPrivacyPolicy(false);
    dispatch(
      closeDialog({
        dialogName: 'registerDialog'
      })
    );
  };

  const openDialogAGBs = () => {
    dispatch(openDialog({ dialogName: 'registerAGBs' }));
    // dispatch(openDialog({dialogName: 'modalAGBs'}))
  };

  const openDialogPrivacyPolicy = () => {
    dispatch(openDialog({ dialogName: 'registerPrivacyPolicy' }));
    // dispatch(openDialog({dialogName: 'modalAGBs'}))
  };

  if (refreshBrowser) {
    refreshWindowWithDelay(8000);
  }

  function refreshWindowWithDelay(delay) {
    const timer = setTimeout(() => {
      window.location.reload();
    }, delay);
    return () => clearTimeout(timer);
  }

  // eslint-disable-next-line no-console
  const handleUserRegister = async loginPayload => {
    console.log('here is loginPayload====>>>>', loginPayload);

    if (!acceptedTermsAndConditions) {
      dispatch(
        showToast({
          type: 'danger',
          message: t('REGISTER_DIALOG.notAcceptTermsAndConditions')
        })
      );
      return;
    } else if (!acceptedPrivacyPolicy) {
      dispatch(
        showToast({
          type: 'danger',
          message: t('REGISTER_DIALOG.notAcceptPrivacyPolicy')
        })
      );
      return;
    }

    if (selectedOption.length < 1) {
      setSelectorError(1);
    } else if (selectedOCP.length < 1) {
      setSelectorError(2);
    } else {
      if (loginPayload.password !== loginPayload.confirmPassword) {
        setMatchedPassword(false);
        return;
      }
      const skillLevelArr = [];
      selectedLevel.forEach((level, i) => {
        skillLevelArr.push({
          industy_occupation: selectedOCP[i] ? selectedOCP[i].value : null,
          // level: getPropByValue(level)
          level: getPropByValueV2(level)
        });
      });

      console.log('here is skillLevelArr', skillLevelArr);
      const payload = {
        ...loginPayload,
        industries: selectedOption.map(item => item.value),
        industy_occupations: selectedOCP?.map(item => item.value),
        skillLevel: skillLevelArr,
        locale: language || 'en',
        acceptedTermsAndConditions,
        acceptedPrivacyPolicy
      };

      console.log('here is payload', payload);
      await http.post(registerUrl, payloadBody({ ...payload }, role)).then(res => {
        dispatch(
          showToast({
            type: 'success',
            message: role === USER_TYPE.WORKER ? t('NOTIFICATIONS.workerSuccessfulRegistration') : t('NOTIFICATIONS.companySuccessfulRegistration')
          })
        );
        dispatch(
          closeDialog({
            dialogName: 'registerDialog'
          })
        );
        setRefreshBrowser(true);

        return res;
      });
    }
  };

  const customStyles = {
    multiValueRemove: provided => ({
      ...provided,
      ':hover': {
        backgroundColor: '#f95050'
      }
    }),
    control: (prevStyle, state) => ({
      ...prevStyle,
      border: `1px solid ${INPUT_BORDER}`,
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none',
      ':hover': { border: `1px solid ${INPUT_BORDER}` }
    })
  };

  // eslint-disable-next-line complexity
  const renderCompanyRegisterForm = () => (
    <Container fluid>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.fullName')} type="text" {...register('name', { required: true })} />
          {errors.name && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField
            placeholder={t('FORM.fields.email')}
            type="email"
            autocomplete="off"
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address'
              }
            })}
          />
          {errors.email && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.password')} type="password" {...register('password', { required: true })} />
          {errors.password && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.confirmPassword')} type="password" {...register('confirmPassword', { required: true })} />
          {errors.confirmPassword && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          {!matchedPassword && <ErrorMessage>{t('FORM.validation.passwordMismatched')}</ErrorMessage>}
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.company')} type="text" {...register('companyName', { required: true })} />
          {errors.companyName && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col>
          <StyledInputField placeholder={t('FORM.fields.companyNr')} type="text" {...register('uid', { required: true })} />
          {errors.uid && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.mobilePhone')} type="text" {...register('mobilePhone', { required: true })} />
          {errors.mobilePhone && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col lg={6} md={12} sm={12}>
          <StyledInputField placeholder={t('FORM.fields.phoneNumber')} type="text" {...register('phoneNumber', { required: false })} />
          {/* {errors.phoneNumber && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>} */}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <StyledInputField placeholder={t('FORM.fields.country')} type="text" {...register('country', { required: true })} />
          {errors.country && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col>
          <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...register('city', { required: true })} />
          {errors.city && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col>
          <StyledInputField placeholder={t('FORM.fields.postcode')} type="text" {...register('postCode', { required: true })} />
          {errors.postCode && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </Col>
        <Col>
          <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...register('address', { required: true })} />
          {errors.address && <span>{t('FORM.validation.requiredField')}</span>}
        </Col>
      </Row>
      <StyledIndOccBox>
        <StyledIndOccColumn>
          <StyledDescriptionWrapperIndustry>{t('REGISTER_DIALOG.chooseIndustry')}</StyledDescriptionWrapperIndustry>

          {/* {selectedOption?.length > 0 && <StyledDescriptionWrapperOccupation>{t('REGISTER_DIALOG.chooseOccupation')}</StyledDescriptionWrapperOccupation>} */}
          {width >= 996 ? (
            <MultiSelector
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder={t('FORM.fields.selectIndustries')}
              value={selectedOption}
              styles={{
                ...customStyles,
                menuList: base => ({
                  ...base,
                  maxHeight: '220px'
                })
              }}
              onChange={setSelectedOption}
              options={industries}
              // isOptionDisabled={() => selectedOption.length >= 2}
            />
          ) : (
            <MultiSelector
              isMulti
              placeholder={t('FORM.fields.selectIndustries')}
              value={selectedOption}
              onChange={setSelectedOption}
              options={industries}
              styles={{
                menuList: base => ({
                  ...base,
                  maxHeight: '190px' // your desired height
                })
              }}
              // isOptionDisabled={() => selectedOption.length >= 2}
            />
          )}

          {/* <MultiSelector
            isMulti
            closeMenuOnSelect={false}
            components={animatedComponents}
            placeholder={t('FORM.fields.selectIndustries')}
            value={selectedOption}
            styles={{
              menuList: base => ({
                ...base,
                maxHeight: '120px' // your desired height
              })
            }}
            onChange={setSelectedOption}
            options={industries}
            // isOptionDisabled={() => selectedOption.length >= 2}
          /> */}
          {selectorError === 1 && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </StyledIndOccColumn>
      </StyledIndOccBox>
      <StyledIndOccBox>
        <StyledIndOccColumn>
          {selectedOption?.length > 0 && <StyledDescriptionWrapperOccupation>{t('REGISTER_DIALOG.chooseOccupation')}</StyledDescriptionWrapperOccupation>}
        </StyledIndOccColumn>
        <StyledIndOccColumn>
          {width >= 996 && selectedOption?.length > 0 && (
            <MultiSelector
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              placeholder={t('FORM.fields.selectOccupations')}
              value={selectedOCP}
              onChange={setSelectedOCP}
              options={industryOCP}
              styles={{
                ...customStyles,
                menuList: base => ({
                  ...base,
                  maxHeight: '190px'
                })
              }}
            />
          )}
          {width <= 995 && selectedOption?.length > 0 && (
            <MultiSelector
              isMulti
              placeholder={t('FORM.fields.selectOccupations')}
              value={selectedOCP}
              onChange={setSelectedOCP}
              options={industryOCP}
              styles={{
                menuList: base => ({
                  ...base,
                  maxHeight: '190px'
                })
              }}
            />
          )}

          {selectorError === 2 && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
        </StyledIndOccColumn>
      </StyledIndOccBox>

      <StyledCheckboxWrapper onChange={() => setAcceptedTermsAndConditions(!acceptedTermsAndConditions)}>
        <input type="checkbox" name="acceptTermsAndConditions" value={acceptedTermsAndConditions} id="acceptTermsAndConditions" />
        <label htmlFor="acceptTermsAndConditions">
          <a href="#" style={{ color: 'black', textDecoration: 'underline', fontSize: '16px' }} onClick={openDialogAGBs}>
            {t('REGISTER_DIALOG.acceptTermsAndConditions')}{' '}
          </a>
        </label>
        <br />
      </StyledCheckboxWrapper>
      <StyledCheckboxWrapper onChange={() => setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}>
        <input type="checkbox" name="acceptPrivacyPolicy" value={acceptedPrivacyPolicy} id="acceptPrivacyPolicy" />

        <label htmlFor="acceptPrivacyPolicy">
          <a href="#" style={{ color: 'black', textDecoration: 'underline', fontSize: '16px' }} onClick={openDialogPrivacyPolicy}>
            {t('REGISTER_DIALOG.acceptPrivacyPolicy')}
          </a>
        </label>

        <br />
      </StyledCheckboxWrapper>
    </Container>
  );
  // eslint-disable-next-line no-console
  const fetchIndustries = async () => {
    await http.get(`${industryList}?_locale=${language}`).then(res =>
      setIndustries(() =>
        res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || ''
        }))
      )
    );
  };

  useEffect(() => {
    fetchIndustries();
  }, [language]);
  // eslint-disable-next-line no-console
  const fetchIndustriesOcp = async () => {
    if (selectedOption.length > 0) {
      let filter = '';
      selectedOption.length > 0 &&
        selectedOption.forEach((item, index) => {
          if (index === 0) {
            filter = `?industry=${item.value}&_locale=${language}`;
          } else {
            filter = `${filter}&industry=${item.value}`;
          }
        });
      await http.get(industryOccupations + filter).then(res => {
        const ocpList = res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || ''
        }));
        if (selectedOCP && selectedOCP.length > 0) {
          const filteredSelection = ocpList.filter(({ value: id1 }) => selectedOCP.some(({ value: id2 }) => id1 === id2));
          setSelectedOCP(filteredSelection);
        }
        setIndustryOCP(ocpList);
      });
    } else {
      setIndustryOCP([]);
    }
  };
  useEffect(() => {
    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    }
    fetchIndustriesOcp();
  }, [selectedOption, language]);

  useEffect(() => {
    const applyForJob = JSON.parse(localStorage.getItem('applyForJob'));
    if (industries && industries.length > 0 && applyForJob) {
      const industry = industries.filter(ind => ind.value === applyForJob.industry);
      setSelectedOption(industry);
      const industryOcp = industryOCP.filter(ocp => ocp.value === applyForJob.industy_occupation);
      setSelectedOCP(industryOcp);
    }
  }, [industries]);

  // eslint-disable-next-line complexity
  const renderWorkerRegisterForm = () => (
    <FormWrapper>
      <Container fluid>
        <Row style={{ overflowY: 'auto', maxHeight: '80%' }}>
          <Col lg={6} md={12} sm={12}>
            <StyledInputField placeholder={t('FORM.fields.firstName')} type="text" {...register('firstName', { required: true })} />
            {errors.firstName && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
          <Col lg={6} md={12} sm={12}>
            <StyledInputField placeholder={t('FORM.fields.lastName')} type="text" {...register('lastName', { required: true })} />
            {errors.lastName && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
        </Row>
        <Row className="row-container">
          <Col lg={6} md={12} sm={12}>
            <StyledInputField
              placeholder={t('FORM.fields.email')}
              type="text"
              name="email"
              autocomplete="off"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address'
                }
              })}
            />
            {errors.email && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>

          <Col lg={6} md={12} sm={12} className="mb-10 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px' }}>
            <div className="check-box">
              <label style={{ fontSize: '9px' }}>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.deactivateButtonTitle')}</label>
              <input style={{ height: '13px' }} type="checkbox" {...register('isActive')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <StyledInputField placeholder={t('FORM.fields.password')} type="password" {...register('password', { required: true })} />
            {errors.password && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
          <Col lg={6} md={12} sm={12}>
            <StyledInputField placeholder={t('FORM.fields.confirmPassword')} type="password" {...register('confirmPassword', { required: true })} />
            {errors.confirmPassword && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
            {!matchedPassword && <ErrorMessage>{t('FORM.validation.passwordMismatched')}</ErrorMessage>}
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...register('address', { required: true })} />
            {errors.address && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>

          <Col>
            <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...register('city', { required: true })} />
            {errors.city && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
          <Col>
            <StyledInputField placeholder={t('FORM.fields.postcode')} type="text" {...register('rate', { required: true })} />
            {errors.mobilePhone && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledInputField placeholder={t('FORM.fields.country')} type="text" {...register('country', { required: true })} />
            {errors.country && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>

          <Col>
            <StyledInputField placeholder={t('FORM.fields.mobilePhone')} type="text" {...register('mobilePhone', { required: true })} />
            {errors.mobilePhone && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col>
          {/* <Col>
            <StyledInputField
              placeholder={t('FORM.fields.jobTitle')}
              type="text"
              {...register('jobTitle', { required: true })}
            />
            {errors.jobTitle && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </Col> */}
        </Row>
        <StyledIndOccBox>
          <StyledIndOccColumn>
            <StyledDescriptionWrapperIndustry>{t('REGISTER_DIALOG.chooseIndustry')}</StyledDescriptionWrapperIndustry>

            {width >= 996 ? (
              <MultiSelector
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder={t('FORM.fields.selectIndustries')}
                value={selectedOption}
                onChange={setSelectedOption}
                options={industries}
                styles={{
                  ...customStyles,
                  menuList: base => ({
                    ...base,
                    maxHeight: '220px'
                  })
                }}
                // styles={selectStyles}
                isOptionDisabled={() => selectedOption.length >= 2}
              />
            ) : (
              <MultiSelector
                isMulti
                placeholder={t('FORM.fields.selectIndustries')}
                value={selectedOption}
                onChange={setSelectedOption}
                options={industries}
                styles={{
                  menuList: base => ({
                    ...base,
                    maxHeight: '190px' // your desired height
                  })
                }}
                // styles={selectStyles}
                isOptionDisabled={() => selectedOption.length >= 2}
              />
            )}
            {selectorError === 1 && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </StyledIndOccColumn>{' '}
        </StyledIndOccBox>{' '}
        <StyledIndOccBox>
          <StyledIndOccColumn>
            {selectedOption?.length > 0 && <StyledDescriptionWrapperOccupation>{t('REGISTER_DIALOG.chooseOccupation')}</StyledDescriptionWrapperOccupation>}{' '}
          </StyledIndOccColumn>

          <StyledIndOccColumn>
            {width >= 996 && selectedOption?.length > 0 && (
              <MultiSelector
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                styles={{
                  ...customStyles,
                  menuList: base => ({
                    ...base,
                    maxHeight: '190px'
                  })
                }}
                placeholder={t('FORM.fields.selectOccupations')}
                value={selectedOCP}
                // styles={selectStyles}
                onChange={setSelectedOCP}
                options={industryOCP}
                isOptionDisabled={() => selectedOCP.length >= 2}
              />
            )}
            {width <= 995 && selectedOption?.length > 0 && (
              <MultiSelector
                isMulti
                styles={{
                  menuList: base => ({
                    ...base,
                    maxHeight: '190px' // your desired height
                  })
                }}
                placeholder={t('FORM.fields.selectOccupations')}
                value={selectedOCP}
                // styles={selectStyles}
                onChange={setSelectedOCP}
                options={industryOCP}
                isOptionDisabled={() => selectedOCP.length >= 2}
              />
            )}
            {selectorError === 2 && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
          </StyledIndOccColumn>
        </StyledIndOccBox>
        <Row>
          <Col>{selectedOCP.length > 0 && <CustomTable selectedOCP={selectedOCP} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />}</Col>
        </Row>
        <StyledCheckboxWrapper style={{ marginTop: '30px' }} onChange={() => setAcceptedTermsAndConditions(!acceptedTermsAndConditions)}>
          <input type="checkbox" name="acceptTermsAndConditions" value={acceptedTermsAndConditions} id="acceptTermsAndConditions" />
          <label htmlFor="acceptTermsAndConditions">
            <a href="#" style={{ color: 'black', textDecoration: 'underline', fontSize: '16px' }} onClick={openDialogAGBs}>
              {t('REGISTER_DIALOG.acceptTermsAndConditions')}{' '}
            </a>
          </label>
          <br />
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper onChange={() => setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}>
          <input type="checkbox" name="acceptPrivacyPolicy" value={acceptedPrivacyPolicy} id="acceptPrivacyPolicy" />

          <label htmlFor="acceptPrivacyPolicy">
            <a href="#" style={{ color: 'black', textDecoration: 'underline', fontSize: '16px' }} onClick={openDialogPrivacyPolicy}>
              {t('REGISTER_DIALOG.acceptPrivacyPolicy')}
            </a>
          </label>
          <br />
        </StyledCheckboxWrapper>
      </Container>
    </FormWrapper>
  );

  return registerDialogOpened ? (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      <RegisterDialogWrapper onClick={closeRegisterDialog}></RegisterDialogWrapper>
      <RegisterDialogContainer>
        <StyledFrom onSubmit={handleSubmit(handleUserRegister)} style={{ height: 'fit-content', overflowY: 'scroll', maxHeight: '100vh' }}>
          <Card.Header>{renderRegisterType()}</Card.Header>
          <Card.Body>{isWorker ? renderWorkerRegisterForm() : renderCompanyRegisterForm()}</Card.Body>
          {!isWorker && (
            <Footer>
              {/* <FooterLeft>
                {isWorker && <FormChangeHandler onClick={toggleIsWorker}>{t('REGISTER_DIALOG.headerToggleCompany')}</FormChangeHandler>}
                {!isWorker && <FormChangeHandler onClick={toggleIsWorker}>{t('REGISTER_DIALOG.headerToggleEmployee')}</FormChangeHandler>}
              </FooterLeft> */}
              <FooterRight>
                <CustomButtonRegister text={t('BUTTON.cancel')} customHeight="50px" customTextColor={WHITE} handleOnClick={closeRegisterDialog} />
                <CustomButtonRegister text={t('BUTTON.register')} customHeight="50px" customTextColor={WHITE} type="submit" />
              </FooterRight>
            </Footer>
          )}
          {isWorker && (
            <FooterEmployee>
              {/* <FooterLeft>
                {isWorker && <FormChangeHandler onClick={toggleIsWorker}>{t('REGISTER_DIALOG.headerToggleCompany')}</FormChangeHandler>}
                {!isWorker && <FormChangeHandler onClick={toggleIsWorker}>{t('REGISTER_DIALOG.headerToggleEmployee')}</FormChangeHandler>}
              </FooterLeft> */}
              <FooterRight>
                <CustomButtonRegister text={t('BUTTON.cancel')} customHeight="50px" customTextColor={WHITE} handleOnClick={closeRegisterDialog} />
                <CustomButtonRegister text={t('BUTTON.register')} customHeight="50px" customTextColor={WHITE} type="submit" />
              </FooterRight>
            </FooterEmployee>
          )}
        </StyledFrom>
      </RegisterDialogContainer>
    </React.Fragment>
  ) : null;
};

export default Dialog;
