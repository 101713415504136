/* eslint-disable import/named */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  StyledCustomDialog,
  StyledCustomDialogWrapper,
  StyledCustomDialogLeftSide,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogButtonActions,
  StyledInputField,
  StyledCloseIcon
} from 'components/ResetPassDialog/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';
import { getWorkerProfile, getCompanyProfile } from 'redux/modules/auth/asyncThunks';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import { ErrorMessage } from 'components/RegisterDialog/styles';
import { showToast } from 'redux/modules/toast/reducer';
import { resetPass } from 'utils/apis';
import { loginUser } from 'redux/modules/auth/reducer';
// import { AUTH } from 'utils/http';

const ResetPassDialog = ({ history }) => {
  console.log('history', history);
  const { resetPassDialogOpened } = useSelector(dialogSelector);
  const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const resolveApiUrl = `${API_PROTOCOL}://${API_DOMAIN}`;
  const dispatch = useDispatch();
  const [matchedPassword, setMatchedPassword] = useState(true);
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // useEffect(() => {
  //   if (resetPassDialogOpened) {
  //     document.body.style.overflow = 'hidden';
  //   }
  //   return function cleanup() {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [resetPassDialogOpened]);

  const handleResetPassword = async loginPayload => {
    if (loginPayload.password !== loginPayload.passwordConfirmation) {
      setMatchedPassword(false);
      return;
    }
    const url = history.location.search;
    const params = new URLSearchParams(url);
    const isCode = params.get('code');

    try {
      // const responseData = await dispatch(login(loginPayload))?.unwrap();
      let response = await fetch(resolveApiUrl + resetPass, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...loginPayload, code: isCode })
      });
      response = await response.json();
      console.log('here is response', response);
      if (!response.user && response.statusCode !== 200) {
        setErrMsg(t('FORM.validation.failedLogin'));
        dispatch(
          showToast({
            type: 'danger',
            message: t('FORM.validation.failedLogin')
          })
        );
        return;
      }
      setErrMsg('');
      dispatch(loginUser({ user: response.user, jwt: response.jwt }));

      const { user } = response;
      const role = user.role?.type;
      if (role === 'worker') {
        await dispatch(getWorkerProfile(user.worker.id)).unwrap();
      } else if (role === 'company') {
        await dispatch(getCompanyProfile(user.company.id)).unwrap();
      }
      dispatch(
        showToast({
          type: 'success',
          message: t('RESET_PASSWORD_DIALOG.successMessage')
        })
      );
      setTimeout(() => {
        dispatch(
          closeDialog({
            dialogName: 'resetPassDialog'
          })
        );
        history.push(`/${role}/search`);
      }, 3000);


    } catch (err) {
      console.log(err, 'error is not complete'); // I want the full error response from the backend
      setErrMsg(t('FORM.validation.failedLogin'));
    }
  };

  const handleCloseResetPassDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'resetPassDialog'
      })
    );
  };

  return resetPassDialogOpened ? (
    <>
      <StyledCustomDialog onClick={handleCloseResetPassDialog} />
      <StyledCustomDialogWrapper>
        <StyledCloseIcon onClick={handleCloseResetPassDialog} />
        <StyledCustomDialogLeftSide>
          <StyledDialogTitle>{t('RESET_PASSWORD_DIALOG.title')}</StyledDialogTitle>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <StyledDialogContent>
              <StyledInputField placeholder={t('FORM.fields.password')} type="password" {...register('password', { required: true })} />
              {errors.password && <span>{t('FORM.validation.requiredField')}</span>}
              {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
              <StyledInputField placeholder={t('FORM.fields.confirmPassword')} type="password" {...register('passwordConfirmation', { required: true })} />
              {errors.confirmPassword && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
              {!matchedPassword && <ErrorMessage>{t('FORM.validation.passwordMismatched')}</ErrorMessage>}
            </StyledDialogContent>

            <StyledDialogButtonActions>
              <CustomButton
                text={t('RESET_PASSWORD_DIALOG.button')}
                customFontSize="18px"
                customWidth="300px"
                customHeight="50px"
                customTextColor={WHITE}
                customBorderColor={APP_RED}
                type="submit"
              />
            </StyledDialogButtonActions>
          </form>
        </StyledCustomDialogLeftSide>
      </StyledCustomDialogWrapper>
    </>
  ) : null;
};

export default ResetPassDialog;
