import styled from '@emotion/styled';
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';

import { INPUT_BORDER } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

export const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ImageUploadWrapper = styled(Row)``;

export const UserUpdateWrapper = styled(Row)`
  margin-top: 20px;
  .user-fields-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: calc(100% - 200px);

    @media (max-width: ${breakpoints.xl}) {
      max-width: 100%;
    }
  }
`;

export const MultiSelector = styled(Select)`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
`;

export const CompanyUpdateWrapper = styled(Row)`
  margin-top: 20px;

  .company-fields-container {
    display: flex;
    margin-top: 20px;
    max-width: calc(100% - 200px);

    @media (max-width: ${breakpoints.xl}) {
      max-width: 100%;
    }
  }

  .company-education-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 855px;
    padding: 20px 0 0;

    .add-button {
      cursor: pointer;
    }
  }

  .check-box {
    transform: scale(2);
    display: flex;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    /* border: 3px solid yellow; */
    @media (max-width: ${breakpoints.lg}) {
      margin-top: 100px;
      margin-bottom: -50px;
  }
}

.check-boxbottom {
    transform: scale(2);
    display: flex;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    /* border: 3px solid orange; */
    @media (max-width: ${breakpoints.lg}) {
      margin-top: 40px;
  }
}

  input[type='checkbox'] {
    position: relative;
    appearance: none;
    width: 50px;
    height: 20px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
  }

  input:checked[type='checkbox'] {
    background: #f24e4f;
  }

  input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
  }

  input:checked[type='checkbox']::after {
    left: 50%;
  }

  .company-image-container {
    .image-preview {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 5px;

      .image-preview-hover {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .image-preview-hover:hover {
        opacity: 1;
        background: rgba(203, 206, 215, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin-right: 40px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }

    .image-ref {
      height: 0;
      width: 0;
      input {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  margin: 0 5px;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 15px;
  }
`;

export const StyledSelectField = styled('select')`
  font-size: 16px;
  padding: 15px 10px;
  width: 300px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  margin: 0 5px;
`;

export const SectionTitle = styled('h5')`
  margin: 20px 0;
`;

export const StyledTextareaField = styled('textarea')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  resize: none;
  width: 100%;
`;

export const SubmitActionWrapper = styled(Col)`
  display: flex;

  button {
    margin-top: 15px;
  }
`;
