import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.css';
import { Provider } from 'react-redux';
import 'flag-icon-css/css/flag-icons.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import store from 'redux/store';
import { history } from 'redux/combinedReducers';
import http from 'utils/http';
import { setLoader, unsetLoader } from 'redux/modules/loader/actions';
import { showToast } from 'redux/modules/toast/reducer';
// import LoginPage from './components/LoginPage';

import App from './App';
import './i18n/config';

http.interceptors.request.use(config => {
  const requestConfig = { ...config };

  const accessToken = store.getState()?.auth.token;

  if (accessToken) {
    requestConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  store.dispatch(setLoader());
  return requestConfig;
});

http.interceptors.response.use(
  response => {
    store.dispatch(unsetLoader());
    return Promise.resolve(response);
  },
  onReject => {
    store.dispatch(unsetLoader());
    store.dispatch(
      showToast({
        type: 'danger',
        message: onReject.response.data?.message[0]?.messages[0].message,
        delay: 5000
      })
    );

    return Promise.reject(onReject);
  }
);

// const token = localStorage.getItem('myToken');
// ReactDOM.render(<Provider store={store}>{token ? <App history={history} /> : <LoginPage />}</Provider>, document.getElementById('root'));
ReactDOM.render(<Provider store={store}> <App history={history} /> </Provider>, document.getElementById('root'));
