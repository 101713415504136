import styled from '@emotion/styled';
import { ToastContainer } from 'react-bootstrap';

import { WHITE } from 'constants/colors';

export const StyledToastContainer = styled(ToastContainer)`
  position: fixed;
  z-index: 9999;
  height: 100px;
  .toast {
    border-radius: 0 0 0 10px;
    opacity: 0.9;

    &-body {
      font-size: 16px;
      color: ${WHITE};
      font-family: 'Lato Bold';
    }
  }
`;
