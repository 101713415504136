import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatISODateFromShortDate, formatShortDateFromISODate, getPropByValue } from '../../../utils/helpers';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { formatDate } from 'utils/helpers';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
// import ApplicationsForPDF from '../../../components/ApplicationsPDF'
import { useDispatch, useSelector } from 'react-redux';
import DefaultProfileImage from 'assets/images/default.jpeg';
import ApplicationsPDF from '../../../components/ApplicationsPDF';
import withTranslations from 'translations';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import Filters from 'components/Filters';
import CustomButton from 'components/CustomButton';
// import CustomCard from 'components/CustomCard';
import CustomCard from 'components/CustomCardApplicationsWorker';
import { APP_RED, WHITE, GREEN_ACCEPT, DARK_BUTTON } from 'constants/colors';
import { StyledInfoLine, StyledInfoWrapper, StyledWorkerInfoPreviewWrapper } from 'views/companyPage/Projects/styles';
import { USER_TYPE } from 'constants/userTypes';
import PreviewDialog from '../../../components/PreviewDialog';
import { openDialog } from '../../../redux/modules/dialogs/reducer';
import { authProfileSelector, authUserSelector } from '../../../redux/modules/auth/selectors';
import Sanitizer from 'components/Sanitizer';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

import {
  StyledApplicationsContentWrapper,
  StyledApplicationsInfoWrapper,
  StyledOpenApplicationsColWrapper,
  StyledCancelledApplicationsColWrapper,
  StyledApplicationsCardWrapper,
  StyledOpenApplicationSingleWrapper,
  StyledOpenApplicationsInfoWrapper,
  StyledOpenApplicationsColumnWrapper,
  StyledFieldWrapper,
  StyledFieldTitle,
  StyledFieldValue,
  StyledDescription,
  StyledApplicationFooter,
  StyledCancelledApplicationSingleWrapper,
  StyledCancelledApplicationsHeader,
  StyledCancelledApplicationsBody,
  StyledCancelledApplicationsFooter,
  StyledDateOfCancellation,
  StyledDescriptionTitle,
  StyledDatePickerField,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledEmploymentContentWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledEmploymentInfoWrapper,
  StyledEmploymentCardWrapper,
  StyledPreviewDetails,
  StyledBlueBackground,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo
} from './styles';
import http from '../../../utils/http';
import { applicationsUrl } from '../../../utils/apis';
import { formatISODate, formatShortDate } from '../../../utils/helpers';

const Applications = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [openApplications, setOpenApplications] = useState([]);
  const [previewApplication, setPreviewApplication] = useState(null);
  const [rejectedApplications, setRejectedApplications] = useState([]);
  const [filteredAllApplications, setFilteredAllApplications] = useState([]);
  const [openAppsWithoutAccepted, setOpenAppsWithoutAccepted] = useState([]);
  const [openAppsAccepted, setOpenAppsAccepted] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreatePDF, setShowCreatePDF] = useState(false);
  const dispatch = useDispatch();
  const [tab, setTab] = useState('pending');
  const [selectedFormattedToDate, setSelectedFormattedToDate] = useState();
  const userInfo = useSelector(authUserSelector);
  const [role, setRole] = useState(userInfo?.role.type);
  const BE_URL = ``;
  let formattedTime;
  let filteredDataTime = [];
  useEffect(() => {
    formattedTime = formatISODate(new Date(selectedFromDate));
  }, [selectedFromDate]);

  const handleOpenWorkerDetails = application => {
    console.log('here is application ===>>>', application);
    setPreviewApplication(application);
    setPreviewDetails({
      title: application?.company?.companyName,
      workerJobTitle: application?.job?.title,
      jobStreet: application?.job?.address,
      jobCity: application?.job?.city,
      jobPostCode: application?.job?.postCode,
      jobCountry: application?.job?.state,
      jobDescription: application?.job?.description,
      jobDescriptionCompany: application?.job?.descriptionCompany,
      jobContactInformation: application?.job?.contactInformation,
      jobStartPlan: application?.job?.startP,
      // offerSalaryAmount: offer?.salary,
      // offerSalaryType: offer?.salaryType,
      jobMonthlySalary: application?.job?.monthlyPayroll, // check this
      jobHourly: application?.job?.rate, // check this
      jobStartDate: application?.job?.deadline,
      jobJobTitle: application?.job?.title,
      companyStreet: application?.company?.address,
      companyCity: application?.company?.city,
      companyPostcode: application?.company?.postCode,
      companyCountry: application?.company?.state,
      companyMobilePhone: application?.company?.mobilePhone,
      companyPhone: application?.company?.phoneNumber,
      companyWebsite: application?.company?.website,
      companyName: application?.company?.companyName,
      companyEmail: application?.company?.contactEmail,
      companyDescription: application?.company?.description,
      logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,

      originalData: application,
      // title: application?.job?.title,
      company: application?.company?.companyName,
      rate: application?.rate,
      monthlyRate: application?.job?.monthlyPayroll,
      deadline: application?.job?.deadline,
      descriptionCompany: application?.job?.descriptionCompany,
      contactInformation: application?.job?.contactInformation,
      description: application?.job?.description,
      contact: application?.company?.contactEmail,
      startPlan: application?.job?.startP,
      address: `${application?.job?.address}, ${application?.job?.city}, ${application?.job?.state}`,
      logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      mobilePhone: application?.company?.mobilePhone,
      phone: application?.company?.phoneNumber,
      name: application?.company?.name
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await http.get(applicationsUrl);
        console.log('here is data ====>>>>', data);

        const openApps = [];
        const pendingApps = [];
        const rejectedApps = [];
        let filteredApplications = [];

        filteredApplications = data.filter(application => {
          const selectedFormattedFromDate = new Date(selectedFromDate);
          const selectedFormattedToDate = new Date(selectedToDate);
          const today = new Date();
          const createdAtDate = new Date(application.created_at);
          return (
            ['pending', 'accepted', 'rejected'].includes(application.status) &&
            // application.job.status !== 'finished' &&
            // diese line aktivieren wenn nach kuendigung von worker die application nicht mher erscheinen soll.
            createdAtDate > selectedFormattedFromDate &&
            createdAtDate < (selectedToDate === null ? today : selectedToDate)
          );
        });
        setFilteredAllApplications(filteredApplications);

        filteredApplications?.forEach(application =>
          ['pending', 'accepted'].includes(application.status) ? openApps.push(application) : rejectedApps.push(application)
        );
        // console.log("here is rejected Apps==== >>>>>>>>", rejectedApps)
        setOpenApplications(openApps);
        setOpenAppsWithoutAccepted(openApps.filter(apps => apps.status !== 'accepted'));
        setOpenAppsAccepted(openApps.filter(apps => apps.status === 'accepted'));
        setRejectedApplications(rejectedApps);
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    filteredApplications();
  }, [openApplications]);
  // console.log("here is rejected Applications====>>>>", rejectedApplications)
  // console.log('here is openApplications in APPLICATIONS===>>>', openApplications);
  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const footerMinHeightVar = {
    minHeight: '0.1rem'
  };
  const filterApplications = applications => {
    filteredAllApplications.filter(app => app.status === tab);
  };
  const filteredByDate = application => {
    const selectedFormattedFromDate = new Date(selectedFromDate);
    const today = new Date();
    const createdAtDate = new Date(application.created_at);

    return createdAtDate > selectedFormattedFromDate && createdAtDate < (selectedToDate === null ? today : selectedToDate);
  };

  const filteredApplications = () => {
    const filteredApplications = openApplications.filter(filteredByDate);
    const rejectedApps = openApplications.filter(application => application.status === 'Rejected');
    const acceptedApps = openApplications.filter(application => application.status === 'Pending');
    // console.log('here is rejected Aossssppppps===>>> ', rejectedApps);
  };
  const handlePDFDisplay = () => {
    if (showCreatePDF === true) {
      setShowCreatePDF(false);
    } else {
      setShowCreatePDF(true);
    }
  };

  const applicationKey = application => `${application?.worker?.id}-${application?.job?.id}`;
  // console.log('here is applicationKey ===>>>', applicationKey);

  const filterBySearch = application =>
    application.company?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.postCode?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.companyName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.contactEmail?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.mobilePhone?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.job?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.job?.postCode?.toLowerCase()?.includes(searchTerm?.toLowerCase());

  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('SIDE_NAVIGATION.applications.myApplications')} role={USER_TYPE.WORKER}>
      <StyledEmploymentContentWrapper>
        {showCreatePDF && (
          <StyledBlueBackground>
            <Row>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.fromDate')}
                  selected={selectedFromDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedFromDate(date, 'fromDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.toDate')}
                  selected={selectedToDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedToDate(date, 'toDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col>
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  document={
                    <ApplicationsPDF openApplications={openAppsWithoutAccepted} rejectedApplications={rejectedApplications} acceptedApplications={openAppsAccepted} />
                  }
                  fileName="applications.pdf"
                >
                  <CustomButton
                    // onClick={handleSubmit}
                    text="PDF Drucken"
                    customFontSize="18px"
                    customWidth="300px"
                    customHeight="50px"
                    customTextColor={WHITE}
                    customBorderColor={APP_RED}
                  />
                </PDFDownloadLink>
              </Col>
            </Row>
          </StyledBlueBackground>
        )}
        <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />
        <StyledEmploymentInfoWrapper>
          <StyledCompletedEmploymentColWrapper xs={12}>
            <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'pending'} onClick={() => setTab('pending')}>
                {t('SIDE_NAVIGATION.applications.openApplications')} ({(filteredAllApplications || []).filter(a => a.status === 'pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'accepted'} onClick={() => setTab('accepted')}>
                {t('SIDE_NAVIGATION.offers.accepted')} ({(filteredAllApplications || []).filter(a => a.status === 'accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'rejected'} onClick={() => setTab('rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(filteredAllApplications || []).filter(a => a.status === 'rejected')?.length || 0})
              </StyledTitleBox>
              {!showCreatePDF && (
                <StyledTitleBox active={tab === 'rejected' || tab === 'rejected' || tab === 'pending'} onClick={() => handlePDFDisplay()} style={{ marginLeft: '50px' }}>
                  create PDF <FontAwesomeIcon icon={faFileAlt} />
                </StyledTitleBox>
              )}
            </StyledTitleBoxWrapper>
            <StyledEmploymentCardWrapper>
              <StyledSearchResultContent xs={12}>
                {filteredAllApplications
                  .filter(filteredByDate)
                  .filter(app => app.status === tab)
                  .filter(filterBySearch)
                  .map(application => (
                    <StyledCardWrapper
                      // key={`worker-${application.company?.id}`}
                      key={`worker-${applicationKey(application)}`}
                      sm={12}
                      md={6}
                      lg={5}
                    >
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        profileImage={
                          userInfo?.role?.type === 'company'
                            ? application?.worker?.image?.url
                              ? `${BE_URL}${application.worker?.image?.url}`
                              : DefaultProfileImage
                            : application?.company?.image?.url
                            ? `${BE_URL}${application?.company?.image?.url}`
                            : DefaultProfileImage
                        }
                        sectionTitle={application?.company?.companyName}
                        sectionSubtitle={application?.job?.title}
                        // headerDescriptionFirst={worker.citizenship}
                        headerDescriptionSecond={application?.company?.state}
                        headerDescriptionThird={application?.company?.city}
                        // headerDescriptionFifth={application?.job?.rate}
                        headerDescriptionFirstRight={`CHF ${application?.job?.monthlyPayroll || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                        headerDescriptionSecondRight={`CHF ${application.job?.rate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                        headerDescriptionThirdRight={application?.worker?.driverLicences}
                        footerDescription={application?.job?.description}
                        // hasActions
                        favoriteIcon
                        // isFavorite={(favorites || []).find(fav => fav.id === worker.id)}
                        // onFavoriteClick={$event => favoriteClick($event, worker.id)}
                        click={() => handleOpenWorkerDetails(application)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledSearchResultContent>
            </StyledEmploymentCardWrapper>
          </StyledCompletedEmploymentColWrapper>
        </StyledEmploymentInfoWrapper>
      </StyledEmploymentContentWrapper>
      <PreviewDialog {...previewDetails}>
        {/* <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
             { previewDetails?.companyName && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.company')}: </span>
                  {previewDetails?.companyName}
                </p>
              )}
              {previewDetails?.companyStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewDetails?.companyStreet}
                </p>
              )}
              {previewDetails?.companyCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewDetails?.companyCity}
                </p>
              )}
              {previewDetails?.companyPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewDetails?.companyPostcode}
                </p>
              )}
              {previewDetails?.companyCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewDetails?.companyCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine  style={{paddingTop: "20px"}}>
              {previewDetails?.companyMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewDetails?.companyMobilePhone}
                </p>
              )}
              {previewDetails?.companyPhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewDetails?.companyPhone}
                </p>
              )}
              {previewDetails?.companyEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewDetails?.companyEmail}
                </p>
              )}
              {previewDetails?.companyWebsite && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewDetails?.companyWebsite}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <Row>
          {' '}
          {previewDetails?.companyDescription && (
            <StyledColWrapper style={{ paddingTop: '20px', marginBottom: '50px' }}>
              <h4>{t('FORM.fields.aboutUs')}</h4>
              <StyledPreviewDialogFooterInfo>{previewDetails?.companyDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        {previewDetails?.jobJobTitle &&<> <StyledWorkerInfoBottomWrapper>
  
          <h4>
            {' '}
            <span style={{ fontWeight: 'bold'}}> {t('FORM.fields.jobDescription')}</span>
          </h4>
        </StyledWorkerInfoBottomWrapper>
        <StyledWorkerInfoLineWrapper>
          <StyledLineWrapper />
        </StyledWorkerInfoLineWrapper></>}
        {previewDetails?.jobJobTitle && (
          <StyledWorkerInfoPreviewWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewDetails?.jobJobTitle && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                    {previewDetails?.jobJobTitle}
                  </p>
                )}
                {previewDetails?.jobStreet && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewDetails?.jobStreet}
                  </p>
                )}
                {previewDetails?.jobCity && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                    {previewDetails?.jobCity}
                  </p>
                )}
                {previewDetails?.jobPostCode && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                    {previewDetails?.jobPostCode}
                  </p>
                )}
                {previewDetails?.jobCountry && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewDetails?.jobCountry}
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewDetails?.jobStartDate && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {formatDate(new Date(previewDetails?.jobStartDate))}
                    {previewDetails?.jobStartPlan && (
                      <>
                        <span>
                          {' '}
                          {t('FORM.fields.or')} {previewDetails?.jobStartPlan}
                        </span>
                      </>
                    )}
                  </p>
                )}
                {previewDetails?.jobHourly && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewDetails?.jobHourly}.-
                  </p>
                )}
                {previewDetails?.jobMonthlySalary && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewDetails?.jobMonthlySalary}.-
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
          </StyledWorkerInfoPreviewWrapper>
        )}
        <Row>
          {' '}
          {previewDetails?.jobDescriptionCompany && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.companyDescription')}</h4>
              <StyledPreviewDialogFooterInfo>{previewDetails?.jobDescriptionCompany.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        <Row>
          {' '}
          {previewDetails?.jobDescription && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.requirements')}</h4>
              <StyledPreviewDialogFooterInfo>{previewDetails?.jobDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        <Row>
          {' '}
          {previewDetails?.jobContactInformation && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('FORM.fields.descriptionContact')}</h4>
              <StyledPreviewDialogFooterInfo>{previewDetails?.jobContactInformation.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row> */}
        <StyledPreviewDetails>
          {previewDetails?.descriptionCompany ? (
            <>
              <strong>
                <h4>{t('FORM.fields.aboutUs')}</h4>{' '}
              </strong>
              <Sanitizer review={previewDetails?.descriptionCompany} />{' '}
            </>
          ) : null}
          {previewDetails?.description ? (
            <>
              <strong>
                <h4>{t('FORM.fields.jobDescription')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.description} />{' '}
            </>
          ) : null}
          {previewDetails?.contactInformation ? (
            <>
              <strong>
                <h4>{t('FORM.fields.descriptionContact')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.contactInformation} />{' '}
            </>
          ) : null}
          <Row>
            {previewDetails?.rate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.rate')}: </strong>CHF {previewDetails?.rate}.-
              </Col>
            )}
            {previewDetails?.monthlyRate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.ratePerMonth')}:</strong> CHF {previewDetails?.monthlyRate}.-
              </Col>
            )}
            {previewDetails?.deadline && formatDate(new Date(previewDetails?.deadline)) !== '01/01/1970' ? (
              <Col sm={12} md={12} xl={12} style={{ paddingBottom: '10px' }}>
                <strong>{t('FORM.fields.startDate')}:</strong> {formatDate(new Date(previewDetails?.deadline))}
              </Col>
            ) : (
              ''
            )}
            {previewDetails?.startPlan && (
              <Col sm={12} md={12} xl={12} style={{ paddingBottom: '10px' }}>
                <strong>{t('OFFERCARD.startDate')}:</strong> {t(`FORM.fields.${previewDetails?.startPlan}`)}
              </Col>
            )}
            {previewDetails?.name && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.fullName')}: </strong>
                {previewDetails?.name}
              </Col>
            )}
            {previewDetails?.mobilePhone && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.mobilePhone')}:</strong> {previewDetails?.mobilePhone}
              </Col>
            )}
            {previewDetails?.phone && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.phoneNumber')}:</strong> {previewDetails?.phone}
              </Col>
            )}
            {previewDetails?.address && (
              <div style={{ paddingTop: '10px' }}>
                <h4>{t('FORM.fields.address')}</h4>
                <StyledPreviewDialogFooterInfo>{previewDetails.address}</StyledPreviewDialogFooterInfo>
              </div>
            )}
          </Row>
        </StyledPreviewDetails>

        {/* <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine>
              <Row>
            <Sanitizer review={previewApplication?.job?.description} /></Row>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: {previewApplication?.job?.address}, {previewApplication?.job?.city}, {previewApplication?.job?.state}
              </p>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: {previewApplication?.job?.city}
              </p>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.state')}: {previewApplication?.job?.state || 'N/A'}
              </p>
              <p>
                {t('FORM.fields.company')}: {previewApplication?.company?.companyName}
              </p>
              <p>
                {t('FORM.fields.fullName')}: {previewApplication?.company?.name}
              </p>

              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.hourlyRate')}: CHF {previewApplication?.job?.rate}
              </p>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.monthlySalary')}: CHF {previewApplication?.job?.monthlyPayroll}
              </p>
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: {previewApplication?.company?.mobilePhone}
              </p>
              <p>
                {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: {previewApplication?.company?.contactEmail}
              </p>
          
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper> */}
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Applications, 'Applications');
