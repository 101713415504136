/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FarStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as FaStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import Sanitizer from 'components/Sanitizer';
import { useWindowDimensions } from 'utils/helpers';

import { APP_RED, WHITE } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderBottomImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledCardHeaderImageDeRight,
  StyledRowButtons,
  StyledColButtonLeft,
  StyledColButtonRight,
  StyledSmallText,
  StyledCardHeaderMiddle
} from './styles';
import { companyOfferSelector } from 'redux/modules/offers/selectors';

// eslint-disable-next-line complexity
const CustomCarWorkerEmploy = ({
  headerDescriptionEmail,
  headerDescriptionPhone,
  favCompanyDescription,
  profileImage,
  sectionTitle,
  sectionSubtitle,
  handleActiveDeactivateJob,
  upperButtonText,
  lowerButtonText,
  handleDeleteJob,
  handleEditJob,
  editButtonText,
  headerDescriptionFirst,
  headerDescriptionSecond,
  headerDescriptionThird,
  headerDescriptionFourth,
  headerDescriptionFifth,
  headerDescriptionExtra,
  headerDescriptionFirstRight,
  headerDescriptionSecondRight,
  headerDescriptionThirdRight,
  footerDescription,
  profileClick,
  favoriteIcon,
  isFavorite,
  onFavoriteClick,
  isActive,
  click,
  hasActions,
  handleOnItemClick,
  handleOnItemClickReject,
  acceptText,
  rejectText,
  onApplyClick,
  onAddRemoveClick,
  plusMinusSign,
  footerMinHeight,
  deadline,
  headerDescriptionPayrollMonthly,
  headerDescriptionFourthFrontPage,
  openPopUpTerminateJob,
  handleTerminateClick,
  handleTerminateOfferClick,
  headerJobType,
  isOffer,
  isNotOffer,
  isCompleted,
  clickThis,
  worker,
  arbeitsStelle
  // onApplyButton
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
// console.log("here is isOffer in Card ===>>>", isOffer)
  return (
    <StyledCard onClick={click} active={isActive ? 'true' : 'false'}>
      {/* ///////// */}
      {/* //// Flex Column start */}
      <StyledCardHeader>
        {/* //// Flex Column end */}
        {/* /////// */}
        {/* ///////// */}
        {/* //// set to felx only start */}

        <StyledIconWrapper>
          {favoriteIcon &&
            (isFavorite ? (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FaStar} color={APP_RED} fontSize="19px" />
            ) : (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FarStar} color={APP_RED} fontSize="19px" />
            ))}
        </StyledIconWrapper>

        <StyledCardHeaderImageWrapper>
          {/* //// set to felx only end */}
          {/* /////// */}
          {/* ///////// */}

          {/* //// set to felx only start */}
          {/* /// orange */}
          {/* <StyledImageDescriptionWrapper> */}
          {/* //// set to felx only end */}
          <StyledCardHeaderImageDescription>
            <StyledCardHeaderImage onClick={profileClick} src={profileImage} />{' '}
          </StyledCardHeaderImageDescription>
          <StyledCardRowWrapperCol>
            {/* 
              /// yellow */}
            <StyledCardHeaderImageDescriptionFirst>
              {/* <CardName> */}

              {width < 900 ? sectionTitle && sectionTitle.slice(0, 20) : ''}
              {width < 900 && sectionTitle && sectionTitle.length > 19 ? '...' : ''}
              {width >= 900 && width < 1700 ? sectionTitle && sectionTitle.slice(0, 24) : ''}
              {width >= 900 && width < 1700 && sectionTitle && sectionTitle.length > 23 ? '...' : ''}
              {width > 1700 ? sectionTitle && sectionTitle.slice(0, 25) : ''}
              {width >= 1700 && sectionTitle && sectionTitle.length > 24 ? '...' : ''}
              {/* </CardName> */}
            </StyledCardHeaderImageDescriptionFirst>
            <StyledCardHeaderMiddle>
              <span onClick={profileClick}>
                {/* {sectionSubtitle && sectionSubtitle.slice(0, 43)}
                {sectionSubtitle && sectionSubtitle.length > 42 ? '...' : ''} */}
                {width < 900 ? sectionSubtitle && sectionSubtitle.slice(0, 28) : ''}
                {width < 900 && sectionSubtitle && sectionSubtitle.length > 27 ? '...' : ''}
                {width >= 900 && width < 1700 ? sectionTitle && sectionTitle.slice(0, 29) : ''}
              {width >= 900 && width < 1700 && sectionTitle && sectionTitle.length > 28 ? '...' : ''}
              {width > 1700 ? sectionTitle && sectionTitle.slice(0, 32) : ''}
              {width >= 1700 && sectionTitle && sectionTitle.length > 31 ? '...' : ''}
              </span>
              <StyledSmallText>    <span>
                {headerDescriptionThird && headerDescriptionThird.slice(0, 20) + `\u0020`}
                {headerDescriptionThird && headerDescriptionThird.toString().length > 20 ? '...' : ''}
                {headerDescriptionFifth && headerDescriptionFifth.toString().slice(0, 6)}
                {headerDescriptionFifth && headerDescriptionFifth.toString().length > 6 ? '...' : ''}
                {headerDescriptionFirst && headerDescriptionFirst.slice(0, 31)}
                {headerDescriptionFirst && headerDescriptionFirst.length > 31 ? '...' : ''}
                {/* {postCode && postCode} */}
                {headerDescriptionSecond && <span>,&nbsp;</span>}
                {width > 800 ? headerDescriptionSecond && headerDescriptionSecond.slice(0, 15) : headerDescriptionSecond && headerDescriptionSecond.slice(0, 8)}{' '}
                {headerDescriptionSecond && headerDescriptionSecond.length > 15 ? '...' : ''} &nbsp;
              </span></StyledSmallText> 
              {/* {deadline && startPlan && startPlan !== 'immediately' ? t('OFFERCARD.startDate') : ''} {deadline !== '01/01/1970' ? deadline : ''} */}
              {`\u0020`}
              {/* {startPlan && t(`FORM.fields.${startPlan}`)} */}
              {/* {headerDescriptionSecondRight && (
                <span>
                  {t('OFFERCARD.salaryDisplay.perHour')}: CHF {headerDescriptionSecondRight}.-
                </span>
              )}
              {headerDescriptionFirstRight && (
                <span>
                  {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionFirstRight}.-
                </span>
              )} */}
              <p>{headerDescriptionExtra && headerDescriptionExtra}</p>
              {/* {headerDescriptionThird && headerDescriptionThird.slice(0, 10)} {headerDescriptionThird && headerDescriptionThird.length > 10 ? '...' : ''} */}
              {headerDescriptionPhone && <span>Phone: {headerDescriptionPhone}, &nbsp;</span>}
              {headerDescriptionEmail && <span>Email: {headerDescriptionEmail}</span>}
            </StyledCardHeaderMiddle>
            {(headerDescriptionFirstRight || headerDescriptionSecondRight || headerDescriptionThirdRight) && (
              <StyledCardHeaderBottomImageDescription>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionFirstRight}</span>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionSecondRight}</span>
                {headerDescriptionThirdRight && <span style={{ whiteSpace: 'nowrap' }}>Kat. {headerDescriptionThirdRight}</span>}
              </StyledCardHeaderBottomImageDescription>
            )}
            {/* {headerDescriptionPayrollMonthly && (
              <StyledCardHeaderImageDescription>
                <StyledCardFrontPage>
                  <span>
                    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}
                  </span>
                  {deadline && (
                    <p style={footerMinHeight}>
                      {t('OFFERCARD.startDate')}: {deadline}
                    </p>
                  )}
                </StyledCardFrontPage>
              </StyledCardHeaderImageDescription>
            )} */}
          </StyledCardRowWrapperCol>
        </StyledCardHeaderImageWrapper>
      </StyledCardHeader>
      {headerDescriptionFourthFrontPage && (
        <StyledCardFrontBottom>
          <span>{headerDescriptionFourthFrontPage}</span>
        </StyledCardFrontBottom>
      )}
      {headerDescriptionFourth && (
        <StyledCardHeaderBellowImage>
          <span>{headerDescriptionFourth}</span>
        </StyledCardHeaderBellowImage>
      )}
      {/* <StyledCardFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {favCompanyDescription && <p style={footerMinHeight}>{favCompanyDescription}</p>}
      </StyledCardFooter> */}
      <StyledFooterBottom style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {/* {footerDescription && (
          <p style={footerMinHeight}>
            <Sanitizer review={footerDescription} />
          </p>
        )} */}
      </StyledFooterBottom>
      <StyledRowButtons>
        <StyledColButtonLeft>
          {' '}
          {hasActions && (
            <>
              <StyledProjectsButtonRow>
                {onAddRemoveClick && <CustomButton text={plusMinusSign} customTextColor={WHITE} customWidth="120px" handleOnClick={onAddRemoveClick} />}
              </StyledProjectsButtonRow>

              <StyledCardAppFooter
                style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}
              ></StyledCardAppFooter>
            </>
          )}
        </StyledColButtonLeft>
        {upperButtonText ||
          lowerButtonText ||
          (editButtonText && (
            <Col>
              <StyledCardBodyRight>
                {upperButtonText && (
                  <button className="edit-button" type="button" onClick={handleActiveDeactivateJob}>
                    {upperButtonText}
                  </button>
                )}
                {lowerButtonText && (
                  <button className="delete-button" type="button" onClick={handleDeleteJob}>
                    {lowerButtonText}
                  </button>
                )}
                {editButtonText && (
                  <button className="edit-button" type="button" onClick={handleEditJob}>
                    {editButtonText}
                  </button>
                )}
              </StyledCardBodyRight>
            </Col>
          ))}
        <Col>
          <StyledProjectsButtonRow>
         {isNotOffer && !isCompleted && <div style={{ paddingTop: '10px' }}>
              <CustomButton
                custombuttoncolor={WHITE}
                textColor={APP_RED}
                custombordercolor={APP_RED}
                text={t('BUTTON.terminate')}
                customWidth="120px"
                handleOnClick={handleTerminateClick}
              />
            </div> }
            {isOffer && !isCompleted && <div style={{ paddingTop: '10px' }}>
              <CustomButton
                custombuttoncolor={WHITE}
                textColor={APP_RED}
                custombordercolor={APP_RED}
                text={t('BUTTON.terminate')}
                customWidth="120px"
                handleOnClick={handleTerminateOfferClick}
              />
            </div>}
          </StyledProjectsButtonRow>
        </Col>
      </StyledRowButtons>
    </StyledCard>
  );
};

CustomCarWorkerEmploy.defaultProps = {
  profileImage: null,
  sectionTitle: '',
  headerDescriptionFirst: null,
  headerDescriptionSecond: null,
  hasActions: false,
  sectionSubtitle: null,
  isActive: false
};

CustomCarWorkerEmploy.propTypes = {
  profileImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  headerDescriptionFirst: PropTypes.string,
  headerDescriptionSecond: PropTypes.string,
  hasActions: PropTypes.bool,
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CustomCarWorkerEmploy;
