import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledSideMenuWrapper,
  StyledCloseSideMenuWrapper,
  StyledArrowRight
} from 'components/SideMenuMobile/styles';

const SideMenuMobile = ({ isOpen, closeSideMenu, children }) =>
  isOpen ? (
    <StyledSideMenuWrapper>
      <StyledCloseSideMenuWrapper onClick={closeSideMenu}>
        <StyledArrowRight icon="arrow-right" size="md" />
      </StyledCloseSideMenuWrapper>
      {children}
    </StyledSideMenuWrapper>
  ) : null;

SideMenuMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeSideMenu: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default SideMenuMobile;
