import React from 'react';
import { Document, Page, StyleSheet, Image, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import bossasto from '../../assets/logo/jpg/dark-red-horizontal.jpg';
import { formatISODate, formatShortDate } from '../../utils/helpers';

const pixel = '0px';

const styles = StyleSheet.create({
  bossatoImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '432px',
    height: '289px',
    opacity: 0.1,
    marginTop: '144.5px',
    marginLeft: '-170px'
  },

  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    flexWrap: 'wrap'
  },
  makeBold: {
    fontweight: 'bold,'
  },
  page: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  topSection: {
    height: '140px',
    backgroundColor: '#F2F2F2' // Set the background color to light grey for the top section
  },
  pageContent: {
    width: '100%',
    height: '100%',
    padding: '10px',
    backgroundColor: '#FFFFFF' // Set the background color to white initially
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#282E36'
  },
  cardOuterWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    fontSize: '9px',
    overflow: 'hidden',
    height: '150px',
    width: '265px',
    backgroundColor: '#FFFFFF',
    border: '1px solid black',
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
    color: '#282E36',
    // fontFamily: 'Lato'
    padding: '10px',
    margin: '10px',
    marginTop: '30px'
  },
  cardBottomCol: { display: 'flex', borderTop: `1px solid grey`, paddingTop: '5px', textOverflow: 'ellipsis', overflow: 'hidden' },
  cardTopColLeft: { display: 'flex', flexDirection: 'column', border: `${pixel} solid blue` },
  cardTopColRight: { display: 'flex', flexDirection: 'column', border: `${pixel} solid purple` },
  cardTopRow: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: `${pixel} solid pink`, flexWrap: 'noWrap', marginBottom: '5px' },
  keyStyle: { fontWeight: 'bold' },
  image: {
    position: 'absolute',
    top: 1,
    right: 50,
    width: '200px',
    height: '200px',
    marginBottom: '50px'
  },
  // image: { display: 'block', margin: '0 auto', width: '200px', height: '200px' },
  openAppStyle: { textDecoration: 'underline', marginBottom: '50px', paddingBottom: '25px', paddingLeft: '10px', paddingTop: '150px', fontSize: '12px' }
});

const Footer = () => {
  return (
    <View style={styles.footer}>
      <Text>Bossasto GmbH - Hohstr. 1 - 8302 Kloten - info@Bossasto.ch - www.bossasto.ch</Text>
    </View>
  );
};

const ApplicationsPDF = ({ openApplications, rejectedApplications, acceptedApplications }) => {
  const { t } = useTranslation();
  // console.log('here is openApplications in PDFFFFFFFFF', openApplications);

  const renderOpenApplications = () => {
    const chunks = [];
    const pageSize = 6;

    if (!openApplications) {
      return null;
    }

    for (let i = 0; i < openApplications.length; i += pageSize) {
      const chunk = openApplications.slice(i, i + pageSize);
      chunks.push(chunk);
    }
    console.log('here is chunks ===>>>', chunks);
    const bossasto = 'https://i.imgur.com/zUJA3dJ.png';

    return chunks.map((chunk, index) => (
      <Page key={index} style={styles.page}>
        <View style={styles.pageContent}>
          <View style={styles.topSection}>
            <Image style={styles.image} src="https://i.imgur.com/upcdXiy.png" />
            <Text style={styles.openAppStyle}>Offene Bewerbungen:</Text>
          </View>
          <View style={styles.cardRow}>
            <Image src={bossasto} style={styles.bossatoImage} />
            {chunk.map((application, cardIndex) => (
              <View key={`${index}-${cardIndex}`} style={styles.cardOuterWrapper}>
                <View style={styles.cardTopRow}>
                  <View style={styles.cardTopColLeft}>
                    <Text style={styles.makeBold}>{t('FORM.fields.company')}:</Text>
                    <Text style={styles.keyStyle}>
                      {application.company.companyName.length > 9 ? application.company.companyName.slice(0, 9) : application.company.companyName}
                    </Text>

                    <Text>
                      {t('FORM.fields.jobTypes')}: {application.job.title.length > 9 ? application.job.title.slice(0, 9) : application.job.title}
                    </Text>
                    <Text>
                      {t('FORM.fields.rate')}: CHF {application?.job?.rate}.-
                    </Text>
                  </View>

                  <View style={styles.cardTopColRight}>
                    <Text>{application?.job?.monthlyPayroll && <div>{`${t('FORM.fields.monthlyPayroll')}: CHF ${application.job.monthlyPayroll}.-`}</div>}</Text>
                    <Text>
                      {t('OFFERCARD.startDate')}: {formatISODate(new Date(application?.job.deadline))}
                    </Text>
                    <Text>
                      {t('FORM.fields.appliedOn')}: {formatISODate(new Date(application?.created_at))}
                    </Text>
                  </View>
                </View>
                <View style={styles.cardBottomCol}>
                  <Text>Profil: {application?.job?.description.slice(0, 300).replace(/<[^>]+>|&nbsp;/g, ' ')}</Text>
                </View>
              </View>
            ))}
          </View>
          {/* Close the wrapper */}
        </View>
        <Footer />
      </Page>
    ));
  };

  const renderRejectedApplications = () => {
    const chunks = [];
    const pageSize = 3;

    if (!rejectedApplications) {
      return null;
    }

    for (let i = 0; i < rejectedApplications.length; i += pageSize) {
      const chunk = rejectedApplications.slice(i, i + pageSize);
      chunks.push(chunk);
    }
    console.log('here is chunks ===>>>', chunks);

    const bossasto = 'https://i.imgur.com/zUJA3dJ.png';

    return chunks.map((chunk, index) => (
      <Page key={index} style={styles.page}>
        <View style={styles.pageContent}>
          <View style={styles.topSection}>
            <Image style={styles.image} src="https://i.imgur.com/upcdXiy.png" />
            <Text style={styles.openAppStyle}>Abgelehnte Bewerbungen:</Text>
          </View>
          <View style={styles.cardRow}>
            <Image src={bossasto} style={styles.bossatoImage} />
            {chunk.map((application, cardIndex) => (
              <View key={`${index}-${cardIndex}`} style={styles.cardOuterWrapper}>
                <View style={styles.cardTopRow}>
                  <View style={styles.cardTopColLeft}>
                    <Text>{t('FORM.fields.company')}:</Text>
                    <Text style={styles.keyStyle}>
                      {application.company.companyName.length > 9 ? application.company.companyName.slice(0, 9) : application.company.companyName}
                    </Text>

                    <Text>
                      {t('FORM.fields.jobTypes')}: {application.job.title.length > 9 ? application.job.title.slice(0, 9) : application.job.title}
                    </Text>
                    <Text>
                      {t('FORM.fields.rate')}: CHF {application?.job?.rate}.-
                    </Text>
                  </View>

                  <View style={styles.cardTopColRight}>
                    <Text>{application?.job?.monthlyPayroll && <div>{`${t('FORM.fields.monthlyPayroll')}: CHF ${application.job.monthlyPayroll}.-`}</div>}</Text>
                    <Text>
                      {t('OFFERCARD.startDate')}: {formatISODate(new Date(application?.job.deadline))}
                    </Text>
                    <Text>
                      {t('FORM.fields.appliedOn')}: {formatISODate(new Date(application?.created_at))}
                    </Text>
                  </View>
                </View>
                <View style={styles.cardBottomCol}>
                  <Text>Profil: {application?.job?.description.slice(0, 300).replace(/<[^>]+>|&nbsp;/g, ' ')}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Footer />
      </Page>
    ));
  };

  const renderAcceptedApplications = () => {
    const chunks = [];
    const pageSize = 3;
    if (!acceptedApplications) {
      return null;
    }

    for (let i = 0; i < acceptedApplications.length; i += pageSize) {
      const chunk = acceptedApplications.slice(i, i + pageSize);
      chunks.push(chunk);
    }
    console.log('here is chunks ===>>>', chunks);
    const bossasto = 'https://i.imgur.com/zUJA3dJ.png';

    return chunks.map((chunk, index) => (
      <Page key={index} style={styles.page}>
        <View style={styles.pageContent}>
          <View style={styles.topSection}>
            <Image style={styles.image} src="https://i.imgur.com/upcdXiy.png" />
            <Text style={styles.openAppStyle}>Akzeptierte Bewerbungen:</Text>
          </View>
          <View style={styles.cardRow}>
            <Image src={bossasto} style={styles.bossatoImage} />
            {chunk.map((application, cardIndex) => (
              <View key={`${index}-${cardIndex}`} style={styles.cardOuterWrapper}>
                <View style={styles.cardTopRow}>
                  <View style={styles.cardTopColLeft}>
                    <Text>{t('FORM.fields.company')}:</Text>
                    <Text style={styles.keyStyle}>
                      {application.company.companyName.length > 9 ? application.company.companyName.slice(0, 9) : application.company.companyName}
                    </Text>

                    <Text>
                      {t('FORM.fields.jobTypes')}: {application.job.title.length > 9 ? application.job.title.slice(0, 9) : application.job.title}
                    </Text>
                    <Text>
                      {t('FORM.fields.rate')}: CHF {application?.job?.rate}.-
                    </Text>
                  </View>

                  <View style={styles.cardTopColRight}>
                    <Text>{application?.job?.monthlyPayroll && <div>{`${t('FORM.fields.monthlyPayroll')}: CHF ${application.job.monthlyPayroll}.-`}</div>}</Text>
                    <Text>
                      {t('OFFERCARD.startDate')}: {formatISODate(new Date(application?.job.deadline))}
                    </Text>
                    <Text>
                      {t('FORM.fields.appliedOn')}: {formatISODate(new Date(application?.created_at))}
                    </Text>
                  </View>
                </View>
                <View style={styles.cardBottomCol}>
                  <Text>Profil: {application?.job?.description.slice(0, 300).replace(/<[^>]+>|&nbsp;/g, ' ')}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Footer />
      </Page>
    ));
  };

  const companyObject = {
    companyName: 'Strongwood',
    hourlyRate: '90',
    deadline: '30/07/2023',
    monthlyPayroll: '30000',
    appliedDate: '25/06/2023',
    job: 'Pilot',
    description:
      'fuel consumption, and other aircraft systems during flight. Respond to changing conditions, such as weather events and emergencies for example, a mechanical malfunction. Respond to changing conditions, such as weather events and emergencies for example, a mechanical malfunction'
  };

  return (
    <Document>
      {renderOpenApplications()}
      {renderRejectedApplications()}
      {renderAcceptedApplications()}
    </Document>
  );
};

export default ApplicationsPDF;
