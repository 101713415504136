import { configureStore } from '@reduxjs/toolkit';

import { AUTH, TOKEN } from 'utils/http';

import rootReducer from './combinedReducers';

const store = configureStore({
  reducer: {
    ...rootReducer
  }
});

store.subscribe(() => {
  localStorage.setItem(AUTH, JSON.stringify(store.getState().auth.user));
  localStorage.setItem(TOKEN, JSON.stringify(store.getState().auth.token));
  localStorage.setItem('profile', JSON.stringify(store.getState().auth.profile));
});

export default store;
