export const EmployeePage = {
  navigationItems: [
    { name: 'Search', link: '/employee/search' },
    { name: 'My Profile', link: '/employee/profile' },
    { name: 'My Employment', link: '/employee/employment' },
    { name: 'My Applications', link: '/employee/applications' },
    { name: 'Weekly Report', link: '/employee/weekly-report' },
    { name: 'News', link: '/employee/news' },
    { name: 'Favorites', link: '/employee/favorites' }
  ]
};
