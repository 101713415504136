import styled from '@emotion/styled';

import { DARK_BLUE } from 'constants/colors';

export const InputStyles = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled('input')`
  border-radius: 25px;
  border: 1px solid ${DARK_BLUE};
  padding: 5px 10px;
  outline: none;
  font-size: 16px;
`;
