/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  StyledImage,
  StyledImageWrapper,
  StyledPreviewDialog,
  StyledPreviewDialogBody,
  StyledPreviewDialogCloseIcon,
  StyledPreviewDialogHeader,
  StyledPreviewDialogTitle,
  StyledPreviewDialogWrapper,
  StyledHalfBorder,
  StyledPreviewDialogSubtitle,
  StyledVerticalLine,
  StyledPreviewMainContent,
  StyledColLeft,
  StyledColRight,
  StyledRow,
  StyledRowButtons,
  StyledImageRow,
  StyledImageTextRow
} from 'components/PreviewTerminate/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import UserImageRounded from 'assets/images/user-image.jpeg';
import CustomButton from 'components/CustomButton';
import { APP_RED, GRAY, WHITE } from 'constants/colors';


const PreviewTerminate = ({
  title,
  subtitle,
  logoUrl,
  children,
  //   disabledApply = false,
  onTerminateClick,
  onTerminateOfferClick,
  jobId,
  workerId,
  isOffer,
  realWorkerId,
  originalData,
}) => {
  const { t } = useTranslation();
  const { previewTerminateOpened } = useSelector(dialogSelector);
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'previewTerminate'
      })
    );
  };
console.log("here is isOffer in Preview =====>>>", isOffer)
  const handleClickOutside = e => {
    if (previewTerminateOpened && e.target.closest('.styled-preview-dialog-wrapper')) {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [previewTerminateOpened]);

  return previewTerminateOpened ? (
    <StyledPreviewDialog>
      <StyledPreviewDialogWrapper>
        {/* <StyledPreviewDialogHeader>
        
          <StyledImageWrapper>
            <StyledHalfBorder />
            <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
            <StyledPreviewDialogTitle>{title}</StyledPreviewDialogTitle>
          
          </StyledImageWrapper>
        </StyledPreviewDialogHeader> */}
         <StyledPreviewDialogHeader>
          <StyledPreviewDialogCloseIcon onClick={handleCloseDialog} />
          <StyledImageWrapper>
            <StyledImageRow>
              <StyledHalfBorder />
              <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
            </StyledImageRow>  </StyledImageWrapper>
            <StyledImageTextRow>
              <StyledPreviewDialogTitle>{title && title.slice(0, 66)}{title && title.length > 65 ? "...": ""}</StyledPreviewDialogTitle>
            
  
            </StyledImageTextRow>
        
        </StyledPreviewDialogHeader>
        <StyledPreviewDialogBody>
          {/* <hr /> */}
          <StyledPreviewMainContent>
            <StyledVerticalLine />
            {children}
            {t('FORM.fields.awareOfTermination')}
          </StyledPreviewMainContent>
        </StyledPreviewDialogBody>
        <StyledRowButtons>
          <CustomButton
            text={t('FORM.fields.cancelTermination')}
            customTextColor={WHITE}
            // customButtonColor={disabledApply ? GRAY : APP_RED}
            // disabled={disabledApply}
            customWidth="120px"
            handleOnClick={handleCloseDialog}
          />

          {isOffer === false ? (<CustomButton
            text={t('FORM.fields.proceedTermination')}
            customTextColor={WHITE}
            // customButtonColor={disabledApply ? GRAY : APP_RED}
            // disabled={disabledApply}
            customWidth="120px"
            handleOnClick={() => onTerminateClick(jobId, workerId, realWorkerId)}
          />) :
              <CustomButton
            text={t('FORM.fields.proceedTermination')}
            customTextColor={WHITE}
            // customButtonColor={disabledApply ? GRAY : APP_RED}
            // disabled={disabledApply}
            customWidth="120px"
            handleOnClick={() => onTerminateOfferClick(jobId, workerId, realWorkerId)}
          />}
        </StyledRowButtons>
        {/* </StyledPreviewDialogFooter> */}
      </StyledPreviewDialogWrapper>
    </StyledPreviewDialog>
  ) : null;
};

// PreviewDialog.defaultProps = {
//   onApplyClick: () => {}
// };

// PreviewDialog.defaultProps = {
//   title: '',
//   contact: '',
//   address: ''
// };

// PreviewDialog.propTypes = {
//   title: PropTypes.string,
//   children: PropTypes.node.isRequired,
//   contact: PropTypes.string,
//   address: PropTypes.string,
//   onApplyClick: PropTypes.func
// };

export default PreviewTerminate;
