import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  type: null,
  message: null,
  delay: 3000
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (_, { payload }) => ({
      isOpen: true,
      type: payload.type,
      message: payload.message,
      delay: payload.delay
    }),
    resetToast: state => ({
      ...state,
      isOpen: false
    })
  }
});

export const { showToast, resetToast } = toastSlice.actions;

export default toastSlice.reducer;
