import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ placeholder, value, onChange }) => {
  const style = {
    height: '200px' // set the height of the default textarea here
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ header: [1, 2, 3, 4, 5, false] }],
      [{ align: [] }],
      ['link'],
      ['clean'] // remove formatting button
      // ['formula'], Requires window.katex to be set
    ],
    clipboard: {
      matchVisual: false
    },
    placeholder: ''
  };

  return (
    <ReactQuill
      theme="snow"
      placeholder={placeholder}
      value={value}
      onChange={(content, _deltas, source, editor) => onChange(content, _deltas, source, editor)}
      modules={modules}
      style={style}
    />
  );
};

export default Editor;
