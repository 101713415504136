import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import projectsList from 'redux/modules/projects/reducer';
import auth from 'redux/modules/auth/reducer';
import dialog from 'redux/modules/dialogs/reducer';
import loader from 'redux/modules/loader/reducer';
import jobsList from 'redux/modules/jobs/reducer';
import offerList from 'redux/modules/offers/reducer';
import workersList from 'redux/modules/workers/reducer';
import language from 'redux/modules/language/reducer';
import toast from 'redux/modules/toast/reducer';
import contact from 'redux/modules/contact/reducer';

export const history = createBrowserHistory();

const rootReducer = {
  router: connectRouter(history),
  auth,
  dialog,
  loader,
  jobsList,
  workersList,
  projectsList,
  language,
  offerList,
  contact,
  toast
};

export default rootReducer;
