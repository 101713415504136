/* eslint-disable complexity */
/* eslint-disable promise/always-return */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeAnimated from 'react-select/animated';

import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import { authProfileSelector, authUserSelector } from 'redux/modules/auth/selectors';
import { StyledContainer, StyledInputField, UserUpdateWrapper, CompanyUpdateWrapper, SubmitActionWrapper, MultiSelector } from 'views/companyPage/EditProfile/styles';
import CustomButton from 'components/CustomButton';
import { WHITE, INPUT_BORDER } from 'constants/colors';
import { updateCompany, updateUser } from 'redux/modules/auth/asyncThunks';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import http from 'utils/http';
import { industryList, industryOccupations } from 'utils/apis';
import { languageSelector } from 'redux/modules/language/selectors';
import CustomTable from 'components/CustomTable';
import { getPropByValue } from 'utils/helpers';
import { SkillLevel } from 'constants/skillLevels';
import Editor from 'components/QuillEditor';
import { ErrorMessage } from 'components/RegisterDialog/styles';
import { showToast } from 'redux/modules/toast/reducer';

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const authUser = useSelector(authUserSelector);
  const authProfile = useSelector(authProfileSelector);
  const [userImage, setUserImage] = useState('');
  const [isSub, setIsSub] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [industryOCP, setIndustryOCP] = useState([]);
  const [industryOCPComp, setIndustryOCPComp] = useState([]);
  const [selectedOCP, setSelectedOCP] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectorError, setSelectorError] = useState(0); // 1=> Industry error 2=> industries Occupations

  // company industries and industry occupations
  const [selectedOptionComp, setSelectedOptionComp] = useState([]);
  const [selectedOCPComp, setSelectedOCPComp] = useState([]);

  const imageUploadRef = useRef(null);
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;

  const animatedComponents = makeAnimated();

  const {
    register: registerUserInfoFields,
    setValue: setUserFieldsValue,
    handleSubmit: handleSubmitUserUpdate,
    formState: { errors: userStateErrors }
  } = useForm();

  const {
    register: registerCompanyInfoFields,
    setValue: setCompanyFieldsValue,
    handleSubmit: handleSubmitCompanyUpdate,
    watch: watchCompanyInfoFields,
    formState: { errors: companyStateErrors }
  } = useForm();

  useEffect(() => {
    const setPredefinedUserValues = () => {
      setUserFieldsValue('name', authUser.name);
      setUserFieldsValue('username', authUser.username);
      setUserFieldsValue('email', authUser.email);
    };

    setPredefinedUserValues();
  }, [authUser.email, authUser.name, authUser.username, setUserFieldsValue]);

  useEffect(() => {
    const setPredefinedUserValues = () => {
      setCompanyFieldsValue('state', authProfile.state);
      setCompanyFieldsValue('city', authProfile.city);
      setCompanyFieldsValue('address', authProfile.address);
      setCompanyFieldsValue('mobilePhone', authProfile.mobilePhone);
      setCompanyFieldsValue('phoneNumber', authProfile.phoneNumber);
      setCompanyFieldsValue('contactEmail', authProfile.contactEmail);
      setCompanyFieldsValue('companyName', authProfile.companyName);
      setCompanyFieldsValue('description', authProfile.description);
      setCompanyFieldsValue('website', authProfile.website);
      setCompanyFieldsValue('isEmailSubscription', authProfile.isEmailSubscription); // EmailSubFor

      http.get(`${industryList}?_locale=${language}`).then(res => {
        setIndustries(() =>
          res?.data.map(item => ({
            value: item?.id || '',
            label: item?.name || '',
            locale: item?.locale || '',
            localizations: item?.localizations || []
          }))
        );
        setSelectedOptionComp(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          authProfile?.industries.forEach(sele => {
            let found;
            for (let i = 0; i < res?.data.length; i++) {
              const industry = res?.data[i];
              if (language !== sele.locale) {
                const temp = industry.localizations.find(locObj => locObj.id === sele.id);
                if (temp) {
                  found = industry;
                  break;
                }
              }
            }
            if (language === sele.locale) {
              unTranslatedSelectedOption.push({ value: sele.id, label: sele.name, locale: sele.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.id, label: found.name, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
        setSelectedOption(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          authProfile?.emailSubFor.forEach(sele => {
            let found;
            for (let i = 0; i < res?.data.length; i++) {
              const industry = res?.data[i];
              if (language !== sele.industy_occupation.locale) {
                const temp = industry.localizations.find(locObj => locObj.id === sele.industy_occupation.industry);
                if (temp) {
                  found = industry;
                  break;
                }
              } else if (language === sele.industy_occupation.locale && industry.id === sele.industy_occupation.industry) {
                unTranslatedSelectedOption.push({ value: industry.id, label: industry.name, locale: industry.locale });
              }
            }
            if (found) {
              translatedSelectedOption.push({ value: found.id, label: found.name, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
      });

      setSelectedLevel(authProfile?.emailSubFor.map(item => ({ value: item?.industy_occupation?.id || '', label: SkillLevel[item?.level] || '' })));

      // console.log('here is user====>', authProfile);

      setIsSub(authProfile.isEmailSubscription);

      setUserImage(authProfile?.image?.url ? `${BE_URL}${authProfile.image.url}` : DefaultProfileImage);
    };

    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    } else if (selectedOptionComp && selectedOptionComp.length <= 0) {
      setSelectedOCPComp([]);
    }

    setPredefinedUserValues();
  }, [setCompanyFieldsValue, authProfile, BE_URL, loading, language]);

  const imageWatch = watchCompanyInfoFields('profileImage');
  const descriptionWatch = watchCompanyInfoFields('description');

  const handleUserUpdate = payload => {
    dispatch(updateUser({ userId: authUser.id, payload }));
  };

  const handleCompanyUpdate = async updatedFormData => {
    if (isSub && selectedOption.length < 1) {
      setSelectorError(1);
    }
    // const data = updatedFormData;
    const skillLevelArr = [];
    selectedLevel.forEach((level, i) => {
      if (selectedOCP[i] && selectedOCP[i].value) {
        skillLevelArr.push({
          industy_occupation: selectedOCP[i].value,
          level: getPropByValue(level)
        });
      }
    });

    const data = {
      ...updatedFormData,
      emailSubFor: skillLevelArr
    };

    if (selectedOptionComp.length) {
      data.industries = selectedOptionComp.map(ele => ele.value);
    }

    if (selectedOCPComp.length) {
      data.industy_occupations = selectedOCPComp.map(ele => ele.value);
    }
    // Must be normalized since useForm removes id prop from objects

    const formData = new FormData();

    // If image source is changed we need to PUT data as FormData otherwise pure object is allowed
    if (userImage !== `${BE_URL}${authProfile?.image?.url}` && userImage !== DefaultProfileImage) {
      formData.append('data', JSON.stringify(data));
      formData.append(`files.image`, imageWatch[0], imageWatch[0].name);
      const isCompanyUpdated = await dispatch(updateCompany({ companyId: authProfile.id, payload: formData }));
      if (isCompanyUpdated) {
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.updateProfile.success')
          })
        );
      }
    } else {
      const isCompanyUpdated = await dispatch(updateCompany({ companyId: authProfile.id, payload: data })).unwrap();
      if (isCompanyUpdated) {
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.updateProfile.success')
          })
        );
      }
    }
  };

  const openFileBrowser = () => {
    imageUploadRef.current.children[0].click();
  };

  const fetchIndustriesOcp = async () => {
    if (selectedOption.length > 0) {
      let filter = '';
      selectedOption.length > 0 &&
        selectedOption.forEach((item, index) => {
          if (index === 0) {
            filter = `?industry=${item.value}&_locale=${language}`;
          } else {
            filter = `${filter}&industry=${item.value}`;
          }
        });
      await http.get(industryOccupations + filter).then(res => {
        // console.log('Fetched Industry Occupations:', res?.data);
        const ocpList = res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || '',
          locale: item?.locale || '',
          localizations: item?.localizations || []
        }));
        setSelectedOCP(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          authProfile?.emailSubFor.forEach(sele => {
            let found;
            for (let i = 0; i < ocpList.length; i++) {
              const industryOcc = ocpList[i];
              if (language !== sele.industy_occupation.locale) {
                const temp = industryOcc.localizations.find(locObj => locObj.id === sele.industy_occupation.industry);
                if (temp) {
                  found = industryOcc;
                  break;
                }
              }
            }
            if (language === sele.industy_occupation.locale) {
              unTranslatedSelectedOption.push({ value: sele.industy_occupation.id, label: sele.industy_occupation.name, locale: sele.industy_occupation.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.value, label: found.label, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
        setIndustryOCP(ocpList);
      });
    }
    if (selectedOptionComp.length > 0) {
      let filter = '';
      selectedOptionComp.length > 0 &&
        selectedOptionComp.forEach((item, index) => {
          if (index === 0) {
            filter = `?industry=${item.value}&_locale=${language}`;
          } else {
            filter = `${filter}&industry=${item.value}`;
          }
        });
      await http.get(industryOccupations + filter).then(res => {
        const ocpList = res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || '',
          locale: item?.locale || '',
          localizations: item?.localizations || []
        }));
        setSelectedOCPComp(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          authProfile?.industy_occupations.forEach(sele => {
            let found;
            for (let i = 0; i < ocpList.length; i++) {
              const industryOcc = ocpList[i];
              if (language !== sele.locale) {
                const temp = industryOcc.localizations.find(locObj => locObj.id === sele.id);
                if (temp) {
                  found = industryOcc;
                  break;
                }
              }
            }
            if (language === sele.locale) {
              unTranslatedSelectedOption.push({ value: sele.id, label: sele.name, locale: sele.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.value, label: found.label, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
        setIndustryOCPComp(ocpList);
      });
    } else {
      setIndustryOCP([]);
      setIndustryOCPComp([]);
    }
  };

  // console.log("here is industryOCP ==>>", industryOCP)

  const fetchIndustries = async () => {
    if (industries && industries.length) {
      fetchIndustriesOcp();
    } else {
      await http.get(`${industryList}?_locale=${language}`).then(res =>
        setIndustries(() =>
          res?.data.map(item => ({
            value: item?.id || '',
            label: item?.name || '',
            locale: item?.locale || '',
            localizations: item?.localizations || '',
            industy_occupations: item.industy_occupations || ''
          }))
        )
      );
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    } else if (selectedOptionComp && selectedOptionComp.length <= 0) {
      setSelectedOCPComp([]);
    }
    fetchIndustries();
    fetchIndustriesOcp();
  }, [selectedOption, selectedOptionComp, language]);

  useEffect(() => {
    if (imageWatch && imageWatch.length > 0) {
      const file = imageWatch[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => setUserImage(reader.result);
    }
  }, [imageWatch]);

  const handleFormSubmit = e => {
    e.preventDefault();
    handleSubmitCompanyUpdate(handleCompanyUpdate)();
    handleSubmitUserUpdate(handleUserUpdate)();
  };

  const customStyles = {
    multiValueRemove: provided => ({
      ...provided,
      ':hover': {
        backgroundColor: '#f95050'
      }
    }),
    control: (prevStyle, state) => ({
      ...prevStyle,
      border: `1px solid ${INPUT_BORDER}`,
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none',
      ':hover': { border: `1px solid ${INPUT_BORDER}` }
    })
  };

  console.log("here is selectedOCP", selectedOCP)

  const userUpdateInfo = () => (
    <UserUpdateWrapper>
      <h4>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.updateUserSectionTitle')}</h4>
      <form onSubmit={handleFormSubmit}>
        <Row className="user-fields-container" style={{ display: 'flex', flexDirection: 'column' }}>
          <Col sm={12} md={6} style={{ marginBottom: '20px' }}>
            <StyledInputField style={{ width: '300px' }} placeholder={t('FORM.fields.fullName')} type="text" {...registerUserInfoFields('name')} />
            {userStateErrors.name && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={6}>
            <StyledInputField style={{ width: '300px' }} placeholder={t('FORM.fields.email')} type="email" {...registerUserInfoFields('email')} />
            {userStateErrors.email && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>

        <SubmitActionWrapper>
          <CustomButton type="submit" text={t('BUTTON.updateBasicInfo')} customTextColor={WHITE} />
        </SubmitActionWrapper>
      </form>
    </UserUpdateWrapper>
  );

  const companyUpdateInfo = () => (
    <CompanyUpdateWrapper>
      <h4>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.updateWorkerSectionTitle')}</h4>
      <form onSubmit={handleFormSubmit}>
        <Col className="company-image-container" md={12} lg={9}>
          <div className="image-preview">
            <img src={userImage} alt="profile" />
            <div className="image-preview-hover" onClick={openFileBrowser}>
              <FontAwesomeIcon icon="cloud-upload-alt" size="4x" color="rgba(249, 80, 80, 0.8)" />
            </div>
          </div>
          <div ref={imageUploadRef} className="image-ref">
            <input type="file" {...registerCompanyInfoFields('profileImage')} />
          </div>
        </Col>
        <Row className="company-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.company')} type="text" {...registerCompanyInfoFields('companyName')} />
            {companyStateErrors.companyName && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.mobilePhone')} type="text" {...registerCompanyInfoFields('mobilePhone')} />
            {companyStateErrors.mobilePhone && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.phoneNumber')} type="text" {...registerCompanyInfoFields('phoneNumber')} />
            {companyStateErrors.mobilePhone && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row className="company-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.contactEmail')} type="text" {...registerCompanyInfoFields('contactEmail')} />
            {companyStateErrors.companyName && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.website')} type="text" {...registerCompanyInfoFields('website')} />
            {companyStateErrors.mobilePhone && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row className="company-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...registerCompanyInfoFields('address')} />
            {companyStateErrors.address && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...registerCompanyInfoFields('city')} />
            {companyStateErrors.city && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.country')} type="text" {...registerCompanyInfoFields('state')} />
            {companyStateErrors.state && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row className="company-fields-container">
          <Col sm={12} md={8} xl={6} className="mb-10">
            <MultiSelector
              isMulti
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder={t('FORM.fields.selectIndustries')}
              value={selectedOptionComp}
              onChange={setSelectedOptionComp}
              options={industries}
              styles={{
                ...customStyles, // Include custom animation styles
                menuList: base => ({
                  ...base,
                  maxHeight: '220px' // your desired height
                })
              }}
              // isOptionDisabled={() => selectedOptionComp.length >= 2}
            />
          </Col>
          <Col>
            {selectedOptionComp?.length > 0 && (
              <MultiSelector
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder={t('FORM.fields.selectOccupations')}
                value={selectedOCPComp}
                onChange={setSelectedOCPComp}
                options={industryOCPComp}
                styles={{
                  ...customStyles, // Include custom animation styles
                  menuList: base => ({
                    ...base,
                    maxHeight: '190px' // your desired height
                  })
                }}
                // isOptionDisabled={() => selectedOCP.length >= 2}
              />
            )}
          </Col>
        </Row>
        <Row className="company-fields-container">
          <Col sm={12}>
            <Editor placeholder={t('FORM.fields.description')} value={descriptionWatch} onChange={content => setCompanyFieldsValue('description', content)} />
            {companyStateErrors.description && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="check-box">
            <label style={{ fontSize: '10px' }}>{t('FORM.fields.emailSub')}</label>
          </Col>
          <Col sm={6} md={6} xl={6} xxl={6}>
            <div className="check-boxbottom">
              {/* <label style={{ fontSize: '10px' }}>{t('FORM.fields.emailSub')}</label> */}
              <input type="checkbox" onClick={e => setIsSub(e.target.checked)} {...registerCompanyInfoFields('isEmailSubscription')} />
            </div>
            {companyStateErrors.isEmailSubscription && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        {isSub && (
          <Row>
            <Col sm={12} md={12} xl={12} className="mb-10">
              <label style={{ fontSize: '16px' }}>{t('FORM.fields.emailSubOptions')}</label>
            </Col>
            <Col sm={12} md={8} xl={6} className="mb-10">
              <MultiSelector
                isMulti
                placeholder="Select Industries"
                value={selectedOption}
                onChange={setSelectedOption}
                options={industries}
                isOptionDisabled={() => selectedOption.length >= 2}
              />
              {selectorError === 1 && <span>{t('FORM.validation.requiredField')}</span>}
            </Col>
            <Col>
              {selectedOption?.length > 0 && (
                <MultiSelector
                  isMulti
                  placeholder="Select industry occupations"
                  value={selectedOCP}
                  onChange={setSelectedOCP}
                  options={industryOCP}
                  // isOptionDisabled={() => selectedOCP.length >= 2}
                />
              )}
              {selectorError === 2 && <ErrorMessage>{t('FORM.validation.requiredField')}</ErrorMessage>}
            </Col>
          </Row>
        )}
        <Row>
          <Col>{selectedOCP.length > 0 && <CustomTable selectedOCP={selectedOCP} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />}</Col>
        </Row>
        <SubmitActionWrapper>
          <CustomButton type="submit" text={t('BUTTON.updateWorkersInfo')} customTextColor={WHITE} />
        </SubmitActionWrapper>
      </form>
    </CompanyUpdateWrapper>
  );

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('EMPLOYEE_PROFILE.EDIT_PROFILE.title')} role={USER_TYPE.COMPANY}>
      <StyledContainer fluid>
        {userUpdateInfo()}
        {companyUpdateInfo()}
      </StyledContainer>
    </ProfilePageContainer>
  );
};

export default EditProfile;
