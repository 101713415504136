import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { LIGHT_GRAY_BACKGROUND, DARK_BLUE_FOOTER } from 'constants/colors';

const StyledSubscriptionContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledSubscriptionWrapper = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 0px 6px 6px 6px;
  margin-top: 20px;
  position: relative;
`;

const StyledSubscriptionCardsWrapper = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  //margin-top: 16px;
  border-radius: 6px 6px 0 0;
  padding: 30px 35px;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledBlockTitle = styled('p')`
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${DARK_BLUE_FOOTER};
  padding: 0;
  margin-bottom: 36px;
  font: normal normal bold 30px/55px Lato;
  letter-spacing: 0px;
  color: #192433;
  text-transform: capitalize;
  opacity: 1;
`;

const StyledInformationsWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;

  & > div:not(:first-of-type) {
    border-top: 1px solid rgba(112, 112, 112, 0.3);
    padding-top: 30px;
  }
`;

const StyledNotificationWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledInformationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
`;

const StyledInformationRowWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 30px;
`;

const StyledPeriod = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 500px;

  p {
    text-align: left;
    font: normal normal normal 24px/32px Lato;
    letter-spacing: 0px;
    color: #192432;
    opacity: 1;
  }
`;

const StyledButtonsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  & > button:nth-of-type(1) {
    margin-right: 32px;
  }
`;

const StyledCancel = styled('div')`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-left: 10px;
    margin: 0;
  }
`;

export {
  StyledSubscriptionContentWrapper,
  StyledSubscriptionWrapper,
  StyledSubscriptionCardsWrapper,
  StyledCardWrapper,
  StyledBlockTitle,
  StyledInformationsWrapper,
  StyledNotificationWrapper,
  StyledInformationWrapper,
  StyledPeriod,
  StyledInformationRowWrapper,
  StyledButtonsWrapper,
  StyledCancel
};
