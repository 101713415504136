/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import Filters from 'components/Filters';
import CustomCard from 'components/CustomCarOffers';
import { USER_TYPE } from 'constants/userTypes';
import { formatDate } from 'utils/helpers';
import { companyOfferSelector } from 'redux/modules/offers/selectors';
import { workersSelector } from '../../../redux/modules/workers/selectors';
// import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import DefaultProfileImage from 'assets/images/default.jpeg';

import {
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo,
  StyledOfferHeaderWrapper,
  StyledLineWrapper,
  StyledVerticalLine,
  StyledPreviewMainContent,
  StyledInfoWrapperBottom,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper
} from './styles';
import { openDialog } from '../../../redux/modules/dialogs/reducer';
import PreviewDialog from '../../../components/PreviewDialog';
import { authProfileSelector } from '../../../redux/modules/auth/selectors';
import { companyOffers } from '../../../redux/modules/offers/asyncThunks';
import { countWorkers, fetchWorkersList } from '../../../redux/modules/workers/asyncThunks';

const Offers = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const [previewFilesWorker, setPreviewFilesWorker] = useState(null);
  const [previewEducation, setPreviewEducation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const company = useSelector(authProfileSelector);
  const workersListSelector = useSelector(workersSelector);
  const dispatch = useDispatch();
  const [tab, setTab] = useState('Pending');
  const offers = useSelector(companyOfferSelector);
  const { width } = useWindowDimensions();
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;

  const PER_PAGE = 12;
  const defaultParams = {
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
  };

  useEffect(async () => {
    await dispatch(fetchWorkersList(defaultParams));
    await dispatch(countWorkers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // console.log('here is workersListSelector ====>>>', workersListSelector);
  useEffect(() => {
    dispatch(companyOffers(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const handleOpenWorkerDetails = worker => {
    // console.log('here is worker in handleOpenWorkerDetails ===>>', worker);
    let workerWithFiles = workersListSelector.find(user => user.id === worker.worker.id);
    let workerFilesOnly = workerWithFiles?.files;
    {
      workerFilesOnly && setPreviewEducation(workerWithFiles);
    }

    // setPreviewWorker(worker?.worker);
    setPreviewWorker({
      originalData: worker,
      title: worker?.worker?.user?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.worker?.jobTitle,
      workerName: worker?.worker?.user?.name,
      workerStreet: worker?.worker?.address,
      workerCity: worker?.worker?.city,
      workerPostcode: worker?.worker?.rate,
      workerCountry: worker?.worker?.state,
      workerEmail: worker?.worker?.user?.email,
      workerCitizenship: worker?.worker?.citizenship,
      workerBirthdate: worker?.worker?.birthdate,
      workerDriverLicenses: worker?.worker?.driverLicences,
      workerHourlyRate: worker?.worker?.hourlyRate,
      workerMonthlyRate: worker?.worker?.monthlySalary,
      workerMobilePhone: worker?.worker?.mobilePhone,
      workerBio: worker?.worker?.jobDescription,
      offerStreet: worker?.address?.streatAddress,
      offerCity: worker?.address?.city,
      offerPostCode: worker?.address?.postCode,
      offerCountry: worker?.address?.country,
      offerDescription: worker?.description,
      offerSalaryAmount: worker?.salary,
      offerSalaryType: worker?.salaryType,
      offerStartDate: worker?.startDate,
      offerJobTitle: worker?.jobTitle,
      companyStreet: worker?.company?.address,
      companyCity: worker?.company?.city,
      companyPostcode: worker?.company?.postCode,
      companyCountry: worker?.company?.state,
      companyMobilePhone: worker?.company?.mobilePhone,
      companyPhone: worker?.company?.phoneNumber,
      companyWebsite: worker?.company?.website,
      companyName: worker?.company?.companyName,
      companyEmail: worker?.company?.contactEmail,
      companyDescription: worker?.company?.description,
      logoUrl: worker?.worker?.image?.url ? `${BE_URL}${worker?.worker?.image?.url}` : null
    });
    setPreviewFilesWorker(workerFilesOnly);
    // console.log("here is previewWorker====>>>>", previewWorker)
    setPreviewDetails({
      originalData: worker,
      title: worker?.worker?.user?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.worker?.jobTitle,
      workerName: worker?.worker?.user?.name,
      workerStreet: worker?.worker?.description,
      workerCity: worker?.worker?.city,
      workerPostcode: worker?.worker?.rate,
      workerCountry: worker?.worker?.state,
      workerEmail: worker?.worker?.user?.email,
      workerCitizenship: worker?.worker?.citizenship,
      workerBirthdate: worker?.worker?.birthdate,
      workerDriverLicenses: worker?.worker?.driverLicenses,
      workerHourlyRate: worker?.worker?.hourlyRate,
      workerMonthlyRate: worker?.worker?.monthlySalary,
      workerMobilePhone: worker?.worker?.mobilePhone,
      workerBio: worker?.worker?.jobDescription,
      offerStreet: worker?.address?.streatAddress,
      offerCity: worker?.address?.city,
      offerPostCode: worker?.address?.postCode,
      offerCountry: worker?.address?.country,
      offerDescription: worker?.description,
      offerSalaryAmount: worker?.salary,
      offerSalaryType: worker?.salaryType,
      offerStartDate: worker?.startDate,
      offerJobTitle: worker?.jobTitle,
      companyStreet: worker?.company?.address,
      companyCity: worker?.company?.city,
      companyPostcode: worker?.company?.postCode,
      companyCountry: worker?.company?.state,
      companyMobilePhone: worker?.company?.mobilePhone,
      companyPhone: worker?.company?.phoneNumber,
      companyWebsite: worker?.company?.website,
      companyName: worker?.company?.companyName,
      companyEmail: worker?.company?.contactEmail,
      companyDescription: worker?.company?.description,
      logoUrl: worker?.worker?.image?.url ? `${BE_URL}${worker?.worker?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };
  // console.log("here is previewWorker outside of handler====>>>>", previewWorker)
  const filterApplications = application => application.status === tab;
  // console.log('here is filterApplications ===>>>', filterApplications);
  const filterBySearch = application =>
    application.worker?.user?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.jobTitle?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.worker?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    workersListSelector
      .find(user => user?.id === application?.worker?.id)
      ?.industy_occupations.map(el => el?.name)
      .join()
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());
  // application.job?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase());

  // const acceptApplication = (event, applicationId) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   // dispatch(updateApplication({ applicationId, payload: { status: 'accepted' } }));
  // };

  // const rejectApplication = (event, applicationId) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   dispatch(updateApplication({ applicationId, payload: { status: 'rejected' } }));
  // };

  const footerMinHeightVar = {
    minHeight: '6rem'
  };
  // console.log('here is offers====>>>>', offers);
  // console.log("here is workersListSelector =====>>>", workersListSelector)
  // console.log('here is tab ==>>', tab);
  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.offers.pageTitle')} role={USER_TYPE.COMPANY}>
      <StyledEmploymentContentWrapper>
       {width >= 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
        <StyledEmploymentInfoWrapper>
          <StyledCompletedEmploymentColWrapper xs={12}>
         {width >= 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'Pending'} onClick={() => setTab('Pending')}>
                {t('SIDE_NAVIGATION.offers.openOffers')} ({(offers || []).filter(a => a.status === 'Pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Accepted'} onClick={() => setTab('Accepted')}>
                {t('SIDE_NAVIGATION.offers.accepted')} ({(offers || []).filter(a => a.status === 'Accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Rejected'} onClick={() => setTab('Rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(offers || []).filter(a => a.status === 'Rejected')?.length || 0})
              </StyledTitleBox>
            </StyledTitleBoxWrapper>: ""}
            <StyledEmploymentCardWrapper>
            {width < 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
            {width < 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'Pending'} onClick={() => setTab('Pending')}>
                {t('SIDE_NAVIGATION.offers.openOffers')} ({(offers || []).filter(a => a.status === 'Pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Accepted'} onClick={() => setTab('Accepted')}>
                {t('SIDE_NAVIGATION.offers.accepted')} ({(offers || []).filter(a => a.status === 'Accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Rejected'} onClick={() => setTab('Rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(offers || []).filter(a => a.status === 'Rejected')?.length || 0})
              </StyledTitleBox>
            </StyledTitleBoxWrapper>: ""}
              <StyledSearchResultContent xs={12}>
                {offers
                  .filter(filterApplications)
                  .filter(filterBySearch)
                  // .filter(a => a.status === tab)
                  .map((application, index) => (
                    <StyledCardWrapper key={`offer-${application.company?.id}-${index}`} sm={12} md={6} lg={5}>
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        profileImage={application.worker?.image?.url ? `${BE_URL}${application.worker?.image?.url}` : DefaultProfileImage}
                        sectionTitle={application.worker?.user?.name}
                        sectionSubtitle={application?.jobTitle}
                        headerDescriptionFirst={`${t('OFFERCARD.salaryAmount')}: CHF ${application.salary || 0}.- \u0020 ${
                          application.salaryType === 'Hourly' ? t('OFFERCARD.salaryDisplay.perHour') : t('OFFERCARD.salaryDisplay.perMonth')
                        }`}
                        headerDescriptionSecond={application.address?.city}
                        // headerDescriptionThird={`${t('OFFERCARD.status')}: ${
                        //   application.status === 'Pending'
                        //     ? t('SIDE_NAVIGATION.offers.openOffers')
                        //     : application.status === 'Accepted'
                        //     ? t('SIDE_NAVIGATION.applications.accepted')
                        //     : t('SIDE_NAVIGATION.applications.declined')
                        // }`}
                        headerDescriptionExtra={`${t('OFFERCARD.startDate')}: ${formatDate(new Date(application.startDate))}`}
                        footerDescription={application?.description.replace(/<[^>]*>/g, '')}
                        hasActions
                        // handleOnItemClick={
                        //   application.status === 'pending'
                        //     ? $event => acceptApplication($event, application.id)
                        //     : undefined
                        // }
                        // handleOnItemClickReject={
                        //   application.status === 'pending'
                        //     ? $event => rejectApplication($event, application.id)
                        //     : undefined
                        // }
                        // acceptText={application.status === 'Pending' ? 'Accept' : undefined}
                        // rejectText={application.status === 'Pending' ? 'Reject' : undefined}
                        // favoriteIcon
                        // isFavorite={(favorites || []).find(
                        //   fav => fav.id === application.worker?.id
                        // )}
                        // onFavoriteClick={$event => favoriteClick($event, application.worker?.id)}
                        postCode={application?.address?.postCode}
                        click={() => handleOpenWorkerDetails(application)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledSearchResultContent>
            </StyledEmploymentCardWrapper>
          </StyledCompletedEmploymentColWrapper>
        </StyledEmploymentInfoWrapper>
      </StyledEmploymentContentWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewWorker?.workerStreet}
                </p>
              )}
              {previewWorker?.workerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}:</span>
                  {previewWorker?.workerCity}
                </p>
              )}
              {previewWorker?.workerPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.workerPostcode}
                </p>
              )}
              {previewWorker?.workerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewWorker?.workerCountry}
                </p>
              )}
              {previewWorker?.workerBirthdate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>
                  {formatDate(new Date(previewWorker?.workerBirthdate))}
                </p>
              )}
              {previewWorker?.workerCitizenship && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>
                  {previewWorker?.workerCitizenship}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.workerMobilePhone}
                </p>
              )}
              {previewWorker?.workerEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewWorker?.workerEmail}
                </p>
              )}
              {previewWorker?.workerDriverLicenses && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewWorker?.workerDriverLicenses}
                </p>
              )}
              {previewWorker?.workerHourlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>
                  {previewWorker?.workerHourlyRate}.-
                </p>
              )}
              {previewWorker?.workerMonthlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>
                  {previewWorker?.workerMonthlyRate}.-
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <StyledWorkerDocumentsWrapper>
          <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
          <StyledDocumentsWrapper>
            {(previewFilesWorker || []).map(file => (
              <StyledDocumentLine key={`file-${file.id}`}>
                <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                  <span>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </span>
                  {file.name}
                </p>
              </StyledDocumentLine>
            ))}
          </StyledDocumentsWrapper>
        </StyledWorkerDocumentsWrapper>

        <Row>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
            {previewEducation?.workExperience && previewEducation?.workExperience?.length ? (
              previewEducation?.workExperience.map(
                work =>
                  work.companyName &&
                  work.position && (
                    <>
                      <strong>
                        {work.companyName}, {work.position}
                      </strong>
                      <p>
                        {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                      </p>
                    </>
                  )
              )
            ) : (
              <></>
            )}
          </StyledColWrapper>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
            {previewEducation?.education?.length ? (
              previewEducation?.education.map(work => (
                <>
                  <strong>
                    {work.name}, {work.type}
                  </strong>
                  <p>
                    {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                  </p>
                </>
              ))
            ) : (
              <></>
            )}
          </StyledColWrapper>
        </Row>
        <Row>
          {' '}
          {previewWorker?.workerBio && (
            <StyledColWrapper style={{ paddingTop: '20px' }}>
              <h4>{t('FORM.fields.aboutMe')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>

        <StyledWorkerInfoBottomWrapper>
          {/* <StyledInfoWrapperBottom style={{ paddingTop: '50px' }}> */}{' '}
          <h4>
            {' '}
            <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.offer')}</span>
          </h4>
        </StyledWorkerInfoBottomWrapper>
        <StyledWorkerInfoLineWrapper>
          <StyledLineWrapper />
        </StyledWorkerInfoLineWrapper>

        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerJobTitle && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                  {previewWorker?.offerJobTitle}
                </p>
              )}
              {previewWorker?.offerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewWorker?.offerStreet}
                </p>
              )}
              {previewWorker?.offerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.offerCity}
                </p>
              )}
              {previewWorker?.offerPostCode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.offerPostCode}
                </p>
              )}
              {previewWorker?.offerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewWorker?.offerCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerStartDate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {formatDate(new Date(previewWorker?.offerStartDate))}
                </p>
              )}
              {previewWorker?.offerSalaryAmount &&
                (previewWorker?.offerSalaryType === 'Hourly' ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ))}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <Row>
          {' '}
          {previewWorker?.offerDescription && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('OFFERCARD.description')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.offerDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Offers, 'Offers');
