import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'boxicons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { APP_RED } from 'constants/colors';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { authUserSelector, authProfileSelector } from 'redux/modules/auth/selectors';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';

import {
  StyledProfileWrapper,
  StyledProfileDescriptionWrapper,
  StyledProfileDescription,
  StyledProfileDescriptionLeftSide,
  StyledProfileInfo,
  StyledInfo,
  StyledDocs,
  StyledDoc,
  StyledProfileActions,
  StyledProfileContentWrapper,
  StyledProfileInfoWrapper
} from './styles';
import { updateCompany } from '../../../redux/modules/auth/asyncThunks';
import Sanitizer from '../../../components/Sanitizer';

const Profile = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);
  const authProfile = useSelector(authProfileSelector);
  const formRef = useRef(null);
  const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;

  const goToEditProfile = () => {
    history.push(`/company/${authProfile.id}/profile/${authUser?.id}`);
  };

  const { register, watch } = useForm();

  const watchDocumentsUpload = watch('documents');

  useEffect(() => {
    const formData = new FormData();
    if (watchDocumentsUpload && watchDocumentsUpload.length > 0) {
      formData.append('data', JSON.stringify({}));
      for (let i = 0; i < watchDocumentsUpload.length; i++) {
        formData.append(`files.files`, watchDocumentsUpload[i], watchDocumentsUpload[i].name);
      }
      dispatch(updateCompany({ companyId: authProfile.id, payload: formData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDocumentsUpload]);

  const openFileInNewTab = url => window.open(`${url}`, '_blank');

  const triggerUpload = () => formRef.current.children[0].click();

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('EMPLOYEE_PROFILE.MY_PROFILE.title')} role={USER_TYPE.COMPANY}>
      <StyledProfileContentWrapper>
        <StyledProfileWrapper>
          <StyledProfileInfo>
            <StyledProfileDescriptionWrapper>
              <StyledProfileDescriptionLeftSide>
                {/* <img src={authProfile?.image?.url ? `${BE_URL}${authProfile.image.url}` : DefaultProfileImage} alt="user" /> */}
                <img src={authProfile?.image?.url ? `${authProfile.image.url}` : DefaultProfileImage} alt="user" />
                <StyledProfileDescription>
                  <h1>
                    {authProfile?.companyName} ({authUser?.name})
                  </h1>
                  <h3>{authProfile?.companyCategory?.name}</h3>
                </StyledProfileDescription>
              </StyledProfileDescriptionLeftSide>
              <StyledProfileActions>
                <button type="button" onClick={goToEditProfile}>
                  {t('BUTTON.editProfile')}
                </button>
                <button type="button" onClick={triggerUpload}>
                  {t('BUTTON.addFiles')}
                </button>
                <form ref={formRef}>
                  <input type="file" multiple {...register('documents')} />
                </form>
              </StyledProfileActions>
            </StyledProfileDescriptionWrapper>
            <StyledProfileInfoWrapper>
              <StyledInfo>
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.address')}</h4>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: {authProfile?.address}
                </p>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: {authProfile?.city}
                </p>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.state')}: {authProfile?.state}
                </p>
              </StyledInfo>
              <StyledInfo>
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.contact')}</h4>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: {authProfile?.mobilePhone}
                </p>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.phoneNumber')}: {authProfile?.phoneNumber}
                </p>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: {authProfile?.contactEmail}
                </p>
                <p>
                  {t('EMPLOYEE_PROFILE.MY_PROFILE.website')}: {authProfile?.website}
                </p>
              </StyledInfo>
              <StyledDocs>
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
                {(authProfile?.files || []).map(file => (
                  <StyledDoc key={`file-${file.id}`} onClick={() => openFileInNewTab(file?.url)}>
                    {file.name} <box-icon name="link-external" color={APP_RED} size="14px" />
                  </StyledDoc>
                ))}
              </StyledDocs>
            </StyledProfileInfoWrapper>
            <StyledProfileInfoWrapper>
              <StyledInfo>
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.description')}</h4>
                <div>{authProfile?.description ? <Sanitizer review={authProfile?.description} /> : t('EMPLOYEE_PROFILE.MY_PROFILE.noDescription')}</div>
              </StyledInfo>
            </StyledProfileInfoWrapper>
            {authProfile.isEmailSubscription && (
              <StyledProfileInfoWrapper>
                <StyledInfo>
                  <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.EmailSubFor')}</h4>
                  <div>
                    {authProfile?.emailSubFor && (
                      <ul>
                        {authProfile?.emailSubFor.map((item, ind) => (
                          <li key={ind}>{item?.industy_occupation?.name}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </StyledInfo>
              </StyledProfileInfoWrapper>
            )}
          </StyledProfileInfo>
        </StyledProfileWrapper>
      </StyledProfileContentWrapper>
    </ProfilePageContainer>
  );
};

export default Profile;
