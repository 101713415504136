import styled from '@emotion/styled';
import { Row, Col, Container } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import { INPUT_BORDER } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const pixel = '0px solid'

export const StyledProjectsContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${pixel} orange;
`;
export const MultiSelector = styled(Select)`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
`;

export const StyledContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  /* border: ${pixel} red; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ImageUploadWrapper = styled(Row)``;

export const UserUpdateWrapper = styled(Row)`
  margin-top: 20px;
  .user-fields-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: calc(100% - 200px);

    @media (max-width: ${breakpoints.xl}) {
      max-width: 100%;
    }
  }
`;

export const WorkerUpdateWrapper = styled(Row)`
  .worker-fields-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: calc(100% - 200px);
    border: ${pixel} pink;
    @media (max-width: ${breakpoints.xl}) {
      max-width: 100%;
    }
  }

  .worker-education-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 0;
    max-width: calc(100% - 200px);

    .add-button {
      cursor: pointer;
    }

    @media (max-width: ${breakpoints.xl}) {
      max-width: 100%;
    }
  }

  .worker-image-container {
    .image-preview {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 5px;

      .image-preview-hover {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .image-preview-hover:hover {
        opacity: 1;
        background: rgba(203, 206, 215, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin-right: 40px;
        box-shadow: 0px 3px 6px #00000029;
      }
    }

    .image-ref {
      height: 0;
      width: 0;
      input {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  margin: 0 5px;
  /* border: ${pixel} yellow; */

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 15px;
  }
`;

export const StyledDatePickerField = styled(DatePicker)`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  width: 100%;
`;

export const StyledSelectField = styled('select')`
  font-size: 16px;
  padding: 5px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  margin: 0 5px;
`;

export const SectionTitle = styled('h5')`
  margin: 20px 0;
  padding-top: 50px;
  border: ${pixel} blue;
`;

export const StyledTextareaField = styled('textarea')`
  font-size: 14px;
  padding-top: 30px;
  padding-left: 15px;
  font-style: italic;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  resize: none;
  width: 100%;
  border: ${pixel} green;
`;

export const StyledEditorLong = styled('Editor')`
  height: 300px; /* set the desired height here */
  /* border: ${pixel} purple; */
`;

export const SubmitActionWrapper = styled(Col)`
  display: flex;
  border: ${pixel} lightgreen;
  button {
    margin-top: 15px;
  }
`;
