/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
// import Sanitizer from 'components/Sanitizer';

import {
  StyledImage,
  StyledImageWrapper,
  StyledPreviewDialog,
  StyledPreviewDialogBody,
  StyledPreviewDialogCloseIcon,
  StyledPreviewDialogFooter,
  StyledPreviewDialogFooterInfo,
  StyledPreviewDialogHeader,
  StyledPreviewDialogTitle,
  StyledPreviewDialogWrapper,
  StyledHalfBorder,
  StyledPreviewDialogSubtitle,
  StyledVerticalLine,
  StyledPreviewMainContent
} from 'components/PreviewDialog/styles';
import {
  StyledImageRow,
  StyledImageTextRow,
  StyledButtonCol,
  StyledButtonRow
} from 'components/PreviewDialog/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import UserImageRounded from 'assets/images/user-image.jpeg';
import CustomButton from 'components/CustomButton';
import { APP_RED, GRAY, WHITE } from 'constants/colors';

const PreviewDiaApplicationJob = ({
  title,
  subtitle,
  logoUrl,
  children,
  workerJobTitle,
  description,
  contact,
  address,
  originalData,
  customButtonText,
  onApplyClick,
  disabledApply = false,
  // isOfferSent,
  offerStatus,
  onSendOffer,
  id
}) => {
  const { t } = useTranslation();
  const { previewJobDialogOpened } = useSelector(dialogSelector);
  const dispatch = useDispatch();


  console.log('here is previewJobDialogOpened===>>>', previewJobDialogOpened);

  const handleCloseDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'previewJobDialog'
      })
    );
  };

  const handleClickOutside = e => {
    if (previewJobDialogOpened && !e.target.closest('.styled-preview-dialog-wrapper')) {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [previewJobDialogOpened]);

  return previewJobDialogOpened ? (
    <StyledPreviewDialog>
    <StyledPreviewDialogWrapper>
      <StyledPreviewDialogHeader>
        <StyledPreviewDialogCloseIcon onClick={handleCloseDialog} />
        <StyledImageWrapper>
          <StyledImageRow>
            <StyledHalfBorder />
            <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
          </StyledImageRow>
          <StyledImageTextRow>
            <StyledPreviewDialogTitle>{title && title.slice(0, 50)}{title && title.length > 50 ? "...": ""}</StyledPreviewDialogTitle>
            {workerJobTitle && <StyledPreviewDialogSubtitle>{workerJobTitle.slice(0, 50)}{workerJobTitle && workerJobTitle.length > 50 ? "...": ""}</StyledPreviewDialogSubtitle>} 
            {/* {subtitle && <StyledPreviewDialogSubtitle>{subtitle}</StyledPreviewDialogSubtitle>} */}
          </StyledImageTextRow>
        </StyledImageWrapper>
      </StyledPreviewDialogHeader>
      <StyledPreviewDialogBody>
        {/* <hr /> */}
        <StyledPreviewMainContent>
          {/* <StyledVerticalLine /> */}
          {children}
        </StyledPreviewMainContent>
        {/* {workerBio && <StyledPreviewDialogFooterInfo>{workerBio}</StyledPreviewDialogFooterInfo>} */}
      </StyledPreviewDialogBody>
      
      </StyledPreviewDialogWrapper>
      {/* <StyledPreviewDialogWrapper>
        <StyledPreviewDialogHeader>
          <StyledPreviewDialogCloseIcon onClick={handleCloseDialog} />
          <StyledImageWrapper>
            <StyledHalfBorder />
            <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
            <StyledPreviewDialogTitle>{title}</StyledPreviewDialogTitle>
            {subtitle && <StyledPreviewDialogSubtitle>{subtitle}</StyledPreviewDialogSubtitle>}
          </StyledImageWrapper>
        </StyledPreviewDialogHeader>
        <StyledPreviewDialogBody>
          <hr />
          <StyledPreviewMainContent>
            <StyledVerticalLine />
            {children}
          </StyledPreviewMainContent>
        </StyledPreviewDialogBody>
        <StyledPreviewDialogFooter>
          <StyledPreviewDialogFooterInfo>
            <Sanitizer review={description} />
          </StyledPreviewDialogFooterInfo>
         
        </StyledPreviewDialogFooter>
      </StyledPreviewDialogWrapper> */}
    </StyledPreviewDialog>
  ) : null;
};

// PreviewDiaApplicationJob.defaultProps = {
//   title: '',
//   contact: '',
//   address: ''
// };

// PreviewDiaApplicationJob.propTypes = {
//   title: PropTypes.string,
//   children: PropTypes.node.isRequired,
//   contact: PropTypes.string,
//   address: PropTypes.string,
//   onApplyClick: PropTypes.func
// };

export default PreviewDiaApplicationJob;
