import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import { WHITE, APP_RED, LIGHT_GRAY_BACKGROUND, DARK_BLUE_FOOTER, INPUT_BORDER, LIGHT_BLUE_PAGE_BG } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const pixelSize = "0px"

const StyledBlueBackground = styled('div')`
  /* display: flex; */
  padding: 12px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  border-radius: 6px;
`;

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;
`;

const StyledEmploymentContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledEmploymentInfoWrapper = styled(Row)`
  margin-top: 12px;
  height: 100%;
  overflow: hidden;
`;

const StyledDatePickerField = styled(DatePicker)`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
`;

const StyledOngoingEmploymentColWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCompletedEmploymentColWrapper = styled(Col)`
  padding-left: 7px;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitleBoxWrapper = styled('div')`
  display: flex;
  border-bottom: 2px solid red;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

const StyledTitleBox = styled('div')`
  background: ${({ active }) => (active ? '#f95050 0% 0% no-repeat padding-box' : '#F5F5F5')};
  border-radius: 6px 6px 0 0;
  text-align: center;
  font: normal normal bold 14px/48px Lato;
  letter-spacing: 0px;
  color: ${({ active }) => (active ? WHITE : '#474A4F')};
  border-top: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-left: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-right: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  padding: 0 20px;
  cursor: pointer;
  margin-right: 6px;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 5px;
    width: 100%;
  }
`;

const StyledEmploymentCardWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background: ${LIGHT_GRAY_BACKGROUND} 0% 0% no-repeat padding-box;
  border-radius: 0 6px 6px 6px;
  padding: 16px;
  overflow-y: auto;
`;

const StyledOngoingInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledOngoingInfoColumnWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledFieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledFieldTitle = styled('p')`
  margin-bottom: 8px;
  text-align: left;
  font: normal normal normal 14px/17px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledFieldValue = styled('p')`
  margin: 0;
  text-align: left;
  font: normal normal bold 18px/28px 'Lato';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledDescription = styled('p')`
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
  margin-top: 7px;
`;

const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;


const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

const StyledOfferHeaderWrapper = styled('div')`
  padding: 20px 0px 0;
  h4 {
    font-size: 25px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
`;

const StyledLineWrapper = styled('hr')`
  width: 75vw; /* Set width to 70% of viewport width */
  height: 1px; /* Set desired height */
  background-color: black; /* Set desired color */
  border: none; /* Remove default border */
`;

const StyledVerticalLine = styled('hr')`
  transform: rotate(90deg);
  width: 100%;
  border: none;
  border-left: 3px solid ${LIGHT_GRAY_BACKGROUND};
  margin: 0;
`;

const StyledPreviewMainContent = styled('div')`
  max-height: 500px; // sets the height of the container that cointains the text
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    height: 120px;
  }
`;

const StyledInfoWrapperBottom = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: ${pixelSize} solid green;
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const StyledWorkerInfoBottomWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
padding-top: 70px;
font-weight: bold;
  border: ${pixelSize} solid orange;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledWorkerInfoLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
/* padding-top: 10px; */
  border: ${pixelSize} solid red;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

export {
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledOngoingEmploymentColWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledOngoingInfoWrapper,
  StyledOngoingInfoColumnWrapper,
  StyledFieldWrapper,
  StyledFieldTitle,
  StyledFieldValue,
  StyledDescription,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledDatePickerField,
  StyledPreviewDetails,
  StyledBlueBackground,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo,
  StyledOfferHeaderWrapper,
  StyledLineWrapper,
  StyledVerticalLine,
  StyledPreviewMainContent,
  StyledInfoWrapperBottom,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper
};
