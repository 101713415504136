import styled from '@emotion/styled';
import { Row, Col, Container } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';

import { DARK_BLUE_FOOTER, LIGHT_GRAY_BACKGROUND, APP_RED, LIGHT_BLUE_PAGE_BG } from 'constants/colors';

const parameters = 'solid 0px';

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;
`;

const StyledFavoritesContentWrapper = styled(Container)`
  overflow: auto;
  height: 100%;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border: ${parameters} orange;
`;

const StyledFavoritesCardsWrapper = styled(Row)`
  background-color: ${LIGHT_GRAY_BACKGROUND};
  margin-top: 16px;
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 43%;
  overflow: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  border: ${parameters} red;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px;
`;

const StyledBlockTitle = styled('p')`
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${DARK_BLUE_FOOTER};
  padding: 0 9px;
  margin-bottom: 0;
  margin-top: 26px;
  border: ${parameters} blue;
`;

const StyledWorkerInfoBottomWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
  padding-top: 20px;
  font-weight: bold;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledWorkerInfoLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 70px;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledLineWrapper = styled('hr')`
  width: 75vw; /* Set width to 70% of viewport width */
  height: 1px; /* Set desired height */
  background-color: black; /* Set desired color */
  border: none; /* Remove default border */
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

export {
  StyledFavoritesContentWrapper,
  StyledPreviewDialogFooterInfo,
  StyledBlockTitle,
  StyledFavoritesCardsWrapper,
  StyledCardWrapper,
  StyledPreviewDetails,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper
};
