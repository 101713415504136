import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';

import { APP_RED, DARK_BLUE_TEXT, DISABLED, LIGHT_GRAY_BACKGROUND } from '../../constants/colors';

const StyledPaginationWrapper = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  padding: 21px 18px;
  border-radius: 0 0 6px 6px;
`;

const StyledPaginationArrow = styled(FontAwesomeIcon)`
  color: ${({ disabled }) => (disabled ? DISABLED : APP_RED)};
  font-size: 16px;
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer !important;
`;

const StyledPaginationButton = styled('button')`
  background: transparent;
  outline: 0;
  border: none;
  color: ${DARK_BLUE_TEXT};
  margin-right: 9px;
  margin-left: 9px;
  font-size: 18px;
  font-weight: ${({ active }) => (active ? '700' : 'normal')};
  border-bottom: ${({ active }) => (active ? `4px solid ${APP_RED}` : 'none')};
  line-height: ${active => (active ? `1.2` : 'auto')};
  cursor: pointer !important;
  padding-left: 3px;
  padding-right: 3px;
`;

export { StyledPaginationWrapper, StyledPaginationArrow, StyledPaginationButton };
