/* eslint-disable no-sequences */
/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import { PDFDownloadLink } from '@react-pdf/renderer';
import withTranslations from 'translations';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import Filters from 'components/Filters';
import CustomCard from 'components/CustomCardOffersWorker';
import { USER_TYPE } from 'constants/userTypes';
import { WorkerOfferSelector } from 'redux/modules/offers/selectors';
import { StyledInfoLine, StyledInfoWrapper, StyledWorkerInfoPreviewWrapper } from 'views/companyPage/Projects/styles';
import { showToast } from 'redux/modules/toast/reducer';
import { formatDate } from 'utils/helpers';
// import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import DefaultProfileImage from 'assets/images/default.jpeg';
import ApplicationsPDF from '../../../components/ApplicationsPDF';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE, GREEN_ACCEPT, DARK_BUTTON } from 'constants/colors';
import Sanitizer from 'components/Sanitizer';

import {
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledDatePickerField,
  StyledPreviewDetails,
  StyledBlueBackground,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo,
  StyledOfferHeaderWrapper,
  StyledLineWrapper,
  StyledVerticalLine,
  StyledPreviewMainContent,
  StyledInfoWrapperBottom,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper
} from './styles';
// import { getWorkerProfile} from 'redux/modules/auth/asyncThunks';
import { openDialog } from '../../../redux/modules/dialogs/reducer';
// import PreviewDialog from '../../../components/PreviewDiaSearch';
import PreviewDialog from '../../../components/PreviewDialog';
import { authProfileSelector, authUserSelector } from '../../../redux/modules/auth/selectors';
import { updateJobOffers, workerOffers } from '../../../redux/modules/offers/asyncThunks';

const WorkerOffers = () => {
  const { t } = useTranslation();
  const [filteredAllApplications, setFilteredAllApplications] = useState([]);
  const [openAppsWithoutAccepted, setOpenAppsWithoutAccepted] = useState([]);
  const [rejectedAppsSendThisToPDF, setRejectedAppsSendThisToPDF] = useState([]);
  const [openAppsSendThisToPDF, setOpenAppsSendThisToPDF] = useState([]);
  const [acceptedAppsSendThisToPDF, setAcceptedAppsSendThisToPDF] = useState([]);
  const [showCreatePDF, setShowCreatePDF] = useState(false);
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const workerDetails = useSelector(authProfileSelector);
  const userInfo = useSelector(authUserSelector);
  const dispatch = useDispatch();
  const [role, setRole] = useState(userInfo?.role.type);
  const [tab, setTab] = useState('Pending');
  const [openApplications, setOpenApplications] = useState([]);
  const [openAppsAccepted, setOpenAppsAccepted] = useState([]);
  const [rejectedApplications, setRejectedApplications] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const offers = useSelector(WorkerOfferSelector);
  const { width } = useWindowDimensions();
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;

  useEffect(() => {
    dispatch(workerOffers(workerDetails.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  // console.log('here is searchTerm', searchTerm);
  const handleOpenWorkerDetails = offer => {
    console.log('here is offer ===>>>', offer);
    setPreviewWorker({
      originalData: offer,
      title: offer?.company?.companyName,
      subtitle: offer?.jobTitle,
      // workerJobTitle: offer?.worker?.jobTitle,
      workerName: offer?.worker?.user?.name,
      workerStreet: offer?.worker?.description,
      workerCity: offer?.worker?.city,
      workerPostcode: offer?.worker?.rate,
      workerCountry: offer?.worker?.state,
      workerEmail: offer?.worker?.user?.email,
      workerCitizenship: offer?.worker?.citizenship,
      workerBirthdate: offer?.worker?.birthdate,
      workerDriverLicenses: offer?.worker?.driverLicenses,
      workerHourlyRate: offer?.worker?.hourlyRate,
      workerMonthlyRate: offer?.worker?.monthlySalary,
      workerMobilePhone: offer?.worker?.mobilePhone,
      workerBio: offer?.worker?.jobDescription,
      offerStreet: offer?.address?.streatAddress,
      offerCity: offer?.address?.city,
      offerPostCode: offer?.address?.postCode,
      offerCountry: offer?.address?.country,
      offerDescription: offer?.description,
      offerSalaryAmount: offer?.salary,
      offerSalaryType: offer?.salaryType,
      offerStartDate: offer?.startDate,
      offerJobTitle: offer?.jobTitle,
      companyContactPerson: offer?.company?.name,
      companyStreet: offer?.company?.address,
      companyCity: offer?.company?.city,
      companyPostcode: offer?.company?.postCode,
      companyCountry: offer?.company?.state,
      companyMobilePhone: offer?.company?.mobilePhone,
      companyPhone: offer?.company?.phoneNumber,
      companyWebsite: offer?.company?.website,
      companyName: offer?.company?.companyName,
      companyEmail: offer?.company?.contactEmail,
      companyDescription: offer?.company?.description,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    setPreviewDetails({
      originalData: offer,
      title: offer?.company?.companyName,
      subtitle: offer?.jobTitle,
      // workerJobTitle: offer?.worker?.jobTitle,
      workerName: offer?.worker?.user?.name,
      workerStreet: offer?.worker?.description,
      workerCity: offer?.worker?.city,
      workerPostcode: offer?.worker?.rate,
      workerCountry: offer?.worker?.state,
      workerEmail: offer?.worker?.user?.email,
      workerCitizenship: offer?.worker?.citizenship,
      workerBirthdate: offer?.worker?.birthdate,
      workerDriverLicenses: offer?.worker?.driverLicenses,
      workerHourlyRate: offer?.worker?.hourlyRate,
      workerMonthlyRate: offer?.worker?.monthlySalary,
      workerMobilePhone: offer?.worker?.mobilePhone,
      workerBio: offer?.worker?.jobDescription,
      offerStreet: offer?.address?.streatAddress,
      offerCity: offer?.address?.city,
      offerPostCode: offer?.address?.postCode,
      offerCountry: offer?.address?.country,
      offerDescription: offer?.description,
      offerSalaryAmount: offer?.salary,
      offerSalaryType: offer?.salaryType,
      offerStartDate: offer?.startDate,
      offerJobTitle: offer?.jobTitle,
      companyContactPerson: offer?.company?.name,
      companyStreet: offer?.company?.address,
      companyCity: offer?.company?.city,
      companyPostcode: offer?.company?.postCode,
      companyCountry: offer?.company?.state,
      companyMobilePhone: offer?.company?.mobilePhone,
      companyPhone: offer?.company?.phoneNumber,
      companyWebsite: offer?.company?.website,
      companyName: offer?.company?.companyName,
      companyEmail: offer?.company?.contactEmail,
      companyDescription: offer?.company?.description,
      logoUrl: offer?.company?.image?.url ? `${BE_URL}${offer?.company?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const openApps = [];
  // const rejectedApps = [];
  let filteredByPendingRejected = [];

  const filteredByDate = application => {
    const selectedFormattedFromDate = new Date(selectedFromDate);
    const today = new Date();
    const createdAtDate = new Date(application.created_at);
    return createdAtDate > selectedFormattedFromDate && createdAtDate < (selectedToDate === null ? today : selectedToDate);
  };

  const filteredApplications = () => {
    const filteredApplications = offers.filter(filteredByDate).filter(filterBySearch);
    const rejectedApps = filteredApplications.filter(application => application.status === 'Rejected');
    const acceptedApps = filteredApplications.filter(application => application.status === 'Pending');
    // console.log('here is rejected Aossssppppps===>>> ', rejectedApps);
  };

  useEffect(() => {
    filteredApplications();
  }, [offers, selectedFromDate, selectedToDate]);

  useEffect(() => {
    (async () => {
      try {
        const openApps = [];
        const pendingApps = [];
        const rejectedApps = [];
        let filteredApplications = [];
        // console.log("here is offers ===>>>", offers)
        filteredApplications = offers.filter(application => {
          const selectedFormattedFromDate = new Date(selectedFromDate);
          const selectedFormattedToDate = new Date(selectedToDate);
          const today = new Date();
          const createdAtDate = new Date(application.created_at);
          return (
            ['Pending', 'Accepted', 'Rejected'].includes(application.status) &&
            createdAtDate > selectedFormattedFromDate &&
            createdAtDate < (selectedToDate === null ? today : selectedToDate)
          );
        });
        setFilteredAllApplications(filteredApplications);

        filteredApplications?.forEach(application =>
          ['Pending', 'Accepted'].includes(application.status) ? openApps.push(application) : rejectedApps.push(application)
        );
        console.log('here is openApps==== >>>>>>>>', openApps);

        setOpenApplications(openApps);

        setOpenAppsWithoutAccepted(openApps.filter(apps => apps.status !== 'Accepted'));
        setRejectedApplications(rejectedApps);
        setOpenAppsAccepted(openApps.filter(apps => apps.status === 'Accepted'));
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, [selectedFromDate, selectedToDate, offers]);

  useEffect(() => {
    filteredApplications();
  }, [openApplications]);

  // console.log('here is openApplications==== >>>>>>>>', openApplications);

  const filterApplications = application => application.status === tab;
  const filterBySearch = application =>
    application.address?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.address?.country?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.companyName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.contactEmail?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.mobilePhone?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.description?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.jobTitle?.toLowerCase()?.includes(searchTerm?.toLowerCase());

  const acceptApplication = async (event, jobOfferId, application) => {
    console.log('here is application ===>>>', application);
    let workerName = application?.worker?.user?.name;
    let lang = application?.worker?.user?.lang;
    let workerId = application?.worker?.id;
    event.stopPropagation();
    event.preventDefault();
    const isOfferAccepted = await dispatch(updateJobOffers({ jobOfferId, payload: { status: 'Accepted', name: workerName, lang: lang, workerId: workerId } }));
    if (isOfferAccepted && isOfferAccepted.payload) {
      dispatch(workerOffers(workerDetails.id));
      dispatch(
        showToast({
          type: 'success',
          message: 'Offer is Accepted Successfully.'
        })
      );
      // dispatch(getWorkerProfile(workerDetails.id))
    }
  };

  // useEffect(() => {
  //   dispatch(getWorkerProfile(workerDetails.id))
  // }, [])

  const rejectApplication = async (event, jobOfferId) => {
    event.stopPropagation();
    event.preventDefault();
    const isOfferRejected = await dispatch(updateJobOffers({ jobOfferId, payload: { status: 'Rejected' } }));
    if (isOfferRejected && isOfferRejected.payload) {
      dispatch(workerOffers(workerDetails.id));
      dispatch(
        showToast({
          type: 'success',
          message: 'Offer is Rejected Successfully.'
        })
      );
    }
  };

  const footerMinHeightVar = {
    minHeight: '0.1rem'
  };

  const handlePDFDisplay = () => {
    if (showCreatePDF === true) {
      setShowCreatePDF(false);
    } else {
      setShowCreatePDF(true);
    }
  };

  // console.log('here is openAppsWithoutAccepted ===>>>', openAppsWithoutAccepted);

  const openAppsDataForPDF = () => {
    console.log("here is openAppsWithoutAccepted ==>>", openAppsWithoutAccepted)
    setOpenAppsSendThisToPDF(
      openAppsWithoutAccepted.map(data => ({
        company: { companyName: data.company?.companyName },
        created_at: data?.created_at,
        job: { title: data?.jobTitle, rate: data?.salary, deadline: data?.startDate, description: data?.description }
      }))
    );
  };
  useEffect(() => {
    openAppsDataForPDF();
  }, [openAppsWithoutAccepted]);

  console.log("here is  openAppsSendThisToPDF ==>>",  openAppsSendThisToPDF)

  const rejectedAppsDataForPDF = () => {
    console.log("here is rejectedApplications ==>>", rejectedApplications)
    setRejectedAppsSendThisToPDF(
      rejectedApplications.map(data => ({
        company: { companyName: data.company?.companyName },
        created_at: data?.created_at,
        job: { title: data?.jobTitle, rate: data?.salary, deadline: data?.startDate, description: data?.description }
      }))
    );
  };
  useEffect(() => {
    rejectedAppsDataForPDF();
  }, [rejectedApplications]);


  const acceptedAppsDataForPDF = () => {
    console.log("here is rejectedApplications ==>>", rejectedApplications)
    setAcceptedAppsSendThisToPDF(
      openAppsAccepted.map(data => ({
        company: { companyName: data.company?.companyName },
        created_at: data?.created_at,
        job: { title: data?.jobTitle, rate: data?.salary, deadline: data?.startDate, description: data?.description }
      }))
    );
  };
  useEffect(() => {
    acceptedAppsDataForPDF ();
  }, [openAppsAccepted]);




  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('SIDE_NAVIGATION.offers.pageTitle')} role={USER_TYPE.WORKER}>
      <StyledEmploymentContentWrapper>
        {width >= 900 && showCreatePDF ? (
          <StyledBlueBackground>
            <Row>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.fromDate')}
                  selected={selectedFromDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedFromDate(date, 'fromDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.toDate')}
                  selected={selectedToDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedToDate(date, 'toDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col>
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  document={<ApplicationsPDF openApplications={openAppsSendThisToPDF} rejectedApplications={rejectedAppsSendThisToPDF} 
                  acceptedApplications={acceptedAppsSendThisToPDF} 
                  />}
                  fileName="applications.pdf"
                >
                  <CustomButton
                    // onClick={handleSubmit}
                    text="PDF Drucken"
                    customFontSize="18px"
                    customWidth="300px"
                    customHeight="50px"
                    customTextColor={WHITE}
                    customBorderColor={APP_RED}
                  />
                </PDFDownloadLink>
              </Col>
            </Row>
          </StyledBlueBackground>
        ): ""}

        {width >= 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
        <StyledEmploymentInfoWrapper>
          <StyledCompletedEmploymentColWrapper xs={12}>
          {width >= 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'Pending'} onClick={() => setTab('Pending')}>
                {t('SIDE_NAVIGATION.applications.openApplications')} ({(filteredAllApplications || []).filter(a => a.status === 'Pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Accepted'} onClick={() => setTab('Accepted')}>
                {t('SIDE_NAVIGATION.offers.accepted')} ({(filteredAllApplications || []).filter(a => a.status === 'Accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Rejected'} onClick={() => setTab('Rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(filteredAllApplications || []).filter(a => a.status === 'Rejected')?.length || 0})
              </StyledTitleBox>
              {!showCreatePDF && (
                <StyledTitleBox active={tab === 'Rejected' || tab === 'Rejected' || tab === 'Pending'} onClick={() => handlePDFDisplay()} style={{ marginLeft: '50px' }}>
                  create PDF <FontAwesomeIcon icon={faFileAlt} />
                </StyledTitleBox>
              )}
            </StyledTitleBoxWrapper>: ""}
            <StyledEmploymentCardWrapper>
            {width < 900 && showCreatePDF ? (
          <StyledBlueBackground>
            <Row>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.fromDate')}
                  selected={selectedFromDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedFromDate(date, 'fromDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col sm={12} md={4}>
                <StyledDatePickerField
                  placeholderText={t('FORM.fields.toDate')}
                  selected={selectedToDate}
                  dateFormat="MM/yyyy"
                  onChange={date => setSelectedToDate(date, 'toDate')}
                  showMonthYearPicker
                />
              </Col>
              <Col>
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  document={<ApplicationsPDF openApplications={openAppsSendThisToPDF} rejectedApplications={rejectedAppsSendThisToPDF} 
                  acceptedApplications={acceptedAppsSendThisToPDF} 
                  />}
                  fileName="applications.pdf"
                >
                  <CustomButton
                    // onClick={handleSubmit}
                    text="PDF Drucken"
                    customFontSize="18px"
                    customWidth="300px"
                    customHeight="50px"
                    customTextColor={WHITE}
                    customBorderColor={APP_RED}
                  />
                </PDFDownloadLink>
              </Col>
            </Row>
          </StyledBlueBackground>
        ): ""}
          {width < 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
          {width < 900 ? <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === 'Pending'} onClick={() => setTab('Pending')}>
                {t('SIDE_NAVIGATION.applications.openApplications')} ({(filteredAllApplications || []).filter(a => a.status === 'Pending')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Accepted'} onClick={() => setTab('Accepted')}>
                {t('SIDE_NAVIGATION.offers.accepted')} ({(filteredAllApplications || []).filter(a => a.status === 'Accepted')?.length || 0})
              </StyledTitleBox>
              <StyledTitleBox active={tab === 'Rejected'} onClick={() => setTab('Rejected')}>
                {t('SIDE_NAVIGATION.offers.declined')} ({(filteredAllApplications || []).filter(a => a.status === 'Rejected')?.length || 0})
              </StyledTitleBox>
              {!showCreatePDF && (
                <StyledTitleBox active={tab === 'Rejected' || tab === 'Rejected' || tab === 'Pending'} onClick={() => handlePDFDisplay()} style={{ marginLeft: '50px' }}>
                  create PDF <FontAwesomeIcon icon={faFileAlt} />
                </StyledTitleBox>
              )}
            </StyledTitleBoxWrapper>: ""}
              <StyledSearchResultContent xs={12}>
                {offers
                  .filter(filteredByDate)
                  .filter(filterApplications)
                  .filter(filterBySearch)
                  .map(application => (
                    <StyledCardWrapper key={`worker-${application.company?.id}`} sm={12} md={6} lg={5}>
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        profileImage={
                          userInfo?.role?.type === 'company'
                            ? application?.worker?.image?.url
                              ? `${BE_URL}${application.worker?.image?.url}`
                              : DefaultProfileImage
                            : application?.company?.image?.url
                            ? `${BE_URL}${application?.company?.image?.url}`
                            : DefaultProfileImage
                        }
                        sectionTitle={application?.company?.companyName}
                        sectionSubtitle={application?.jobTitle}
                        headerDescriptionFirst={application?.address?.city}
                        postCode={application?.address?.postCode}
                        headerDescriptionPayrollMonthly={application?.monthlyPayroll}
                        headerDescriptionHourly={application?.salary}
                        headerDescriptionSecond={application?.address?.country}
                        headerDescriptionExtra={`${t('OFFERCARD.startDate')}: ${formatDate(new Date(application.startDate))}`}
                        footerDescription={application?.description}
                        hasActions
                        handleOnItemClick={application.status === 'Pending' ? $event => acceptApplication($event, application.id, application) : undefined}
                        handleOnItemClickReject={application.status === 'Pending' ? $event => rejectApplication($event, application.id) : undefined}
                        acceptText={application.status === 'Pending' ? t('BUTTON.applicationAccept') : undefined}
                        rejectText={application.status === 'Pending' ? t('BUTTON.applicationReject') : undefined}
                        click={() => handleOpenWorkerDetails(application)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledSearchResultContent>
            </StyledEmploymentCardWrapper>
          </StyledCompletedEmploymentColWrapper>
        </StyledEmploymentInfoWrapper>
      </StyledEmploymentContentWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.companyName && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.company')}: </span>
                  {previewWorker?.companyName}
                </p>
              )}
              {previewWorker?.companyContactPerson && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.fullName')}: </span>
                  {previewWorker?.companyContactPerson}
                </p>
              )}

              {previewWorker?.companyStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewWorker?.companyStreet}
                </p>
              )}
              {previewWorker?.companyCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.companyCity}
                </p>
              )}
              {previewWorker?.companyPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.companyPostcode}
                </p>
              )}
              {previewWorker?.companyCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewWorker?.companyCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.companyMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.companyMobilePhone}
                </p>
              )}
              {previewWorker?.companyPhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.companyPhone}
                </p>
              )}
              {previewWorker?.companyEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewWorker?.companyEmail}
                </p>
              )}
              {previewWorker?.companyWebsite && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewWorker?.companyWebsite}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <Row>
          {' '}
          {previewWorker?.companyDescription && (
            <StyledColWrapper style={{ paddingTop: '20px' }}>
              <h4>{t('FORM.fields.aboutUs')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.companyDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
        <StyledWorkerInfoBottomWrapper>
          {/* <StyledInfoWrapperBottom style={{ paddingTop: '50px' }}> */}{' '}
          <h4>
            {' '}
            <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.offer')}</span>
          </h4>
        </StyledWorkerInfoBottomWrapper>
        <StyledWorkerInfoLineWrapper>
          <StyledLineWrapper />
        </StyledWorkerInfoLineWrapper>

        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerJobTitle && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                  {previewWorker?.offerJobTitle}
                </p>
              )}
              {previewWorker?.offerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewWorker?.offerStreet}
                </p>
              )}
              {previewWorker?.offerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.offerCity}
                </p>
              )}
              {previewWorker?.offerPostCode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.offerPostCode}
                </p>
              )}
              {previewWorker?.offerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewWorker?.offerCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerStartDate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {formatDate(new Date(previewWorker?.offerStartDate))}
                </p>
              )}
              {previewWorker?.offerSalaryAmount &&
                (previewWorker?.offerSalaryType === 'Hourly' ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ))}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <Row>
          {' '}
          {previewWorker?.offerDescription && (
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('OFFERCARD.description')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.offerDescription.replace(/<\/?[^>]+(>|$)/g, '')}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(WorkerOffers, 'WorkerOffers');
