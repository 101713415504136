import { createAsyncThunk } from '@reduxjs/toolkit';

import { workersCountUrl, workersUrl } from 'utils/apis/index';
import http from 'utils/http';

const fetchWorkersList = createAsyncThunk('workers/fetchWorkersList', async (params = {}) => {
  // console.log("here is params in thunk ===>>>", params)
  const { data } = await http.get(workersUrl, {
    params
  });

  // console.log("here is fetchWorkersList ===>>>", data)

  return data;
});

const countWorkers = createAsyncThunk('workers/countWorkersList', async (params = {}) => {
  const { data } = await http.get(workersCountUrl, {
    params
  });

  return data;
});

export { fetchWorkersList, countWorkers };
