import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';

import { INPUT_TEXT_DARK_BLUE, WHITE, LIGHT_GRAY, DARK_GRAY, APP_RED } from 'constants/colors';

const StyledCardWrapper = styled('div')`
  background-color: ${WHITE};
  color: ${INPUT_TEXT_DARK_BLUE};
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  ${({ isActive }) =>
    isActive ? `border: 2px solid #E65D57;` : `border: 2px solid ${LIGHT_GRAY};`}
  border-radius: 6px;
`;

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  flex-direction: column;
  background-color: ${DARK_GRAY};
  height: 188px;
  position: relative;
  border-radius: 6px !important;
  padding: 0;
`;

const StyledStatus = styled('div')`
  background: ${WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  border: 1px solid #cbced7;
  box-shadow: 0px 2px 2px #00000024;

  svg {
    font-size: 8px;
    margin-right: 2px;
  }

  p {
    margin-bottom: 0;
    text-transform: capitalize;
  }
`;

const StyledDate = styled('div')`
  background: ${WHITE};
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 22px 0 22px;
  position: absolute;
  bottom: -1px;
  left: 0;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${APP_RED};
`;

const StyledCardBodyLeft = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  overflow: hidden;

  > h4 {
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`;

const StyledCardBodyRight = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  box-sizing: border-box;

  button {
    border: none;
    background: #FFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1px 5px;
    transition: box-shadow .2s ease-in-out;
  }
  button:hover {
    box-shadow: 0 0 0 2px ${APP_RED};
  }

`;

const StyledCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  font-family: Lato;
  color: #282e36;
  opacity: 0.8;
  padding: 12px 25px 18px 25px;
`;

export { StyledCardWrapper, StyledCardHeader, StyledCardBody, StyledStatus, StyledDate, StyledCardBodyLeft, StyledCardBodyRight };
