// export const prepareApplicationData = ({ job, company, worker }) => ({
//   job: job.id,
//   worker: worker.id,
//   company: company.id
// });
export const prepareApplicationData = ({ job, company, worker }) => ({
  job: job ? job.id : null,
  worker: worker ? worker.id : null,
  company: company ? company.id : null
});
