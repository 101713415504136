export const APP_RED = '#F95050';
export const APP_RED_HOVER = '#EF5252';
export const DARK_RED = '#FF7777';
export const DARK_BUTTON = '#474A4F';
export const BLACK = '#000000';
export const CIRCLE_BLACK = '#181A1F';
export const DARK_BLUE = '#192433';
export const INPUT_TEXT_DARK_BLUE = '#282E36';
export const DARK_BLUE_TEXT = '#192433';
export const DARK_GRAY = '#313338';
export const LIGHT_GRAY_BLUE = '#CBCED7';
export const LIGHT_BLUE_PAGE_BG = '#F0F4FC';
export const DARK_BLUE_FOOTER = '#26282D';
export const GRAY_BORDER_FOOTER = '#707070';
export const BOX_SHADOW_ELEVATION2 = '#16376434';
export const BOX_SHADOW_ELEVATION5 = '#333b3e4a';
export const WHITE = '#FFFFFF';
export const GRAY_BLUE = '#909CB9';
export const LIGHT_BLACK = '#192432';
export const GRAY = '#aaa';
export const GRAY_DISABLED = '#bfb';
export const INPUT_BORDER = '#D4D4D8';
export const DIALOG_FALLBACK_COLOR = '';
export const LIGHT_GRAY_BACKGROUND = '#F5F5F5';
export const NAV_BORDER = '#CACED8';
export const DISABLED = '#9C9DA0';
export const LIGHT_GRAY = '#e4e4e4';
export const LIGHTER_GRAY = '#d3d3d3'
export const GREEN_ACCEPT = '#4DDE7F';
export const BAN_RED = '#E3645F';
export const SUBTLE_RED = '#c44b4b';
