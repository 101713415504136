import styled from '@emotion/styled';
import { Container, Row } from 'react-bootstrap';

import breakpoints from 'constants/breakpoints';

const StyledMainWrapper = styled(Container)``;

const StyledContainerRow = styled(Row)`
  display: flex;
`;

const StyledContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledSidebarWrapper = styled('div')`
  width: 300px;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: ${breakpoints.lg}) {
    width: 0px;
  }
`;

const StyledViewWrapper = styled('div')`
  width: calc(100% - 300px);

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

export {
  StyledMainWrapper,
  StyledContainerRow,
  StyledContainerWrapper,
  StyledSidebarWrapper,
  StyledViewWrapper
};
