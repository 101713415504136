import React from 'react';

import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfileTopHeader from 'components/ProfileTopHeader';
import ProfilePageContainer from 'components/ProfilePageContainer';
import LogoDarkRedHorizontal from 'assets/logo/png/dark-red-horizontal-stripped.png';
import { WHITE } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import { USER_TYPE } from 'constants/userTypes';

import {
  StyledWeeklyReportsContentWrapper,
  StyledTable,
  StyledContainer,
  StyledColumn,
  StyledRow
} from './styles';

const WeeklyReport = () => (
  <ProfilePageContainer
    navigationItems={companyNavigationItems}
    pageTitle="Weekly Reports"
    role={USER_TYPE.COMPANY}
  >
    <ProfileTopHeader navigationItems={companyNavigationItems} />
    <StyledWeeklyReportsContentWrapper>
      <StyledTable>
        <StyledContainer>
          <StyledRow>
            <StyledColumn xs={12}>
              <img src={LogoDarkRedHorizontal} alt="" />
            </StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn xs={6}>Kalenderwoche / Jahr</StyledColumn>
            <StyledColumn xs={6}>Einsatz Nr.</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn xs={12}>Vorname / Name</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn xs={12}>Einsatzfirma</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn>Absenzen:</StyledColumn>
            <StyledColumn>
              PE-Ferien
              <br />
              FT-Feiertag
            </StyledColumn>
            <StyledColumn>
              PR-Privat
              <br />
              KR-Krank
            </StyledColumn>
            <StyledColumn>
              UN-Unfall
              <br />
              MU-Mutterschaftsurlaub
            </StyledColumn>
            <StyledColumn>
              MZ-Militär / Zivilschutz
              <br />
              KO-Kompenation Überstunden
            </StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Datum</StyledColumn>
            <StyledColumn customWidth="10%">31.05.21</StyledColumn>
            <StyledColumn customWidth="10%">01.06.21</StyledColumn>
            <StyledColumn customWidth="10%">02.06.21</StyledColumn>
            <StyledColumn customWidth="10%">03.06.21</StyledColumn>
            <StyledColumn customWidth="10%">04.06.21</StyledColumn>
            <StyledColumn customWidth="10%">05.06.21</StyledColumn>
            <StyledColumn customWidth="10%">06.06.21</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Tag</StyledColumn>
            <StyledColumn customWidth="10%">
              Montag
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Dienstag
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Mitwocch
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Donnerstag
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Freitag
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Samstag
              <br /> von / bis
            </StyledColumn>
            <StyledColumn customWidth="10%">
              Sonntag
              <br /> von / bis
            </StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">
              Arbeitszeit
              <br />
              Vormittag
            </StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">
              Arbeitszeit
              <br />
              Nachmittag
            </StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
            <StyledColumn customWidth="10%">/</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Überstunden</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Überzeit</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Verpflegungsspese</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Div. Spesen:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow borderBottom>
            <StyledColumn customWidth="25%">Km:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow marginTop="30px">
            <StyledColumn>Kostenstelle 1:</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Studen:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn>Kostenstelle 2:</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Studen:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn>Kostenstelle 3:</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Studen:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn>Kostenstelle 4:</StyledColumn>
          </StyledRow>
          <StyledRow>
            <StyledColumn customWidth="25%">Studen:</StyledColumn>
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
            <StyledColumn customWidth="10%" />
          </StyledRow>
          <StyledRow>
            <StyledColumn>Info:</StyledColumn>
          </StyledRow>
          <StyledRow borderBottom>
            <StyledColumn col={6}>
              Der Mitarbeiter/in bestätigt mit der Weiterleitung diesem Wochenrapport, dass nur die
              geleisteten und von Einsatzbetrieb auf diesem Wochenrapport bestätigten Arbeitsstunden
              ausbezahlt werden.
            </StyledColumn>
            <StyledColumn col={6} center>
              <CustomButton
                text="Send"
                customWidth="180px"
                customHeight="40px"
                customFontSize="18px"
                customTextColor={WHITE}
              />
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      </StyledTable>
    </StyledWeeklyReportsContentWrapper>
  </ProfilePageContainer>
);

export default withTranslations(WeeklyReport, 'WeeklyReport');
