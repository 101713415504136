import styled from '@emotion/styled';
import { Navbar, Nav, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { APP_RED, INPUT_TEXT_DARK_BLUE, WHITE } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const StyledNavigationWrapper = styled(Navbar)`
  margin: 0;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: ${WHITE};
`;

const StyledNavItem = styled(Nav)`
  align-items: center;
  justify-content: space-between;
  min-width: calc((100% - 430px) / 2);
  @media (max-width: ${breakpoints.lg}) {
    min-width: 100%;
  }

  a {
    font-size: 18px;
    text-decoration: none;
    color: ${INPUT_TEXT_DARK_BLUE};
    position: relative;
    div > span {
      font-family: 'Lato Semibold';
    }
  }

  .is-active {
    div > span {
      font-family: 'Lato Bold';
    }
    ::before {
      content: '';
      left: 45%;
      bottom: 0;
      position: absolute;
      width: 10px;
      border-bottom: 3px solid ${APP_RED};
    }
  }
`;

const StyledNavLinkItem = styled(NavLink)`
  div {
    margin: 20px 0 20px;
    border-bottom: 2px solid transparent;
    font-size: 18px;
    font-family: 'Lato Semibold';
    span {
      padding: 0 20px;
    }
  }

  :hover {
    div {
      width: 100%;
      text-align: center;
      span {
        font-family: 'Lato Bold';
      }
    }
  }
`;

const StyledNavPagesWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    max-width: 100%;
  }
`;

const StyledNavActions = styled(Row)`
  justify-content: space-between;
  min-width: 200px;

  @media (max-width: ${breakpoints.lg}) {
    height: 100%;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  button {
    margin-right: 10px;
  }
`;

export {
  StyledNavigationWrapper,
  StyledNavItem,
  StyledNavActions,
  StyledNavLinkItem,
  StyledNavPagesWrapper
};
