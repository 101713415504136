import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { LoaderWrapper, StyledProgressBar } from 'components/Loading/styles';

const Loader = ({ isLoading }) => {
  const ref = useRef(null);
  useEffect(() => {
    isLoading && ref.current.continuousStart(50);
  }, [isLoading]);

  return isLoading ? (
    <LoaderWrapper>
      <StyledProgressBar ref={ref} shadow />
    </LoaderWrapper>
  ) : null;
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default Loader;
