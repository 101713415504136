import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';

import { INPUT_TEXT_DARK_BLUE, SUBTLE_RED, INPUT_BORDER, WHITE, LIGHT_BLUE_PAGE_BG, APP_RED, DARK_BLUE, DARK_BUTTON, LIGHTER_GRAY } from 'constants/colors';

const parameters = 'solid 0px';

// note: Der untere gruene Container wird nicht automatisch nach unten geschoben wenn die screen size sich verringert. Also im lightskyblue max-height funzt nicht einfach so. Sondern @media height einstellen um die hoehe zu aendern.
const StyledCard = styled(Card)`
  background-color: ${WHITE};
  color: ${INPUT_TEXT_DARK_BLUE};
  width: 100%;
  height: 280px;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  justify-content: space-between;
  padding-top: 10px;
  /* border: ${parameters} lightskyblue; */
  ${({ active }) => (active ? `border: 2px solid ${APP_RED}` : '')}

  @media (max-width: 1900px) {
    height: 350px;
    max-height: unset;
  }

  @media (max-width: ${breakpoints.xxl}) {
    max-height: 340px;

    /* flex-wrap: wrap; */
  }
  @media (max-width: ${breakpoints.md}) {
    max-height: 340px;
    /* flex-wrap: wrap; */
  }

  /* @media (max-width: 1800px) {
    max-height: 420px;
  } */
  /* @media (min-width: 420px) and (max-width: 767px) {
    height: 280px;
  }
  @media (min-width: 1px) and (max-width: 419px) {
    height: 390px;
  } */
`;

const StyledProjectsButtonRow = styled('div')`
  display: flex;
  justify-content: center;

  border: ${parameters} brown;
`;

const StyledFooterBottom = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: auto; // changed from hidden to auto. getting rid of 2nd scrollbar
  overflow-x: hidden;
  min-height: 113px;
  border: ${parameters} green;
  @media (max-width: ${breakpoints.lg}) {
    max-height: 600px;
  }

  /* max-height: 100px; */
  /* green  border: 3px solid light; */
  /* ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`} */

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  p {
    /* overflow-y: scroll; */
    overflow-x: hidden;
    box-sizing: border-box;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      /* height: 120px;  */
      // getting rid of 2nd scrollbar
    }
    /* display: -webkit-box; */    // getting rid of 2nd scrollbar
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};

    /* @media (max-width: 1390px) {
      -webkit-line-clamp: 7;
    } */
  }
`;

const StyledBottomCardDescription = styled('div')`
  display: flex;
  padding-top: 10px;
  border: ${parameters} darkred;
`;

const StyledCardRowWrapperCol = styled('div')`
  display: flex;

  /* padding-top: 20px; */
  flex-direction: column;
  border: ${parameters} red;
  /* @media (max-width: 1840px) {
    flex-direction: column;
    max-widt: 130px;
    padding-top: 0px;
  } */
  max-width: auto;
  @media (max-width: 1440px) {
    max-width: 300px;
  }
  @media (max-width: 768px) {
max-width:240px;
  }
`;



const StyledCardBodyRight = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    border: none;
    background: #fff;
    box-sizing: border-box;
    font-size: 80%;
    padding: 1px 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: box-shadow 0.2s ease-in-out;
    width: 80%;
  }

  button {
    border-radius: 4px;
  }

  .delete-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .edit-button {
    color: ${DARK_BUTTON};
    background-color: none;
    border: 1px solid ${LIGHTER_GRAY};
    padding-top: 4px;
    padding-bottom: 4px;
  }
  /* border-top: 1px solid ${INPUT_BORDER}; */
`;

// height for card grey line
const StyledCardHeader = styled(Card.Header)`
  background-color: ${WHITE};
  min-height: 150px;
  max-height: 260px;
  border: ${parameters}purple;
  @media (max-width: 1440px) {
    /* min-height: 230px; */
    height: 350px;
  }
  @media (max-width: 768px) {
    /* min-height: 230px; */
    max-height: 230px;
  }
`;

const StyledCardHeaderImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'Lato Regular';
  border: ${parameters} blue;
  margin-bottom: 20px;
  max-height: 300px;
  /* @media (max-width: 1800) {
    flex-direction: column;

  } */
  @media (max-width: 1440px) {
    /* flex-direction: column; */
    max-height: 600px;
    justify-content: center;
  }
`;

const StyledImageDescriptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  border: ${parameters} blueviolet;
  /* overflow-y: hidden; */
  /* overflow-x: scroll; */
  gap: 1rem;
  font-family: 'Lato Regular';
  /* &::-webkit-scrollbar {
    height: 0.3rem;
    background-color: transparent;
    border-radius: 5px;
  } */
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    // height: 2px;
    // width: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 10px;
    height: 2px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: horizontal;
`;

const CardName = styled.div`
  /* width: 150px;
  display: flex;
  justify-content: flex-start;
  /* height: 100px; */
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* @media (max-width: 750px) {
    display: flex;
    justify-content: center;
  }  */
`;

const StyledCardHeaderImage = styled(Card.Img)`
  border-radius: 50%;
  /* width: 70px;
  height: 70px; */
  width: 90px;
  height: 90px;
  margin-right: 20px;
  background: ${LIGHT_BLUE_PAGE_BG};
  /* @media (max-width: 750px) {
    margin-right: 0px;
  } */
`;

// const StyledCardHeaderImageDescription = styled('div')`
//   display: flex;
//   font-size: 15px;
//   flex-direction: column;
// `;

const StyledCardHeaderImageDescription = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  border: ${parameters} pink;
  /* @media (max-width: 767px) {

    align-items: center;
  } */
`;

const StyledCardHeaderImageDeRight = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  border: ${parameters} orange;
  /* @media (max-width: 767px) {

    align-items: center;
  } */
`;

const StyledCardHeaderBottomImageDescription = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-left: 20px;
  /* justify-content: center; */
  border: ${parameters} red;
  /* margin-bottom: 100px; */

  @media (max-width: ${breakpoints.lg}) {
    padding-left: 0px;
  }
`;

const StyledCardHeaderImageDescriptionRight = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-bottom: 10px;
  border: ${parameters} grey;
`;

const StyledCardHeaderImageDescriptionRightFrontPage = styled('div')`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding-top: 25px;
`;

const OrangeRedContainerTop = styled('div')`
  display: flex;
  padding-top: 3px;
  border: ${parameters} greenyellow;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const StyledCardHeaderImageDescriptionFirst = styled('span')`
  display: flex;
  font-size: 18px;
  padding-top: 10px;
  font-weight: 1000;
  border: ${parameters} yellow;
  /* @media (max-width: 750px) {
    align-items: center;
  } */
`;

const StyledCardHeaderCol = styled('div')`
  display: flex;

  border: ${parameters} brown;
`;

const StyledCardHeaderBellowImage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
`;

const StyledCardFrontPage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
  border: ${parameters} lightgreen;
  /* @media (max-width: 750px) {
    padding-top: 0px;
    padding-left: 0px;
    align-items: center;
  } */
`;

const StyledCardFrontBottom = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato Regular';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 10px;
  border: ${parameters} darkgreen;
  /* @media (max-width: 750px) {
    padding-top: 60px;
    padding-bottom: 10px;
    align-items: center;
  } */
`;
const StyledCardAppFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding-bottom: 25px; */
  border: ${parameters} lightgreen;
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  button:nth-of-type(1) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
  }

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    margin-top: -8px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }
`;

const StyledCardFooter = styled(Card.Footer)`
  background-color: ${WHITE};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-top: none;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding-bottom: 25px; */
  border: ${parameters} lightgreen;
  ${({ footerMinHeight }) => footerMinHeight && `min-height: ${footerMinHeight}rem;`}

  .actions-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  /* button:nth-of-type(1) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: 1px solid ${DARK_BLUE};
    background: transparent;
    color: ${DARK_BLUE};
  } */

  button:nth-of-type(2) {
    outline: 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    padding: 6px 25px;
    border: none;
    background: #f95050;
    color: ${WHITE};
  }

  p {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0.5em;
      background-color: transparent;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${APP_RED};
      border-radius: 10px;
      height: 120px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 22px;
    opacity: 0.8;
    font-family: 'Lato';
    color: ${INPUT_TEXT_DARK_BLUE};
  }
`;

const StyledIconWrapper = styled('div')`
  /* position: relative; */
  display: flex;
  justify-content: flex-end;

  /* justify-content: flex-end; */
  /* bottom: 28px; */
  /* align-self: flex-start; */
  /* padding-top: 30px; */
`;

export {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardHeaderImageDescriptionRightFrontPage,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardHeaderBottomImageDescription,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledCardHeaderImageDeRight,
  OrangeRedContainerTop
};
