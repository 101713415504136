import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation, FormattedMessage } from 'react-i18next';

import { MultiSelector } from 'components/RegisterDialog/styles';
// import { SkillLevel } from 'constants/skillLevels';
import { getPropByValue } from 'utils/helpers';
import { APP_RED, APP_RED_HOVER, INPUT_BORDER } from 'constants/colors';

import { StyledTableWrapper, StyledTd } from './styles';

const CustomTable = ({ selectedOCP, selectedLevel, setSelectedLevel, predefinedValue }) => {
  const [skillLevel, setSkillLevel] = useState([]);
  const [skillLevelDisplayed, setSkillLevelDisplayed] = useState([])
  const { t } = useTranslation();


  // { value: 'CH learned', label: t('FORM.fields.skillLevelsChLearned') },
  // { value: 'Learned abroad', label: t('FORM.fields.skillLevelsLearnedAbroad') },
  // { value: 'Semi-skilled', label: t('FORM.fields.skillLevelsSemiSkilled') },
  // { value: 'Unskilled', label: t('FORM.fields.skillLevelsUnskilled') }



// const SkillLevel = {
//   level_A: t('FORM.fields.skillLevelsChLearned'),
//   level_B: t('FORM.fields.skillLevelsLearnedAbroad'),
//   level_C:  t('FORM.fields.skillLevelsSemiSkilled') ,
//   level_D:  t('FORM.fields.skillLevelsUnskilled'),
// };

  const selectStyles = {
    singleValue: prevStyles => ({
      ...prevStyles,
      // border: 'none',
      border: `1px solid ${INPUT_BORDER}`
    }),
    placeholder: prevStyles => ({
      ...prevStyles,
      fontSize: '12px',
      color: '#B2B2B2',
      fontWeight: 500,
      letterSpacing: '0.2px'
    }),
    indicatorSeparator: prevStyles => ({
      ...prevStyles
    }),
    menuList: prevStyles => ({
      ...prevStyles,
      maxHeight: '15rem',
      //   color: '',
      fontSize: '12px'
    }),
    container: prevStyle => ({
      ...prevStyle,
      color: APP_RED,
      ':focus': {
        border: `1px solid ${APP_RED_HOVER}`
      }
    }),
    control: (prevStyle, state) => ({
      ...prevStyle,
      border: `1px solid ${INPUT_BORDER}`,
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none'
    }),
    valueContainer: prevStyle => ({
      ...prevStyle,
      flexWrap: 'noWrap',
      padding: '1px 16px',
      gap: '0rem 0.5rem',
      overflowX: 'auto',
      width: '100%',
      // border: 'none',
      // border: `1px solid ${INPUT_BORDER}`,
      '::-webkit-scrollbar': {
        height: '3px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '::-webkit-scrollbar-thumb': {
        // background: '#DCDCDC',
        backgroundColor: APP_RED
      },
      '::-webkit-scrollbar-thumb:hover': {
        // background: '#AEAEAE',
        backgroundColor: APP_RED_HOVER
      }
    }),
    multiValueRemove: prevStyles => ({
      ...prevStyles,
      padding: '2px 0px',
      color: '#6785DD',
      position: 'absolute',
      top: '-4px',
      left: '94%',
      background: '#fff',
      ':hover': {
        background: '#fff'
      }
    }),
    multiValue: prevStyle => ({
      ...prevStyle,
      border: `0.5px solid  ${APP_RED}`,
      backgroundColor: 'transparent',
      fontWeight: '400',
      letterSpacing: '.5px',
      padding: '0px 8px',
      flexWrap: 'nowrap',
      borderRadius: '3px',
      position: 'relative',
      minWidth: '8rem'
    }),
    multiValueLabel: prevStyle => ({
      ...prevStyle,
      // color: '',
      padding: '0px 3px',
      fontWeight: 600
    }),
    input: prevStyle => ({
      ...prevStyle,
      border: '0 !important',
      outline: '0 !important',
      ':focus': {
        outline: 'none',
        boxShadow: 'none !important'
      }
    })
  };

//   const SkillLevel = {
//     level_A: 'CH learned',
//     level_B: 'Learned abroad',
//     level_C: 'Semi-skilled',
//     level_D: 'Unskilled',
// };

  
  // useEffect(() => {
  //   const skillLevelOptions = Object.keys(SkillLevel).map((key, index) => ({
  //     value: index + 1,
  //     label: SkillLevel[key]
  //   }));
  //   setSkillLevel(skillLevelOptions);
  // }, []);

  // Original skill level labels with their corresponding values
const SkillLevel = {
  level_A: 'CH learned',
  level_B: 'Learned abroad',
  level_C: 'Semi-skilled',
  level_D: 'Unskilled',
};

// Translation function that translates the labels

// Usage in your component
// useEffect(() => {
//   const skillLevelOptions = Object.keys(SkillLevel).map((key) => ({
//     value: key,
//     label: translateSkillLevelLabel(SkillLevel[key]),
//   }));
//   setSkillLevel(skillLevelOptions);
// }, []);
const skillLevelList = {
  level_A: t('FORM.fields.skillLevelsChLearned'),
  level_B: t('FORM.fields.skillLevelsLearnedAbroad'),
  level_C:  t('FORM.fields.skillLevelsSemiSkilled') ,
  level_D:  t('FORM.fields.skillLevelsUnskilled'),
  }

useEffect(() => {
    const skillLevelOptions = Object.keys(SkillLevel).map((key, index) => ({
      value: index + 1,
      label: skillLevelList[key]
    }));
    setSkillLevel(skillLevelOptions);
  }, []);



  // useEffect(() => {
  //   const skillLevelOptions = Object.keys(skillLevelList).map((key, index) => ({
  //     value: index + 1,
  //     label: skillLevelList[key]
  //   }));
  //   setSkillLevelDisplayed(skillLevelOptions);
  // }, []);




  const changeSelectedLevel = (index, newValue) => {
    const updatedSelectedLevel = [...selectedLevel];
    updatedSelectedLevel[index] = newValue;
    setSelectedLevel(updatedSelectedLevel);
  };

  console.log("here is selectedLevel", selectedLevel)

  console.log("here is skillLevel", skillLevel)

  return (
    <StyledTableWrapper>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>{t('FORM.fields.jobTitle')}</th>
            <th>Level</th>
          </tr>
        </thead>
        <tbody>
          {selectedOCP.length &&
            selectedOCP.map((ocp, i) => (
              <tr key={i}>
                <StyledTd>{ocp.label}</StyledTd>
                <StyledTd>
                  <MultiSelector
                    style={selectStyles}
                    placeholder={predefinedValue ? predefinedValue : t('REGISTER_DIALOG.chooseSkillLevel')}
                    value={selectedLevel[i]} // t(`FORM.fields.skillLevels.${getPropByValue(selectedLevel[i])}`)
                    onChange={newValue => changeSelectedLevel(i, newValue)}
                    options={skillLevel}
                    
                    // formatOptionLabel={(option) => (
                    //     <FormattedMessage id={option.label} defaultMessage={option.label} />
                    // )}
                    // isOptionDisabled={() => selectedLevel.length >= 1}
                />
                </StyledTd>
              </tr>
            ))}
        </tbody>
      </Table>
    </StyledTableWrapper>
  );
};

export default CustomTable;
