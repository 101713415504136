import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { languageSelector } from 'redux/modules/language/selectors';
import { setLanguage } from 'redux/modules/language/reducer';
import { DropdownToggle, StyledDropdown } from 'components/LanguageSelector/styles';

const LanguageSelector = ({ languages }) => {
  const dispatch = useDispatch();
  const langSelector = useSelector(languageSelector);

  const changeLang = lang => {
    dispatch(setLanguage(lang));
    i18next.changeLanguage(lang);
  };

  return (
    <StyledDropdown>
      <DropdownToggle id="dropdown-basic">
        <span>{langSelector}</span>
      </DropdownToggle>

      <Dropdown.Menu>
        {languages.map(language => (
          <Dropdown.Item key={language.coutry_code} onClick={() => changeLang(language.code)}>
            <span className={`flag-icon flag-icon-${language.coutry_code}`} />
            <span>{language.name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.array.isRequired
};

export default LanguageSelector;
