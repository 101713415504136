import { createAsyncThunk } from '@reduxjs/toolkit';

import { projectsUrl, countProjectsUrl, projectUrl } from 'utils/apis/index';
import http from 'utils/http';

const fetchProjectsList = createAsyncThunk('projects/fetchProjectsList', async (params = {}) => {
  // console.log('here is params in async thunk', params);
  const { data } = await http.get(projectsUrl, {
    params
  });
  // console.log("Here is data in fetchProjectsList ==>>", data)
  return data;
});

const fetchProjectsByWorkerList = createAsyncThunk('projects/fetchProjectsList', async (params = {}) => {
  // console.log('here is params in async thunk', params);
  const { data } = await http.get(projectsUrl, {
    params
  });
  // console.log('Here is data in fetchProjectsByWorkerList ==>>', data);
  return data;
});

const fetchProject = createAsyncThunk('projects/fetchProject', async (projectId = {}) => {
  const { data } = await http.get(projectUrl(projectId));

  return data;
});

const updateProject = createAsyncThunk('projects/update-project', async ({ projectId, payload }) => {
  const { data } = await http.put(projectUrl(projectId), payload);

  return data;
});


const updateProjectByWorker = createAsyncThunk('projects/update-project', async ({ payload }) => {
  const { data } = await http.put(projectUrl, payload);

  return data;
});

const updateProjekts = createAsyncThunk('projects/update-projekts', async updatedProjectList => {
  const updatedProjects = [];

  for (const updatedProject of updatedProjectList) {
    const { id, ...payload } = updatedProject;
    const { data } = await http.put(projectUrl(id), payload);
    updatedProjects.push(data);
  }

  // Dispatch an action to update the projects in the Redux store
  // dispatch(updateProjectsInStore(updatedProjects));

  // Return the updated projects if needed
  return updatedProjects;
});

const createProject = createAsyncThunk('projects/create-project', async payload => {
  const { data } = await http.post(projectsUrl, payload);

  return data;
});

const countProjects = createAsyncThunk('projects/countProjectsList', async (params = {}) => {
  const { data } = await http.get(countProjectsUrl, {
    params
  });

  return data;
});

export { updateProjectByWorker, fetchProjectsByWorkerList, fetchProjectsList, countProjects, fetchProject, updateProject, createProject, updateProjekts };
