import styled from '@emotion/styled';

import LoginBackground from 'assets/images/login-bg.jpg';
import BossastoTextLogo from 'assets/logo/png/light-horizontal.png';
import { ReactComponent as CloseIcon } from 'assets/icons/x_icon.svg';
import { APP_RED, BLACK, INPUT_BORDER, WHITE, LIGHT_GRAY_BACKGROUND, LIGHT_BLUE_PAGE_BG } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const pixel = "solid 3px"

export const StyledCustomDialog = styled('div')`
  display: flex;
  background-color: ${BLACK}; /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 9998; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
`;

export const StyledCustomDialogWrapper = styled('div')`
  display: flex;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  border: 1px solid ${INPUT_BORDER};
  opacity: 1;
  max-width: 100%; /* Max width where it stops expanding */
  margin: auto; /* Auto margin according to the element width */
  padding: 10px;
  border-radius: 5px;
`;

export const StyledCustomDialogLeftSide = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 475px;
  height: 500px;
`;

export const StyledDialogTitle = styled('div')`
  margin-top: 70px;
  font-size: 36px;
`;

export const StyledDialogContent = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const StyledDialogButtonActions = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 45px;

  button {
    margin: 10px auto;
    :active {
      background-color: ${APP_RED};
    }
    :focus {
      background-color: ${APP_RED};
    }
  }
`;

export const StyledDialogCreateAccountLabel = styled('div')`
  display: flex;
  width: 300px;

  span {
    font-style: italic;
    font-size: 14px;
    :nth-of-type(2) {
      margin-left: 5px;
      font-weight: bold;
      font-style: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  width: 300px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 20px;
`;

export const StyledCustomDialogRightSide = styled('div')`
  display: flex;
  position: relative;
  width: 475px;
  height: 540px;
  background: url(${LoginBackground});
  background-size: cover;
  background-position: left;
  background-position: center;

  .login-logo-title {
    position: absolute;
    background: url(${BossastoTextLogo});
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

// import styled from '@emotion/styled';

// import { ReactComponent as CloseIcon } from 'assets/icons/x_icon.svg';
// import { APP_RED, BLACK, LIGHT_BLUE_PAGE_BG, WHITE, LIGHT_GRAY_BACKGROUND } from 'constants/colors';
import HeaderBackground from 'assets/images/pricing-background.png';
// import breakpoints from 'constants/breakpoints';



export const StyledButtonRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border: ${pixel} solid blue;
`;

export const StyledButtonCol = styled('Col')`
  display: flex;
  border: ${pixel} solid green;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  
  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    padding-top: 10px;
  }
`;

export const StyledPreviewDialog = styled('div')`
  display: flex;
  background-color: ${BLACK}; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 9999; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100vh;
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
`;

export const StyledPreviewDialogWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  opacity: 1;
  min-width: 650px;
  width: 1200px; /* Max width where it stops expanding */
  margin: auto; /* Auto margin according to the element width */
  border-radius: 5px;
  border: ${pixel} solid red;

  @media (max-width: ${breakpoints.md}) {
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
`;

export const StyledPreviewDialogHeader = styled('div')`
  position: relative;
  background-image: url(${HeaderBackground});
  display: flex;
  justify-content: center;
  height: 150px;
  background-size: cover;
  background-position: center right;
  border: ${pixel} solid orange;
`;
export const StyledImageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 29px;
  left: 10px;
  border: ${pixel} solid yellow;
`;

export const StyledImageRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: ${pixel} solid lightgreen;
`;
export const StyledImageTextRow = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 160px;
  top: 23px;
  align-items: flex-start;
  border: ${pixel} solid black;
  @media (max-width: ${breakpoints.md}) {
    /* left: 80px; */
  }
`;

// export const StyledImageWrapper = styled('div')`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   position: absolute;
//   top: 92px;
//   border: ${pixel} solid yellow;
// `;
export const StyledImage = styled('div')`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-size: 100%;
  background-position: center;
  border: ${pixel} solid green;
`;

export const StyledHalfBorder = styled('div')`
  position: absolute;
  width: 120px;
  height: 120px;
  top: 0;
  border: 10px solid ${APP_RED};
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
`;

export const StyledPreviewDialogTitle = styled('h4')`
  display: flex;
  justify-content: flex-start;
  width: 300px;

  border: ${pixel} solid blue;
  @media (max-width: ${breakpoints.md}) {
    font-weight: 1000;
  }
`;
export const StyledPreviewDialogSubtitle = styled('h5')`
  font-weight: 100;
  font-family: 'Lato', sans-serif;
  padding-bottom: 20px;
  border: ${pixel} solid purple;
`;

export const StyledPreviewDialogBody = styled('div')`
  margin-top: 10px;
  padding: 0 30px 20px 30px;
  text-align: left;

  & > div {
    max-height: 70vh; // sets the allowed height in relation to it's viewport
    overflow-y: auto;
  }
  border: ${pixel} solid pink;
`;
export const StyledPreviewDialogCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const StyledVerticalLine = styled('hr')`
  transform: rotate(90deg);
  width: 100%;
  border: none;
  border-left: 3px solid ${LIGHT_GRAY_BACKGROUND};
  margin: 0;
`;

export const StyledPreviewMainContent = styled('div')`
  max-height: 500px; // sets the height of the container that cointains the text
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;


  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    height: 120px;
  }
  border: ${pixel} solid grey;
`;

export const StyledPreviewDialogFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 20px 30px;
  flex-wrap: no-wrap;
  column-gap: 20px;
`;

export const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;