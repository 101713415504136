import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'boxicons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { APP_RED } from 'constants/colors';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { authUserSelector, authProfileSelector } from 'redux/modules/auth/selectors';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import { showToast } from 'redux/modules/toast/reducer';
// import ApplicationsForPDF from '../../../components/ApplicationsPDF';

import {
  StyledProfileWrapper,
  StyledProfileDescriptionWrapper,
  StyledProfileDescriptionLeftSide,
  StyledProfileDescription,
  StyledProfileInfo,
  StyledInfo,
  StyledDocs,
  StyledDoc,
  StyledProfileActions,
  StyledProfileContentWrapper,
  StyledProfileInfoWrapper,
  StyledInfoInnerWrapper,
  StyledProfileImage,
  StyledDescription,
  StyledDescriptionDocumentsRow,
  StyledImageContainer
} from './styles';
import { formatShortDate } from '../../../utils/helpers';
import { formatDate } from 'utils/helpers';
import { deleteSingleFile, updateWorker } from '../../../redux/modules/auth/asyncThunks';

const Profile = ({ history, openApplications }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);
  const authProfile = useSelector(authProfileSelector);
  const formRef = useRef(null);
  const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;

  const goToEditProfile = () => {
    history.push(`/worker/${authProfile.id}/profile/${authUser.id}`);
  };

  const { register, watch } = useForm();

  const watchDocumentsUpload = watch('documents');

  useEffect(() => {
    // console.log('authProfile====>>>>>>', authProfile);
    const formData = new FormData();
    if (watchDocumentsUpload && watchDocumentsUpload.length > 0) {
      if (watchDocumentsUpload.length > 4) {
        dispatch(
          showToast({
            type: 'danger',
            message: t('DOCUMENT_UPLOAD_ERROR.fileAmount'),
            delay: 5000
          })
        );
        return;
      }
      formData.append('data', JSON.stringify({}));
      for (let i = 0; i < watchDocumentsUpload.length; i++) {
        if (watchDocumentsUpload[i].size > 10485760) {
          dispatch(
            showToast({
              type: 'danger',
              message: t('DOCUMENT_UPLOAD_ERROR.fileSize')
              // delay: 5000
            })
          );
          return;
        }
        console.log(watchDocumentsUpload);
        formData.append(`files.files`, watchDocumentsUpload[i], watchDocumentsUpload[i].name);
      }
      dispatch(updateWorker({ workerId: authProfile?.id, payload: formData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDocumentsUpload]);

  // console.log(formData);

  const openFileInNewTab = url => window.open(`${url}`, '_blank');

  const triggerUpload = () => {
    if (authProfile?.files.length >= 4) {
      dispatch(
        showToast({
          type: 'danger',
          message: t('DOCUMENT_UPLOAD_ERROR.fileAmount'),
          delay: 5000
        })
      );
    } else {
      formRef.current.children[0].click();
    }
  };

  const deleteDoc = (id, e) => {
    try {
      e.stopPropagation();
      dispatch(deleteSingleFile(id));
    } catch (err) {
      throw new Error(err);
    }
  };
  console.log('here is openApplications in ProFILE===>>>', openApplications);

  function calculateAge(birthdate) {
    if (!birthdate) {
      return 'Birthdate not provided';
    }
    // Parse the birthdate string into a Date object
    const birthDate = new Date(birthdate);

    const currentDate = new Date();

    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year or not
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      // If the birthdate has not occurred yet this year, subtract 1 from the age
      return age - 1;
    }

    return age;
  }

  const age = calculateAge(authProfile?.birthdate);
  console.log('here is age ->>', age); // This will log the calculated age

  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} role={USER_TYPE.WORKER}>
      <StyledImageContainer>{t('EMPLOYEE_PROFILE.MY_PROFILE.title')}</StyledImageContainer>
      <StyledProfileContentWrapper>
        {/* <StyledProfileWrapper> */}
        <StyledProfileInfo>
          <StyledProfileDescriptionWrapper>
            <StyledProfileDescriptionLeftSide>
              {/* <img style={{width: '20%', height: '50%'}} src={authProfile?.image?.url ? `${BE_URL}${authProfile.image.url}` : DefaultProfileImage} alt="user" /> */}
              <StyledProfileImage>
                <img src={authProfile?.image?.url ? `${authProfile?.image?.url}` : DefaultProfileImage} alt="user" />
              </StyledProfileImage>

              <StyledProfileDescription>
                <h1>
                  {authUser?.name} {age > 1 ? age: ""}
                </h1>
                <h3>{authProfile?.jobTitle}</h3>
                <StyledProfileActions>
                  <button type="button" onClick={goToEditProfile}>
                    {t('BUTTON.editProfile')}
                  </button>
                  <button type="button" onClick={triggerUpload}>
                    {t('BUTTON.cvDoc')}
                  </button>
                  <form ref={formRef}>
                    <input type="file" multiple {...register('documents')} />
                  </form>
                </StyledProfileActions>
              </StyledProfileDescription>
              <StyledProfileInfoWrapper>
                <StyledInfo>
                  <h4>
                    <strong>{t('EMPLOYEE_PROFILE.MY_PROFILE.details')}</strong>
                  </h4>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: {authProfile?.address}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: {authProfile?.rate}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: {authProfile?.city}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: {authProfile?.state}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: {authProfile?.mobilePhone}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: {authProfile?.citizenship}
                  </p>
                </StyledInfo>
                <StyledInfo>
                  <h4 className="invisible m-0">.</h4>
                  <h4 className="invisible m-0">.</h4>
                 <p>
                    {/* {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: {formatShortDate(authProfile?.birthdate)} */}
                      {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: {formatDate(new Date(authProfile?.birthdate))}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: {authProfile?.driverLicences}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.hourlyRate')}: {authProfile?.hourlyRate || ''}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.monthlySalary')}: {authProfile?.monthlySalary || ''}
                  </p>
                  <p>
                    {t('EMPLOYEE_PROFILE.MY_PROFILE.status')}: <strong>{authProfile?.isActive === true ? 'Active' : 'Inactive'}</strong>
                  </p>
                </StyledInfo>
              </StyledProfileInfoWrapper>
            </StyledProfileDescriptionLeftSide>
          </StyledProfileDescriptionWrapper>
        </StyledProfileInfo>
        <StyledProfileInfoWrapper>
          {authProfile?.workExperience?.length > 0 && (
            <StyledInfo>
              <h4>
                <strong>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</strong>
              </h4>
              {authProfile?.workExperience.map(experience => (
                <StyledInfoInnerWrapper key={`experience-${experience?.id}`}>
                  <p>
                    {experience?.position} @ {experience?.companyName}
                  </p>
                  {experience?.description && <p>{experience?.description}</p>}
                  <p>
                    {formatShortDate(experience?.startDate)} - {formatShortDate(experience?.endDate || '/')}
                  </p>
                </StyledInfoInnerWrapper>
              ))}
            </StyledInfo>
          )}
          {authProfile?.education?.length > 0 && (
            <StyledInfo>
              <h4>
                <strong>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</strong>
              </h4>
              {(authProfile?.education || []).map(education => (
                <StyledInfoInnerWrapper key={`education-${education?.id}`}>
                  <p>
                    <span>{education?.type?.toLowerCase()}</span> @ {education?.name}
                  </p>
                  {education?.description && <p>{education?.description}</p>}
                  <p>
                    {formatShortDate(education?.startDate)} - {formatShortDate(education?.endDate || '/')}
                  </p>
                </StyledInfoInnerWrapper>
              ))}
            </StyledInfo>
          )}
          <p />
          <p />
        </StyledProfileInfoWrapper>
        <StyledDescriptionDocumentsRow>
          {' '}
          <StyledDescription>
            <strong className="w-75">{authProfile?.jobDescription || t('EMPLOYEE_PROFILE.MY_PROFILE.noDescription')}</strong>
          </StyledDescription>
          {/* </StyledProfileWrapper> */}
          <StyledDocs>
            {authProfile?.files.length ? (
              <>
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
                {(authProfile?.files || []).map(file => (
                  <StyledDoc key={`file-${file?.id}`} onClick={() => openFileInNewTab(file?.url)}>
                    {file?.name} <box-icon name="link-external" color={APP_RED} size="14px" /> {'  '}
                    <box-icon name="trash" color={APP_RED} size="14px" onClick={e => deleteDoc(file?.id, e)} />
                  </StyledDoc>
                ))}
              </>
            ) : null}
          </StyledDocs>
        </StyledDescriptionDocumentsRow>
      </StyledProfileContentWrapper>
      {/* <ApplicationsForPDF /> */}
    </ProfilePageContainer>
  );
};

export default Profile;
