/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  StyledCustomDialog,
  StyledCustomDialogWrapper,
  StyledCustomDialogLeftSide,
  StyledCustomDialogRightSide,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogButtonActions,
  StyledDialogCreateAccountLabel,
  StyledInputField,
  StyledCloseIcon,
  StyledDialogForgetPassLable
} from 'components/LoginDialog/styles';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';
import { login, getWorkerProfile, getCompanyProfile } from 'redux/modules/auth/asyncThunks';
import { closeDialog, openDialog } from 'redux/modules/dialogs/reducer';
import { ErrorMessage } from 'components/RegisterDialog/styles';
import { showToast } from 'redux/modules/toast/reducer';
import {
  loginUrl,
} from 'utils/apis';
import { loginUser } from 'redux/modules/auth/reducer';
// import { AUTH } from 'utils/http';

const LoginDialog = ({ history }) => {
  const { loginDialogOpened } = useSelector(dialogSelector);
  const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
  const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

  const resolveApiUrl = `${API_PROTOCOL}://${API_DOMAIN}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (loginDialogOpened) {
      document.body.style.overflow = 'hidden';
    }
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, [loginDialogOpened]);

  const handleCloseDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'loginDialog'
      })
    );
  };

  const handleLogin = async loginPayload => {
    try {
      // const responseData = await dispatch(login(loginPayload))?.unwrap();
      let response = await fetch(resolveApiUrl + loginUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginPayload)
      });
      response = await response.json()
      if (!response.user && response.statusCode !== 200) {
        setErrMsg(t('FORM.validation.failedLogin'));
        dispatch(
          showToast({
            type: 'danger',
            message: t('FORM.validation.failedLogin')
          })
        );
        return
      }
      setErrMsg('')
      dispatch(loginUser({user: response.user, jwt: response.jwt}))

      const { user } = response
      console.log('Here is user: ' , (user));
      const role = user.role?.type;
      if (role === 'worker') {
        await dispatch(getWorkerProfile(user.worker.id)).unwrap();
      } else if (role === 'company') {
        await dispatch(getCompanyProfile(user.company.id)).unwrap();
      }

      dispatch(
        closeDialog({
          dialogName: 'loginDialog'
        })
      );

      history.push(`/${role}/search`);
    } catch (err) {
      console.log(err, 'error is not complete')// I want the full error response from the backend
      setErrMsg(t('FORM.validation.failedLogin'))
    }
  };

  const handleCloseLoginDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'loginDialog'
      })
    );
  };

  const handleOnCreateOne = () => {
    dispatch(
      openDialog({
        dialogName: 'registerDialog'
      })
    );
  };

  const handleOnCreateForgetPassword = () => {
    handleCloseLoginDialog();
    dispatch(
      openDialog({
        dialogName: 'forgetPassDialog'
      })
    )
  }

  return loginDialogOpened ? (
    <>
      <StyledCustomDialog onClick={handleCloseLoginDialog} />
      <StyledCustomDialogWrapper>
        <StyledCloseIcon onClick={handleCloseLoginDialog} />
        <StyledCustomDialogLeftSide>
          <StyledDialogTitle>{t('LOGIN_DIALOG.title')}</StyledDialogTitle>
          <form onSubmit={handleSubmit(handleLogin)}>
            <StyledDialogContent>
              <StyledInputField
                placeholder={t('FORM.fields.email')}
                type="text"
                {...register('identifier', { required: true })}
              />
              {errors.identifier && <span>{t('FORM.validation.requiredField')}</span>}
              <StyledInputField
                placeholder={t('FORM.fields.password')}
                type="password"
                {...register('password', { required: true })}
              />
              {errors.password && <span>{t('FORM.validation.requiredField')}</span>}
              {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
            </StyledDialogContent>

            <StyledDialogForgetPassLable>
              <span onClick={handleOnCreateForgetPassword}>{t('LOGIN_DIALOG.forgetpassword')}</span>
            </StyledDialogForgetPassLable>

            <StyledDialogButtonActions>
              <CustomButton
                text={t('BUTTON.signIn')}
                customFontSize="18px"
                customWidth="300px"
                customHeight="50px"
                customTextColor={WHITE}
                customBorderColor={APP_RED}
                type="submit"
              />
            </StyledDialogButtonActions>
          </form>

          <StyledDialogCreateAccountLabel>
            <span>{t('LOGIN_DIALOG.dontHaveAccount')}</span>
            <span onClick={handleOnCreateOne}>{t('LOGIN_DIALOG.createOne')}</span>
          </StyledDialogCreateAccountLabel>
        </StyledCustomDialogLeftSide>
        <StyledCustomDialogRightSide>
          <span className="login-logo-title" />
        </StyledCustomDialogRightSide>
      </StyledCustomDialogWrapper>
    </>
  ) : null;
};

export default LoginDialog;
