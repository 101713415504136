import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { WHITE, LIGHT_BLUE_PAGE_BG, LIGHT_GRAY_BACKGROUND, DARK_BLUE_FOOTER, APP_RED } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const StyledEmploymentContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
`;

const StyledEmploymentInfoWrapper = styled(Row)`
  margin-top: 12px;
  height: 100%;
  overflow: hidden;
`;

const StyledOngoingEmploymentColWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCompletedEmploymentColWrapper = styled(Col)`
  padding-left: 7px;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitleBoxWrapper = styled('div')`
  display: flex;
  border-bottom: 2px solid red;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

const StyledTitleBox = styled('div')`
  background: ${({ active }) => (active ? '#f95050 0% 0% no-repeat padding-box' : '#F5F5F5')};
  border-radius: 6px 6px 0 0;
  text-align: center;
  font: normal normal bold 14px/48px Lato;
  letter-spacing: 0px;
  color: ${({ active }) => (active ? WHITE : '#474A4F')};
  border-top: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-left: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-right: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  padding: 0 20px;
  cursor: pointer;
  margin-right: 6px;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 5px;
    width: 100%;
  }
`;

const StyledEmploymentCardWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background: ${LIGHT_GRAY_BACKGROUND} 0% 0% no-repeat padding-box;
  border-radius: 0 6px 6px 6px;
  padding: 16px;
  overflow-y: auto;
`;

const StyledOngoingInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledOngoingInfoColumnWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledFieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledFieldTitle = styled('p')`
  margin-bottom: 8px;
  text-align: left;
  font: normal normal normal 14px/17px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledFieldValue = styled('p')`
  margin: 0;
  text-align: left;
  font: normal normal bold 18px/28px 'Lato';
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
`;

const StyledDescription = styled('p')`
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: ${DARK_BLUE_FOOTER};
  margin-top: 7px;
`;

const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

export {
  StyledPreviewDetails,
  StyledEmploymentContentWrapper,
  StyledEmploymentInfoWrapper,
  StyledOngoingEmploymentColWrapper,
  StyledCompletedEmploymentColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledEmploymentCardWrapper,
  StyledOngoingInfoWrapper,
  StyledOngoingInfoColumnWrapper,
  StyledFieldWrapper,
  StyledFieldTitle,
  StyledFieldValue,
  StyledDescription,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledPreviewDialogFooterInfo
};
