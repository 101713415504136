import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { StyledButtonRegister, StyledButtonIcon } from 'components/CustomButtonVacancies/styles';
import { APP_RED } from 'constants/colors';

const CustomButtonRegister = ({
  text,
  title,
  link,
  customButtonColor,
  customWidth,
  customHeight,
  customBorderColor,
  customFontSize,
  customTextColor,
  customradiustopleft,
  customradiustopright,
  handleOnClick,
  hasIcon,
  icon,
  btnIconText,
  iconColor,
  iconFontSize,
  disabled,
  type,
  className
}) => (
  <>
    <StyledButtonRegister
      title={title}
      customradiustopleft={customradiustopleft}
      customradiustopright={customradiustopright}
      custombuttoncolor={customButtonColor}
      customfontsize={customFontSize}
      customwidth={customWidth}
      customheight={customHeight}
      custombordercolor={customBorderColor}
      customtextcolor={customTextColor}
      onClick={handleOnClick}
      type={type}
      disabled={disabled}
      className={className}
    >
      {hasIcon ? (
        <StyledButtonIcon>
          <FontAwesomeIcon icon={icon} color={iconColor} fontSize={iconFontSize} />
          <span>{btnIconText}</span>
          <span>{text}</span>
        </StyledButtonIcon>
      ) : (
        <span>{text}</span>
      )}
    </StyledButtonRegister>
  </>
);

CustomButtonRegister.defaultProps = {
  customButtonColor: APP_RED,
  text: null,
  customWidth: null,
  customHeight: null,
  customTextColor: null,
  customBorderColor: null,
  iconColor: null,
  icon: null,
  iconFontSize: '14px',
  hasIcon: false,
  btnIconText: null,
  type: 'button',
  disabled: false,
  handleOnClick: () => {}
};

CustomButtonRegister.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  btnIconText: PropTypes.string,
  hasIcon: PropTypes.bool,
  handleOnClick: PropTypes.func,
  customButtonColor: PropTypes.string,
  customWidth: PropTypes.string,
  customHeight: PropTypes.string,
  customBorderColor: PropTypes.string,
  customTextColor: PropTypes.string,
  iconFontSize: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool
};

export default CustomButtonRegister;
