import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';
import { contact } from 'redux/modules/contact/asyncThunks';

const initialState = {
  contactInfo: null,
  status: ASYNC_STATUS.IDLE
};

const contactsSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: {
    [contact.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [contact.fulfilled]: (state, { payload }) => {
      state.contactInfo = payload;
      state.status = ASYNC_STATUS.IDLE;
    }
  }
});

export default contactsSlice.reducer;
