import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DARK_BLUE_FOOTER, WHITE } from 'constants/colors';

export const StyledSideMenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${DARK_BLUE_FOOTER};
  width: calc(100% - 75px);
  height: 100vh;
  color: ${WHITE};
  z-index: 2;

  .sidebar {
    margin-left: 50px;
    width: 100%;
    max-width: 100%;

    div:first-of-type {
      margin-left: 10px;
      margin-bottom: 20px;
      border: none;
      img {
        min-width: 180px;
        height: 27px;
        border: none;
        border-radius: 0;
      }
    }
    div:nth-of-type(2) {
      padding-left: 20px;
      margin-bottom: 20px;

      img {
        width: 70px;
        height: 70px;
      }
    }
    div:nth-of-type(3) > div {
      padding-left: 20px;

      .links {
        svg {
          display: none;
        }
      }
    }
  }
`;

export const StyledCloseSideMenuWrapper = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
`;

export const StyledArrowRight = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
`;
