import styled from '@emotion/styled';

export const StyledTableWrapper = styled('div')`
  margin-top: 20px;
  width: 100%;
`;

export const StyledTd = styled('td')`
  text-align: center;
  vertical-align: middle;
  border : none;
  &:hover {
    border: none;
  }
`;