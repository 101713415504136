import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    set: () => true,
    unset: () => false
  }
});

export default loaderSlice.reducer;
