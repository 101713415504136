import React from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledMainWrapper,
  StyledContainerRow,
  StyledContainerWrapper,
  StyledSidebarWrapper,
  StyledViewWrapper
} from 'components/ProfilePageContainer/styles';
import SideNavigation from 'components/SideNavigation';
import withTranslations from 'translations';
import { clearAuth } from 'redux/modules/auth/reducer';
import { authUserSelector } from 'redux/modules/auth/selectors';
import ProfileTopHeader from 'components/ProfileTopHeader';

const ProfilePageContainer = ({ navigationItems, children, role, pageTitle }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(authUserSelector);

  const handleLogout = () => {
    dispatch(clearAuth());
  };

  return (
    <StyledMainWrapper fluid>
      <StyledContainerRow>
        <StyledSidebarWrapper>
          <SideNavigation links={navigationItems} user={authUser} handleLogout={handleLogout} />
        </StyledSidebarWrapper>
        <StyledViewWrapper>
          <StyledContainerWrapper>
            <>
              <ProfileTopHeader
                pageTitle={pageTitle}
                role={role}
                navigationItems={navigationItems}
              />
              {children}
            </>
          </StyledContainerWrapper>
        </StyledViewWrapper>
      </StyledContainerRow>
    </StyledMainWrapper>
  );
};

export default withTranslations(withRouter(ProfilePageContainer), 'ProfilePageContainer');
