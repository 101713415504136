import styled from '@emotion/styled';
import { Card, Row } from 'react-bootstrap';
import Select from 'react-select';
import breakpoints from 'constants/breakpoints';

import { APP_RED, APP_RED_HOVER, BLACK, INPUT_BORDER, WHITE, DARK_BLUE, LIGHT_GRAY_BLUE, GRAY, GRAY_DISABLED, INPUT_TEXT_DARK_BLUE } from 'constants/colors';

const colorsOn = 'solid 0px';

export const StyledWrapperHeadingLeft = styled.div`
display: flex;
padding-right: 20px;
`;
export const StyledWrapperHeadingRight = styled.div`
display: flex;
padding-left: 20px;
`;


export const RegisterDialogWrapper = styled('div')`
  display: flex;
  justify-content: center;
  background-color: ${BLACK}; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 9998; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  border: ${colorsOn} yellow;
  overflow: auto; /* Enable scroll if needed */
`;

export const RegisterDialogContainer = styled(Card)`
  display: flex;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: ${WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  border: 1px solid ${INPUT_BORDER};
  opacity: 1;
  margin: auto; /* Auto margin according to the element width */
  border-radius: 5px;
  border: ${colorsOn} green;
  max-height: 2000px;
 
`;
export const StyledFrom = styled('form')`
  overflow-y: scroll;
  overflow-x: hidden;
  border: ${colorsOn} orange;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    height: 120px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 22px;
  font-family: 'Lato';
  color: ${INPUT_TEXT_DARK_BLUE};
`;

export const MultiSelector = styled(Select)`
  font-size: 16px;
  padding: 15px 10px;
  // border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  /* margin-top: 5px; */
  // width: 100%;
  // max-height: 18rem;
  /* border: ${colorsOn} red; */
  max-width: 18rem;
`;
export const ErrorMessage = styled('span')`
  color: ${APP_RED};
  font-size: 12px;
  font-family: 'Lato Bold';
  font-style: italic;
`;

export const StyledCheckboxWrapper = styled('div')`
  font-family: 'Lato';
  width: 100%;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  border: ${colorsOn} blue;
  margin-top: -10px;

  label[for="acceptPrivacyPolicy"] > a:first-child {
    @media (max-width: ${breakpoints.sm}) {
       font-size: 14px;
    font-family: 'Lato';
    }
  }

  label[for="acceptTermsAndConditions"] > a:first-child {
    @media (max-width: ${breakpoints.sm}) {
       font-size: 14px;
    font-family: 'Lato';
    }
  }

  label {
    text-align: left;
    font: normal normal normal 18px/28px 'Lato Regular';
    letter-spacing: 0px;
    color: ${DARK_BLUE};
    opacity: 1;
    padding-right: 15px;
  }

  input[type='checkbox'] + label {
    display: block;
    cursor: pointer;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '';
    border-radius: 0.2em;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    vertical-align: sub;
    color: transparent;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: ${WHITE} 0% 0% no-repeat content-box;
    border: 1px solid ${LIGHT_GRAY_BLUE};
    border-radius: 6px;
    opacity: 1;
  }

  input[type='checkbox'] + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    background-color: ${APP_RED};
    border-color: ${LIGHT_GRAY_BLUE};
    color: ${WHITE};
  }

  input[type='checkbox']:disabled + label:before {
    transform: scale(1);
    border-color: ${GRAY};
  }

  input[type='checkbox']:checked:disabled + label:before {
    transform: scale(1);
    background-color: ${GRAY_DISABLED};
    border-color: ${GRAY_DISABLED};
  }
`;

export const FooterEmployee = styled(Card.Footer)`
  display: flex;
  border: ${colorsOn} purple;
  align-items: center;
  margin-top: 0px;
 

  button:first-of-type {
    margin-right: 10px;
  }
`;

export const Footer = styled(Card.Footer)`
  display: flex;
  border: ${colorsOn} purple;
  align-items: center;
  margin-top: -15px;
 

  button:first-of-type {
    margin-right: 10px;
  }
`;


export const FooterLeft = styled('div')`
  margin-left: 12px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const FooterRight = styled('div')`
  display: flex;
  margin-right: 10px;
  margin-left: auto;
`;

export const HeaderContent = styled('div')`
  display: flex;
  justify-content: center;
  border: ${colorsOn} brown;
  font-weight: bold;
  font-size: 18px;

  .header-content-description {
    padding: 5px 10px;
  }
  @media (max-width: 996px) {
    padding-top: 20px;
  }
`;

export const StyledIndOccBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: ${colorsOn} yellow;
`;

export const StyledIndOccColumn = styled('div')`
  display: flex;
  flex-direction: column;
  border: ${colorsOn} brown;
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 10px; // changed this from 20px to make the input fields narrower
  width: 100%;
`;

export const FormChangeHandler = styled('div')`
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  color: ${(props)=> props.colorText};
  border-radius: 5px;
  &:hover {
    background-color: ${APP_RED_HOVER};
    color: white;
  }
  @media (max-width: 880px) {
    margin-right: 10px;
  }
`;

export const FormChangeHandlerWorker = styled('div')`
  display: flex;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  color: ${(props)=> props.colorText};
  border-radius: 5px;
  &:hover {
    background-color: ${APP_RED_HOVER};
    color: white;
  }
  @media (max-width: 880px) {
    margin-right: 10px;
  }
`;


export const StyledDescriptionWrapperIndustry = styled('div')`
  display: flex;
  padding-top: 15px;
  padding-left: 0px;
  padding-bottom: 5px;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE};
  opacity: 1;
  border: ${colorsOn} lightgreen;
`;

export const FormWrapper = styled(Row)`
  .row-container {
    display: flex;
    justify-content: space-between;

    .remove-row-button {
      margin-top: 10px;

      span {
        margin: 0;
      }
    }

    .check-box {
      gap: 5px;
      transform: scale(2);
      display: flex;
      align-content: space-around;
      justify-content: space-evenly;
      align-items: center;
      height: 50px;
      overflow: hidden;
    }

    input[type='checkbox'] {
      position: relative;
      appearance: none;
      width: 35px;
      height: 20px;
      background: #ccc;
      border-radius: 50px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type='checkbox'] {
      background: #f24e4f;
    }

    input[type='checkbox']::after {
      position: absolute;
      content: '';
      width: 14px;
      height: 13px;
      top: 0;
      left: 0;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
      transition: 0.4s;
    }

    input:checked[type='checkbox']::after {
      left: 50%;
    }
  }
`;

export const StyledDescriptionWrapperOccupation = styled('div')`
  padding-top: 15px;
  /* padding-left: 20px; */
  padding-bottom: 5px;
  font: normal normal normal 18px/28px 'Lato Regular';
  letter-spacing: 0px;
  color: ${DARK_BLUE};
  opacity: 1;
  border: ${colorsOn} green;
`;
