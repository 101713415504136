import React from 'react';
import { SyncLoader } from 'react-spinners';

import { SuspenseSpinnerContainer } from 'components/SuspenseSpinner/styles';
import { APP_RED } from 'constants/colors';

const SuspenseSpinner = () => (
  <SuspenseSpinnerContainer>
    <SyncLoader loading color={APP_RED} size={50} />
  </SuspenseSpinnerContainer>
);

export default SuspenseSpinner;
