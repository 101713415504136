import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  StyledTopHeaderWrapper,
  StyledActionBarWrapper,
  StyledDropdownToggle,
  StyledDropdownMenu,
  StyledLine,
  StyledTitle,
  SignOutMenuItem
} from 'components/ProfileTopHeader/styles';
import { DARK_BLUE_FOOTER, WHITE } from 'constants/colors';
import { clearAuth } from 'redux/modules/auth/reducer';
import { authProfileSelector, authUserSelector } from 'redux/modules/auth/selectors';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import SideMenuMobile from 'components/SideMenuMobile';
import SideNavigation from 'components/SideNavigation';
import LanguageSelector from 'components/LanguageSelector';
import languages from 'constants/languages';
import { StyledNavItem, StyledNavPagesWrapper } from 'components/LandingPageTopNavigation/styles';
import { StyledDropdown } from 'components/LanguageSelector/styles';

const ProfileTopHeader = ({ pageTitle, role, navigationItems }) => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const authUser = useSelector(authUserSelector);
  const authProfile = useSelector(authProfileSelector);
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearAuth());
  };

  const showSideMenu = () => {
    setMenu(prevState => !prevState);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  // console.log("here is navigationItems =====>>>>", navigationItems)
  return (
    <StyledTopHeaderWrapper>
      <StyledActionBarWrapper>
        <StyledNavPagesWrapper>
          <StyledNavItem>
            <LanguageSelector languages={languages} />
          </StyledNavItem>
        </StyledNavPagesWrapper>
        {width <= 992 ? (
          <SideMenuMobile isOpen={menu} closeSideMenu={showSideMenu}>
            <SideNavigation links={navigationItems} user={authUser} handleLogout={handleLogout} />
          </SideMenuMobile>
        ) : null}
        <StyledDropdown>
          <StyledDropdownToggle variant={DARK_BLUE_FOOTER} id="dropdown-basic">
            <img src={authProfile?.image?.url ? `${BE_URL}${authProfile?.image?.url}` : DefaultProfileImage} alt="user" />
            <p>{authUser.name}</p>
          </StyledDropdownToggle>

          <StyledDropdownMenu align="right">
            {/* {languages.map(language => ( */}
            <Dropdown.Item>
              <NavLink to={`/${role}/profile`}>{t('EMPLOYEE_PROFILE.MY_PROFILE.title')}</NavLink>
              {/* <span className={`flag-icon flag-icon-${language.coutry_code}`} /> */}
              {/* <span>{language.name}</span> */}
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink to={`/${role}/search`}>{t('SIDE_NAVIGATION.search.pageTitle')}</NavLink>
            </Dropdown.Item>

            {role === 'Company' && (
              <Dropdown.Item>
                <NavLink to={`/${role}/projects`}>{t('SIDE_NAVIGATION.myProjects.pageTitle')}</NavLink>
              </Dropdown.Item>
            )}
            {role === 'Company' && (
              <Dropdown.Item>
                <NavLink to={`/${role}/vacancies`}>{t('SIDE_NAVIGATION.vacancies.pageTitle')}</NavLink>
              </Dropdown.Item>
            )}
            {role === 'Worker' && (
              <Dropdown.Item>
                <NavLink to={`/${role}/employment`}>{t('SIDE_NAVIGATION.myEmployment.pageTitle')}</NavLink>
              </Dropdown.Item>
            )}
            {role === 'Company' && (
              <Dropdown.Item>
                <NavLink to={`/${role}/applications`}>{t('SIDE_NAVIGATION.applications.pageTitle')}</NavLink>
              </Dropdown.Item>
            )}
            {role === 'Worker' && (
              <Dropdown.Item>
                <NavLink to={`/${role}/applications`}>{t('SIDE_NAVIGATION.applications.myApplications')}</NavLink>
              </Dropdown.Item>
            )}

            <Dropdown.Item>
              <NavLink to={`/${role}/offers`}>{t('SIDE_NAVIGATION.offers.pageTitle')}</NavLink>
            </Dropdown.Item>

            <Dropdown.Item>
              <NavLink to={`/${role}/favorites`}>{t('SIDE_NAVIGATION.favorites.pageTitle')}</NavLink>
            </Dropdown.Item>
            {role === 'Company' &&
            <Dropdown.Item>
              <NavLink to={`/${role}/blacklist`}>{t('SIDE_NAVIGATION.blacklist.pageTitle')}</NavLink>
            </Dropdown.Item>}

            <Dropdown.Item>
              {/* <NavLink to={`/${role}/profile`}>Profile</NavLink> */}
              <SignOutMenuItem onClick={() => dispatch(clearAuth())} style={{ color: WHITE }}>
                {t('BUTTON.signOut')}
              </SignOutMenuItem>
              {/* <span className={`flag-icon flag-icon-${language.coutry_code}`} /> */}
              {/* <span>{language.name}</span> */}
            </Dropdown.Item>
            {/* ))} */}
          </StyledDropdownMenu>
        </StyledDropdown>
        {/* <StyledDropdownToggle variant={DARK_BLUE_FOOTER} onClick={showSideMenu}>
      <img
        src={
          authProfile?.image?.url ? `${BE_URL}${authProfile?.image?.url}` : DefaultProfileImage
        }
        alt="user"
      />
      <p>{authUser.name}</p>
    </StyledDropdownToggle>
    {menu ? (
      <StyledDropdownMenu align="right" show={menu && width > 992}>
        <NavLink to={`/${role}/profile`}>Profile</NavLink>
        <SignOutMenuItem onClick={() => dispatch(clearAuth())}>Sign out</SignOutMenuItem>
      </StyledDropdownMenu>
    ) : null} */}
      </StyledActionBarWrapper>
      <StyledLine>
        <div />
      </StyledLine>
      <StyledTitle>
        <h1>{pageTitle}</h1>
      </StyledTitle>
    </StyledTopHeaderWrapper>
  );
};

ProfileTopHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default ProfileTopHeader;
