import React from 'react';
import { Col } from 'react-bootstrap';

import { StyledPaginationArrow, StyledPaginationButton, StyledPaginationWrapper } from './styles';

const Pager = ({ active, jobsCount, perPage, changePage }) => {
  const pageCount = Math.ceil(jobsCount / perPage) || 1;
  const pages = [...Array(pageCount + 1).keys()].slice(1);

  const decreasePage = () => {
    if (active > 1) changePage(active - 1);
  };

  const increasePage = () => {
    if (active < pageCount) changePage(active + 1);
  };

  return (
    <StyledPaginationWrapper>
      <Col>
        <StyledPaginationArrow icon="arrow-left" disabled={active === 1} onClick={decreasePage} />
        {pages.map(page => (
          <StyledPaginationButton
            key={`page-${page}`}
            active={page === active}
            onClick={() => changePage(page)}
          >
            {page}
          </StyledPaginationButton>
        ))}
        <StyledPaginationArrow
          icon="arrow-right"
          onClick={increasePage}
          disabled={active === pageCount}
        />
      </Col>
    </StyledPaginationWrapper>
  );
};

export default Pager;
