import { USER_TYPE } from 'constants/userTypes';

const user = (payload, role) => ({
  username:
    role === USER_TYPE.WORKER
      ? `${payload.firstName}${payload.lastName}${Math.random().toString(36).substring(2, 7)}`
      : `Company-${Math.random().toString(36).substring(2, 7)}`,
  name: role === USER_TYPE.WORKER ? `${payload.firstName} ${payload.lastName}` : payload.name,
  firstName: payload.firstName,
  lastName: payload.lastName,
  email: payload.email,
  password: payload.password,
  acceptedTermsAndConditions: payload.acceptedTermsAndConditions,
  acceptedPrivacyPolicy: payload.acceptedPrivacyPolicy,
  role
});

const workerProfile = payload => ({
  profile: {
    state: payload.country,
    city: payload.city,
    address: payload.address,
    rate: payload.rate,
    mobilePhone: payload.mobilePhone,
    jobTitle: payload.jobTitle,
    industries: payload.industries,
    industy_occupations: payload.industy_occupations,
    skillLevel: [...payload.skillLevel],
    locale: payload.locale,
    isActive: payload.isActive !== true
  }
});

const companyProfile = payload => ({
  profile: {
    companyName: payload.companyName,
    name: payload.name,
    state: payload.country,
    city: payload.city,
    postCode: payload.postCode,
    address: payload.address,
    mobilePhone: payload.mobilePhone,
    jobTitle: payload.jobTitle,
    uid: payload.uid,
    contactEmail: payload.email,
    phoneNumber: payload.phoneNumber,
    industries: payload.industries,
    industy_occupations: payload.industy_occupations,
    locale: payload.locale,
    isEmailSubscription: true
  }
});

export const payloadBody = (payload, role) => {
  if (role === USER_TYPE.WORKER) {
    return {
      ...user(payload, role),
      ...workerProfile(payload)
    };
  }
  return {
    ...user(payload, role),
    ...companyProfile(payload)
  };
};
