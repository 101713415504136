import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';
import {
  companyOffers,
  workerOffers
} from 'redux/modules/offers/asyncThunks';

const initialState = {
  offers: [],
  status: ASYNC_STATUS.IDLE
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: {
    [companyOffers.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [companyOffers.fulfilled]: (state, { payload }) => {
      state.offers = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [workerOffers.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [workerOffers.fulfilled]: (state, { payload }) => {
      state.offers = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
  }
});

export default offersSlice.reducer;
