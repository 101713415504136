import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { LIGHT_GRAY_BACKGROUND, LIGHT_BLUE_PAGE_BG, DARK_BLUE_FOOTER, APP_RED, BLACK, WHITE, DISABLED, INPUT_BORDER } from 'constants/colors';
import breakpoints from 'constants/breakpoints';
import DatePicker from 'react-datepicker';

const pixelSize = '0px';

const StyledLineWrapper = styled('hr')`
  width: 75vw; /* Set width to 70% of viewport width */
  height: 1px; /* Set desired height */
  background-color: black; /* Set desired color */
  border: none; /* Remove default border */
`;

const StyledWorkerInfoBottomWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
  padding-top: 70px;
  font-weight: bold;
  border: ${pixelSize} solid orange;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledWorkerInfoLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
  /* padding-top: 10px; */
  border: ${pixelSize} solid red;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

const StyledEmploymentContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${pixelSize} solid orange;
`;

const StyledRowDate = styled(Row)`
  padding-bottom: 10px;
  width: 300px;
  @media (max-width: ${breakpoints.lg}) {
  }
`;

const StyledReason = styled('div')`
  padding-top: 20px;
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 1500px) {
  }
`;

const StyledOffersRow = styled('div')`
  display: flex;
  padding-bottom: 10px;
`;

const StyledColLeft = styled(Col)`
  padding-left: 180px;
  @media (max-width: 1500px) {
    padding-left: 0px;
  }
`;

const StyledColRight = styled(Col)`
  padding-left: 180px;
  @media (max-width: ${breakpoints.lg}) {
    padding-left: 0px;
  }
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
  @media (max-width: ${breakpoints.lg}) {
  }
`;
const StyledRowButtons = styled(Row)`
  justify-content: flex-end;
  @media (max-width: ${breakpoints.lg}) {
  }
`;

const StyledDatePickerField = styled(DatePicker)`
  font-size: 16px;
  padding: 15px 10px;
  width: 100%;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  height: 50px;
  width: 100%;
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;

const StyledTextareaField = styled('textarea')`
  font-size: 16px;
  padding: 15px 10px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  resize: none;
  width: 100%;
  margin-bottom: -20px;
`;

const StyledProjectsCardsWrapper = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 0;
  height: 100%;
  min-height: 200px;
  width: 100%;
  overflow: auto;
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: ${pixelSize} solid red;
  @media (max-width: ${breakpoints.lg}) {
    overflow: visible;
    min-height: unset;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTabs = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow: auto;
  position: relative;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTab = styled('div')`
  background: ${({ active }) => (active ? APP_RED : LIGHT_GRAY_BACKGROUND)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  padding: ${({ active }) => (active ? '5px 10px' : '5px 10px')};
  color: ${({ active }) => (active ? WHITE : BLACK)};
  font-size: 18px;
  font-weight: 700;
  border-radius: 6px 6px 0 0;
  border-top: 1px solid #cbced7;
  border-left: 1px solid #cbced7;
  border-right: 1px solid #cbced7;
  border: ${({ active }) => (active ? `1px solid ${APP_RED}` : '')};
  line-height: 1.2;
  min-width: 200px;
  cursor: pointer;
`;

const StyledActions = styled('div')`
  display: flex;
  align-items: flex-end;
  padding: 5px 0;
  width: 100%;
  justify-content: flex-end;
  button:nth-of-type(1) {
    margin-right: 14px;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;

    button {
      width: 100%;
    }
    button:nth-of-type(1) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const StyledParentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: ${pixelSize} solid purple;
  /* height: 100%; */
`;

const StyledProjectsContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  min-height: 400px;
  border: ${pixelSize} solid yellow;
  @media (max-width: ${breakpoints.lg}) {
    height: auto;
    overflow: visible;
    min-height: unset;
  }
`;

const StyledProjectsWrapper = styled('div')`
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 0px 6px 6px 6px;
  margin-top: 20px;
  padding-bottom: 100px;
  border: ${pixelSize} solid orange;
  @media (max-width: ${breakpoints.lg}) {
    overflow: auto;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    color: #192432;
    text-align: left;
    padding-left: 25px;
  }
`;

const StyledEmployeesCardsWrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  /* overflow: auto; */
  margin: 0;
  width: 100%;
  border: ${pixelSize} solid blue;
  /* flex-wrap: wrap; */
  @media (max-width: ${breakpoints.lg}) {
    /* overflow: auto; */
    /* height: auto;
    width: auto; */
    /* min-height: 900px; */
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledProjectInfo = styled('div')`
  display: flex;
  width: 100%;
  padding: 16px 27px;
  border: ${pixelSize} solid green;
  background: ${LIGHT_GRAY_BACKGROUND};

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const StyledProjectInfoLeftSide = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
  }
`;

const StyledProjectInfoDetails = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  min-width: 200px;
  max-width: 350px;
  border: ${pixelSize} solid lightgreen;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;
const StyledProjectInfoDetaiTwo = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  min-width: 200px;
  max-width: 350px;
  border: ${pixelSize} solid lightblue;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;

const StyledProjectInfoDetaiThree = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  min-width: 200px;
  max-width: 350px;
  border: ${pixelSize} solid green;
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-top: 2px;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;

const StyledProjectRate = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100px;
  border: ${pixelSize} solid darkred;
  @media (max-width: ${breakpoints.md}) {
    padding-top: 2px;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledProjectsHeader = styled('div')`
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${DARK_BLUE_FOOTER};
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 4px solid ${APP_RED};
  /* position: relative; */
  border: ${pixelSize} solid pink;
`;

const StyledMoverWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledContainerBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: auto;
  border: ${pixelSize} solid green;
`;

const StyledMover = styled('div')`
  height: 100%;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const StyledTabsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: ${pixelSize} solid brown;
  @media (min-width: 1400) {
    width: calc((100% - 200px) / 1.2);
    height: 50px;
  }
`;

const StyledWorkerInfoPreviewWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 70px;
  padding: 0 30px;
`;

const StyledInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  border: ${pixelSize} solid brown;
`;

const StyledInfoLine = styled('div')`
  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    line-height: 28px;
  }
`;

const StyledWorkerDocumentsWrapper = styled('div')`
  padding: 20px 30px 0;
  h4 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
`;

const StyledDocumentsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  & > :nth-of-type(even) {
    position: relative;
    left: 40px;
  }
`;

const StyledDocumentLine = styled('div')`
  width: 50%;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: ${APP_RED};
    cursor: pointer;

    span {
      padding-right: 10px;
      color: ${DISABLED};
    }
  }
`;

export {
  StyledProjectsContentWrapper,
  StyledProjectsWrapper,
  StyledProjectsCardsWrapper,
  StyledEmployeesCardsWrapper,
  StyledCardWrapper,
  StyledProjectsHeader,
  StyledTabs,
  StyledTab,
  StyledActions,
  StyledProjectInfoDetails,
  StyledProjectInfo,
  StyledProjectInfoLeftSide,
  StyledProjectRate,
  StyledMover,
  StyledMoverWrapper,
  StyledTabsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledInfoWrapper,
  StyledInfoLine,
  StyledWorkerDocumentsWrapper,
  StyledDocumentsWrapper,
  StyledDocumentLine,
  StyledParentContainer,
  StyledProjectInfoDetaiTwo,
  StyledProjectInfoDetaiThree,
  StyledDatePickerField,
  StyledTextareaField,
  StyledColWrapper,
  StyledColLeft,
  StyledColRight,
  StyledRow,
  StyledRowButtons,
  StyledReason,
  StyledRowDate,
  StyledOffersRow,
  StyledEmploymentContentWrapper,
  StyledPreviewDialogFooterInfo,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledContainerBar
};
