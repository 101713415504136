import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { formatDate } from 'utils/helpers';

import _ from 'lodash';
import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import CustomCard from 'components/CustomCarProjects';
// import CustomCardOffers from 'components/CustomCarOffers';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { USER_TYPE } from 'constants/userTypes';
import { fetchProjectsList, updateProject, updateProjekts } from 'redux/modules/projects/asyncThunks';
import { projectsSelector } from 'redux/modules/projects/selectors';
import { countWorkersSelector, workersSelector } from '../../../redux/modules/workers/selectors';
import { countWorkers, fetchWorkersList } from '../../../redux/modules/workers/asyncThunks';
import { fetchJobsList, updateJob, workerApplications, updateApplication } from 'redux/modules/jobs/asyncThunks';
import { companyOfferSelector } from 'redux/modules/offers/selectors';
import { updateJobOffers, workerOffers } from '../../../redux/modules/offers/asyncThunks';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';

import {
  StyledProjectsContentWrapper,
  StyledProjectsWrapper,
  StyledProjectsCardsWrapper,
  StyledCardWrapper,
  StyledProjectsHeader,
  StyledEmployeesCardsWrapper,
  StyledTabs,
  StyledTab,
  StyledActions,
  StyledProjectInfoDetails,
  StyledProjectInfo,
  StyledProjectInfoLeftSide,
  StyledProjectRate,
  StyledMover,
  StyledTabsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledInfoWrapper,
  StyledInfoLine,
  StyledWorkerDocumentsWrapper,
  StyledDocumentsWrapper,
  StyledDocumentLine,
  StyledParentContainer,
  StyledProjectInfoDetaiThree,
  StyledProjectInfoDetaiTwo,
  StyledDatePickerField,
  StyledTextareaField,
  StyledColWrapper,
  StyledColLeft,
  StyledColRight,
  StyledRow,
  StyledRowButtons,
  StyledReason,
  StyledRowDate,
  StyledOffersRow,
  StyledEmploymentContentWrapper,
  StyledPreviewDialogFooterInfo,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledContainerBar
} from './styles';
import Filters from '../../../components/Filters';
import CustomButton from '../../../components/CustomButton';
import { APP_RED, DARK_BUTTON, WHITE } from '../../../constants/colors';
import { jobsSelector } from '../../../redux/modules/jobs/selectors';
import { authProfileSelector, authUserSelector, favoritesProfileSelector } from '../../../redux/modules/auth/selectors';
import { updateFavoritesCompany } from '../../../redux/modules/auth/asyncThunks';
import { companyOffers } from '../../../redux/modules/offers/asyncThunks';
import http from '../../../utils/http';
import { applicationsUrl } from '../../../utils/apis';
import { applicationsSelector } from '../../../redux/modules/jobs/selectors';

import PreviewDialog from '../../../components/PreviewDialog';
import PreviewTerminate from '../../../components/PreviewTerminate';
import { openDialog, closeDialog } from '../../../redux/modules/dialogs/reducer';
import { showToast } from '../../../redux/modules/toast/reducer';

// import { StyledColWrapper } from '../Search/styles';
import { stubTrue } from 'lodash';

// eslint-disable-next-line complexity
const Projects = () => {
  const { t } = useTranslation();
  const [isUp, setIsUp] = useState(1);
  const [preUp, setPreUp] = useState(2);
  const [previewEducation, setPreviewEducation] = useState(null);
  const [previewFilesWorker, setPreviewFilesWorker] = useState(null);
  const [activatePopUp, setActivatePopUp] = useState(false);
  const [previewJobDetails, setPreviewJobDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [terminationReason, setTerminationReason] = useState('');
  const [terminationDate, setTerminationDate] = useState(null);
  const [activeCard, setActiveCard] = useState(0);
  const [previewWorker, setPreviewWorker] = useState(null);
  const [offersOfWorker, setOffersOfWorker] = useState(null);
  const history = useHistory();
  const [previewDetails, setPreviewDetails] = useState({});
  const [searchEmployees, setSearchEmployees] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const jobList = useSelector(jobsSelector);
  const [employees, setEmployees] = useState([]);
  const workersList = useSelector(workersSelector);
  const projectsTabsRef = useRef(null);
  const company = useSelector(authProfileSelector);
  const userInfo = useSelector(authUserSelector);
  const projectList = useSelector(projectsSelector);
  const [btnSigns, setBtnSigns] = useState({ plus: '+', minus: '-' });
  const favorites = useSelector(favoritesProfileSelector);
  const offers = useSelector(companyOfferSelector);
  const worker = useSelector(authProfileSelector);
  //newly added
  const [applications, setApplications] = useState([]);
  const [allWorkerApplications, setAllWorkerApplications] = useState([]);
  const applicationList = useSelector(applicationsSelector);

  const dispatch = useDispatch();
  const SCROLL_AMOUNT = 200;
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  const params = {
    _sort: 'created_at:DESC',
    _limit: 1000
  };

  const PER_PAGE = 500;
  const defaultParams = {
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
    // isActive: false
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await http.get(applicationsUrl);
        // console.log("here is data in applicationsUrl", data)
        setAllWorkerApplications(data);
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, []);
  console.log('here is allWorkerApplications ==>>', allWorkerApplications);
  useEffect(() => {
    dispatch(workerApplications(worker.id));
  }, [applicationList]);

  // console.log('here is workersList top===>>>>', workersList);

  useEffect(() => {
    dispatch(companyOffers(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(async () => {
    await dispatch(fetchWorkersList(defaultParams));
    await dispatch(countWorkers());
  }, []);

  // console.log("here is worker ==>>", worker)

  useEffect(() => {
    dispatch(fetchJobsList({ ...params }));
    // dispatch(fetchProjectsList({ ...params }));
  }, [dispatch]);

  useEffect(() => {
    if (userInfo.id) {
      Object.assign(params, { company: userInfo?.id });
      dispatch(fetchProjectsList({ ...params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // console.log('here is workersList ===>>>', workersList);
  console.log('here is userInfo ===>>>', userInfo);

  useEffect(async () => {
    let localEmployees = [];
    let jobListWithoutFinishedJobs = [];
    jobListWithoutFinishedJobs = await jobList.filter(job => job.status !== 'finished');
    // for (let i = 0; i < jobListWithoutFinishedJobs .length; i++) {
    //   if (jobListWithoutFinishedJobs [i].applications.length) {
    //     jobListWithoutFinishedJobs [i].applications.forEach(application => {
    //       if (application.status === 'accepted') {
    //         const worker = workersList.find(labor => labor.id === application?.worker?.id) || application.worker;
    //         localEmployees.push(worker);
    //       }
    //     });
    //   }
    // }
    const modifiedJobList = jobListWithoutFinishedJobs
      .map(job => {
        const acceptedApplication = job.applications.find(application => application.status === 'accepted');
        const worker = acceptedApplication
          ? {
              ...acceptedApplication.worker,
              job: job
            }
          : null;

        return {
          ...job,
          worker: worker
        };
      })
      .filter(job => job.worker !== null);

    // console.log("here is modifiedJobList ====>>>>", modifiedJobList)

    const workerArrayIncludingJob = modifiedJobList.map(job => job.worker);
    // workerArrayIncludingJob = workerArrayIncludingJob.concat(offers.map(offer => offer.worker));

    // console.log("here is workerArrayIncludingJob ====>>>>", workerArrayIncludingJob)

    // console.log("here is modifiedJobList ====>>>>", modifiedJobList.map(job => job.worker))

    setEmployees(workerArrayIncludingJob);
    setSearchEmployees(workerArrayIncludingJob);
  }, [jobList]);

  // console.log('here is offers=====>>>>>>>>>>>>>-', offers);

  const handleOpenWorkerDetails = worker => {
    // console.log("here is worker ==>>-", worker)
    const workerOffers = offers.filter(offer => offer.worker.id === worker.id);
    // console.log("here is workerOffers ==>>-", workerOffers )
    setOffersOfWorker(workerOffers);
    console.log('here is setOffersOfWorker ===>>>', offersOfWorker);
    const employeeFullProfile = (workersList && workersList || []).find(employee => employee.id === worker.id);
    console.log("here is employeeFullProfile ==>>", employeeFullProfile)
    let workerWithFiles = workersList.find(user => user.id === worker.id);
    let workerFilesOnly = workerWithFiles?.files;

    {
      workerFilesOnly && setPreviewEducation(workerWithFiles);
    }

    // console.log('here is setOffersOfWorker ===>>>', offersOfWorker);
    // console.log('here is worker ===>>>', worker);
    console.log('here is workersList ===>>>', workersList);
    // console.log('here is employeeFullProfile ===>>>', employeeFullProfile);
    // setPreviewWorker(employeeFullProfile || worker);
    setPreviewWorker({
      originalData: employeeFullProfile,
      title: employeeFullProfile?.name,
      subtitle: employeeFullProfile?.jobTitle,
      workerJobTitle: employeeFullProfile?.jobTitle,
      workerName: employeeFullProfile?.user?.name,
      workerStreet: employeeFullProfile?.address,
      workerCity: employeeFullProfile?.city,
      workerPostcode: employeeFullProfile?.rate,
      workerCountry: employeeFullProfile?.state,
      workerEmail: employeeFullProfile?.email,
      workerCitizenship: employeeFullProfile?.citizenship,
      workerBirthdate: employeeFullProfile?.birthdate,
      workerDriverLicenses: employeeFullProfile?.driverLicences,
      workerHourlyRate: employeeFullProfile?.hourlyRate,
      workerMonthlyRate: employeeFullProfile?.monthlySalary,
      workerMobilePhone: employeeFullProfile?.mobilePhone,
      workerBio: employeeFullProfile?.jobDescription,
      // offerStreet: offersOfWorker[0]?.address?.streatAddress,
      // offerCity: offersOfWorker[0]?.address?.city,
      // offerPostCode: offersOfWorker[0]?.address?.postCode,
      // offerCountry: offersOfWorker[0]?.address?.country,
      // offerDescription: offersOfWorker[0]?.description,
      // offerSalaryAmount: offersOfWorker[0]?.salary,
      // offerSalaryType: offersOfWorker[0]?.salaryType,
      // offerStartDate: offersOfWorker[0]?.startDate,
      // offerJobTitle: offersOfWorker[0]?.jobTitle,
      // companyStreet: offersOfWorker[0]?.company?.address,
      // companyCity: offersOfWorker[0]?.company?.city,
      // companyPostcode: offersOfWorker[0]?.company?.postCode,
      // companyCountry: offersOfWorker[0]?.company?.state,
      // companyMobilePhone: offersOfWorker[0]?.company?.mobilePhone,
      // companyPhone: offersOfWorker[0]?.company?.phoneNumber,
      // companyWebsite: offersOfWorker[0]?.company?.website,
      // companyName: offersOfWorker[0]?.company?.companyName,
      // companyEmail: offersOfWorker[0]?.company?.contactEmail,
      // companyDescription: offersOfWorker[0]?.company?.description,
      logoUrl: employeeFullProfile?.image?.url ? `${BE_URL}${employeeFullProfile?.image?.url}` : null
    });
    setPreviewFilesWorker(workerFilesOnly);
    setPreviewDetails({
      originalData: employeeFullProfile,
      title: employeeFullProfile?.name,
      subtitle: employeeFullProfile?.jobTitle,
      workerJobTitle: employeeFullProfile?.jobTitle,
      workerName: employeeFullProfile?.user?.name,
      workerStreet: employeeFullProfile?.address,
      workerCity: employeeFullProfile?.city,
      workerPostcode: employeeFullProfile?.rate,
      workerCountry: employeeFullProfile?.state,
      workerEmail: employeeFullProfile?.email,
      workerCitizenship: employeeFullProfile?.citizenship,
      workerBirthdate: employeeFullProfile?.birthdate,
      workerDriverLicenses: employeeFullProfile?.driverLicences,
      workerHourlyRate: employeeFullProfile?.hourlyRate,
      workerMonthlyRate: employeeFullProfile?.monthlySalary,
      workerMobilePhone: employeeFullProfile?.mobilePhone,
      workerBio: employeeFullProfile?.jobDescription,
      // offerStreet: offersOfWorker[0]?.address?.streatAddress,
      // offerCity: offersOfWorker[0]?.address?.city,
      // offerPostCode: offersOfWorker[0]?.address?.postCode,
      // offerCountry: offersOfWorker[0]?.address?.country,
      // offerDescription: offersOfWorker[0]?.description,
      // offerSalaryAmount: offersOfWorker[0]?.salary,
      // offerSalaryType: offersOfWorker[0]?.salaryType,
      // offerStartDate: offersOfWorker[0]?.startDate,
      // offerJobTitle: offersOfWorker[0]?.jobTitle,
      // companyStreet: offersOfWorker[0]?.company?.address,
      // companyCity: offersOfWorker[0]?.company?.city,
      // companyPostcode: offersOfWorker[0]?.company?.postCode,
      // companyCountry: offersOfWorker[0]?.company?.state,
      // companyMobilePhone: offersOfWorker[0]?.company?.mobilePhone,
      // companyPhone: offersOfWorker[0]?.company?.phoneNumber,
      // companyWebsite: offersOfWorker[0]?.company?.website,
      // companyName: offersOfWorker[0]?.company?.companyName,
      // companyEmail: offersOfWorker[0]?.company?.contactEmail,
      // companyDescription: offersOfWorker[0]?.company?.description,
      logoUrl: employeeFullProfile?.image?.url ? `${BE_URL}${employeeFullProfile?.image?.url}` : null
      // logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  const scrollLeft = event => {
    event.stopPropagation();
    event.preventDefault();

    if (projectsTabsRef && projectsTabsRef.current) {
      if (projectsTabsRef.current.scrollLeft > 0) {
        projectsTabsRef.current.scrollTo(projectsTabsRef.current.scrollLeft < SCROLL_AMOUNT ? 0 : projectsTabsRef.current.scrollLeft - SCROLL_AMOUNT, 0);
      }
    }
  };

  const scrollRight = event => {
    event.stopPropagation();
    event.preventDefault();

    if (projectsTabsRef && projectsTabsRef.current) {
      if (projectsTabsRef.current.scrollLeft < projectsTabsRef.current.scrollWidth) {
        projectsTabsRef.current.scrollTo(
          projectsTabsRef.current.scrollLeft + SCROLL_AMOUNT > projectsTabsRef.current.scrollWidth
            ? projectsTabsRef.current.scrollWidth
            : projectsTabsRef.current.scrollLeft + SCROLL_AMOUNT,
          0
        );
      }
    }
  };

  const chooseProject = (event, index) => {
    event?.target?.scrollIntoView({ behavior: 'smooth' });
    setActiveCard(index);
  };

  const handleOnSearch = searchTerm => {
    if (searchTerm) {
      setSearchEmployees(
        employees.filter(
          worker =>
            worker?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            worker.jobTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            worker.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            worker.state?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setSearchEmployees(employees);
    }
  };

  const goToEditProject = () => {
    if (projectList[activeCard]?.id) {
      history.push(`/projects/edit/${projectList[activeCard].id}`);
    }
  };

  const goToCreateProject = () => {
    history.push(`/projects/new`);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (width < 600) {
      setIsUp(2);
      setPreUp(3);
    }
  }, [width]);

  const addWorkerToProject = (e, worker) => {
    // console.log('here is worker in addtoProject ===>>>', worker);
    e.stopPropagation();
    const projectId = projectList[activeCard]?.id;
    const projectData = { ...projectList[activeCard] };
    projectData.workers = [worker?.id, ...projectData.workers];
    dispatch(updateProject({ projectId, payload: projectData }));
  };

  const removeWorkerFromProject = (e, worker) => {
    e.stopPropagation();
    const projectId = projectList[activeCard]?.id;
    const projectData = { ...projectList[activeCard] };
    const existIndex = projectData.workers.findIndex(labor => labor.id === worker?.id);
    if (existIndex > -1) {
      projectData.workers = [...projectData.workers];
      projectData.workers.splice(existIndex, 1);
    }
    dispatch(updateProject({ projectId, payload: projectData }));
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const employeesWrapperRef = useRef(null);

  useEffect(() => {
    if (employeesWrapperRef.current) {
      const wrapperHeight = employeesWrapperRef.current.clientHeight;
      employeesWrapperRef.current.style.minHeight = `${wrapperHeight}px`;
    }
  }, [searchEmployees]);
  const companyId = userInfo.id.toString();
  // console.log('here is searchEmployees =====<<<v>>>', searchEmployees);
  const onTerminateClick = async (jobId, workerId, realWorkerId) => {
    try {
      const terminateJob = await dispatch(
        updateJob({
          jobId: jobId,
          payload: {
            workerId: workerId,
            // activated: false,
            terminatedBy: companyId,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason,
            status: 'finished'
          }
        })
      ).unwrap();
      if (terminateJob) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.userTerminated.success') }));
      } else {
        dispatch(showToast({ type: 'failed', message: t('MESSAGES.userTerminated.failed') }));
      }
      dispatch(
        closeDialog({
          dialogName: 'previewTerminate'
        })
      );
    } catch (error) {
      console.log('an error occured', error);
    }

    // HERE THE TERMINATION GETS ADDED TO THE APPLICATIONS
    let idOfUser = userInfo.id.toString();
    // console.log('Here is idOfUser ==>>', idOfUser);
    let applicationFull = await allWorkerApplications.find(application => jobId === application.job.id);
    // console.log('Here is applicationFull ==>>', applicationFull);

    if (applicationFull) {
      let applicationId = applicationFull.id;
      let idOfWorker = applicationFull.worker.id;
      console.log('Here is applicationId ==>>', applicationId);
      /// CHECK THIS
      let employeeList = searchEmployees?.filter(em => {
        let oneApp = em.job.applications.find(app => app.worker.id === em.id);
        console.log('Here is oneApp ==>>', oneApp.terminatedBy);
        if (oneApp && oneApp.terminatedBy && oneApp.terminatedBy.length > 1 ) {
          return em}
        })
      

      console.log('here is employeeList===>>>', employeeList);
      dispatch(
        updateApplication({
          applicationId,
          payload: {
            workerId: idOfWorker,

            terminatedBy: idOfUser,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason
          }
        })
      );
    } else {
      console.log('No application found for the given jobId.');
    }

    const updatedProjectList = projectList.map(project => {
      const updatedProject = { ...project };
      updatedProject.workers = updatedProject.workers.filter(worker => worker.id !== realWorkerId);
      return updatedProject;
    });
    // console.log('here is updatedProjectList ===>>>', updatedProjectList);
    dispatch(updateProjekts(updatedProjectList));
    setActivatePopUp(false);
  };
  // console.log("here is projectList ===>>>", projectList)

  const onTerminateOfferClick = async (jobId, workerId, realWorkerId) => {
    let userId = workerId.toString();
    try {
      const terminateOffer = await dispatch(
        updateJobOffers({
          jobOfferId: jobId,
          payload: {
            terminatedBy: userId,
            terminationByUserDate: new Date(),
            terminationEffectiveDate: terminationDate,
            reasonForTermination: terminationReason,
            activated: false
          }
        })
      );
      dispatch(workerOffers(worker.id));
      // console.log("here is terminateOffer ===>>>", terminateOffer)
      if (terminateOffer) {
        dispatch(showToast({ type: 'success', message: t('MESSAGES.userTerminated.success') }));
      } else {
        dispatch(showToast({ type: 'failed', message: t('MESSAGES.userTerminated.failed') }));
      }
      dispatch(
        closeDialog({
          dialogName: 'previewTerminate'
        })
      );
    } catch (error) {
      console.log('====error occured while terminating offer====', error);
    }

    const updatedProjectList = projectList.map(project => {
      const updatedProject = { ...project };
      updatedProject.workers = updatedProject.workers.filter(worker => worker.id !== realWorkerId);
      return updatedProject;
    });
    // console.log('here is updatedProjectList ===>>>', updatedProjectList);
    dispatch(updateProjekts(updatedProjectList));

    setActivatePopUp(false);
  };

  const openPopUpTerminateJob = (e, worker) => {
    e.stopPropagation();
    e.preventDefault();
    setPreviewJobDetails({
      originalData: worker,
      title: worker?.user?.name,
      jobId: worker?.job?.id,
      workerId: worker?.id,
      subtitle: worker?.job.title,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null,
      realWorkerId: worker?.id,
      isOffer: false
    });

    dispatch(
      openDialog({
        dialogName: 'previewTerminate'
      })
    );
  };

  const handleTerminateClick = (e, worker) => {
    e.stopPropagation();
    e.preventDefault();
    // alert("hi")
    openPopUpTerminateJob(e, worker);
  };

  ///////////// offer
  const openPopUpTerminateOffer = (e, application) => {
    // console.log('hello from  application====>>>>', application);
    e.stopPropagation();
    e.preventDefault();
    setPreviewJobDetails({
      originalData: application,
      companyName: application?.company?.companyName,
      title: application?.company?.companyName,
      jobId: application?.id,
      workerId: application?.worker?.user?.id,
      subtitle: application?.jobTitle,
      logoUrl: application?.company?.image?.url ? `${BE_URL}${application?.company?.image?.url}` : null,
      isOffer: true,
      realWorkerId: application?.worker?.id
    });

    dispatch(
      openDialog({
        dialogName: 'previewTerminate'
      })
    );
  };

  const handleTerminateOfferClick = (e, application) => {
    e.stopPropagation();
    e.preventDefault();
    openPopUpTerminateOffer(e, application);
  };

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const filterBySearch = application =>
    // (application?.address && application?.address.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.user?.name && application?.user?.name.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.city && application?.city.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.postCode && application?.postCode.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.jobTitle && application?.jobTitle.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.company?.description && application?.company?.description.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.state && application?.state?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.user?.email && application?.user?.email.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.job?.title && application?.job?.title.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    workersList
      .find(user => user.id === application.id)
      ?.industy_occupations.map(el => el.name)
      .join()
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());

  // console.log('here is previewJobDetails ===>>>', previewJobDetails);
  // console.log('here is offers ====>>>>', offers);

  // console.log('here is workersList ===>>>>', workersList);

  const handleUpDownRendering = () => {
    if (isUp === 2 && preUp === 1) {
      setIsUp(3);
      setPreUp(2);
      return;
    } else if (isUp === 3 && preUp === 2) {
      setIsUp(2);
      setPreUp(3);
      return;
    } else if (isUp === 2 && preUp === 3) {
      setIsUp(1);
      setPreUp(2);
      return;
    } else if (isUp === 1 && preUp === 2) {
      setIsUp(2);
      setPreUp(1);
      return;
    }
  };

  // console.log('here is isUp ===>>>', isUp);
  // console.log('here is preUp ===>>>', preUp);
  // console.log("here is offersss", offers)

  // console.log("here is workersList ===>>>", workersList)

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.myProjects.pageTitle')} role={USER_TYPE.COMPANY}>
      <StyledParentContainer style={{ height: isUp === 1 && preUp === 2 ? '100%' : '' }}>
        {isUp === 2 || isUp === 1 ? (
          <StyledProjectsContentWrapper>
            <Filters placeholder={t('SIDE_NAVIGATION.myProjects.searchEmployees')} onInputChange={debouncedOnInputChange} />
            <StyledProjectsWrapper>
              <h1>{t('SIDE_NAVIGATION.myProjects.employees')}</h1>
              <StyledEmployeesCardsWrapper ref={employeesWrapperRef} xs={12}>
                {/* -- filter out the workers that have been terminated -- */}
                {searchEmployees?.filter(filterBySearch)
                .filter(em => {
                  let oneApp = em.job.applications.find(app => app.worker.id === em.id);
                  if (oneApp && !oneApp.terminatedBy  ) {
                    return em}
                  })
                .map((worker, index) => (
                  <StyledCardWrapper key={`emply-${worker.id}`} sm={12} md={5} lg={4}>
                    <CustomCard
                      profileImage={worker?.image?.url ? `${BE_URL}${worker?.image.url}` : DefaultProfileImage}
                      sectionTitle={worker?.user?.name}
                      sectionSubtitle={
                        workersList
                          .find(user => user.id === worker?.id)
                          ?.industy_occupations.map(ele => ele.name)
                          .join() || ''
                      }
                      headerJobType={worker.job.title}
                      headerDescriptionFirst={worker?.citizenship}
                      headerDescriptionSecond={worker?.city}
                      headerDescriptionThird={worker?.state}
                      footerDescription={worker?.jobDescription}
                      headerDescriptionFirstRight={`CHF ${worker?.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                      headerDescriptionSecondRight={`CHF ${worker?.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                      headerDescriptionThirdRight={worker?.driverLicences}
                      hasActions
                      favoriteIcon
                      isNotOffer
                      isFavorite={(favorites || []).find(fav => fav.id === worker?.id)}
                      onFavoriteClick={$event => favoriteClick($event, worker?.id)}
                      click={() => handleOpenWorkerDetails(worker)}
                      onAddRemoveClick={$e => addWorkerToProject($e, worker)}
                      plusMinusSign={btnSigns.plus}
                      handleTerminateClick={e => handleTerminateClick(e, worker)}
                    />
                  </StyledCardWrapper>
                ))}
                {offers
                  ?.filter(filterBySearch)
                  .filter(offer => offer?.status === 'Accepted' && offer.activated === true)
                  .map(worker => (
                    <StyledCardWrapper key={`emply-${worker.id}`} sm={12} md={5} lg={4}>
                      <CustomCard
                        profileImage={worker?.worker.image?.url ? `${BE_URL}${worker?.worker.image.url}` : DefaultProfileImage}
                        sectionTitle={worker.worker?.user?.name}
                        sectionSubtitle={workersList &&
                          workersList
                            .find(user => user.id === worker?.worker?.id)
                            ?.industy_occupations.map(ele => ele.name)
                            .join() || ''
                        }
                        // sectionSubtitle={worker?.jobTitle}
                        headerDescriptionFirst={worker?.worker?.citizenship}
                        headerDescriptionSecond={worker?.worker?.city}
                        headerDescriptionThird={worker?.worker?.state}
                        footerDescription={worker?.worker?.jobDescription && worker?.worker?.jobDescription}
                        headerDescriptionFirstRight={
                          worker?.worker?.monthlySalary > 0 ? `CHF ${worker?.worker?.monthlySalary}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}` : ''
                        }
                        headerDescriptionSecondRight={
                          worker?.worker?.hourlyRate > 0 ? `CHF ${worker?.worker?.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}` : ''
                        }
                        headerDescriptionThirdRight={worker?.worker?.driverLicences}
                        hasActions
                        favoriteIcon
                        isOffer
                        isFavorite={(favorites || []).find(fav => fav.id === worker?.worker?.id)}
                        onFavoriteClick={$event => favoriteClick($event, worker?.worker?.id)}
                        click={() => handleOpenWorkerDetails(worker?.worker)}
                        onAddRemoveClick={$e => addWorkerToProject($e, worker.worker)}
                        plusMinusSign={btnSigns.plus}
                        // handleTerminateClick={e => handleTerminateClick(e, worker)}
                        handleTerminateOfferClick={e => handleTerminateOfferClick(e, worker)}
                      />
                    </StyledCardWrapper>
                  ))}
              </StyledEmployeesCardsWrapper>
            </StyledProjectsWrapper>
          </StyledProjectsContentWrapper>
        ) : (
          ''
        )}
 {width < 900 && projectList && projectList.length > 0 ? (
              <StyledActions>
                {projectList && projectList.length > 0 && (
            
                    <CustomButton
                      text={t('BUTTON.editProject')}
                      customButtonColor={DARK_BUTTON}
                      customHeight="50px"
                      customWidth="168px"
                      customTextColor={WHITE}
                      hasIcon
                      icon="pen"
                      iconFontSize="15px"
                      customFontSize="16px"
                      handleOnClick={goToEditProject}
                    />
                  
                )}
                <CustomButton
                  text={t('BUTTON.newProject')}
                  customButtonColor={APP_RED}
                  customHeight="50px"
                  customWidth="168px"
                  customTextColor={WHITE}
                  hasIcon
                  icon="plus-circle"
                  iconFontSize="15px"
                  customFontSize="16px"
                  handleOnClick={goToCreateProject}
                />
              </StyledActions>
            ) : null}
        <StyledContainerBar>
   
          <StyledProjectsHeader>
            
            <StyledTabsWrapper>
              {projectsTabsRef?.current?.scrollWidth > projectsTabsRef?.current?.clientWidth && (
                <StyledMover>
                  <FontAwesomeIcon icon="chevron-circle-left" onClick={$event => scrollLeft($event)} />
                </StyledMover>
              )}
              <StyledTabs ref={projectsTabsRef}>
                {(projectList || []).map((project, index) => (
                  <StyledTab active={index === activeCard} key={`project-${project.id}`} onClick={$event => chooseProject($event, index)}>
                    {project.title}
                  </StyledTab>
                ))}
              </StyledTabs>
              {projectsTabsRef?.current?.scrollWidth > projectsTabsRef?.current?.clientWidth && (
                <StyledMover>
                  <FontAwesomeIcon icon="chevron-circle-right" onClick={$event => scrollRight($event)} />
                </StyledMover>
              )}
            </StyledTabsWrapper>
            {width > 600 && projectList && projectList.length > 0 ? (
              <StyledActions>
                <CustomButton
                  customMarginLeft="0px"
                  customButtonColor={DARK_BUTTON}
                  customHeight="50px"
                  customWidth="100px"
                  customTextColor={WHITE}
                  hasIcon
                  icon="arrowsUpDown"
                  iconFontSize="25px"
                  customFontSize="16px"
                  handleOnClick={handleUpDownRendering}
                />{' '}
              </StyledActions>
            ) : null}
            {width >= 900 && projectList && projectList.length > 0 ? (
              <StyledActions>
                {projectList && projectList.length > 0 && (
                  <div style={{ marginRight: '-8px' }}>
                    <CustomButton
                      text={t('BUTTON.editProject')}
                      customButtonColor={DARK_BUTTON}
                      customHeight="50px"
                      customWidth="168px"
                      customTextColor={WHITE}
                      hasIcon
                      icon="pen"
                      iconFontSize="15px"
                      customFontSize="16px"
                      handleOnClick={goToEditProject}
                    />
                  </div>
                )}
                <CustomButton
                  text={t('BUTTON.newProject')}
                  customButtonColor={APP_RED}
                  customHeight="50px"
                  customWidth="168px"
                  customTextColor={WHITE}
                  hasIcon
                  icon="plus-circle"
                  iconFontSize="15px"
                  customFontSize="16px"
                  handleOnClick={goToCreateProject}
                />
              </StyledActions>
            ) : null}

            {width > 100 && projectList && projectList.length < 1 ? (
              <StyledActions>
                {projectList && projectList.length > 0 && (
                  <div style={{ marginRight: '-8px' }}>
                    <CustomButton
                      text={t('BUTTON.editProject')}
                      customButtonColor={DARK_BUTTON}
                      customHeight="50px"
                      customWidth="168px"
                      customTextColor={WHITE}
                      hasIcon
                      icon="pen"
                      iconFontSize="15px"
                      customFontSize="16px"
                      handleOnClick={goToEditProject}
                    />
                  </div>
                )}
                <CustomButton
                  text={t('BUTTON.newProject')}
                  customButtonColor={APP_RED}
                  customHeight="50px"
                  customWidth="168px"
                  customTextColor={WHITE}
                  hasIcon
                  icon="plus-circle"
                  iconFontSize="15px"
                  customFontSize="16px"
                  handleOnClick={goToCreateProject}
                />
              </StyledActions>
            ) : null}
          </StyledProjectsHeader>{' '}
        </StyledContainerBar>
        {isUp === 3 || isUp === 2 ? (
          <div>
            <StyledProjectInfo>
              <StyledProjectInfoLeftSide>
                <StyledProjectInfoDetails>
                  <h3>{projectList[activeCard]?.title}</h3>
                  {projectList[activeCard]?.description ? <h3>{projectList[activeCard]?.description.replace(/<\/?[^>]+(>|$)/g, '')}</h3> : ''}
                  {/* <p>{console.log(projectList[activeCard]?.description.replace(/<\/?[^>]+(>|$)/g, ''))}</p> */}
                </StyledProjectInfoDetails>
                <StyledProjectRate>
                  <h3>Deadline</h3>
                  <p>{projectList[activeCard]?.deadline}</p>
                </StyledProjectRate>
                <StyledProjectInfoDetails>
                  <h3>Status</h3>
                  <p>{projectList[activeCard]?.status}</p>
                </StyledProjectInfoDetails>
              </StyledProjectInfoLeftSide>
            </StyledProjectInfo>
            {(projectList[activeCard]?.workers || []).map(emp => (
              <StyledCardWrapper key={`worker-${emp?.id}`} sm={12} md={5} lg={4}>
                <CustomCard
                  profileImage={emp?.image?.url ? `${BE_URL}${emp?.image.url}` : DefaultProfileImage}
                  sectionTitle={workersList.find(user => user.id === emp?.id)?.name || emp?.name}
                  sectionSubtitle={
                    workersList
                      .find(user => user.id === emp?.id)
                      ?.industy_occupations.map(ele => ele.name)
                      .join() || ''
                  }
                  headerDescriptionFirst={emp?.citizenship}
                  headerDescriptionSecond={emp?.city}
                  headerDescriptionThird={emp?.state}
                  headerDescriptionFirstRight={`CHF ${emp.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                  headerDescriptionSecondRight={`CHF ${emp.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                  headerDescriptionThirdRight={emp.driverLicences}
                  footerDescription={emp?.jobDescription}
                  hasActions
                  favoriteIcon
                  isFavorite={(favorites || []).find(fav => fav.id === emp?.id)}
                  onFavoriteClick={$event => favoriteClick($event, emp?.id)}
                  click={() => handleOpenWorkerDetails(emp)}
                  onAddRemoveClick={$e => removeWorkerFromProject($e, emp)}
                  plusMinusSign={btnSigns.minus}
                />
              </StyledCardWrapper>
            ))}
          </div>
        ) : (
          ''
        )}
      </StyledParentContainer>

      <PreviewDialog {...previewDetails}>
        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                  {previewWorker?.workerStreet}
                </p>
              )}
              {previewWorker?.workerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.workerCity}
                </p>
              )}
              {previewWorker?.workerPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.workerPostcode}
                </p>
              )}
              {previewWorker?.workerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                  {previewWorker?.workerCountry}
                </p>
              )}
              {previewWorker?.workerBirthdate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>
                  {formatDate(new Date(previewWorker?.workerBirthdate))}
                </p>
              )}
              {previewWorker?.workerCitizenship && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>
                  {previewWorker?.workerCitizenship}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.workerMobilePhone && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                  {previewWorker?.workerMobilePhone}
                </p>
              )}
              {previewWorker?.workerEmail && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                  {previewWorker?.workerEmail}
                </p>
              )}
              {previewWorker?.workerDriverLicenses && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                  {previewWorker?.workerDriverLicenses}
                </p>
              )}
              {previewWorker?.workerHourlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>
                  {previewWorker?.workerHourlyRate}.-
                </p>
              )}
              {previewWorker?.workerMonthlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>
                  {previewWorker?.workerMonthlyRate}.-
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <StyledWorkerDocumentsWrapper>
          <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
          <StyledDocumentsWrapper>
            {(previewFilesWorker || []).map(file => (
              <StyledDocumentLine key={`file-${file.id}`}>
                <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                  <span>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </span>
                  {file.name}
                </p>
              </StyledDocumentLine>
            ))}
          </StyledDocumentsWrapper>
        </StyledWorkerDocumentsWrapper>
        <Row>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
            {previewEducation?.workExperience && previewEducation?.workExperience?.length ? (
              previewEducation?.workExperience.map(
                work =>
                  work.companyName &&
                  work.position && (
                    <>
                      <strong>
                        {work.companyName}, {work.position}
                      </strong>
                      <p>
                        {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                      </p>
                    </>
                  )
              )
            ) : (
              <></>
            )}
          </StyledColWrapper>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
            {previewEducation?.education?.length ? (
              previewEducation?.education.map(work => (
                <>
                  <strong>
                    {work.name}, {work.type}
                  </strong>
                  <p>
                    {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                  </p>
                </>
              ))
            ) : (
              <></>
            )}
          </StyledColWrapper>
        </Row>
        <Row>
          {' '}
          {previewWorker?.workerBio && (
            <StyledColWrapper style={{ paddingTop: '20px' }}>
              <h4>{t('FORM.fields.aboutMe')}</h4>
              <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          )}
        </Row>

        {previewWorker?.offerCity && (
          <StyledWorkerInfoBottomWrapper>
            {/* <StyledInfoWrapperBottom style={{ paddingTop: '50px' }}> */}{' '}
            <h4>
              {' '}
              <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.offer')}</span>
            </h4>
          </StyledWorkerInfoBottomWrapper>
        )}
        {previewWorker?.offerCity && (
          <StyledWorkerInfoLineWrapper>
            <StyledLineWrapper />
          </StyledWorkerInfoLineWrapper>
        )}

        <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerJobTitle && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.occupation')}: </span>
                  {previewWorker?.offerJobTitle}
                </p>
              )}
              {previewWorker?.offerStreet && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span> {previewWorker?.offerStreet}
                </p>
              )}
              {previewWorker?.offerCity && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}: </span>
                  {previewWorker?.offerCity}
                </p>
              )}
              {previewWorker?.offerPostCode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                  {previewWorker?.offerPostCode}
                </p>
              )}
              {previewWorker?.offerCountry && (
                <p>
                  <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span> {previewWorker?.offerCountry}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{ paddingTop: '20px' }}>
              {previewWorker?.offerStartDate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>{t('FORM.fields.startDate')}: </span> {formatDate(new Date(previewWorker?.offerStartDate))}
                </p>
              )}
              {previewWorker?.offerSalaryAmount &&
                (previewWorker?.offerSalaryType === 'Hourly' ? (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.hourlyRate')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('FORM.fields.monthlySalary')}: CHF</span> {previewWorker?.offerSalaryAmount}.-
                  </p>
                ))}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        {previewWorker?.offerDescription && (
          <Row>
            {' '}
            <StyledColWrapper style={{ paddingTop: '40px', marginBottom: '40px' }}>
              <h4>{t('OFFERCARD.description')}</h4>
              <StyledPreviewDialogFooterInfo>
                {previewWorker?.offerDescription && previewWorker?.offerDescription.replace(/<\/?[^>]+(>|$)/g, '')}
              </StyledPreviewDialogFooterInfo>{' '}
            </StyledColWrapper>
          </Row>
        )}
      </PreviewDialog>
      <PreviewTerminate
        {...previewJobDetails}
        onTerminateClick={onTerminateClick}
        onTerminateOfferClick={onTerminateOfferClick}
        isOffer={previewJobDetails.isOffer}
        realWorkerId={previewJobDetails.realWorkerId}
      >
        <StyledInfoLine>
          <StyledRow>
            <p>
              <strong> {t('FORM.fields.JobTitle')}: </strong>
              {previewJobDetails?.subtitle}
            </p>

            <p>
              <strong>{t('FORM.fields.addressOfJob')}: </strong> {previewJobDetails?.originalData?.job?.address}
            </p>
            <p>
              <strong> {t('FORM.fields.cityOfJob')}: </strong> {previewJobDetails?.originalData?.job?.city}
            </p>
            <p>
              <strong> {t('FORM.fields.hourlyRate')}: </strong>CHF {previewJobDetails?.originalData?.job?.rate}
            </p>
            <p>
              <strong> {t('FORM.fields.monthlyPayroll')}:</strong> CHF {previewJobDetails?.originalData?.job?.monthlyPayroll}
            </p>

            <p>{/* {t('EMPLOYEE_PROFILE.MY_PROFILE.state')}: {previewWorker?.state} */}</p>
          </StyledRow>
        </StyledInfoLine>
        <StyledRow>
          <StyledReason>
            {' '}
            <p>{t('FORM.fields.terminationReason')}:</p>
          </StyledReason>
          <label>
            <StyledTextareaField required value={terminationReason} type="text" onChange={e => setTerminationReason(e.target.value)} />
            {/* <input type="text" value={terminationReason} onChange={e => setTerminationReason(e.target.value)} /> */}
          </label>
        </StyledRow>
        <StyledRowDate>
          {' '}
          <StyledReason>
            {' '}
            <p>{t('FORM.fields.terminationEffectiveDate')}</p>
          </StyledReason>
          <label>
            <StyledDatePickerField
              dateFormat="dd.MM.yyyy"
              required
              placeholderText={t('FORM.fields.toDate')}
              value={terminationDate}
              selected={terminationDate}
              onChange={date => setTerminationDate(date, 'toDate')}
            />

            {/* <input type="date" value={terminationDate} onChange={e => setTerminationDate(e.target.value)} /> */}
          </label>
        </StyledRowDate>
      </PreviewTerminate>
    </ProfilePageContainer>
  );
};

export default withTranslations(Projects, 'Projects');
