import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  companyProfileUrl,
  loginUrl,
  sendOfferCompanyUrl,
  updateCompanyUrl,
  updateUserUrl,
  updateWorkerUrl,
  workerProfileUrl,
  deleteDoc,
  updateBlacklistUrl
} from 'utils/apis';
import http from 'utils/http';

const login = createAsyncThunk('auth/login', async payload => {
  const { data } = await http.post(loginUrl, payload);
  return data;
});

const getWorkerProfile = createAsyncThunk('auth/worker-profile', async workerId => {
  const { data } = await http.get(workerProfileUrl(workerId));

  return data;
});

const getCompanyProfile = createAsyncThunk('auth/company-profile', async companyId => {
  const { data } = await http.get(companyProfileUrl(companyId));
  return data;
});

const updateUser = createAsyncThunk('auth/update-user', async ({ userId, payload }) => {
  const { data } = await http.put(updateUserUrl(userId), payload);

  return data;
});

const updateWorker = createAsyncThunk('auth/update-worker', async ({ workerId, payload }) => {
  const { data } = await http.put(updateWorkerUrl(workerId), payload);
console.log("here is data in update thunk ===>>>", data)
  return data;
});
const updateWorkerApplication = createAsyncThunk('auth/update-worker-application', async ({ workerId, payload }) => {
  const { data } = await http.put(updateWorkerUrl(workerId), payload);

  return data;
});

const updateCompany = createAsyncThunk('auth/update-company', async ({ companyId, payload }) => {
  const { data } = await http.put(updateCompanyUrl(companyId), payload);

  return data;
});

const updateFavorites = createAsyncThunk('auth/update-favorites', async ({ workerId, payload }) => {
  const { data } = await http.put(updateWorkerUrl(workerId), payload);

  return data;
});

const updateFavoritesCompany = createAsyncThunk('auth/update-favorites-company', async ({ companyId, payload }) => {
  const { data } = await http.put(updateCompanyUrl(companyId), payload);

  return data;
});

const updateBlacklist = createAsyncThunk('auth/update-blacklist', async ({ companyId, payload }) => {
  const { data } = await http.put(updateBlacklistUrl(companyId), payload);
  return data.blacklists
});

const sendOfferCompany = createAsyncThunk('auth/send-offer-company', async ({ payload }) => {
  const { data } = await http.post(sendOfferCompanyUrl, payload);

  return data;
});

// const deleteSingleFile = createAsyncThunk('/files/deleteOne', async docId => {
//   const { data } = await http.delete(deleteDoc(docId));

//   return data;
// });
const deleteSingleFile = createAsyncThunk('/files/deleteOne', async docId => {
  try {
    const { data } = await http.delete(deleteDoc(docId));
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export {
  login,
  getWorkerProfile,
  getCompanyProfile,
  updateUser,
  updateWorker,
  updateFavorites,
  updateFavoritesCompany,
  updateCompany,
  sendOfferCompany,
  deleteSingleFile,
  updateBlacklist,
  updateWorkerApplication
};
