import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import DatePicker, { registerLocale }  from 'react-datepicker';
import makeAnimated from 'react-select/animated';

import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import { authProfileSelector, authUserSelector } from 'redux/modules/auth/selectors';
import { getWorkerProfile} from 'redux/modules/auth/asyncThunks';
import {
  StyledContainer,
  StyledInputField,
  StyledTextareaField,
  UserUpdateWrapper,
  WorkerUpdateWrapper,
  SubmitActionWrapper,
  StyledSelectField,
  SectionTitle,
  StyledDatePickerField,
  StyledRadioButtonCol,
  StyledRadio2ButtonCol
} from 'views/employeePage/EditProfile/styles';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE, INPUT_BORDER } from 'constants/colors';
import { updateUser, updateWorker } from 'redux/modules/auth/asyncThunks';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';
import { ErrorMessage, MultiSelector } from 'components/RegisterDialog/styles';
import CustomTable from 'components/CustomTable';
import http from 'utils/http';
import { industryList, industryOccupations } from 'utils/apis';
import { languageSelector } from 'redux/modules/language/selectors';
import { SkillLevel } from 'constants/skillLevels';
import { showToast } from 'redux/modules/toast/reducer';

import { formatISODateFromShortDate, formatShortDateFromISODate, getPropByValue } from '../../../utils/helpers';
import {de, enGB, fr, it} from "date-fns/locale";

[["de", de], ["en", enGB], ["fr", fr], ["it", it]].forEach(([locale, localeModule]) =>
  registerLocale(locale, localeModule)
);

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const worker = useSelector(authProfileSelector);
  const authUser = useSelector(authUserSelector);
  const [userImage, setUserImage] = useState('');
  const imageUploadRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const [industries, setIndustries] = useState();
  const [industryOCP, setIndustryOCP] = useState([]);
  const [selectedOCP, setSelectedOCP] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [startDate, setStartDate] = useState(new Date())
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;

  const animatedComponents = makeAnimated();

  const {
    register: registerUserInfoFields,
    setValue: setUserFieldsValue,
    handleSubmit: handleSubmitUserUpdate,
    formState: { errors: userStateErrors }
  } = useForm();

  // fetch potentially updated user Profile
    useEffect(() => {
    dispatch(getWorkerProfile(authUser?.id))
  }, [])

  const {
    register: registerWorkerInfoFields,
    setValue: setWorkerFieldsValue,
    handleSubmit: handleSubmitWorkerUpdate,
    watch: watchWorkerInfoFields,
    control: controlWorkerInfoFields,
    formState: { errors: workerStateErrors },
    reset: resetFormFields
  } = useForm();

  const { fields: educationFields, append: educationAppend, remove: educationRemove } = useFieldArray({ control: controlWorkerInfoFields, name: 'education' });

  const {
    fields: workExperienceFields,
    append: workExperienceAppend,
    remove: workExperienceRemove
  } = useFieldArray({ control: controlWorkerInfoFields, name: 'workExperience' });
  console.log(userImage);
  useEffect(() => {
    const setPredefinedUserValues = () => {
      setUserFieldsValue('name', authUser.name);
      setUserFieldsValue('username', authUser.username);
      setUserFieldsValue('email', authUser.email);
    };

    setPredefinedUserValues();
  }, [authUser.email, authUser.name, authUser.username, setUserFieldsValue]);

  useEffect(() => {
    // console.log('======>>>>>> worker', worker);
    resetFormFields();
    const setPredefinedUserValues = () => {
      setWorkerFieldsValue('state', worker.state);
      setWorkerFieldsValue('city', worker.city);
      setWorkerFieldsValue('address', worker.address);
      setWorkerFieldsValue('mobilePhone', worker.mobilePhone);
      setWorkerFieldsValue('jobTitle', worker.jobTitle);
      setWorkerFieldsValue('jobDescription', worker.jobDescription);
      setWorkerFieldsValue('driverLicences', worker.driverLicences);
      setWorkerFieldsValue('citizenship', worker.citizenship);
      setWorkerFieldsValue('birthdate', formatISODateFromShortDate(worker.birthdate, true));
      setWorkerFieldsValue('desiredJobs', worker.desiredJobs.map(j => j.name).join(', '));
      setWorkerFieldsValue('undesiredJobs', worker.undesiredJobs.map(j => j.name).join(', '));
      setWorkerFieldsValue('rate', worker.rate);
      setWorkerFieldsValue('hourlyRate', (worker.hourlyRate === 0 ? "" : worker.hourlyRate ));
      setWorkerFieldsValue('monthlySalary', (worker.monthlySalary === 0 ? "" : worker.monthlySalary));
      setWorkerFieldsValue('isActive', worker.isActive !== true);

      http.get(`${industryList}?_locale=${language}`).then(res => {
        setIndustries(() =>
          res?.data.map(item => ({
            value: item?.id || '',
            label: item?.name || '',
            locale: item?.locale || '',
            localizations: item?.localizations || []
          }))
        );
        setSelectedOption(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          worker?.industries.forEach(sele => {
            let found;
            for (let i = 0; i < res?.data.length; i++) {
              const industry = res?.data[i];
              if (language !== sele.locale) {
                const temp = industry.localizations.find(locObj => locObj.id === sele.id);
                if (temp) {
                  found = industry;
                  break;
                }
              }
            }
            if (language === sele.locale) {
              unTranslatedSelectedOption.push({ value: sele.id, label: sele.name, locale: sele.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.id, label: found.name, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
      });

      setSelectedLevel(worker?.skillLevel.map(item => ({ value: item?.id || '', label: SkillLevel[item?.level] || '' })));

      (worker?.education || []).forEach(education => {
        educationAppend({
          ...education,
          startDate: formatISODateFromShortDate(education.startDate, true),
          endDate: formatISODateFromShortDate(education.endDate, true),
          ID: education.id
        });
      });

      (worker?.workExperience || []).forEach(workExperience => {
        workExperienceAppend({
          ...workExperience,
          startDate: formatISODateFromShortDate(workExperience.startDate, true),
          endDate: formatISODateFromShortDate(workExperience.endDate, true),
          ID: workExperience.id
        });
      });
      setUserImage(worker?.image?.url ? `${BE_URL}${worker.image.url}` : DefaultProfileImage);
    };

    setPredefinedUserValues();

    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWorkerFieldsValue, worker, educationAppend, BE_URL, language]);

  const imageWatch = watchWorkerInfoFields('profileImage');
  const birthdateWatch = watchWorkerInfoFields('birthdate');

  const workExperienceWatch = watchWorkerInfoFields('workExperience');
  const educationWatch = watchWorkerInfoFields('education');

  const handleUserUpdate = payload => {
    dispatch(updateUser({ userId: authUser.id, payload }));
  };

  const fetchIndustriesOcp = async () => {
    if (selectedOption.length > 0) {
      let filter = '';
      selectedOption.length > 0 &&
        selectedOption.forEach((item, index) => {
          if (index === 0) {
            filter = `?industry=${item.value}&_locale=${language}`;
          } else {
            filter = `${filter}&industry=${item.value}`;
          }
        });
      await http.get(industryOccupations + filter).then(res => {
        const ocpList = res?.data.map(item => ({
          value: item?.id || '',
          label: item?.name || '',
          locale: item?.locale || '',
          localizations: item?.localizations || []
        }));

        setSelectedOCP(() => {
          const translatedSelectedOption = [];
          const unTranslatedSelectedOption = [];
          worker?.industy_occupations.forEach(sele => {
            let found;
            for (let i = 0; i < ocpList.length; i++) {
              const industryOcc = ocpList[i];
              if (language !== sele.locale) {
                const temp = industryOcc.localizations.find(locObj => locObj.id === sele.id);
                if (temp) {
                  found = industryOcc;
                  break;
                }
              }
            }
            if (language === sele.locale) {
              unTranslatedSelectedOption.push({ value: sele.id, label: sele.name, locale: sele.locale });
            }
            if (found) {
              translatedSelectedOption.push({ value: found.value, label: found.label, locale: found.locale });
            }
          });
          return translatedSelectedOption.length ? translatedSelectedOption : unTranslatedSelectedOption;
        });
        setIndustryOCP(ocpList);
      });
    } else {
      setIndustryOCP([]);
    }
  };

  useLayoutEffect(() => {
    if (selectedOption && selectedOption.length <= 0) {
      setSelectedOCP([]);
    }
    fetchIndustriesOcp();
  }, [selectedOption]);

  const handleWorkerUpdate = async updatedFormData => {
    const data = { ...updatedFormData };
    data.isActive = data.isActive !== true;

    // Must be normalized since useForm removes id prop from objects
    data.bithdate = formatShortDateFromISODate(data.birthdate);

    const skillLevelArr = [];
    selectedLevel.forEach((level, i) => {
      if (selectedOCP[i] && selectedOCP[i].value) {
        skillLevelArr.push({
          industy_occupation: selectedOCP[i].value,
          level: getPropByValue(level)
        });
      }
    });

    data.skillLevel = skillLevelArr;

    data.industries = selectedOption.map(selected => {
      const found = industries.find(ind => ind.value === selected.value);
      if (found) {
        return found.value;
      }
    });

    data.industy_occupations = selectedOCP.map(selected => {
      const found = industryOCP.find(occ => occ.value === selected.value);
      if (found) {
        return found.value;
      }
    });

    data.education = (data?.education || []).map(education => {
      const cleanEducation = {
        ...education,
        startDate: formatShortDateFromISODate(education.startDate),
        endDate: formatShortDateFromISODate(education.endDate)
      };
      if (cleanEducation.ID) {
        cleanEducation.id = cleanEducation.ID;
        delete cleanEducation.ID;
      }
      return cleanEducation;
    });
    data.workExperience = (data?.workExperience || []).map(workExperience => {
      const cleanWorkExperience = {
        ...workExperience,
        startDate: formatShortDateFromISODate(workExperience.startDate),
        endDate: formatShortDateFromISODate(workExperience.endDate)
      };
      if (cleanWorkExperience.ID) {
        cleanWorkExperience.id = cleanWorkExperience.ID;
        delete cleanWorkExperience.ID;
      }
      return cleanWorkExperience;
    });

    data.desiredJobs = (data?.desiredJobs?.split(',') || []).map(job => {
      const alreadyExist = worker.desiredJobs.find(desiredJob => desiredJob.name.toLowerCase().trim() === job.toLowerCase().trim());
      if (alreadyExist) {
        return alreadyExist;
      }
      return { name: job.trim() };
    });
    data.undesiredJobs = (data?.undesiredJobs?.split(',') || []).map(job => {
      const alreadyExist = worker.undesiredJobs.find(desiredJob => desiredJob.name.toLowerCase().trim() === job.toLowerCase().trim());
      if (alreadyExist) {
        return alreadyExist;
      }
      return { name: job.trim() };
    });

    const formData = new FormData();
    console.log(formData);
    data.locale = language || 'en';
    // If image source is changed we need to PUT data as FormData otherwise pure object is allowed
    if (userImage !== `${BE_URL}${worker?.image?.url}` && userImage !== DefaultProfileImage) {
      formData.append('data', JSON.stringify(data));
      formData.append(`files.image`, imageWatch[0], imageWatch[0].name);
      const isWorkerUpdated = await dispatch(updateWorker({ workerId: worker.id, payload: formData }));
      // alert("user is updated isWorkerUpdated ===>>>", isWorkerUpdated)
      if (isWorkerUpdated) {
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.updateProfile.success')
          })
        );
      }
    } else {
      const isWorkerUpdated = await dispatch(updateWorker({ workerId: worker.id, payload: data }));
    //  alert("user is updated isWorkerUpdated  BOTTOM===>>>", isWorkerUpdated)
      if (isWorkerUpdated) {
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.updateProfile.success')
          })
        );
      }
    }
  };

  const openFileBrowser = () => {
    imageUploadRef.current.children[0].click();
  };

  useEffect(() => {
    if (imageWatch && imageWatch.length > 0) {
      const file = imageWatch[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => setUserImage(reader.result);
    }
  }, [imageWatch]);

  

  const setDateInForm = (date, formFieldName) => {
    setWorkerFieldsValue(formFieldName, date);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    handleSubmitWorkerUpdate(handleWorkerUpdate)();
    handleSubmitUserUpdate(handleUserUpdate)();
  }

  console.log("here is language ==>>", language)



  
//  const StyledDatePickerField = styled(DatePicker)`
//     font-size: 16px;
//     padding: 15px 10px;
//     width: 100%;
//     border: 1px solid ${INPUT_BORDER};
//     border-radius: 5px;
//     height: 50px;
//   `;

const customStyles = {
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: '#f95050'
    }
  }),
  control: (prevStyle, state) => ({
    ...prevStyle,
    border: `1px solid ${INPUT_BORDER}`,
    boxShadow: state.isFocused ? 0 : 0,
    outline: 'none',
    ':hover': { border: `1px solid ${INPUT_BORDER}` }
  })
};

  const userUpdateInfo = () => (
    <UserUpdateWrapper>
      <h4>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.updateUserSectionTitle')}</h4>
      <form onSubmit={handleFormSubmit}>
        <Row className="user-fields-container" style={{flexDirection: "column"}}>
          <Col sm={12} md={4} style={{marginBottom: "20px"}}>
            <StyledInputField placeholder={t('FORM.fields.firstAndLastName')} type="text" {...registerUserInfoFields('name')} />
            {userStateErrors.name && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          {/* <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.username')} type="text" {...registerUserInfoFields('username')} />
            {userStateErrors.username && <span>{t('FORM.validation.requiredField')}</span>}
          </Col> */}
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.email')} type="email" {...registerUserInfoFields('email')} />
            {userStateErrors.username && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>

        <SubmitActionWrapper>
          <CustomButton type="submit" text={t('BUTTON.updateBasicInfo')} customTextColor={WHITE} />
        </SubmitActionWrapper>
      </form>
    </UserUpdateWrapper>
  );

  // eslint-disable-next-line complexity
  const workerUpdateInfo = () => (
    <WorkerUpdateWrapper>
      <h4>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.updateWorkerSectionTitle')}</h4>
      <form onSubmit={handleFormSubmit}>
        <Col className="worker-image-container" sm={12} md={6} xl={3}>
          <div className="image-preview">
            <img src={userImage} alt="profile" />
            <div className="image-preview-hover" onClick={openFileBrowser}>
              <FontAwesomeIcon icon="cloud-upload-alt" size="4x" color="rgba(249, 80, 80, 0.8)" />
            </div>
          </div>
          <div ref={imageUploadRef} className="image-ref">
            <input type="file" {...registerWorkerInfoFields('profileImage')} />
          </div>
        </Col>
        <Row className="worker-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...registerWorkerInfoFields('address')} />
            {workerStateErrors.address && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.postcode')} type="text" {...registerWorkerInfoFields('rate')} />
            {workerStateErrors.rate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...registerWorkerInfoFields('city')} />
            {workerStateErrors.city && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>{' '}
        </Row>
        <Row className="worker-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('FORM.fields.country')} type="text" {...registerWorkerInfoFields('state')} />
            {workerStateErrors.state && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row className="worker-fields-container">
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')} type="text" {...registerWorkerInfoFields('citizenship')} />
            {workerStateErrors.citizenship && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            <StyledInputField placeholder={t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')} type="text" {...registerWorkerInfoFields('driverLicences')} />
            {workerStateErrors.driverLicences && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={4}>
            {/* <StyledDatePickerField> */}
            <StyledDatePickerField
              dateFormat="dd.MM.yyyy"
              placeholderText={t('LANDING_PAGE.CONTACT_US.placeholders.dateOfBirth')}
              selected={birthdateWatch}
              onChange={date => setDateInForm( date, 'birthdate')}
              // selected={startDate}
              // onChange={(date) => setStartDate(date)}
              // readOnly={false}
              locale={language}
            />
            {workerStateErrors.birthdate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Col className="worker-fields-container" sm={12} md={6} xl={4}>
          <StyledInputField placeholder={t('FORM.fields.mobilePhone')} type="text" {...registerWorkerInfoFields('mobilePhone')} />
          {workerStateErrors.mobilePhone && <span>{t('FORM.validation.requiredField')}</span>}
        </Col>
        <Col className="worker-fields-container" sm={12} md={6} xl={4}>
          <StyledInputField placeholder={t('FORM.fields.jobTitle')} type="text" {...registerWorkerInfoFields('jobTitle')} />
          {workerStateErrors.jobTitle && <span>{t('FORM.validation.requiredField')}</span>}
        </Col>
        <Col className="worker-fields-container" sm={12}>
          <StyledTextareaField placeholder={t('FORM.fields.aboutMe')} type="text" rows="5" cols="75" {...registerWorkerInfoFields('jobDescription')} />
          {workerStateErrors.jobDescription && <span>{t('FORM.validation.requiredField')}</span>}
        </Col>
        <Row className="worker-fields-container ">
          <Col sm={12} md={8} xl={6} className="mb-10">
            <StyledInputField placeholder={t('FORM.fields.hourlyRate')} type="number" min={1} max={99999999} {...registerWorkerInfoFields('hourlyRate')} />
            {workerStateErrors.hourlyRate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={8} xl={6} className="mb-10">
            <StyledInputField placeholder={t('FORM.fields.monthlySalary')} type="number" min={1} max={99999999} {...registerWorkerInfoFields('monthlySalary')} />
            {workerStateErrors.monthlySalary && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
        </Row>
        <Row className="worker-fields-container ">
          {/* <Col sm={12} md={8} xl={6} className="mb-10" style={{ marginBottom: '10px' }}>
            <StyledInputField placeholder={t('FORM.fields.postcode')} type="text" {...registerWorkerInfoFields('rate')} />
            {workerStateErrors.rate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col> */}
          {/* <Col sm={12} md={8} xl={6} className="mb-10">
            <StyledInputField
              placeholder={t('FORM.fields.desiredJobs')}
              type="text"
              {...registerWorkerInfoFields('desiredJobs')}
            />
            {workerStateErrors.rate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col>
          <Col sm={12} md={8} xl={6} className="mb-10">
            <StyledInputField
              placeholder={t('FORM.fields.undesiredJobs')}
              type="text"
              {...registerWorkerInfoFields('undesiredJobs')}
            />
            {workerStateErrors.rate && <span>{t('FORM.validation.requiredField')}</span>}
          </Col> */}
          {/* <Col sm={12} md={8} xl={6} className="mb-10" style={{border: "3px solid orange"}} > */}

            <div className="check-box"><StyledRadioButtonCol>
              <label type="checkbox" style={{ fontSize: '12px' }}>{t('EMPLOYEE_PROFILE.EDIT_PROFILE.deactivateButtonTitle')}</label>
              </StyledRadioButtonCol>
              <StyledRadio2ButtonCol>
              <input type="checkbox" {...registerWorkerInfoFields('isActive')} /> </StyledRadio2ButtonCol>
            </div>
            {workerStateErrors.rate && <span>{t('FORM.validation.requiredField')}</span>}
           
          {/* </Col> */}
        </Row>
        <Col>
          <Row>
            <Col sm={12} md={8} xl={6} className="mb-10">
              <MultiSelector
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder="Select111 Industries"
                value={selectedOption}
                onChange={setSelectedOption}
                options={industries}
                isOptionDisabled={() => selectedOption.length >= 2}
                styles={{
                  ...customStyles, // Include custom animation styles
                  menuList: base => ({
                    ...base,
                    maxHeight: '190px' // your desired height
                  })
                }}
              />
            </Col>
            <Col>
              {selectedOption?.length > 0 && (
                <MultiSelector
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  placeholder="Select industry occupations"
                  value={selectedOCP}
                  onChange={setSelectedOCP}
                  options={industryOCP}
                  styles={{
                    ...customStyles, // Include custom animation styles
                    menuList: base => ({
                      ...base,
                      maxHeight: '190px' // your desired height
                    })
                  }}
                  isOptionDisabled={() => selectedOCP.length >= 2}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>{selectedOCP.length > 0 && <CustomTable selectedOCP={selectedOCP} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />}</Col>
          </Row>
        </Col>
        <SectionTitle>{`${t('FORM.fields.educationTitle')}`}</SectionTitle>
        {educationFields.map((education, index) => (
          <Row className="worker-fields-container" key={`education-edit-${education.id}`}>
            <Col sm={12} md={6} xl={3}>
              <StyledInputField placeholder={t('FORM.fields.education.type')} type="text" {...registerWorkerInfoFields(`education.${index}.type`)}>
              
              </StyledInputField>
            </Col>
            {/* <Col sm={12} md={6} xl={3}>
              <StyledSelectField placeholder={t('FORM.fields.education.type')} type="text" {...registerWorkerInfoFields(`education.${index}.type`)}>
                <option value="SCHOOL">School</option>
                <option value="FACULTY">Faculty</option>
                <option value="SEMINAR">Seminar</option>
                <option value="COURSE">Course</option>
                <option value="TRAINING">Training</option>
              </StyledSelectField>
            </Col> */}
            <Col sm={12} md={6} xl={3}>
              <StyledInputField placeholder={t('FORM.fields.education.name')} type="text" {...registerWorkerInfoFields(`education.${index}.name`)} />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <StyledDatePickerField locale={language} selected={educationWatch[index]?.startDate} dateFormat="dd.MM.yyyy" onChange={date => setWorkerFieldsValue(`education.${index}.startDate`, date)} />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <StyledDatePickerField locale={language} selected={educationWatch[index]?.endDate} dateFormat="dd.MM.yyyy" onChange={date => setWorkerFieldsValue(`education.${index}.endDate`, date)} />
            </Col>
            <Col sm={12} md={1}>
              <CustomButton
                className="remove-row-button"
                type="button"
                icon="trash-alt"
                customButtonColor={APP_RED}
                hasIcon
                iconColor={WHITE}
                handleOnClick={() => educationRemove(index)}
              />
            </Col>
          </Row>
        ))}
        <Col className="worker-education-add" sm={12} md={6} xl={3}>
          <FontAwesomeIcon className="add-button" icon="plus-circle" size="2x" color={APP_RED} onClick={() => educationAppend({})} />
        </Col>
        <SectionTitle>{`${t('FORM.fields.workExperienceTitle')}`}</SectionTitle>
        {workExperienceFields.map((workExperience, index) => (
          <Row className="worker-fields-container" key={`education-edit-${workExperience.id}`}>
            <Col sm={12} md={6} xl={3}>
              <StyledInputField
                placeholder={t('FORM.fields.workExperience.companyName')}
                type="text"
                {...registerWorkerInfoFields(`workExperience.${index}.companyName`)}
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <StyledInputField placeholder={t('FORM.fields.workExperience.position')} type="text" {...registerWorkerInfoFields(`workExperience.${index}.position`)} />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <StyledDatePickerField
              locale={language}
              dateFormat="dd.MM.yyyy"
                selected={workExperienceWatch[index]?.startDate}
                onChange={date => setWorkerFieldsValue(`workExperience.${index}.startDate`, date)}
              />
            </Col>
            <Col sm={12} md={6} xl={3}>
              <StyledDatePickerField 
              locale={language}
              selected={workExperienceWatch[index]?.endDate} dateFormat="dd.MM.yyyy" onChange={date => setWorkerFieldsValue(`workExperience.${index}.endDate`, date)} />
            </Col>
            <Col sm={12} md={1}>
              <CustomButton
                className="remove-row-button"
                type="button"
                icon="trash-alt"
                customButtonColor={APP_RED}
                hasIcon
                iconColor={WHITE}
                handleOnClick={() => workExperienceRemove(index)}
              />
            </Col>
          </Row>
        ))}
        <Col className="worker-education-add" sm={12} md={6} xl={3}>
          <FontAwesomeIcon className="add-button" icon="plus-circle" size="2x" color={APP_RED} onClick={() => workExperienceAppend({})} />
        </Col>
        <SubmitActionWrapper sm={12} md={6} xl={3}>
          <CustomButton type="submit" text={t('BUTTON.updateWorkersInfo')} customTextColor={WHITE} />
        </SubmitActionWrapper>
      </form>
    </WorkerUpdateWrapper>
  );

  return (
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('EMPLOYEE_PROFILE.EDIT_PROFILE.title')} role={USER_TYPE.WORKER}>
      <StyledContainer fluid>
        {userUpdateInfo()}
        {workerUpdateInfo()}
      </StyledContainer>
    </ProfilePageContainer>
  );
};

export default EditProfile;
