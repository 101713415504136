export const SkillLevel = {
    level_A: 'CH learned',
    level_B: 'Learned abroad',
    level_C: 'Semi-skilled',
    level_D: 'Unskilled',
};

export const SkillLevelV2 = {
    level_A: {
      en: 'CH learned',
      de: 'CH gelernt',
      fr: 'Appris en Suisse',
      it: 'Appreso in Svizzera',
    },
    level_B: {
      en: 'Learned abroad',
      de: 'Im Ausland gelernt',
      fr: "Appris à l'étranger",
      it: "Appreso all'estero",
    },
    level_C: {
      en: 'Semi-skilled',
      de: 'Semiqualifiziert',
      fr: 'Semi-qualifié',
      it: 'Semi-qualificato',
    },
    level_D: {
      en: 'Unskilled',
      de: 'Unqualifiziert',
      fr: 'Non qualifié',
      it: 'Non qualificato',
    },
  };
