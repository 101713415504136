import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import Sanitizer from 'components/Sanitizer';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { useWindowDimensions } from 'utils/helpers';

import Filters from 'components/Filters';
import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import CustomMapCard from 'components/CustomMapCard';
import CustomButton from 'components/CustomButtonVacancies';
import CustomCard from 'components/CustomCardVacancies';
import { authProfileSelector, favoritesProfileSelector } from 'redux/modules/auth/selectors';
import { openDialog } from 'redux/modules/dialogs/reducer';
import { updateFavoritesCompany } from 'redux/modules/auth/asyncThunks';
import PreviewDialog from 'components/PreviewDialog';
import { workersSelector } from 'redux/modules/workers/selectors';
import { formatISODate, formatShortDate } from '../../../utils/helpers';
import { formatISODateFromShortDate, formatShortDateFromISODate, formatDate } from '../../../utils/helpers';
import {
  StyledVacanciesContentWrapper,
  StyledVacanciesInfoWrapper,
  StyledCompletedVacanciesColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledVacanciesCardWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledTabs,
  StyledTab,
  StyledActions,
  StyledProjectInfoDetails,
  StyledProjectInfo,
  StyledProjectInfoLeftSide,
  StyledProjectRate,
  StyledMover,
  StyledTabsWrapper,
  StyledProjectsHeader,
  StyledProjectsCardsWrapper,
  StyledPreviewDetails,
  StyledTitleColumn,
  StyledPreviewDialogFooterInfo,
  StyledPreviewDialogFooter
} from './styles';
import { jobsSelector } from '../../../redux/modules/jobs/selectors';
import { fetchJobsList, updateJob, deleteJob } from '../../../redux/modules/jobs/asyncThunks';
import { APP_RED, DARK_BUTTON, WHITE } from '../../../constants/colors';
import {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper
} from '../Projects/styles';
import { StyledColWrapper } from '../Search/styles';
import { element } from 'prop-types';

// eslint-disable-next-line complexity
const Vacancies = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const jobList = useSelector(jobsSelector);
  const favorites = useSelector(favoritesProfileSelector);
  const history = useHistory();
  const [previewDetails, setPreviewDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const company = useSelector(authProfileSelector);
  const [previewWorker, setPreviewWorker] = useState(null);
  const workersList = useSelector(workersSelector);
  const [activeCard, setActiveCard] = useState(0);
  const projectsTabsRef = useRef(null);
  // const [width, setWidth] = useState(window.innerWidth);
  const [tab, setTab] = useState(true);
  const [dateMonthY, setDateMonthY] = useState();
  const dispatch = useDispatch();
  const params = {
    _sort: 'status:DESC',
    _limit: 999
  };
  const SCROLL_AMOUNT = 200;
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  console.log('here are jobByCompany', jobList);
  let jobsByCompany = jobList;

  // console.log("here is allJobs +++====>>>", jobList)

  const fallbackProfileImage = DefaultProfileImage || '';

  const scrollLeft = event => {
    event.stopPropagation();
    event.preventDefault();

    if (projectsTabsRef && projectsTabsRef.current) {
      if (projectsTabsRef.current.scrollLeft > 0) {
        projectsTabsRef.current.scrollTo(projectsTabsRef.current.scrollLeft < SCROLL_AMOUNT ? 0 : projectsTabsRef.current.scrollLeft - SCROLL_AMOUNT, 0);
      }
    }
  };

  const scrollRight = event => {
    event.stopPropagation();
    event.preventDefault();

    if (projectsTabsRef && projectsTabsRef.current) {
      if (projectsTabsRef.current.scrollLeft < projectsTabsRef.current.scrollWidth) {
        projectsTabsRef.current.scrollTo(
          projectsTabsRef.current.scrollLeft + SCROLL_AMOUNT > projectsTabsRef.current.scrollWidth
            ? projectsTabsRef.current.scrollWidth
            : projectsTabsRef.current.scrollLeft + SCROLL_AMOUNT,
          0
        );
      }
    }
  };

  console.log('here is jobList', jobList);
  // const filteredJobList = jobList.filter(job => job.activated === tab);
  // const jobList = allJobs.gimmeJobs
  // console.log('here is filteredJoblist ====>>>>', filteredJobList);
  // const chooseProject = (event, index, c) => {
  //   event?.target?.scrollIntoView({ behavior: 'smooth' });
  //   setActiveCard(index);
  // };

  const goToEditVacancy = idOfJob => {
    // console.log('=====>>>> here is filteeredjobslist', filteredJobList[idOfJob]);

    history.push(`/vancancies/edit/${idOfJob}`);
  };

  const goToCreateVacancy = () => {
    history.push(`/vancancies/new`);
  };

  const handleOpenWorkerDetails = worker => {
    const employeeFullProfile = (workersList || []).find(employee => employee.id === worker.id);
    setPreviewWorker(employeeFullProfile || worker);
    setPreviewDetails({
      originalData: worker,
      title: worker?.user?.name,
      subtitle: worker?.jobTitle,
      address: worker?.jobDescription,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim(), 400));

  const filterBySearch = application =>
    application.address?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.address?.country?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.postCode?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.postCode?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.companyName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.contactEmail?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.company?.mobilePhone?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.description?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    application.title?.toLowerCase()?.includes(searchTerm?.toLowerCase());

  // const handleUpdateJob = async (jobId, isActivated) => {
  //   try {
  //     console.log('I have been clicked ===========');
  //     await dispatch(updateJob({ jobId, payload: { activated: !isActivated } }));
  //     console.log('========Im dispatching updatejob========');
  //     await dispatch(fetchJobsList({ ...params }));
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const handleUpdateJob = async (jobId, isActivated) => {
    try {
      // console.log('I have been clicked ===========');
      const updatedJobData = await dispatch(updateJob({ jobId, payload: { activated: !isActivated } }));
      // console.log('========Im dispatching updatejob========', updatedJobData);
      await dispatch(fetchJobsList({ ...params }));
    } catch (e) {
      console.error(e);
    }
  };
  const handleDeleteJob = async jobId => {
    try {
      await dispatch(deleteJob({ jobId }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(fetchJobsList({ ...params }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(async () => {
  //   console.log('I am firing a dispatch action to reducer jobsList');
  //   await dispatch(fetchJobsByCompany({ gimmeJobs: true }));
  // }, []);

  // console.log(
  //   'here is jobsByCompany',
  //   jobsByCompany.map(el => el)
  // );

  // const changeDate = (oldDate) => {

  // let dateComponents = oldDate && oldDate !== null && oldDate.split('-');

  // return dateComponents[2] + '-' + dateComponents[1] + '-' + dateComponents[0];

  // }

  // const handleOpenJobDetails = job => {

  //   let dateString = job?.deadline;

  //   let dateComponents = dateString && dateString.split('-');

  //   let dayMonthYearFormat = dateComponents[2] + '-' + dateComponents[1] + '-' + dateComponents[0];

  //   setDateMonthY(dayMonthYearFormat);
  //   setPreviewDetails({
  //     originalData: job,
  //     title: job?.title,
  //     // children : '<div style={display: flex}><h3> CHF 20</h3> </div>',
  //     rate: job?.rate,
  //     monthlyRate: job?.monthlyPayroll,
  //     deadline: dayMonthYearFormat,
  //     description: job?.description,
  //     contact: job?.company?.contactEmail,
  //     address: `${job?.address}, ${job?.city}, ${job.state}`,
  //     logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null
  //   });
  //   dispatch(
  //     openDialog({
  //       dialogName: 'previewDialog'
  //     })
  //   );
  // };

  const handleOpenJobDetails = job => {
    console.log('here is job in vacancies ===>>>', job);
    let dateString = job?.deadline;
    let dateComponents = dateString && dateString.split('-');

    let dayMonthYearFormat = '';
    if (dateComponents && dateComponents.length >= 3) {
      dayMonthYearFormat = dateComponents[2] + '-' + dateComponents[1] + '-' + dateComponents[0];
    }

    setDateMonthY(dayMonthYearFormat);
    setPreviewDetails({
      originalData: job,
      title: job?.title,
      company: job?.company?.companyName,
      rate: job?.rate,
      monthlyRate: job?.monthlyPayroll,
      deadline: job?.deadline,
      jobStartPlan: job?.startP,
      descriptionCompany: job?.descriptionCompany,
      contactInformation: job?.contactInformation,
      description: job?.description,
      contact: job?.company?.contactEmail,
      address: `${job?.address}, ${job?.city}, ${job?.state}`,
      logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,
      mobilePhone: job?.company?.mobilePhone,
      phone: job?.company?.phoneNumber,
      name: job?.company?.name
      // originalData: job,
      // rate: job?.rate,
      // deadline: dayMonthYearFormat,
      // description: job?.description,
      // contact: job?.company?.contactEmail,
      // address: `${job?.address}, ${job?.city}, ${job.state}`,
      // logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const footerMinHeightVar = {
    minHeight: '7rem'
  };

  // console.log('here is job in previewDetails.address ===>>>', previewDetails.address);

  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.vacancies.pageTitle')} role={USER_TYPE.COMPANY}>
      <StyledVacanciesContentWrapper>
     {width >= 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
        <StyledVacanciesInfoWrapper>
          <StyledCompletedVacanciesColWrapper xs={12}>
          {width >= 900 ?  <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === true} onClick={() => setTab(true)}>
                {t('SIDE_NAVIGATION.vacancies.activeVacancies')} {(jobsByCompany && jobsByCompany.filter(a => a.activated === true).length) || 0}
              </StyledTitleBox>
              <StyledTitleBox active={tab === false} onClick={() => setTab(false)}>
                {t('SIDE_NAVIGATION.vacancies.inActiveVacancies')} {(jobsByCompany && jobsByCompany.filter(a => a.activated === false).length) || 0}
              </StyledTitleBox>
              {/* <StyledTitleColumn> */}
              <CustomButton
                customradiustopleft="6px"
                customradiustopright="6px"
                text={t('SIDE_NAVIGATION.vacancies.newVacancy')}
                customButtonColor={APP_RED}
                customHeight="50px"
                customWidth="220px"
                customTextColor={WHITE}
                hasIcon
                icon="plus-circle"
                iconFontSize="15px"
                customFontSize="14px"
                handleOnClick={goToCreateVacancy}
              />
              {/* </StyledTitleColumn> */}
            </StyledTitleBoxWrapper> : ""}
            <StyledVacanciesCardWrapper>
            {width < 900 ? <Filters placeholder={t('SIDE_NAVIGATION.offers.searchOffers')} onInputChange={debouncedOnInputChange} />: ""}
            {width < 900 ?  <StyledTitleBoxWrapper>
              <StyledTitleBox active={tab === true} onClick={() => setTab(true)}>
                {t('SIDE_NAVIGATION.vacancies.activeVacancies')} {(jobsByCompany && jobsByCompany.filter(a => a.activated === true).length) || 0}
              </StyledTitleBox>
              <StyledTitleBox active={tab === false} onClick={() => setTab(false)}>
                {t('SIDE_NAVIGATION.vacancies.inActiveVacancies')} {(jobsByCompany && jobsByCompany.filter(a => a.activated === false).length) || 0}
              </StyledTitleBox>
              {/* <StyledTitleColumn> */}
              <CustomButton
                customradiustopleft="6px"
                customradiustopright="6px"
                text={t('SIDE_NAVIGATION.vacancies.newVacancy')}
                customButtonColor={APP_RED}
                customHeight="50px"
                customWidth="220px"
                customTextColor={WHITE}
                hasIcon
                icon="plus-circle"
                iconFontSize="15px"
                customFontSize="14px"
                handleOnClick={goToCreateVacancy}
              />
              {/* </StyledTitleColumn> */}
            </StyledTitleBoxWrapper> : ""}
              <StyledSearchResultContent sm={12} md={2} xl={4}>
                {jobsByCompany &&
                  jobsByCompany
                    .filter(job => job?.activated === tab)
                    .filter(filterBySearch)
                    .map(job => (
                      <StyledCardWrapper key={job?.id} sm={12} md={6} lg={4} xl={3}>
                        <CustomCard
                          footerMinHeight={footerMinHeightVar}
                          profileImage={job.company?.image?.url ? `${BE_URL}${job.company?.image?.url}` : fallbackProfileImage}
                          sectionTitle={job?.title}
                          sectionSubtitle={job.company?.companyName}
                          headerDescriptionFirst={job?.city}
                          headerDescriptionSecond={job?.state}
                          footerDescription={job?.description}
                          deadline={job?.deadline && formatISODate(new Date(job?.deadline)) || null}
                          // profileClick={$event => handleOpenProfileDetails($event, job?.company)}

                          onFavoriteClick={$event => favoriteClick($event, job?.company?.id)}
                          click={() => handleOpenJobDetails(job)}
                          upperButtonText={job?.activated ? t('SIDE_NAVIGATION.vacancies.deactivate') : t('SIDE_NAVIGATION.vacancies.activate')}
                          lowerButtonText={t('SIDE_NAVIGATION.vacancies.delete')}
                          editButtonText={t('SIDE_NAVIGATION.vacancies.edit')}
                          handleActiveDeactivateJob={() => handleUpdateJob(job.id, job.activated)}
                          handleDeleteJob={() => handleDeleteJob(job.id)}
                          handleEditJob={() => goToEditVacancy(job.id)}
                        />
                      </StyledCardWrapper>
                      // <StyledCardWrapper key={`job-${job.id}`} sm={12}>
                      //   <CustomMapCard
                      //     status={job.status}
                      //     activated={job.activated}
                      //     address={`${job.address}, ${job.city}, ${job.state}`}
                      //     date={job.created_at}
                      //     title={job.title}
                      //     subtitle={`${job.address}, ${job.city}, ${job.state}`}
                      //     upperButtonText={job.activated ? t('SIDE_NAVIGATION.vacancies.deactivate') : t('SIDE_NAVIGATION.vacancies.activate')}
                      //     lowerButtonText={t('SIDE_NAVIGATION.vacancies.delete')}
                      //     handleActiveDeactivateJob={() => handleUpdateJob(job.id, job.activated)}
                      //     handleDeleteJob={() => handleDeleteJob(job.id)}
                      //   />
                      // </StyledCardWrapper>
                    ))}
              </StyledSearchResultContent>
            </StyledVacanciesCardWrapper>
          </StyledCompletedVacanciesColWrapper>
        </StyledVacanciesInfoWrapper>
      </StyledVacanciesContentWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledPreviewDetails>
          {previewDetails?.descriptionCompany ? (
            <>
              <strong>
                <h4>{t('FORM.fields.aboutUs')}</h4>{' '}
              </strong>
              <Sanitizer review={previewDetails?.descriptionCompany} />{' '}
            </>
          ) : null}
          {previewDetails?.description ? (
            <>
              <strong>
                <h4>{t('FORM.fields.jobDescription')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.description} />{' '}
            </>
          ) : null}
          {previewDetails?.contactInformation ? (
            <>
              <strong>
                <h4>{t('FORM.fields.descriptionContact')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.contactInformation} />{' '}
            </>
          ) : null}
          <Row style={{ paddingBottom: '20px' }}>
            {previewDetails?.rate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.rate')}: </strong>CHF {previewDetails?.rate}.-
              </Col>
            )}
            {previewDetails?.monthlyRate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.ratePerMonth')}:</strong> CHF {previewDetails?.monthlyRate}.-
              </Col>
            )}
            {previewDetails?.deadline && formatDate(new Date(previewDetails?.deadline)) !== ('01/01/1970') ? (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.startDate')}:</strong> {formatDate(new Date(previewDetails?.deadline))}
              </Col>
            ): ""}
                 {previewDetails?.jobStartPlan && (
              <Col sm={12} md={12} xl={12} style={{ paddingBottom: '10px' }}>
                <strong>{t('OFFERCARD.startDate')}:</strong> {t(`FORM.fields.${previewDetails?.jobStartPlan}`)}
              </Col>
            )}
            {previewDetails?.address && (
              <div style={{ paddingTop: '10px' }}>
                <h4>{t('FORM.fields.address')}</h4>
                <StyledPreviewDialogFooterInfo>{previewDetails?.address}</StyledPreviewDialogFooterInfo>
              </div>
            )}
          </Row>
        </StyledPreviewDetails>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Vacancies, 'Vacancies');
