import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { formatDate } from 'utils/helpers';

// import { workersSelector } from 'redux/modules/workers/selectors';
import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import CustomCard from 'components/CustomCarSearch';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { USER_TYPE } from 'constants/userTypes';

import { StyledFavoritesContentWrapper, StyledFavoritesCardsWrapper, StyledCardWrapper } from './styles';
import { favoritesProfileSelector, authProfileSelector } from '../../../redux/modules/auth/selectors';
import { openDialog } from '../../../redux/modules/dialogs/reducer';
import PreviewDialog from '../../../components/PreviewDialog';
import { countWorkers, fetchWorkersList } from '../../../redux/modules/workers/asyncThunks';
import { countWorkersSelector, workersSelector } from '../../../redux/modules/workers/selectors';
import { updateFavoritesCompany } from '../../../redux/modules/auth/asyncThunks';

import {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper
} from '../Projects/styles';
import { StyledColWrapper, StyledPreviewDialogFooterInfo } from '../Search/styles';


const Favorites = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const workersList = useSelector(workersSelector);
  const favorites = useSelector(favoritesProfileSelector);
  const company = useSelector(authProfileSelector);
  const dispatch = useDispatch();
  // const workersListSelector = useSelector(workersSelector);
  // const workersCountSelector = useSelector(countWorkersSelector);
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  const PER_PAGE = 500;
  const defaultParams = {
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
    // isActive: false,
  };

  useEffect(() => {
    dispatch(fetchWorkersList(defaultParams));
    dispatch(countWorkers());
  }, []);

  const handleOpenWorkerDetails = worker => {
    const employeeFullProfile = (workersList || []).find(employee => employee.id === worker.id);
    // console.log('here is workersList in handleOpenWorkerDetails ', workersList);
    console.log('here is employeeFullProfile====>>>', employeeFullProfile);
    setPreviewWorker({
      originalData: worker,
      title: worker?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.jobTitle,
      workerName: worker?.name,
      workerStreet: worker?.address,
      workerCity: worker?.city,
      workerPostcode: worker?.rate,
      workerCountry: worker?.state,
      workerEmail: worker?.email,
      workerCitizenship: worker?.citizenship,
      workerBirthdate: worker?.birthdate,
      workerDriverLicenses: worker?.driverLicences,
      workerHourlyRate: worker?.hourlyRate,
      workerMonthlyRate: worker?.monthlySalary,
      workerMobilePhone: worker?.mobilePhone,
      workerBio: worker?.jobDescription,
      workerFiles: employeeFullProfile?.files,
      workerEducation: employeeFullProfile?.education,
      workerExperience: employeeFullProfile?.workExperience,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    setPreviewDetails({
      originalData: worker,
      workerJobTitle: worker?.jobTitle,
      title: employeeFullProfile?.name,
      subtitle: worker?.jobTitle,
      address: worker?.jobDescription,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  // console.log('here is previewWorker in Favs====>>>', previewWorker);

  const footerMinHeightVar = {
    minHeight: '6rem'
  };

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  // console.log('here is favorites ===>>>', favorites);
  // const allThem =  favorites.map((el) => el).find(user => user?.id === employeeFullProfile?.id)
  // console.log("alle hier ====>>>>", employeeFullProfile?.name )
  // console.log("here is everything", favorites.map((el) => el).find(user => user?.id === employeeFullProfile?.id ))
  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.favorites.pageTitle')} role={USER_TYPE.COMPANY}>
      <StyledFavoritesContentWrapper>
        <StyledFavoritesCardsWrapper>
          {favorites.map(favorite => (
            <StyledCardWrapper key={`favorite-${favorite.id}`} sm={12} md={6} xl={4}>
              <CustomCard
                footerMinHeight={footerMinHeightVar}
                profileImage={favorite.image?.url ? `${BE_URL}${favorite.image.url}` : DefaultProfileImage}
                sectionTitle={favorite?.user?.name}
                sectionSubtitle={
                  workersList
                    .find(user => user.id === favorite?.id)
                    ?.industy_occupations.map(ele => ele.name)
                    .join() || ''
                }
                favoriteIcon
                isFavorite={(favorites || []).find(fav => fav.id === favorite.id)}
                onFavoriteClick={$event => favoriteClick($event, favorite.id)}
                headerDescriptionFirst={favorite?.citizenship}
                headerDescriptionSecond={favorite?.city}
                headerDescriptionThird={favorite?.state}
                footerDescription={favorite?.jobDescription}
                headerDescriptionFirstRight={favorite?.monthlySalary && `CHF ${favorite?.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                headerDescriptionSecondRight={favorite?.hourlyRate && `CHF ${favorite?.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                headerDescriptionThirdRight={favorite?.driverLicences && favorite?.driverLicences}
                // hasActions
                click={() => handleOpenWorkerDetails(favorite)}
              />
            </StyledCardWrapper>
          ))}
        </StyledFavoritesCardsWrapper>
      </StyledFavoritesContentWrapper>
      <PreviewDialog {...previewDetails}>
      <StyledWorkerInfoPreviewWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{paddingTop: "20px"}}>
              {previewWorker?.workerStreet && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>  {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>{previewWorker?.workerStreet}
                </p>
              )}
              {previewWorker?.workerCity && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>  {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}:</span>{previewWorker?.workerCity}
                </p>
              )}
              {previewWorker?.workerPostcode && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>   {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>{previewWorker?.workerPostcode}
                </p>
              )}
              {previewWorker?.workerCountry && (
                <p>
                <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>{previewWorker?.workerCountry}
                </p>
              )}
              {previewWorker?.workerBirthdate && (
                <p>
                <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>{formatDate(new Date(previewWorker?.workerBirthdate))}
                </p>
              )}
              {previewWorker?.workerCitizenship && (
                <p>
               <span style={{ fontWeight: 'bold' }}>      {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>{previewWorker?.workerCitizenship}
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
          <StyledInfoWrapper>
            <StyledInfoLine style={{paddingTop: "20px"}}>
              {previewWorker?.workerMobilePhone && (
                <p>
                   <span style={{ fontWeight: 'bold' }}>   {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>{previewWorker?.workerMobilePhone}
                </p>
              )}
              {previewWorker?.workerEmail && (
                <p>
                <span style={{ fontWeight: 'bold' }}>      {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>{previewWorker?.workerEmail}
                </p>
              )}
              {previewWorker?.workerDriverLicenses && (
                <p>
                 <span style={{ fontWeight: 'bold' }}>     {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>{previewWorker?.workerDriverLicenses}
                </p>
              )}
              {previewWorker?.workerHourlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>    {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>{previewWorker?.workerHourlyRate}.-
                </p>
              )}
              {previewWorker?.workerMonthlyRate && (
                <p>
                  <span style={{ fontWeight: 'bold' }}>    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>{previewWorker?.workerMonthlyRate}.-
                </p>
              )}
            </StyledInfoLine>
          </StyledInfoWrapper>
        </StyledWorkerInfoPreviewWrapper>
        <StyledWorkerDocumentsWrapper>
          <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
          <StyledDocumentsWrapper>
              {(previewWorker?.workerFiles || []).map(file => (
                <StyledDocumentLine key={`file-${file.id}`}>
                  <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                    <span>
                      <FontAwesomeIcon icon={faFileAlt} />
                    </span>
                    {file.name}
                  </p>
                </StyledDocumentLine>
              ))}
            </StyledDocumentsWrapper>
        </StyledWorkerDocumentsWrapper>
        <Row>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
            {previewWorker?.workerExperience && previewWorker?.workerExperience?.length ? (
              previewWorker?.workerExperience.map(
                work =>
                  work.companyName &&
                  work.position && (
                    <>
                      <strong>
                        {work.companyName}, {work.position}
                      </strong>
                      <p>
                        {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                      </p>
                    </>
                  )
              )
            ) : (
              <></>
            )}
          </StyledColWrapper>
          <StyledColWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
            {previewWorker?.workerEducation?.length ? (
              previewWorker?.workerEducation.map(work => (
                <>
                  <strong>
                    {work.name}, {work.type}
                  </strong>
                  <p>
                    {new Date(work.startDate).toLocaleDateString('en-GB')} | {new Date(work.endDate).toLocaleDateString('en-GB')}
                  </p>
                </>
              ))
            ) : (
              <></>
            )}
          </StyledColWrapper>
        </Row>
        <Row>
          {' '}
         {previewWorker?.workerBio && <StyledColWrapper style={{ paddingTop: '20px' }}>
            <h4>{t('FORM.fields.aboutMe')}</h4>
            <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>
          </StyledColWrapper>}
        </Row>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Favorites, 'Favorites');
