import styled from '@emotion/styled';

import { LIGHT_BLUE_PAGE_BG } from 'constants/colors';

const StyledFiltersWrapper = styled('div')`
  display: flex;
  padding: 12px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  border-radius: 6px;
`;

export { StyledFiltersWrapper };
