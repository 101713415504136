import styled from '@emotion/styled';
import { Row, Col, Container } from 'react-bootstrap';

const StyledWeeklyReportsContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 30px 10px 30px;
  overflow: auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled('div')`
  width: 100%;
  max-width: 750px;
  min-height: 900px;
  margin-top: 350px;

  img {
    height: 35px;
    margin: 30px 20px;
  }
`;

const StyledContainer = styled(Container)`
  /* Rows */
  & > div {
    /* Cols */
    & > div:not(:last-of-type) {
      border-right: 1px solid black;
    }
  }
`;

const StyledColumn = styled(Col)`
  ${({ customWidth }) => (customWidth ? `width: ${customWidth};` : '')}
  ${({ center }) => (center ? 'justify-content: center;' : '')}
  ${({ center }) => (center ? 'align-items:center;' : '')}
  ${({ center }) => (center ? 'display: flex;' : '')}
  word-break: break-all;
  font-size: 12px;
  padding: 0 1px;
`;

const StyledRow = styled(Row)`
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  ${({ borderBottom }) => (borderBottom ? `border-bottom: 1px solid black;` : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
`;

export { StyledWeeklyReportsContentWrapper, StyledTable, StyledContainer, StyledColumn, StyledRow };
