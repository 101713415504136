import { createAsyncThunk } from '@reduxjs/toolkit';

import { applicationsUrl, applicationUrl, countJobsUrl, jobsUrl, jobUrl } from 'utils/apis/index';
import http from 'utils/http';

const fetchJobsList = createAsyncThunk('jobs/fetchJobsList', async (params = { deleted: false }) => {
  console.log('async thunk is firing now ====>> ');
  params.deleted = false;
  console.log('Here are the paraaams ===>>>', params);
  const { data } = await http.get(jobsUrl, {
    params
  });
  console.log('here is data =====>>>>', data);

  return data;
});

const fetchJob = createAsyncThunk('jobs/fetchJob', async (jobId = {}) => {
  const { data } = await http.get(jobUrl(jobId));

  return data;
});

const countJobs = createAsyncThunk('jobs/countJobsList', async (params = { deleted: false }) => {
  params.deleted = false;
  const { data } = await http.get(countJobsUrl, {
    params
  });

  return data;
});

const updateJob = createAsyncThunk('jobs/update-job', async ({ jobId, payload }) => {
  // console.log('here is jobId and payload in thunk', jobId, payload);
  const { data } = await http.put(jobUrl(jobId), payload);
  // console.log('updated data from updateJob===>', data);
  return data;
});

const deleteJob = createAsyncThunk('jobs/update-job', async ({ jobId }) => {
  const payload = { deleted: true };
  const { data } = await http.put(jobUrl(jobId), payload);

  return data;
});

const createJob = createAsyncThunk('jobs/create-job', async payload => {
  console.log("here is payload in thunk payload====>>>>", payload)
  const { data } = await http.post(jobsUrl, payload);
  return data;
});

const companyJobs = createAsyncThunk('jobs/company', async companyId => {
  const { data } = await http.get(jobsUrl, {
    params: {
      company: companyId,
      status_nin: ['canceled', 'finished'],
      _limit: 30
    }
  });

  return data;
});

const workerApplications = createAsyncThunk('jobs/workerApplications', async workerId => {
  const { data } = await http.get(applicationsUrl, {
    params: {
      worker: workerId
    }
  });

  return data;
});

const companyApplications = createAsyncThunk('jobs/companyApplications', async companyId => {
  try {
    const params = {
      _where: {
        company: companyId
      },
      _sort: 'created_at:DESC'
    };
    const { data } = await http.get(applicationsUrl, { params });
    return data;
  } catch (error) {
    console.log('error while getting applications with companyApplications thunk');
  }
});

const updateApplication = createAsyncThunk('jobs/update-application', async ({ applicationId, payload }) => {
 
  const { data } = await http.put(applicationUrl(applicationId), payload);

  return data;
});

export { fetchJobsList, updateJob, deleteJob, createJob, fetchJob, countJobs, updateApplication, companyJobs, workerApplications, companyApplications };
