import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withTranslations from 'translations';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { USER_TYPE } from 'constants/userTypes';
import {
  StyledPricingPlan,
  StyledPricingPlanDescription,
  StyledPricingPlanDescriptionElement,
  StyledPricingPlanPrice,
  StyledPricingPlanRabat,
  StyledPricingPlanTitle,
  StyledPricingPlanWrapper,
  StyledSeparator
} from 'views/landingPage/Pricing/styles';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';

import {
  StyledSubscriptionContentWrapper,
  StyledSubscriptionWrapper,
  StyledSubscriptionCardsWrapper,
  StyledBlockTitle,
  StyledInformationsWrapper,
  StyledNotificationWrapper,
  StyledInformationWrapper,
  StyledPeriod,
  StyledInformationRowWrapper,
  StyledButtonsWrapper,
  StyledCancel
} from './styles';

const renderPricingPlans = () => (
  <StyledPricingPlanWrapper paddingTop="0" style={{ width: '100%' }}>
    <StyledPricingPlan xs={5}>
      <StyledPricingPlanTitle>1 Profil für</StyledPricingPlanTitle>
      <StyledPricingPlanRabat>(15% Rabatt)</StyledPricingPlanRabat>
      <StyledPricingPlanDescription>
        <StyledPricingPlanDescriptionElement>
          Up to 10 job offers per project manager{' '}
        </StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>Time tracking</StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>
          Online document signing
        </StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>Unlimited reports</StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>
          Contract templates
        </StyledPricingPlanDescriptionElement>
      </StyledPricingPlanDescription>
      <StyledSeparator />
      <StyledPricingPlanPrice>Chf 765</StyledPricingPlanPrice>
    </StyledPricingPlan>
    <StyledPricingPlan xs={5}>
      <StyledPricingPlanTitle>3 Profil für</StyledPricingPlanTitle>
      <StyledPricingPlanRabat>(25% Rabatt)</StyledPricingPlanRabat>
      <StyledPricingPlanDescription>
        <StyledPricingPlanDescriptionElement>
          Up to 10 job offers per project manager{' '}
        </StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>Time tracking</StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>
          Online document signing
        </StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>Unlimited reports</StyledPricingPlanDescriptionElement>
        <StyledPricingPlanDescriptionElement>
          Contract templates
        </StyledPricingPlanDescriptionElement>
      </StyledPricingPlanDescription>
      <StyledSeparator />
      <StyledPricingPlanPrice>Chf 2’025</StyledPricingPlanPrice>
    </StyledPricingPlan>
  </StyledPricingPlanWrapper>
);
const Subscription = () => (
  <ProfilePageContainer
    navigationItems={companyNavigationItems}
    pageTitle="My Subscription"
    role={USER_TYPE.COMPANY}
  >
    <StyledSubscriptionContentWrapper>
      <StyledSubscriptionWrapper>
        <StyledSubscriptionCardsWrapper xs={12}>
          <StyledInformationsWrapper xs={9}>
            <StyledInformationWrapper>
              <StyledBlockTitle>Subscription period</StyledBlockTitle>
              <StyledPeriod>
                <p>Start: 22.01.2021</p>
                <p>End: 22.01.2022</p>
              </StyledPeriod>
            </StyledInformationWrapper>
            <StyledInformationRowWrapper>
              <StyledBlockTitle>Upgrade</StyledBlockTitle>
              {renderPricingPlans()}
            </StyledInformationRowWrapper>
            <StyledInformationWrapper>
              <StyledBlockTitle>Change</StyledBlockTitle>
              <StyledButtonsWrapper>
                <CustomButton
                  text="Change subscription"
                  customButtonColor={APP_RED}
                  customTextColor={WHITE}
                  customHeight="50px"
                  customWidth="200px"
                />
                <CustomButton
                  text="Pay subscription"
                  customButtonColor={APP_RED}
                  customTextColor={WHITE}
                  customHeight="50px"
                  customWidth="200px"
                />
              </StyledButtonsWrapper>
            </StyledInformationWrapper>
          </StyledInformationsWrapper>
          <StyledNotificationWrapper xs={3} />
        </StyledSubscriptionCardsWrapper>
        <StyledCancel>
          <FontAwesomeIcon icon="power-off" color={APP_RED} />
          <p>Cancel subscription</p>
        </StyledCancel>
      </StyledSubscriptionWrapper>
    </StyledSubscriptionContentWrapper>
  </ProfilePageContainer>
);

export default withTranslations(Subscription, 'Subscription');
