import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import withTranslations from 'translations';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { workerNavigationItems } from 'constants/sidebarNavItems';
import { useWindowDimensions } from 'utils/helpers';
import {
  StyledSearchContentWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledPreviewDetails,
  CenterSpinner,
  StyledDropDownWrapper,
  StyledSearchContentTopWrapper,
  StyledDrop,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo,
  StyledSearchField,
  StyledOccupationField
} from 'views/employeePage/Search/styles';
import { StyledInfoLine, StyledInfoWrapper, StyledWorkerInfoPreviewWrapper } from 'views/companyPage/Projects/styles';
import Filters from 'components/Filters';
import CustomCard from 'components/CustomCarWorkerSearch';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import { USER_TYPE } from 'constants/userTypes';
import Pager from 'components/Pager';
import { countJobsSelector, jobsSelector } from 'redux/modules/jobs/selectors';
import { countJobs, fetchJobsList } from 'redux/modules/jobs/asyncThunks';
import { closeDialog, openDialog } from 'redux/modules/dialogs/reducer';
import PreviewDialog from 'components/PreviewDialog';
import Sanitizer from 'components/Sanitizer';
import http from 'utils/http';
import { applicationsUrl } from 'utils/apis';
import { applicationsProfileSelector, authProfileSelector, favoritesProfileSelector } from 'redux/modules/auth/selectors';
import { prepareApplicationData } from 'views/employeePage/Search/config';
import SuspenseSpinner from 'components/SuspenseSpinner';
import DefaultProfileImage from 'assets/images/default.jpeg';
import Select from 'react-select';

import { getWorkerProfile, updateFavorites } from '../../../redux/modules/auth/asyncThunks';
import { formatShortDateFromISODate, addTwoWeeks } from '../../../utils/helpers';
import { formatDate } from 'utils/helpers';

const Search = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [isDataRefresh, setIsDataRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const jobsListSelector = useSelector(jobsSelector);
  const jobsCountSelector = useSelector(countJobsSelector);
  const favorites = useSelector(favoritesProfileSelector);
  const applications = useSelector(applicationsProfileSelector);
  const worker = useSelector(authProfileSelector);
  const [selectedSkillLevel, setSelectedSkillLevel] = useState(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;
  const PER_PAGE = 12;
  const defaultParams = {
    activated: true,
    status_nin: ['canceled', 'finished', 'ongoing'],
    // filtering jobs out that passed todays date
    // deadline_gte: formatShortDateFromISODate(new Date()),
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
  };

  const skillLevels = [
    { value: 'All Jobs', label: t('FORM.fields.skillLevelsAllJobs') },
    { value: 'CH learned', label: t('FORM.fields.skillLevelsChLearned') },
    { value: 'Learned abroad', label: t('FORM.fields.skillLevelsLearnedAbroad') },
    { value: 'Semi-skilled', label: t('FORM.fields.skillLevelsSemiSkilled') },
    { value: 'Unskilled', label: t('FORM.fields.skillLevelsUnskilled') }
  ];

  useLayoutEffect(() => {
    const data = async () => {
      const jobs = await dispatch(fetchJobsList(defaultParams));
      console.log('jobsssss from dispatch ===>>>', jobs);
      if (jobs && jobs.payload) {
        setIsDataRefresh(true);
      }
      dispatch(countJobs());
    };
    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleOnSearch = (inputValue, page) => {
    // console.log('heere is Q ===>>', inputValue);
    const params = {
      ...defaultParams,
      _start: page ? (page - 1) * PER_PAGE : (activePage - 1) * PER_PAGE
    };

    if (inputValue) {
      if (!searchTerm) {
        // eslint-disable-next-line no-underscore-dangle
        params._start = 0;
        setActivePage(1);
      }

      setSearchTerm(inputValue);
      // eslint-disable-next-line no-underscore-dangle
      params._q = inputValue;
    } else {
      setSearchTerm('');
    }

    dispatch(fetchJobsList({ ...params }));
    dispatch(countJobs({ ...params }));
  };

  const handleOnApply = async job => {
    // console.log("here is job in handleOnApply ==>>", job);
    setLoading(true);
    await http.post(applicationsUrl, prepareApplicationData({ worker, job, company: job?.company }));
    dispatch(getWorkerProfile(worker?.id));
    setLoading(false);
  };

  const handleOpenJobDetails = job => {
    // console.log('here is job in search ===>>>', job);
    const foundJobApplied = applications.find(application => application.job === job.id);
    setPreviewDetails({
      title: job?.company?.companyName,
      jobStreet: job?.address,
      jobCity: job?.city,
      jobPostCode: job?.postCode,
      jobCountry: job?.state,
      jobDescription: job?.description,
      jobDescriptionCompany: job?.descriptionCompany,
      jobContactInformation: job?.contactInformation,
      jobStartPlan: job?.startP,
      // offerSalaryAmount: offer?.salary,
      // offerSalaryType: offer?.salaryType,
      jobMonthlySalary: job?.monthlyPayroll, // check this
      jobHourly: job?.rate, // check this
      jobStartDate: job?.deadline,
      jobJobTitle: job?.title,
      companyStreet: job?.company?.address,
      companyCity: job?.company?.city,
      companyPostcode: job?.company?.postCode,
      companyCountry: job?.company?.state,
      companyMobilePhone: job?.company?.mobilePhone,
      companyPhone: job?.company?.phoneNumber,
      companyWebsite: job?.company?.website,
      companyName: job?.company?.companyName,
      companyEmail: job?.company?.contactEmail,
      companyDescription: job?.company?.description,
      logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,
      workerJobTitle: job?.title,

      originalData: job,
      startPlan: job?.startP,
      rate: job?.rate,
      monthlyRate: job?.monthlyPayroll,
      deadline: job?.deadline,
      descriptionCompany: job?.descriptionCompany,
      description: job?.description,
      contactInformation: job?.contactInformation,
      contact: job?.company?.contactEmail,
      address: `${job?.address}, ${job?.city}, ${job?.state}`,
      customButtonText: foundJobApplied && addTwoWeeks(foundJobApplied?.created_at) > new Date().toISOString() ? t('BUTTON.alreadyApplied') : t('BUTTON.applyNow'),
      logoUrl: job?.company?.image?.url ? `${BE_URL}${job?.company?.image?.url}` : null,
      onApplyClick: () => handleOnApply(job),
      disabledApply: foundJobApplied && addTwoWeeks(foundJobApplied?.created_at) > new Date().toISOString()
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  useEffect(() => {
    const isApplyForJob = JSON.parse(localStorage.getItem('applyForJob'));
    console.log(isApplyForJob);
    if (isApplyForJob && isDataRefresh && jobsListSelector?.some(item => item.id === isApplyForJob.id)) {
      handleOpenJobDetails(isApplyForJob);
      localStorage.removeItem('applyForJob');
    } else {
      dispatch(
        closeDialog({
          dialogName: 'previewDialog'
        })
      );
    }
  }, [jobsListSelector, isDataRefresh]);

  const handleOpenProfileDetails = (event, company) => {
    event.stopPropagation();
    event.preventDefault();

    if (!company) return;

    setPreviewDetails({
      title: company.companyName,
      description: company.description,
      contact: company.contactEmail,
      address: company.address,
      customButtonText: 'WEBSITE',
      logoUrl: company.image?.url ? `${BE_URL}${company.image?.url}` : null,
      onApplyClick: () => window.open(company.website, '_blank')
    });
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  const favoriteClick = (event, companyId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!companyId) return;

    const alreadyExist = favorites.find(fav => fav.id === companyId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), companyId];
    }

    dispatch(updateFavorites({ workerId: worker.id, payload: { favorites: updatedFavorites } }));
  };

  const changePage = page => {
    setActivePage(page);
    handleOnSearch(searchTerm, page);
  };

  const footerMinHeightVar = {
    minHeight: 'rem'
  };
  const handleSkillLevelChange = selectedOption => {
    setSelectedSkillLevel(selectedOption);
  };

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const filterBySearch = application =>
    (application?.address && application?.address.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.city && application?.city.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.postCode && application?.postCode.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.company?.companyName && application?.company?.companyName.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.company?.description && application?.company?.description.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.state && application?.state?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.shortDescription && application?.shortDescription?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (application?.title && application?.title.toLowerCase().includes(searchTerm?.toLowerCase()));

  const filterBySkillLevel = application => {
    if (!selectedSkillLevel || selectedSkillLevel?.value === 'All Jobs') {
      return true;
    }
    return application?.skillLevel && application?.skillLevel.includes(selectedSkillLevel?.value && selectedSkillLevel.value);
  };
  console.log('here is jobsListSelector ===>>>', jobsListSelector);

  return (
    
    <ProfilePageContainer navigationItems={workerNavigationItems} pageTitle={t('SIDE_NAVIGATION.search.pageTitle')} role={USER_TYPE.WORKER}>
      <StyledSearchContentTopWrapper>
      {width >= 900 ?  <StyledDropDownWrapper>
          {' '}
          <Row style={{ justifyContent: 'space-between' }}>
            <StyledSearchField lg={9}>
              <Filters two onInputChange={debouncedOnInputChange} placeholder={t('SIDE_NAVIGATION.search.pageTitle')} />{' '}
            </StyledSearchField>
            <StyledOccupationField lg={4} className="d-flex justify-content-end" style={{ justifyContent: 'flex-end' }}>
              <StyledDrop>
                <Select options={skillLevels} value={selectedSkillLevel} onChange={handleSkillLevelChange} placeholder={t('REGISTER_DIALOG.chooseSkillLevel')} />
              </StyledDrop>
            </StyledOccupationField>
          </Row>{' '}
        </StyledDropDownWrapper> : ""}
     <StyledSearchResultContent> 
     {width < 900 ?  <StyledDropDownWrapper>
          {' '}
          <Row style={{ justifyContent: 'space-between' }}>
            <StyledSearchField lg={9}>
              <Filters two onInputChange={debouncedOnInputChange} placeholder={t('SIDE_NAVIGATION.search.pageTitle')} />{' '}
            </StyledSearchField>
            <StyledOccupationField lg={4} className="d-flex justify-content-end" style={{ justifyContent: 'flex-end' }}>
              <StyledDrop>
                <Select options={skillLevels} value={selectedSkillLevel} onChange={handleSkillLevelChange} placeholder="Select skill level" />
              </StyledDrop>
            </StyledOccupationField>
          </Row>{' '}
        </StyledDropDownWrapper> : ""}

          <Row>
            {jobsListSelector
              ? jobsListSelector
                  ?.filter(filterBySearch)
                  .filter(filterBySkillLevel)
                  .map(job => (
                    <StyledCardWrapper key={job.id} sm={12} md={4} lg={4} xl={4} xxl={4}>
                      <CustomCard
                        footerMinHeight={footerMinHeightVar}
                        profileImage={job.company?.image?.url ? `${BE_URL}${job.company?.image?.url}` : DefaultProfileImage}
                        sectionTitle={job.title}
                        sectionSubtitle={job.company?.companyName}
                        headerDescriptionFirst={job?.city}
                        headerDescriptionSecond={job?.state}
                        footerDescription={job?.description}
                        // profileClick={$event => handleOpenProfileDetails($event, job?.company)}
                        headerDescriptionPayrollMonthly={job?.monthlyPayroll}
                        headerDescriptionHourly={job?.rate}
                        favoriteIcon
                        isFavorite={(favorites || []).find(fav => fav.id === job?.company?.id)}
                        onFavoriteClick={$event => favoriteClick($event, job?.company?.id)}
                        startPlan={job?.startP}
                        deadline={job?.deadline !== null ? formatDate(new Date(job?.deadline)) : ''}
                        click={() => handleOpenJobDetails(job)}
                      />
                    </StyledCardWrapper>
                  ))
              : null}
          </Row>
          {loading && (
            <CenterSpinner>
              <SuspenseSpinner />
            </CenterSpinner>
          )}
        </StyledSearchResultContent>
        <Pager active={activePage} jobsCount={jobsCountSelector} perPage={PER_PAGE} changePage={changePage} />
      </StyledSearchContentTopWrapper>
      <PreviewDialog {...previewDetails}>
        <StyledPreviewDetails>
          {previewDetails?.descriptionCompany ? (
            <>
              <strong>
                <h4>{t('FORM.fields.aboutUs')}</h4>{' '}
              </strong>
              <Sanitizer review={previewDetails?.descriptionCompany} />{' '}
            </>
          ) : null}
          {previewDetails?.description ? (
            <>
              <strong>
                <h4>{t('FORM.fields.jobDescription')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.description} />{' '}
            </>
          ) : null}
          {previewDetails?.contactInformation ? (
            <>
              <strong>
                <h4>{t('FORM.fields.descriptionContact')}</h4>{' '}
              </strong>{' '}
              <Sanitizer review={previewDetails?.contactInformation} />{' '}
            </>
          ) : null}
          <Row>
            {previewDetails?.rate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.rate')}: </strong>CHF {previewDetails?.rate}.-
              </Col>
            )}
            {previewDetails?.monthlyRate && (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.ratePerMonth')}:</strong> CHF {previewDetails?.monthlyRate}.-
              </Col>
            )}
            {previewDetails?.deadline && formatDate(new Date(previewDetails?.deadline)) !== '01/01/1970' ? (
              <Col sm={12} md={12} xl={12}>
                <strong>{t('FORM.fields.startDate')}:</strong> {formatDate(new Date(previewDetails?.deadline))}
              </Col>
            ) : (
              ''
            )}
            {previewDetails?.jobStartPlan && (
              <Col sm={12} md={12} xl={12} style={{ paddingBottom: '10px' }}>
                <strong>{t('OFFERCARD.startDate')}:</strong> {t(`FORM.fields.${previewDetails?.jobStartPlan}`)}
              </Col>
            )}

            {previewDetails?.address && (
              <div style={{ paddingTop: '10px' }}>
                <h4>{t('FORM.fields.address')}</h4>
                <StyledPreviewDialogFooterInfo>{previewDetails?.address}</StyledPreviewDialogFooterInfo>
              </div>
            )}
          </Row>
        </StyledPreviewDetails>
      </PreviewDialog>
    </ProfilePageContainer>
  );
};

export default withTranslations(Search, 'Search');
