import React from 'react';
import { Container, Row } from 'react-bootstrap';

import { StyledFiltersWrapper } from 'components/Filters/styles';
import CustomInput from 'components/FormFields/CustomInput';

const Filters = ({ name, onInputChange, placeholder, two }) => (
  <StyledFiltersWrapper>
    <Container fluid>
      <Row 
      
      lg={two ? 1 : 4}
      >
        <form>
          <CustomInput name={name} onInputChange={onInputChange} placeholder={placeholder} />
        </form>
      </Row>
    </Container>
  </StyledFiltersWrapper>
);

export default Filters;
