import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import breakpoints from 'constants/breakpoints';

import { LIGHT_GRAY_BACKGROUND, LIGHT_BLUE_PAGE_BG, APP_RED } from 'constants/colors';
const pixel = "solid 0px"

const StyledSearchField = styled(Col)`
border: ${pixel} blue;
max-width: 500px;
@media (max-width: 768px) {
  width: 300px;
}
`;

const StyledOccupationField = styled(Col)`
justify-content: center;
align-items: center;
border: ${pixel} orange;
/* width: 200px; */
@media (max-width: 768px) {
  width: 300px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
}

@media (min-width: 769px) {
margin-left: -200px;
}
`;

const StyledDropDownWrapper = styled('div')`
  display: flex;
  padding: 11px;
  /* border: 3px solid green; */
  background-color: ${LIGHT_BLUE_PAGE_BG};
  border-radius: 0 6px 6px 0;
  border: ${pixel} green;
@media (max-width: 768px) {
  margin-left: -10px;
}
`;

const StyledDrop = styled.div`
display: flex;
justify-content: flex-start;
align-items: cent
padding-top: 10px;
width: 800px;
border: ${pixel} purple;
`;

const StyledSearchContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${pixel} pink;
`;


const StyledSearchContentTopWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${pixel} black;
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  margin-top: 16px;
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CenterSpinner = styled('div')`
  position: relative;
  width: 400px;
  margin: auto;
  height: 150px;
  bottom: 50%;
`;

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;
`;

const StyledWorkerInfoBottomWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-wrap: nowrap; */
  align-items: center;
  justify-content: center;
  gap: 70px;
padding-top: 20px;
font-weight: bold;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledWorkerInfoLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 70px;
  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    padding: 0 30px;
    gap: 0px;
  }
`;

const StyledLineWrapper = styled('hr')`
  width: 75vw; /* Set width to 70% of viewport width */
  height: 1px; /* Set desired height */
  background-color: black; /* Set desired color */
  border: none; /* Remove default border */
`;

const StyledColWrapper = styled(Col)`
  padding: 0 40px;
`;

const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  padding: 13px;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;

export {
  CenterSpinner,
  StyledSearchContentWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledPreviewDetails,
  StyledDropDownWrapper,
  StyledSearchContentTopWrapper,
  StyledDrop,
  StyledWorkerInfoBottomWrapper,
  StyledWorkerInfoLineWrapper,
  StyledLineWrapper,
  StyledColWrapper,
  StyledPreviewDialogFooterInfo,
  StyledSearchField,
  StyledOccupationField
};
