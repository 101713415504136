export const workerNavigationItems = [
  { name: 'SIDE_NAVIGATION.search.pageTitle', link: '/worker/search' },
  { name: 'EMPLOYEE_PROFILE.MY_PROFILE.title', link: '/worker/profile' },
  { name: 'SIDE_NAVIGATION.myEmployment.pageTitle', link: '/worker/employment' },
  { name: 'SIDE_NAVIGATION.applications.myApplications', link: '/worker/applications' },
  // Commented items to be done
  // { name: 'Weekly Report', link: '/company/weekly-report' },
  // { name: 'News', link: '/company/news' },
  { name: 'SIDE_NAVIGATION.offers.pageTitle', link: '/worker/offers' },
  { name: 'SIDE_NAVIGATION.favorites.pageTitle', link: '/worker/favorites' }
];

export const companyNavigationItems = [
  { name: 'SIDE_NAVIGATION.search.pageTitle', link: '/company/search' },
  { name: 'SIDE_NAVIGATION.myProjects.pageTitle', link: '/company/projects' },
  { name: 'SIDE_NAVIGATION.vacancies.pageTitle', link: '/company/vacancies' },
  { name: 'EMPLOYEE_PROFILE.MY_PROFILE.title', link: '/company/profile' },
  // { name: 'My Subscription', link: '/company/subscription' },
  { name: 'SIDE_NAVIGATION.applications.pageTitle', link: '/company/applications' },
  // Commented items to be done
  { name: 'SIDE_NAVIGATION.offers.pageTitle', link: '/company/offers' },
  // { name: 'Offers', link: '/company/weekly-report' },
  // { name: 'News', link: '/company/news' },
  { name: 'SIDE_NAVIGATION.favorites.pageTitle', link: '/company/favorites' },
  { name: 'SIDE_NAVIGATION.blacklist.pageTitle', link: '/company/blacklist'},
];
