const { createSlice } = require('@reduxjs/toolkit');

const initialState = 'de';

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (_, { payload }) => payload
  }
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
