import styled from '@emotion/styled';
import { Container } from 'react-bootstrap';

import { APP_RED, INPUT_TEXT_DARK_BLUE, LIGHT_GRAY_BLUE } from 'constants/colors';

const StyledHeaderWrapper = styled(Container)`
  padding: 30px 0 0;
  border-bottom: 1px solid ${LIGHT_GRAY_BLUE};

  h1 {
    text-align: center;
    font-size: 45px;
    color: ${INPUT_TEXT_DARK_BLUE};
    margin-bottom: 0;
    font-family: 'Lato Black';

    ::after {
      content: ''; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0 auto; /* This will center the border. */
      width: 50px; /* Change this to whatever width you want. */
      padding-top: 20px; /* This creates some space between the element and the border. */
      border-bottom: 4px solid ${APP_RED}; /* This creates the border. Replace black with whatever color you want. */
    }
  }
`;

export { StyledHeaderWrapper };
