import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';
import { fetchProjectsList, countProjects, updateProject, updateProjekts, createProject } from 'redux/modules/projects/asyncThunks';

const initialState = {
  projects: [],
  projectsCount: 0,
  status: ASYNC_STATUS.IDLE
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: {
    [fetchProjectsList.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [fetchProjectsList.fulfilled]: (state, { payload }) => {
      state.projects = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [countProjects.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [countProjects.fulfilled]: (state, { payload }) => {
      state.projectsCount = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateProject.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      const existIndex = state.projects.findIndex(p => p.id === payload.id);
      if (existIndex > -1) {
        state.projects.splice(existIndex, 1);
        state.projects = [payload, ...state.projects];
      } else {
        state.projects = [payload, ...state.projects];
      }
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateProjekts.pending]: state => {
      state.status = ASYNC_STATUS.LOADING
    },
    [updateProjekts.fulfilled]: (state, {payload}) => {
      state.projects = payload;
      state.status = ASYNC_STATUS.IDLE;
    },

    [createProject.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [createProject.fulfilled]: (state, { payload }) => {
      state.projects = [payload, ...state.projects];
      state.status = ASYNC_STATUS.IDLE;
    },
  }
});

export default projectsSlice.reducer;
