/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { Alert, Col, Row } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import withTranslations from 'translations';
import ProfilePageContainer from 'components/ProfilePageContainer';
import { companyNavigationItems } from 'constants/sidebarNavItems';
import Filters from 'components/Filters';
import CustomCarSearch from 'components/CustomCardCompany';
import RestaurantMockImage from 'assets/images/restaurant-mock-icon.png';
import { USER_TYPE } from 'constants/userTypes';
import Pager from 'components/Pager';
import SendOffersDialog from 'components/SendOffersDialog';
import { StyledDialogButtonActions, StyledInputField } from 'components/LoginDialog/styles';
import CustomButton from 'components/CustomButton';
import { APP_RED, WHITE } from 'constants/colors';
import { showToast } from 'redux/modules/toast/reducer';
import DefaultProfileImage from 'assets/images/default.jpeg';
import { formatDate } from 'utils/helpers';

import { StyledSearchContentWrapper, StyledSearchResultContent, StyledCardWrapper, StyledTextareaField, StyledColWrapper, StyledSelectField } from './styles';
import { countWorkersSelector, workersSelector } from '../../../redux/modules/workers/selectors';
import { authProfileSelector, favoritesProfileSelector, blacklistSelector } from '../../../redux/modules/auth/selectors';
import { countWorkers, fetchWorkersList } from '../../../redux/modules/workers/asyncThunks';
import { closeDialog, openDialog } from '../../../redux/modules/dialogs/reducer';
import { companyOfferSelector } from 'redux/modules/offers/selectors';
import { sendOfferCompany, updateFavoritesCompany, updateBlacklist, getCompanyProfile } from '../../../redux/modules/auth/asyncThunks';
import { companyOffers } from '../../../redux/modules/offers/asyncThunks';
import PreviewDialog from '../../../components/PreviewDialog';
import {
  StyledDocumentLine,
  StyledDocumentsWrapper,
  StyledInfoLine,
  StyledInfoWrapper,
  StyledWorkerDocumentsWrapper,
  StyledWorkerInfoPreviewWrapper,
  StyledPreviewDialogFooterInfo
} from '../Search/styles';

const Search = () => {
  const { t } = useTranslation();
  const [previewDetails, setPreviewDetails] = useState({});
  const [previewWorker, setPreviewWorker] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const workersListSelector = useSelector(workersSelector);
  const workersCountSelector = useSelector(countWorkersSelector);
  const [previewFilesWorker, setPreviewFilesWorker] = useState(null);
  const [previewEducation, setPreviewEducation] = useState(null);
  const favorites = useSelector(favoritesProfileSelector);
  const blacklisted = useSelector(blacklistSelector);
  const [isAllowedToSendOffer, setIsAllowedToSendOffer] = useState(true);
  const company = useSelector(authProfileSelector);
  const offers = useSelector(companyOfferSelector);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [workerCount, setWorkerCount] = useState(null);
  const BE_URL = ``;
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const PER_PAGE = 500;
  const defaultParams = {
    _limit: PER_PAGE,
    _sort: 'created_at:DESC'
  };

  useEffect(async () => {
    const letter = 'a';
    let count = 0
    const countList = await dispatch(fetchWorkersList(defaultParams));
    count = await dispatch(countWorkers(defaultParams));
    // console.log("here is countList ==>>", countList.payload.filteredCount)
    setWorkerCount(countList.payload.filteredCount)
    handleOnSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("here is workersCountSelector ==<<", workersCountSelector)
//   useEffect(async () => {
  
//     const data = async () => {
//       const workers = await dispatch(fetchWorkersList(defaultParams));
//        workers && workers.payload 
//         await dispatch(countWorkers(defaultParams));
      
      
//     }
// data()
//     // handleOnSearch(letter);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);



  const { register, handleSubmit } = useForm();
  useEffect(async () => {
    const params = {
      ...defaultParams,
      _q: ''
    };

    await dispatch(fetchWorkersList(params));
    await dispatch(countWorkers(params));
    await dispatch(getCompanyProfile(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(companyOffers(company.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  // console.log("here is workerslistSelector in Search====>>>>", workersListSelector);
  // console.log("here is company again ===>>>", company)
  // console.log("here is localstorage ===>>>", localStorage.getItem('profile'))
  // const handleOnSearch = (inputValue, page) => {
  //   const params = {
  //     ...defaultParams,
  //     _start: page ? (page - 1) * PER_PAGE : (activePage - 1) * PER_PAGE
  //   };

  //   if (inputValue) {
  //     if (!searchTerm) {
  //       // eslint-disable-next-line no-underscore-dangle
  //       params._start = 0;
  //       setActivePage(1);
  //     }
  //     setSearchTerm(inputValue);
  //     // eslint-disable-next-line no-underscore-dangle
  //     params._q = inputValue;
  //   } else {
  //     setSearchTerm('');
  //   }

  //   dispatch(fetchWorkersList({ ...params }));
  //   dispatch(countWorkers({ ...params }));
  // };

  // const handleOnSearch = (inputValue, page) => {
  //   const params = {
  //     ...defaultParams,
  //     _start: page ? (page - 1) * PER_PAGE : (activePage - 1) * PER_PAGE,
  //     _q: inputValue
  //   };

  //   // Always set _q to inputValue, even if it's empty
  //   params._q = inputValue;

  //   if (!searchTerm) {
  //     // Reset _start and activePage when a new search term is applied
  //     params._start = 0;
  //     setActivePage(1);
  //   }

  //   setSearchTerm(inputValue);

  //   dispatch(fetchWorkersList({ ...params }));
  //   dispatch(countWorkers({ ...params }));
  // };

  const handleOnSearch = async (inputValue, page) => {
    const params = {
      ...defaultParams,
      _start: page ? (page - 1) * PER_PAGE : 0, // Adjust _start based on the page
      _limit: PER_PAGE, // Set the number of items to display per page
      // _q: inputValue
      // pagination was not working when uncommenting above line
    };
    // added below conditional and commented out setSearchTerm('') below
    if (inputValue) {
      if (!searchTerm) {
        // eslint-disable-next-line no-underscore-dangle
        params._start = 0;
        setActivePage(1);
      }

      setSearchTerm(inputValue);
      // eslint-disable-next-line no-underscore-dangle
      params._q = inputValue;
    } else {
      setSearchTerm('');
    }

    // setSearchTerm(inputValue);

    await dispatch(fetchWorkersList({ ...params }));
    await dispatch(countWorkers({ ...params }));
  };

  // const debouncedOnInputChange = _.debounce(handleOnSearch, 400);

  // Added this new search below instead of above line

  useEffect(() => {
changePage()
  }, [setSearchTerm, searchTerm])

  const debouncedOnInputChange = _.debounce(inputVal => setSearchTerm(inputVal.trim()), 400);

  const filterBySearch = worker =>
    (worker?.address && worker?.address.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.city && worker?.city.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.state && worker?.state?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.name && worker?.name.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.mobilePhone && worker?.mobilePhone.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.jobDescription && worker?.jobDescription.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.jobTitle && worker?.jobTitle.toLowerCase().includes(searchTerm?.toLowerCase())) ||
    (worker?.citizenship && worker?.citizenship?.toLowerCase().includes(searchTerm?.toLowerCase()));
    // (application?.title && application?.title.toLowerCase().includes(searchTerm?.toLowerCase()));

  const handleSendWorkerOffer = worker => {
    console.log('here is worker in SendWorkerOFfer ===>>>', worker);
    setPreviewWorker({ id: worker });
    dispatch(
      closeDialog({
        dialogName: 'previewDialog'
      })
    );
    dispatch(
      openDialog({
        dialogName: 'sendOfferDialog'
      })
    );
  };
  // console.log("here is workersListSelector ==>>", workersListSelector)

  /// checked ob user eine offene Offer von spezifischer company hat
  const handleOpenWorkerDetails = async worker => {
    setIsAllowedToSendOffer(true);

    const allOffersOfWorker = await offers.filter(offer => offer.worker.id === worker.id);
    // console.log('here are allOffersOfWorker in index ====>>>>', allOffersOfWorker);

    let isAllowedToSendOffer = true;
    for (let i = 0; i < allOffersOfWorker.length; i++) {
      console.log('here is allOffersOfWorker.activated ===>>>', allOffersOfWorker[i].activated);
      if (allOffersOfWorker[i].activated === true) {
        console.log('It is false now. Not allowed to send offer');
        // notiz: wenn dies nicht funzt, dann die useState variable substituieren mit einer normalen variable. Die derzeitige useState variable scheint zu funzen solange keine setter function verwendet wird.
        isAllowedToSendOffer = false;
        break;
      }
    }

    // console.log('Here is isAllowedToSendOffer ===>>>', isAllowedToSendOffer);

    // setPreviewWorker(worker);
    setPreviewWorker({
      originalData: worker,
      title: worker?.name,
      subtitle: worker?.jobTitle,
      workerJobTitle: worker?.jobTitle,
      workerName: worker?.name,
      workerStreet: worker?.address,
      workerCity: worker?.city,
      workerPostcode: worker?.rate,
      workerCountry: worker?.state,
      workerEmail: worker?.email,
      workerCitizenship: worker?.citizenship,
      workerBirthdate: worker?.birthdate,
      workerDriverLicenses: worker?.driverLicences,
      workerHourlyRate: worker?.hourlyRate,
      workerMonthlyRate: worker?.monthlySalary,
      workerMobilePhone: worker?.mobilePhone,
      workerBio: worker?.jobDescription,
      workerFiles: worker?.files,
      workerEducation: worker?.education,
      workerExperience: worker?.workExperience,
      // offerStreet: worker?.address?.streatAddress,
      // offerCity: worker?.address?.city,
      // offerPostCode: worker?.address?.postCode,
      // offerCountry: worker?.address?.country,
      // offerDescription: worker?.description,
      // offerSalaryAmount: worker?.salary,
      // offerSalaryType: worker?.salaryType,
      // offerStartDate: worker?.startDate,
      // offerJobTitle: worker?.jobTitle,
      // companyStreet: worker?.company?.address,
      // companyCity: worker?.company?.city,
      // companyPostcode: worker?.company?.postCode,
      // companyCountry: worker?.company?.state,
      // companyMobilePhone: worker?.company?.mobilePhone,
      // companyPhone: worker?.company?.phoneNumber,
      // companyWebsite: worker?.company?.website,
      // companyName: worker?.company?.companyName,
      // companyEmail: worker?.company?.contactEmail,
      // companyDescription: worker?.company?.description,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null
    });
    // console.log('here is isAllowedToSendOffer', isAllowedToSendOffer);
    setPreviewDetails({
      originalData: worker,
      workerJobTitle: worker?.jobTitle,
      id: worker?.id,
      title: worker?.name,
      subtitle: worker?.jobTitle,
      descriptionOfWorker: worker?.jobDescription,
      // isBlacklisted: blacklisted && blacklisted?.find(workerBlacklisted => workerBlacklisted?.id === worker?.id) || null,
      offerStatus: worker?.offerStatus || null,
      isOfferActive: isAllowedToSendOffer,
      // worker && worker.isOfferActive !== undefined ? worker.isOfferActive : null,

      // isOfferActive:  worker.isOfferActive || null,
      logoUrl: worker?.image?.url ? `${BE_URL}${worker?.image?.url}` : null,
      onSendOffer: handleSendWorkerOffer,
      onSendToBlacklist: handleToBlacklist
    });
    // console.log("here is previewDetails ===>>", previewDetails)
    dispatch(
      openDialog({
        dialogName: 'previewDialog'
      })
    );
  };

  // console.log("here is previewDetails ====>>>>", previewDetails)

  const favoriteClick = (event, workerId) => {
    event.stopPropagation();
    event.preventDefault();

    if (!workerId) return;

    const alreadyExist = favorites.find(fav => fav.id === workerId);
    let updatedFavorites = [];

    if (alreadyExist) {
      updatedFavorites = favorites.filter(fav => fav.id !== alreadyExist.id);
    } else {
      updatedFavorites = [...favorites.map(fav => fav.id), workerId];
    }

    dispatch(updateFavoritesCompany({ companyId: company.id, payload: { favorites: updatedFavorites } }));
  };

  // const changePage = page => {
  //   setActivePage(page);
  //   handleOnSearch(searchTerm, page);
  // };

  const changePage = async page => {
    setActivePage(page);
    handleOnSearch(searchTerm, page); 
    // Pass the current searchTerm and page to handleOnSearch
  };
  // console.log('here is company ===>>>', company);

  const handleToBlacklist = async workerId => {
    let updatedBlacklist = [];
    const alreadyBlacklisted = blacklisted.find(blacklistWorker => blacklistWorker.id === workerId);
    if (alreadyBlacklisted) {
      return blacklisted;
    } else {
      updatedBlacklist = [...blacklisted.map(blacklistWorker => blacklistWorker), workerId];
    }
    try {
      const isBSent = await dispatch(updateBlacklist({ companyId: company.id, payload: { blacklists: updatedBlacklist } }));
      // console.log('here is isBsend====>>>>', isBSent);
      if (isBSent) {
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.sendToBlacklist.success')
          })
        );
        dispatch(
          closeDialog({
            dialogName: 'previewDialog'
          })
        );
      }
      // await dispatch(getCompanyProfile(company.id));
    } catch (error) {
      console.log('error updating blacklist in Search', error);
    }
  };

  const handleSendOffer = async payload => {
    try {
      // console.log('previewWorker', previewWorker);
      payload = {
        ...payload,
        worker: previewWorker.id,
        company: company.id,
        status: 'Pending',
        address: {
          postCode: payload.postCode,
          city: payload.city,
          streatAddress: payload.streatAddress,
          country: payload.country
        }
      };
      const isOfferSent = await dispatch(sendOfferCompany({ payload }));
      if (isOfferSent && isOfferSent.payload) {
        dispatch(fetchWorkersList(defaultParams));
        dispatch(
          showToast({
            type: 'success',
            message: t('MESSAGES.sendOffer.success')
          })
        );
        dispatch(
          closeDialog({
            dialogName: 'sendOfferDialog'
          })
        );
      }
    } catch (err) {
      if (err) {
        console.log('ERROR while sending offer', err);
      }
    }
  };
  const footerMinHeightVar = {
    // minHeight: '6rem'
  };
  function convertDateFormat(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObj.getDate()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  // Example usage
  const originalDate = previewWorker?.birthdate;
  const formattedDate = convertDateFormat(originalDate);
  let blacklistIds = blacklisted.map(worker => worker.id);

  // console.log('here is workersListSelector  ==>>', workersListSelector.entities);
  // console.log('here is previewDetails in index=====>>>>', previewDetails);
  return (
    <ProfilePageContainer navigationItems={companyNavigationItems} pageTitle={t('SIDE_NAVIGATION.search.pageTitle')} role={USER_TYPE.COMPANY}>
      <>
        <StyledSearchContentWrapper>
          {width >= 900 ? <Filters onInputChange={debouncedOnInputChange} placeholder={t('SIDE_NAVIGATION.search.pageTitle')} /> : ''}
          <StyledSearchResultContent xs={12}>
            {width < 900 ? <Filters onInputChange={debouncedOnInputChange} placeholder={t('SIDE_NAVIGATION.search.pageTitle')} /> : ''}
            { workersListSelector && workersListSelector
              ?.filter(filterBySearch)
                  .filter(worker => !blacklistIds.includes(worker.id) && worker.isActive !== false)
                  .map(worker => (
                    <StyledCardWrapper key={`worker-${worker.id}`} sm={12} md={5} xl={4}>
                      <CustomCarSearch
                        footerMinHeight={footerMinHeightVar}
                        profileImage={worker.image?.url ? `${BE_URL}${worker.image?.url}` : DefaultProfileImage}
                        sectionTitle={worker?.name}
                        sectionSubtitle={worker?.industy_occupations.map(ele => ele.name).join(', ')}
                        // headerDescriptionFirst={worker.citizenship}
                        headerDescriptionSecond={worker.city}
                        headerDescriptionThird={worker.state}
                        headerDescriptionFifth={worker.rate}
                        headerDescriptionFirstRight={`CHF ${worker.monthlySalary || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perMonth')}`}
                        headerDescriptionSecondRight={`CHF ${worker.hourlyRate || 0}.- ${t('EMPLOYEE_PROFILE.MY_PROFILE.perHour')}`}
                        // headerDescriptionThirdRight={worker.driverLicences}
                        footerDescription={worker.jobDescription}
                        // hasActions
                        favoriteIcon
                        isFavorite={(favorites || []).find(fav => fav.id === worker.id)}
                        onFavoriteClick={$event => favoriteClick($event, worker.id)}
                        click={() => handleOpenWorkerDetails(worker)}
                      />
                    </StyledCardWrapper>
                  ))
              }
          </StyledSearchResultContent>
          <Pager active={activePage} jobsCount={workersCountSelector} perPage={PER_PAGE} changePage={changePage} />
        </StyledSearchContentWrapper>
        <SendOffersDialog {...previewDetails}>
          <form onSubmit={handleSubmit(handleSendOffer)}>
            <Row>
              <Col className="col-4">
                <StyledInputField placeholder={t('FORM.fields.jobTitle')} type="text" {...register('jobTitle', { required: true })} />
              </Col>
              {width >= 900 ? (
                <Col style={{ marginLeft: '-63px' }}>
                  <StyledInputField placeholder={t('OFFERCARD.salaryAmount')} min={0} type="number" {...register('salary', { required: true })} />
                </Col>
              ) : (
                <Col style={{ marginLeft: '0px' }}>
                  <StyledInputField placeholder={t('OFFERCARD.salaryAmount')} min={0} type="number" {...register('salary', { required: true })} />
                </Col>
              )}
              <Col style={{ alignSelf: 'end', marginRight: '190px' }}>
                <StyledSelectField type="text" {...register('salaryType', { required: true })}>
                  <option value="Hourly" selected>
                    {t('OFFERCARD.salaryDropDown.perHour')}
                  </option>
                  <option value="Monthly">{t('OFFERCARD.salaryDropDown.perMonth')}</option>
                </StyledSelectField>
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledInputField
                  placeholder={t('FORM.fields.startDate')}
                  type="text"
                  onFocus={e => (e.target.type = 'date')}
                  onBlur={e => (e.target.type = 'text')}
                  {...register('startDate', { required: true })}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <StyledInputField placeholder={t('FORM.fields.address')} type="text" {...register('streatAddress', { required: true })} />
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <StyledInputField placeholder={t('FORM.fields.city')} type="text" {...register('city', { required: true })} />
              </Col>
              <Col>
                <StyledInputField placeholder={t('FORM.fields.postcode')} type="text" {...register('postCode', { required: true })} />
              </Col>
              <Col>
                <StyledInputField placeholder={t('FORM.fields.country')} type="text" {...register('country', { required: true })} />
              </Col>
              <Col></Col>
              <Col sm={12}>
                <StyledTextareaField placeholder={t('FORM.fields.description')} type="text" {...register('description', { required: true })} />
              </Col>
            </Row>
            <StyledDialogButtonActions>
              <CustomButton
                text={t('BUTTON.SendOffer')}
                customFontSize="18px"
                customWidth="300px"
                customHeight="50px"
                customTextColor={WHITE}
                customBorderColor={APP_RED}
                type="submit"
              />
            </StyledDialogButtonActions>
          </form>
        </SendOffersDialog>
        <PreviewDialog {...previewDetails}>
          <StyledWorkerInfoPreviewWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewWorker?.workerStreet && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.address')}: </span>
                    {previewWorker?.workerStreet}
                  </p>
                )}
                {previewWorker?.workerCity && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.city')}:</span>
                    {previewWorker?.workerCity}
                  </p>
                )}
                {previewWorker?.workerPostcode && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.postcode')}: </span>
                    {previewWorker?.workerPostcode}
                  </p>
                )}
                {previewWorker?.workerCountry && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.country')}: </span>
                    {previewWorker?.workerCountry}
                  </p>
                )}
                {previewWorker?.workerBirthdate && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.birthdate')}: </span>
                    {formatDate(new Date(previewWorker?.workerBirthdate))}
                  </p>
                )}
                {previewWorker?.workerCitizenship && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.citizenship')}: </span>
                    {previewWorker?.workerCitizenship}
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <StyledInfoLine style={{ paddingTop: '20px' }}>
                {previewWorker?.workerMobilePhone && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.mobilePhone')}: </span>
                    {previewWorker?.workerMobilePhone}
                  </p>
                )}
                {previewWorker?.workerEmail && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.contactEmail')}: </span>
                    {previewWorker?.workerEmail}
                  </p>
                )}
                {previewWorker?.workerDriverLicenses && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('EMPLOYEE_PROFILE.MY_PROFILE.driverLicences')}: </span>
                    {previewWorker?.workerDriverLicenses}
                  </p>
                )}
                {previewWorker?.workerHourlyRate && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perHour')}: CHF </span>
                    {previewWorker?.workerHourlyRate}.-
                  </p>
                )}
                {previewWorker?.workerMonthlyRate && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}> {t('OFFERCARD.salaryDisplay.perMonth')}: CHF </span>
                    {previewWorker?.workerMonthlyRate}.-
                  </p>
                )}
              </StyledInfoLine>
            </StyledInfoWrapper>
          </StyledWorkerInfoPreviewWrapper>
          <StyledWorkerDocumentsWrapper>
            <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.documents')}</h4>
            <StyledDocumentsWrapper>
              {(previewWorker?.workerFiles || []).map(file => (
                <StyledDocumentLine key={`file-${file.id}`}>
                  <p onClick={() => window.open(`${BE_URL}${file.url}`, '_blank')}>
                    <span>
                      <FontAwesomeIcon icon={faFileAlt} />
                    </span>
                    {file.name}
                  </p>
                </StyledDocumentLine>
              ))}
            </StyledDocumentsWrapper>
          </StyledWorkerDocumentsWrapper>
          <Row>
            <StyledColWrapper>
              {width >= 900 ? (
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</h4>
              ) : (
                <h4>
                  <div style={{ fontWeight: 'bolder' }}>{t('EMPLOYEE_PROFILE.MY_PROFILE.workExperience')}</div>
                </h4>
              )}
              {previewWorker?.workerExperience && previewWorker?.workerExperience?.length ? (
                previewWorker?.workerExperience.map(
                  work =>
                    work.companyName &&
                    work.position && (
                      <>
                        <strong>
                          {work.companyName}, {work.position}
                        </strong>
                        <p>
                          {new Date(work.startDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : `${new Date(work.startDate).toLocaleDateString('en-GB')} |`}{' '}
                          {new Date(work.endDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : new Date(work.endDate).toLocaleDateString('en-GB')}
                        </p>
                      </>
                    )
                )
              ) : (
                <></>
              )}
            </StyledColWrapper>
            <StyledColWrapper>
              {width >= 900 ? (
                <h4>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</h4>
              ) : (
                <h4>
                  <div style={{ fontWeight: 'bolder' }}>{t('EMPLOYEE_PROFILE.MY_PROFILE.education')}</div>
                </h4>
              )}
              {previewWorker?.workerEducation?.length ? (
                previewWorker?.workerEducation.map(work => (
                  <>
                    <strong>
                      {work.name}, {work.type}
                    </strong>
                    <p>
                      {new Date(work.startDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : `${new Date(work.startDate).toLocaleDateString('en-GB')} |`}{' '}
                      {new Date(work.endDate).toLocaleDateString('en-GB') === '01/01/1970' ? null : new Date(work.endDate).toLocaleDateString('en-GB')}
                    </p>
                  </>
                ))
              ) : (
                <></>
              )}
            </StyledColWrapper>
          </Row>
          <Row>
            {' '}
            {previewWorker?.workerBio && (
              <StyledColWrapper style={{ paddingTop: '20px' }}>
                <h4>{t('FORM.fields.aboutMe')}</h4>
                <StyledPreviewDialogFooterInfo>{previewWorker?.workerBio}</StyledPreviewDialogFooterInfo>{' '}
              </StyledColWrapper>
            )}
          </Row>
        </PreviewDialog>
      </>
    </ProfilePageContainer>
  );
};

export default withTranslations(Search, 'Search');
