/* eslint-disable complexity */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

import {
  StyledImage,
  StyledImageWrapper,
  StyledPreviewDialog,
  StyledPreviewDialogBody,
  StyledPreviewDialogCloseIcon,
  StyledPreviewDialogHeader,
  StyledPreviewDialogTitle,
  StyledPreviewDialogWrapper,
  StyledHalfBorder,
  StyledPreviewDialogSubtitle,
  StyledImageRow,
  StyledImageTextRow
} from 'components/SendOffersDialog/styles.js';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import { closeDialog } from 'redux/modules/dialogs/reducer';
import UserImageRounded from 'assets/images/user-image.jpeg';

const SendOffersDialog = ({ title = 'Send Job Offer', subtitle, logoUrl, children }) => {
  const { sendOfferDialogOpened } = useSelector(dialogSelector);
  // const { previewDialogOpened } = useSelector(dialogSelector);
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    dispatch(
      closeDialog({
        dialogName: 'sendOfferDialog'
      })
    );
  };
  return sendOfferDialogOpened ? (
    <StyledPreviewDialog>
      <StyledPreviewDialogWrapper>
        <StyledPreviewDialogHeader>
          <StyledPreviewDialogCloseIcon onClick={handleCloseDialog} />
          <StyledImageWrapper>
          <StyledImageRow>
            <StyledHalfBorder />
            <StyledImage height="110" width="110" src={logoUrl || UserImageRounded} />
            </StyledImageRow>
            <StyledImageTextRow>
            <StyledPreviewDialogTitle>{title}</StyledPreviewDialogTitle>
            {subtitle && <StyledPreviewDialogSubtitle>{subtitle}</StyledPreviewDialogSubtitle>}
            </StyledImageTextRow>
          </StyledImageWrapper>
        </StyledPreviewDialogHeader>
        <StyledPreviewDialogBody>
          <hr />
          {children}
        </StyledPreviewDialogBody>
      </StyledPreviewDialogWrapper>
    </StyledPreviewDialog>
  ) : null;
};

export default SendOffersDialog;
