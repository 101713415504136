import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';

import { countWorkers, fetchWorkersList } from '../asyncThunks';

const initialState = {
  workers: [],
  workersCount: 0,
  status: ASYNC_STATUS.IDLE
};

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: {
    [fetchWorkersList.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [fetchWorkersList.fulfilled]: (state, { payload }) => {
      state.workers = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [countWorkers.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [countWorkers.fulfilled]: (state, { payload }) => {
      state.workersCount = payload;
      state.status = ASYNC_STATUS.IDLE;
    }
  }
});

export default workersSlice.reducer;
