import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';

import { StyledToastContainer } from 'components/CustomToast/styles';

const CustomToast = ({ message, type, isOpen, closeToast, delay }) => (
  <StyledToastContainer position="top-end">
    <Toast bg={type} show={isOpen} delay={delay} autohide onClose={() => closeToast()}>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  </StyledToastContainer>
);

CustomToast.defaultProps = {
  isOpen: false,
  message: null,
  closeToast: () => {},
  type: null,
  delay: 3000
};

CustomToast.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
  closeToast: PropTypes.func,
  delay: PropTypes.number
};

export default CustomToast;
