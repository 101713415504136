import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageHeaderTitle from 'components/LandingPageHeaderTitle';

import { closeDialog } from 'redux/modules/dialogs/reducer';
import { openDialog } from 'redux/modules/dialogs/reducer';
import { dialogSelector } from 'redux/modules/dialogs/selectors';
import CustomButton from 'components/CustomButton';

import { WHITE } from 'constants/colors';
import {
  Footer,
  RegisterDialogContainer,
  RegisterDialogWrapper,
  StyledMainWrapper,
  StyledPricingPlanWrapper,
  StyledPricingPlanDescriptionElement,
  StyledCloseIcon,
  Title,
  List,
  ListItem,
  SubList,
  SubListItem
} from './styles';

const RegisterPrivacyPolicy = () => {
  const { registerPrivacyPolicyOpened } = useSelector(dialogSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeDialogPrivacyPolicy = () => {
    dispatch(closeDialog({ dialogName: 'registerPrivacyPolicy' }));
    handleOpenRegisterDialog();
  };

  const handleOpenRegisterDialog = () => {
    dispatch(
      openDialog({
        dialogName: 'registerDialog'
      })
    );
  };

  const renderPrivacyPolicy = () => (
    <><div style={{padding: "10px"}}>
      <Title>Datenschutzerklärung - Inhaltsverzeichnis</Title>
      <List>
        <ListItem>
          <a href="#Allgemeines">1. Allgemeines</a>
        </ListItem>
        <ListItem>
          <a href="#Hosting">2. Hosting</a>
        </ListItem>
        <ListItem>
          <a href="#Erhebung">3. Erhebung, Nutzung und Bearbeitung personen- und firmenbezogener Daten</a>
        </ListItem>
        <ListItem>
          <a href="#Zweck">4. Zweck der Datenbearbeitung und Rechtsgrundlagen</a>
        </ListItem>
        <ListItem>
          <a href="#Weitergabe">5. Weitergabe an Dritte</a>
        </ListItem>
        <ListItem>
          <a href="#Auskunft">6. Auskunft, Löschung und Berichtigung</a>
        </ListItem>
        <ListItem>
          <a href="#Datensicherheit">7. Datensicherheit</a>
        </ListItem>
        <ListItem>
          <a href="#Cookies">8. Cookies</a>
          <SubList>
            <SubListItem>
              <a href="AdSense">8.1. Google AdSense</a>
            </SubListItem>
            <SubListItem>
              <a href="#Analytics">8.2. Google Analytics</a>
              <SubList>
                <SubListItem>
                  <a href="#Remarketing">8.3. Google Analytics Remarketing</a>
                </SubListItem>
                <SubListItem>
                  <a href="#Conversion">8.4. Google AdWords und Google Conversion-Tracking</a>
                </SubListItem>
              </SubList>
            </SubListItem>
            <SubListItem>
              <a href="#Facebook">8.5. Facebook Pixel, Facebook Custom Audiences und Facebook-Conversion</a>
            </SubListItem>
          </SubList>
        </ListItem>
        <ListItem>
          <a href="#Plugins">9. Plugins</a>
        </ListItem>
        <SubList>
          <SubListItem>
            <a href="Facebook-Plugins">9.1. Facebook-Plugins</a>
          </SubListItem>
          <SubListItem>
            <a href="LinkedIn">9.2. LinkedIn</a>
          </SubListItem>
          <SubListItem>
            <a href="Instagram">9.3 Instagram</a>
          </SubListItem>
        </SubList>
        <ListItem>
          <a href="#Anderungen">Änderungen</a>
        </ListItem>
      </List>
      <>
      <h3 id="Allgemeines">1. Geltungsbereich</h3>
      <p>
        Die Bossasto GmbH ist der Betreiber der Website www.bossasto.ch (bezeichnet als "Bossasto") ist sie Anbieterin von Dienstleistungen für den Schweizer Markt, die
        auf der Website angeboten werden. Als solches ist Bossasto für die Sammlung, Speicherung, Verarbeitung und Nutzung Ihrer Daten verantwortlich und hat
        sicherzustellen, dass sie im Einklang mit dem Schweizer Recht und der EU- Datenschutzgrundverordnung (DSGVO) sowie anderen anwendbaren Gesetzen steht. Der Schutz
        Ihrer persönlichen Daten ist bei Bossasto von höchster Bedeutung. In dieser Datenschutzerklärung informieren wir Sie darüber, welche personenbezogenen Daten auf
        www.bossasto.ch gesammelt werden und wie sie genutzt werden. Personenbezogene Daten beziehen sich auf alle Informationen, die sich auf eine bestimmte oder
        identifizierbare Person beziehen. Sie erfahren auch, wie Sie die Richtigkeit dieser Informationen überprüfen und die Löschung Ihrer Daten bei uns veranlassen
        können. Diese Datenschutzerklärung ist keine vollständige Beschreibung unserer Verfahren zur Sammlung oder Verarbeitung von personenbezogenen Daten; eventuell
        gelten andere Regelungen (z.B. Allgemeine Geschäftsbedingungen und ähnliche Dokumente) für spezifische Fälle.
      </p>
      <p>
        Bossasto arbeitet nicht direkt mit Arbeitgebern (z.B. Baufirmen) zusammen, sondern stellt den Arbeitgebern und den Arbeitnehmern die Website zur Verfügung, um
        sich bei finden zu können. Um dies zu erreichen, stellen Sie Ihre Daten auf bossasto.ch und die Arbeitgeber haben Einsicht. Bitte beachten Sie, dass diese
        Datenschutzerklärung von Zeit zu Zeit ändern kann. Daher empfehlen wir Ihnen, diese Datenschutzerklärung regelmäßig zu überprüfen, um sicherzustellen, dass Sie
        stets über die neuesten Änderungen informiert sind.
      </p>

      <h3 id="Hosting">2. Hosting</h3>
      <p>
        Das Hosting wird von Hostpoint AG, Neue Jonastrasse 60, 8640 Rapperswil-Jona, Schweiz, bereitgestellt. Nebst Hostpoint AG arbeiten wir zusätzlich mit Strapi und
        Heroku. Es umfasst Infrastruktur- und Plattformdienstleistungen, Datenbankdienste, Rechenleistung, Sicherheitsmaßnahmen und Speicherplatz sowie technische
        Wartungsdienstleistungen, die für den Betrieb unserer Onlineangebote erforderlich sind.
      </p>

      <h3>3. Erhebung, Nutzung und Bearbeitung personen- und firmenbezogener Daten</h3>
      <p id="Erhebung">
        Wir verarbeiten Ihre Personendaten nicht sondern speichern diese nur in unsere Datenbank, so dass die Arbeitgeber oder auch Arbeitnehmer auf bossasto.ch diese
        sehen können. Wir verarbeiten die Personaldaten von Arbeitnehmern erst dann, wenn die Person ausdrücklich um Unterstützung auf eine Festvermittlung anfragt.
      </p>
      <p>
        Beim Besuch von www.bossasto.ch werden aus technischen Gründen bestimmte Daten automatisch für Systemverwaltung, Statistiken oder Sicherungszwecke auf unseren
        Servern gespeichert, darunter: den Namen Ihres Internet Service Providers, die Website, von der aus Sie uns besuchen die Webseiten, die Sie bei uns besuchen das
        Datum und die Dauer Ihres Besuches, Ihre IP-Adresse.
      </p>
      <p>
        Wenn Sie bossasto.ch besuchen, werden bestimmte Daten automatisch auf unseren Servern für Zwecke der Systemadministration, Statistik und Sicherheit gespeichert.
        Diese Daten können eventuell auf einen bestimmten Besucher schließen lassen, jedoch werden keine personenbezogenen Daten verarbeitet. Die Verwendung solcher Daten
        erfolgt ausschließlich anonym. Darüber hinaus werden folgende Daten gespeichert, Bestandsdaten, Kontaktdaten, Inhaltsdaten, Nutzungsdaten, Meta- und
        Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebots.
      </p>
      <p>
        Mit Ihrer Registrierung als Arbeitnehmer oder Arbeitgeber auf www.bossasto.ch und der Eingabe Ihrer Daten erklären Sie sich damit einverstanden, dass Ihre
        angegebenen Daten für die Erbringung der von Ihnen in Anspruch genommenen Dienstleistungen verwendet werden dürfen. Mit der Anmeldung als Arbeitnehmer oder
        Arbeitgeber auf www.bossasto.ch erklären Sie sich einverstanden, dass Ihre angegebenen Daten zur Dienstleistungsnutzung verwendet werden. Darüber hinaus stimmen
        Sie bis auf Widerruf zu, dass Bossasto Ihre Daten für Werbezwecke verwendet. Hierbei stimmen Sie insbesondere zu, dass Bossasto Sie über Ihre angegebene
        E-Mail-Adresse und Mobilnummer (SMS, Whatsapp) kontaktieren und Ihnen personalisierte Werbung, einschließlich Informationen über passende Stellenangebote,
        zukommen lässt. Sollten Sie mit der Verwendung Ihrer Daten zu Werbezwecken nicht mehr einverstanden sein, können Sie dies jederzeit durch eine E-Mail an
        info@bossasto.ch widerrufen. Dieser Widerruf hat jedoch keine Auswirkungen auf bereits durchgeführte Datenverarbeitungen.
      </p>
      <p>
        <h3 id="Zweck">4. Zweck der Datenbearbeitung und Rechtsgrundlagen</h3>
      </p>
      <p>
        Wir nutzen die von Ihnen bereitgestellten Personendaten hauptsächlich, um Sie mit potentiellen Arbeitgebern zu verbinden sowie die Unternehmen au Sie aufmerksam
        zu machen und um unsere Dienstleistungen im Rahmen der Arbeitsvermittlung zu erbringen. Darüber hinaus nutzen wir die Daten, um unseren gesetzlichen
        Verpflichtungen innerhalb und ausserhalb des Landes nachzukommen.
      </p>
      <p>
        Zusätzlich verarbeiten wir personenbezogene Daten, soweit dies gesetzlich erlaubt und angemessen ist, um unser berechtigtes Interesse und das von Dritten zu
        erfüllen:<br></br>
        Wir nutzen die erhobenen Personendaten auch, um unser Angebot, Dienstleistungen und Website, zu verbessern und weiterzuentwickeln, sofern es erlaubt und
        angemessen ist. Wir bearbeiten Personendaten für verschiedene Zwecke, darunter Kommunikation mit Dritten, Analyse von Bedürfnissen für direkte
        Kundenkommunikation, Marktforschung und Meinungsumfragen, Verteidigung rechtlicher Ansprüche, Verhinderung und Aufdeckung von Straftaten, Gewährleistung unseres
        Geschäftsbetriebs, einschließlich unserer IT und Webseite.
      </p>

      <h3 id="Weitergabe">5. Weitergabe an Dritte</h3>
      <p>
        Wir geben Ihre Daten, nur auf Ihren Wunsch oder im Rahmen unserer Geschäftstätigkeit erforderlich ist, an Arbeitgebern (wie beispielsweise Baufirmen, Spitälern
        usw.) weiter, um Ihre Anfragen (z.B. Bewerbungen oder Anfragen aus den Medien) zu bearbeiten. Die Arbeitgeber können die Daten von Arbeitnehmer mit den offenen
        Vakanzen vergleichen und prüfen, ob Sie auf die Stelle passen und kann die Arbeitgeber direkt kontaktieren. In all diesen Fällen unterliegt die weitere
        Verarbeitung der Daten durch den Bossasto-User (Arbeitgeber und Arbeitnehmer) den Datenschutzbestimmungen und der Kontrolle des jeweiligen Bossasto -User. Der
        Bossasto-User ist für die Einhaltung aller anwendbaren Datenschutzbestimmungen verantwortlich, einschließlich sollte der Bossasto-User der Löschung der Daten
        wünschen. Sobald der Bossasto-User die Daten vom Profil löscht, wird es auch vom Server gelöscht. Wenn Arbeitnehmer Fragen zur Verarbeitung der Daten durch den
        Arbeitgeber haben (z.B. eine Anfrage zur Löschung der Daten), wendet sich der Arbeitnehmer bitte direkt an den jeweiligen Arbeitgeber.
      </p>
      <p>
        Wir achten auf den Schutz Ihrer personen- oder firmenbezogenen Daten und sorgen dafür, dass jegliche Weitergabe an externe Dienstleister untersagt ist. Dabei
        nutzen, bearbeiten und weitergeben wir Ihre Daten nur im zulässigen Rahmen oder gemäss Ihrer Einwilligung. Die Übertragung der Daten von Arbeitnehmern an externe
        Arbeitgeber ist ausschliesslich nur mit Ihrer Einwilligung und unterliegt deren Zustimmung zu der entsprechenden Geheimhaltungs- und Sorgfaltspflicht. Wir geben
        Ihre Daten auch nur dann weiter, wenn wir rechtlich dazu verpflichtet sind oder aufgrund behördlicher oder gerichtlicher Anordnungen. Eine Übermittlung Ihrer
        Daten außerhalb der Schweiz oder Europa findet nicht statt. Sie stimmen zu, dass Bossasto Ihre personenbezogenen Daten, die Sie im Zusammenhang mit Ihrer
        Bewerbung bereitstellen, innerhalb von bossasto.ch der Bossasto sowohl im In- als auch im Ausland zum Zweck der Arbeitsvermittlung sowie Personalvermittlung
        speichern, bearbeiten oder übertragen darf.
      </p>
      <p>
        Der Arbeitnehmer stimmt zu, dass Bosssato personenbezogene Daten, die er im Zusammenhang mit seiner Registrierung, während und nach dem Vermittlungsprozess
        weiterhin auf bossasto.ch gespeichert ist. Jedoch nach Annahme einer Stelle ist der Arbeitnehmer anonym und nicht mehr sichtbar für andere Arbeitgeber. Sollte der
        Arbeitnehmer die Löschung seines Profils wollen, so kann er per Mail auf info@bossasto.ch anfragen. Die gespeicherten Daten vom Arbeitnehmer kann er jederzeit vom
        Profil löschen. Wenn der Arbeitnehmer im Zusammenhang mit seiner Registration besonders geschützte Daten oder "besondere Kategorien personenbezogener Daten" nach
        Artikel 9 der EU-Datenschutzgrundverordnung angibt, bezieht sich seine Zustimmung auch auf diese Daten.
      </p>
      <p>
        <h3 id="Auskunft">6. Auskunft, Löschung und Berichtigung</h3>
      </p>
      <p>Bei bossasto.ch sind nur die Daten gespeichert, welche der Bossasto-User hochgeladen hat.</p>
      <p>
        Sie können selbst jederzeit die Daten löschen oder Bossasto anfragen Ihr Profil von bossasto.ch zu löschen. Außerdem haben Sie das Recht, jederzeit Ihre
        Einwilligung in die Verwendung oder Verarbeitung Ihrer personenbezogenen Daten zurückzuziehen. Bitte senden Sie hierzu eine E-Mail an info@Bossasto.ch
      </p>
      <p>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten so lange, wie es für die Erfüllung unserer vertraglichen und gesetzlichen Verpflichtungen oder für die
        Zwecke, die mit der Verarbeitung verbunden sind, erforderlich ist. Im Bereich der Festvermittlung kann diese beispielsweise für die Dauer der gesamten
        Geschäftsbeziehung gelten und auch darüber hinaus, gemäß den gesetzlichen Anforderungen zur Aufbewahrung und Dokumentation. Es kann sein, dass personenbezogene
        Daten für den Zeitraum aufbewahrt werden, in dem Ansprüche gegen Bossasto geltend gemacht werden können. Wenn Sie eine Löschung Ihrer Daten wünschen, ist zu
        beachten, dass wir gewissen gesetzlichen Verpflichtungen unterliegen, die für bestimmte Daten eine längere Aufbewahrungspflicht vorsehen. Wir müssen diesen
        Verpflichtungen nachkommen. Wenn Sie also die Löschung von Daten wünschen, die gesetzlichen Aufbewahrungspflichten unterliegen, werden diese Daten bei uns im
        System gesperrt und nur zur Erfüllung der gesetzlichen Aufbewahrungspflichten genutzt. Nach Ablauf der Aufbewahrungsfrist werden wir Ihrem Löschungsbegehren
        nachkommen.
      </p>
      <p>
        Jede betroffene Person hat das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. In der
        Schweiz ist die zuständige Datenschutzbehörde der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (http://www.edoeb.admin.ch). Name und Kontaktdaten
        des für die Bearbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten
      </p>
      <p>Für die Schweiz: Bossasto GmbH, Hohstrasse 1, 8302 Kloten, Schweiz</p>
      <p>
        Bei Kontaktaufnahme mit dem Datenschutzbeauftragten, geben Sie bitte das Unternehmen an, auf das sich Ihre Anfrage bezieht. Vermeiden Sie es, sensiblen
        Informationen wie zum Beispiel einer Kopie Ihres Ausweises beizufügen.
      </p>
      <p id="Datensicherheit"></p>
      <p>
        <h3>7. Datensicherheit</h3>
      </p>
      <p>
        Wir garantieren die sichere Aufbewahrung Ihrer Daten und ergreifen alle notwendigen technischen und organisatorischen Maßnahmen, um einen Verlust, unberechtigten
        Zugriff, Missbrauch oder Änderungen zu verhindern. Unsere Mitarbeiter und Geschäftspartner, die Zugang zu Ihren Daten haben, sind vertraglich zur Verschwiegenheit
        und Einhaltung der Datenschutzvorschriften verpflichtet. In einigen Fällen kann es erforderlich sein, dass wir Ihre Anfragen an verbundene Unternehmen
        weiterleiten. In jedem Fall werden Ihre Daten vertraulich behandelt.
      </p>

      <p id="Cookies"></p>
      <p>
        <h3>8. Cookies</h3>
      </p>
      <p>
        Um die Nutzung von bossasto.ch zu erleichtern, verwenden wir sogenannte Cookies. Cookies sind kleine Dateien, die beim Besuch unserer Webseite von Ihrem Browser
        auf der Festplatte Ihres Computers oder mobilen Geräts gespeichert werden und für die Benutzung von bossasto.ch erforderlich sind. Die in den Cookies enthaltenen
        Informationen dienen zur Steuerung der Sitzung und verbessern die Navigation, was eine hohe Benutzerfreundlichkeit ermöglicht. Die von uns verwendeten Cookies
        speichern keine personenbezogenen Informationen und ermöglichen es uns, Sie bei erneuten Besuchen unserer Webseite wiederzuerkennen, ohne dass wir wissen, wer Sie
        sind.
      </p>
      <p>
        Neben den sogenannten "Session Cookies", die nur während einer Sitzung verwendet und nach dem Verlassen der Webseite gelöscht werden, können auch "permanente
        Cookies" verwendet werden, um Einstellungen und andere Informationen für einen bestimmten Zeitraum zu speichern. Sie können Ihren Browser so einstellen, dass er
        Cookies ablehnt, nur für eine Sitzung speichert oder frühzeitig löscht. Standardmäßig akzeptieren die meisten Browser Cookies automatisch, aber Sie können dies
        ändern, indem Sie die Einstellungen Ihres Browsers anpassen. Gespeicherte Cookies auf Ihrem PC können jederzeit durch Löschen der temporären Internetdateien
        gelöscht werden.
      </p>
      <p>
        Wenn Sie Cookies deaktivieren, kann es sein, dass Sie nicht alle Funktionalitäten unserer Webseite nutzen können. Wir verwenden sowohl Session-Cookies als auch
        permanente Cookies auf bossasto.ch. Weitere Informationen dazu finden Sie in unserer AGB’s. Durch die Nutzung unserer Webseite und durch Ihre Zustimmung zum
        Empfang von Newslettern und anderen Marketing-E-Mails stimmen Sie der Verwendung von Cookies und vergleichbaren Technologien zu. Wenn Sie dies nicht möchten,
        müssen Sie Ihren Browser oder Ihr E-Mail-Programm entsprechend konfigurieren.
      </p>
      <p>
        Wir nutzen auf bossasto.ch auch Statistik- oder ähnliche Dienste von Dritten. Diese Dienstleister nutzen permanente Cookies, um die Nutzung unserer Webseite (ohne
        personenbezogene Daten) zu messen und auszuwerten. Der Dienstleister erhält von uns keine personenbezogenen Daten und speichert auch keine IP-Adressen, kann aber
        Ihre Nutzung der Webseite verfolgen, die Daten mit Daten von anderen Webseiten, die Sie besucht haben und ebenfalls vom Dienstleister verfolgt werden, kombinieren
        und für eigene Zwecke (z.B. Werbung) verwenden. Wenn Sie sich beim Dienstleister selbst registriert haben, kennt der Dienstleister Sie auch. Die Verarbeitung
        Ihrer Daten erfolgt dann durch den Dienstleister (z.B. Google) nach dessen Datenschutzbestimmungen. Bossasto teilt dem Dienstleister nur mit, wie unsere Webseite
        genutzt wird, nicht aber Informationen über Sie persönlich.
      </p>
      <p>
        <h4>8.1. Google AdSense</h4>
      </p>
      <p id="AdSense">
        Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Webseite (einschließlich der IP-Adresse der Bossasto-User) und Auslieferung
        von Werbeformaten werden an einen Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von
        Google weitergegeben werden. Google wird jedoch Ihre IP- Adresse nicht mit anderen Daten von Google in Verbindung bringen. Die Nutzer können die Installation der
        Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; dieses Angebot weist die Nutzer jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Webseite voll umfänglich nutzen können. Durch die Nutzung dieser Webseite erklären Sie sich mit der Bearbeitung
        der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
      </p>
      <p>
        Informationen über die Nutzung unserer Webseite, einschließlich der IP-Adresse der Benutzer, die durch Cookies und Web Beacons erhoben werden, werden an einen
        Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können an Google-Vertragspartner weitergegeben werden, aber Google wird Ihre
        IP-Adresse nicht mit anderen gespeicherten Daten, die Sie betreffen, kombinieren.
      </p>
      <p>
        Sie haben die Möglichkeit, durch eine entsprechende Einstellung Ihres Browser-Programms, die Verwendung von Cookies zu verhindern. Es muss jedoch beachtet werden,
        dass in diesem Fall einige Funktionen dieser Internetseite möglicherweise nicht vollständig zur Verfügung stehen. Durch die Nutzung dieser Webseite stimmen Sie
        der Verarbeitung Ihrer Daten durch Google in der beschriebenen Art und Weise und zu dem beschriebenen Zweck zu.
      </p>
      <p>
        <h4 id="Analytics">8.2. Google Analytics</h4>
      </p>
      <p>
        Bossasto nutzt Google Analytics, einen Webanalysedienst von Google Inc. mit Sitz in 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Dieser Dienst
        verwendet Cookies, kleine Textdateien, die auf dem Computer des Benutzers gespeichert werden, um eine Analyse der Nutzung der Website zu ermöglichen. Die durch
        die Cookies gesammelten Informationen über die Benutzung der Website werden üblicherweise an einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        IP Anonymisierung Auf unserer Website haben wir die Funktion "IP-Anonymisierung" aktiviert. Dies bedeutet, dass Ihre IP-Adresse von Google vor Übermittlung in die
        USA innerhalb der Mitgliedstaaten der Europäischen Union, der Schweiz oder anderer Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt
        wird. In seltenen Fällen wird die volle IP-Adresse jedoch an einen Server in den USA übertragen und dort verkürzt. Im Auftrag unseres Unternehmens wird Google
        diese Informationen verwenden, um Ihre Nutzung unserer Website zu analysieren, um Berichte über die Website-Aktivitäten zu erstellen und um weitere
        Dienstleistungen im Zusammenhang mit der Website-Nutzung und dem Internet-Verbrauch bereitzustellen. Die von Ihrem Browser übermittelte IP-Adresse, die im Rahmen
        von Google Analytics übermittelt wird, wird nicht mit anderen Daten von Google zusammengeführt.
      </p>
      <p>Demografische Merkmale bei Google Analytics:</p>
      <p>
        Bossasto nutzt die Funktion "Demografische Merkmale" von Google Analytics. Dies ermöglicht es uns, Berichte zu erstellen, die Aussagen über das Alter, Geschlecht
        und die Interessen unserer Besucher enthalten. Diese Daten werden aus interessensbasierter Werbung von Google und aus Besucherdaten Dritter gewonnen. Diese Daten
        können jedoch keiner bestimmten Person zugeordnet werden. Sie haben jederzeit die Möglichkeit, diese Funktion über die Anzeigeneinstellungen in Ihrem Google-
        Konto zu deaktivieren oder die Datenerfassung durch Google Analytics generell zu untersagen.
      </p>
      <p>Datenschutzerklärung von Google Analytics:</p>
      <p>
        Weitere Informationen zur Verwaltung von Nutzerdaten durch Google Analytics finden Sie in der Datenschutzerklärung von Google unter folgendem Link:
        https://support.google.com/analytics/answer/6004245?hl=de.
      </p>
      <p>Browser Plugin:</p>
      <p>
        Sie können die Speicherung von Cookies durch eine entsprechende Einstellung in Ihrer Browser- Software verhindern. Beachten Sie jedoch, dass in diesem Fall
        möglicherweise nicht alle Funktionen dieser Website vollständig nutzbar sind. Darüber hinaus können Sie die Übertragung der durch den Cookie erfassten Daten, die
        mit Ihrer Nutzung der Website verbunden sind (einschließlich Ihrer IP- Adresse), an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie
        das Browser-Plugin herunterladen und installieren, das unter dem folgenden Link verfügbar ist: https://tools.google.com/dlpage/gaoptout?hl=de.
      </p>
      <p>Grundlage:</p>
      <p>
        Die Verwendung von Google Analytics-Cookies basiert auf einem legitimen Interesse, da wir ein berechtigtes Interesse an der Analyse des Verhaltens der Nutzer
        haben, um sowohl unser Online- Angebot als auch unsere Werbung zu verbessern.
      </p>
      <p>Widerspruch gegen die Überwachung Ihrer Daten.:</p>
      <p>
        Sie haben die Möglichkeit, die Überwachung Ihrer Daten durch Google Analytics zu unterbinden, indem Sie ein entsprechendes Browser-Plugin installieren. Dies setzt
        einen Opt-Out-Cookie, welcher zukünftige Überwachung Ihrer Daten bei Besuchen dieser Website verhindert. Klicken Sie dazu auf den folgenden Link:
        https://tools.google.com/dlpage/gaoptout?hl=de.
      </p>
      <p>
        <h3>8.3. Google Analytics Remarketin</h3>
      </p>
      <p id="Remarketing">
        Durch die Verwendung von Google Analytics Remarketing werden Daten, wie die Besucherverhaltensmuster, genutzt, um personalisierte, interessenbezogene Anzeigen für
        Besucher unserer Website zu erstellen, die auch außerhalb unserer Website auf anderen Plattformen angezeigt werden. Dies beinhaltet das Targeting von Werbung auf
        Basis von früheren Interaktionen mit unserer Website und anderen Faktoren wie dem Suchverlauf, den Interessen und dem Gerätetyp.
      </p>
      <p>
        Die Remarketing-Funktion von Google Analytics kombiniert mit den geräteübergreifenden Funktionen von Google AdWords und DoubleClick ermöglicht es, personalisierte
        Werbung aufgrund früherer Nutzungs- und Surfgewohnheiten auf verschiedenen Endgeräten wie Handy, Tablet oder PC anzuzeigen. Hierfür müssen Sie eine entsprechende
        Einwilligung erteilen und Ihr Google-Konto mit Ihrem Web- und App-Browserverlauf verknüpfen. Google erfasst hierfür google-authentifizierte IDs der Nutzer und
        verknüpft diese vorübergehend mit den Google Analytics-Daten, um Zielgruppen für die geräteübergreifende Werbung zu erstellen. Diese können Sie hier einsehen:
        https://policies.google.com/privacy?hl=de&gl=de. Hier finden Sie auch Informationen darüber, wie Sie Ihre Einstellungen im Bereich der Datennutzung verwalten und
        ändern können. Die Übermittlung von Daten in Ihr Google-Konto erfolgt aufgrund Ihrer Einwilligung, die Sie Google geben oder jederzeit widerrufen können. Wenn Sie
        kein Google-Konto haben oder der Übermittlung Ihrer Daten widersprochen haben, erfolgt die Erfassung Ihrer Daten aufgrund unseres berechtigten Interesses. Dieses
        berechtigte Interesse besteht darin, dass wir ein Interesse an einer anonymisierten Analyse unserer Website-Besucher zu Werbezwecken haben. Weitere Informationen
        und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google.
      </p>
      <p>Widerspruch:</p>
      <p>
        Sie haben die Möglichkeit, der Verwendung und Speicherung von "Conversion-Cookies" zu widersprechen. Dies können Sie durch die Deaktivierung der personalisierten
        Werbung in Ihrem Google-Konto dauerhaft tun. Auf diese Weise können Sie das geräteübergreifende Remarketing/Targeting verhindern.
      </p>
      <p>
        <h3 id="Conversion">8.4. Google AdWords und Google Conversion-Tracking</h3>
      </p>
      <p>Bossasto nutzt das Online-Werbeprogramm "Google AdWords" von der Firma Google Inc. mit Sitz in Mountain View, CA 94043, United States.</p>
      <p>
        Wir nutzen das Conversion-Tracking von Google AdWords auf bossasto.ch. Wenn Sie auf eine Anzeige von Google klicken, wird ein Textdatei-Cookie gesetzt, das für
        das Conversion-Tracking verwendet wird. Diese Cookies, die nach 30 Tagen ablaufen, speichern keine Informationen, die es ermöglichen, den Benutzer zu
        identifizieren. Wenn Sie bestimmte Seiten auf unserer Website besuchen, während das Cookie noch gültig ist, können Google und wir erkennen, dass Sie auf die
        Anzeige geklickt haben und zu unserer Website weitergeleitet wurden.
      </p>
      <p>
        Sie haben die Möglichkeit, die Verwendung von Cookies in Ihrem Browser zu regulieren. Dazu können Sie Einstellungen vornehmen, die Sie über das Setzen von Cookies
        informieren, Cookies nur in bestimmten Fällen erlauben oder generell blockieren, sowie das automatische Löschen der Cookies beim Schliessen des Browsers
        aktivieren. Bei einer Deaktivierung von Cookies kann es zu Einschränkungen in der Funktionalität dieser Website kommen. Jeder Kunde von Google AdWords erhält ein
        individuelles Cookie, das nicht über die Websites anderer AdWords-Kunden nachverfolgt werden kann. Die mithilfe des Conversion-Cookies gesammelten Informationen
        werden genutzt, um Conversion-Statistiken für AdWords-Kunden zu erstellen, die Conversion-Tracking nutzen. Diese Kunden erhalten eine Übersicht über die
        Gesamtzahl der Nutzer, die auf ihre Anzeigen geklickt haben und auf eine Seite weitergeleitet wurden, die mit einem Conversion-Tracking-Tag versehen ist.
        Allerdings erhalten sie keine Informationen, die es ermöglichen, Bossasto-User persönlich zu identifizieren.
      </p>
      <p>
        Bossasto.ch und die Bossasto-App verwenden Cookies, um Sie von anderen Benutzern unserer Website oder App zu unterscheiden. Dies ermöglicht es uns, Ihnen einen
        angenehmen Besuch auf bossasto.ch und die Bossasto-App zu gewährleisten und diese weiter zu verbessern.
      </p>
      <p>Datenschutzerklärung von Google AdWords:</p>
      <p>
        Zusätzliche Informationen zu Google AdWords und dem Google Conversion-Tracking stehen in den Datenschutzrichtlinien von Google zur Verfügung.
        <p>Widerspruch (Datenschutzerklärung von Google) doppelt</p>
        <p>
          Wir möchten Ihnen die Wahl geben, ob Sie am Tracking teilnehmen möchten oder nicht. Daher bieten wir Ihnen die Möglichkeit, der Verwendung und Speicherung von
          "Conversion-Cookies" zu widersprechen. Sie können dies tun, indem Sie die Deaktivierung des Google Conversion-Tracking- Cookies über Ihren Internet-Browser in
          den Nutzereinstellungen vornehmen. Durch diese Aktion werden Sie nicht in die Conversion-Tracking-Statistiken einbezogen.
        </p>
        <p>
          <h3>8.5. Facebook Pixel, Facebook Custom Audiences und Facebook-Conversion</h3>
        </p>
        <p id="Facebook">
          Mit dem Einsatz des Facebook-Pixels können wir die Wirksamkeit der Facebook-Werbung für statistische und Marktforschungszwecke erfassen, indem wir feststellen
          können, ob ein Nutzer nach Klick auf eine Facebook-Werbung auf unsere Website weitergeleitet wurde. Das Facebook-Pixel wird beim Besuch unserer Website im
          Rahmen einer Facebook-Werbekampagne gesetzt und ermöglicht so eine nachträgliche Analyse der Wirksamkeit derselben. Hierbei werden Daten, wie beispielsweise die
          Besucherzahlen auf unserer Website, an Facebook übertragen. Wir können so das Verhalten unserer Nutzer auf unserer Website nachvollziehen, um unser Angebot und
          unsere Werbung zu verbessern. Diese Daten werden von Facebook ausschließlich pseudonymisiert erfasst und verarbeitet, was bedeutet, dass Facebook keine
          Rückschlüsse auf die identität des Nutzers ziehen kann.
        </p>
        <p>
          Weitere Informationen zur Datenverarbeitung durch Facebook können Sie den Datenschutzrichtlinien von Facebook entnehmen. Das Facebook-Pixel ermöglicht es uns
          auch, die Wirksamkeit der Facebook- Werbeanzeigen für statistische und Marktforschungszwecke zu überwachen. Hierbei können wir sehen, ob Nutzer, nachdem sie auf
          eine Facebook-Werbeanzeige geklickt haben, auf unsere Website weitergeleitet wurden (sogenannte "Conversions"). Es ist jedoch zu beachten, dass Facebook
          aufgrund des Einsatzes des Facebook-Pixels Informationen über den Besuch bei bossasto.ch sammeln kann.
        </p>
        <p>
          Hierbei kann es zu einer Verbindung zwischen Ihrem Besuch und Ihrem Facebook-Profil kommen. Informationen über die Verwendung des Facebook-Pixels und die
          Verarbeitung der hierbei gewonnenen Daten finden Sie in der Datenschutzerklärung von Facebook. Wenn Sie nicht möchten, dass Facebook über das Facebook-Pixel
          Daten über Sie sammelt, können Sie hierfür in Ihren Facebook- Einstellungen ein Opt-Out setzen.
        </p>
        <p>Datenschutzerklärung von Facebook:</p>
        <p>
          Zusätzlich können Sie auch Ihre Einstellungen im Bereich Datenschutz bei Facebook anpassen, um die Verwendung Ihrer Daten für Werbezwecke zu kontrollieren. Um
          einzustellen, welche Anzeigen Sie auf Facebook sehen, können Sie auch die „Anzeigeneinstellungen“ verwenden. Dort können Sie auch die Verwendung Ihrer Daten für
          Werbezwecke deaktivieren oder beschränken.
        </p>
        <p>Grundlage</p>
        <p>
          Obwohl wir berechtigte Interessen zur Verwendung und Speicherung von «Conversion-Cookies» und dem Facebook Pixel anmelden, bieten wir Ihnen
          Opt-Out-Möglichkeiten an. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie die entsprechenden Cookies über
          Ihren Internet-Browser unter Nutzereinstellungen deaktivieren. Bitte beachten Sie, dass Sie in diesem Fall eventuell nicht alle Funktionalitäten unseres
          Onlineangebotes nutzen können.
        </p>
        <p>Widerspruch</p>
        <p>
          Obwohl wir berechtigte Interessen für die Verwendung des Facebook-Pixels und die Speicherung von "Conversion-Cookies" haben, bieten wir Ihnen die Möglichkeit,
          sich dagegen zu entscheiden. Sie haben die Option, die Erfassung durch das Facebook-Pixel und die Verwendung Ihrer Daten zur Darstellung von Facebook-Werbung zu
          verhindern. Um zu bestimmen, welche Art von Werbung Ihnen auf Facebook angezeigt wird, können Sie die von Facebook eingerichtete Seite besuchen und die
          Anweisungen zu den Einstellungen nutzungsbasierter Werbung befolgen. Diese Einstellungen sind plattformunabhängig, d.h. sie werden für alle Geräte, wie
          Desktop-Computer oder Mobilgeräte, übernommen. Sie können sich auch gegen den Einsatz von Cookies, die zu Reichweitenmessung und Werbezwecken verwendet werden,
          durch den Besuch der Deaktivierungsseite der Netzwerkwerbeinitiative sowie der US-amerikanischen Webseite aboutads.info oder der europäischen Webseite
          youronlinechoices.com entscheiden.
        </p>
        <p>
          <h3 is="Plugins">9. Plugins</h3>
        </p>
        <p>
          Wir verwenden auf unseren Webseiten auch Plug-Ins von sozialen Netzwerken wie Facebook, LinkedIn, etc. (siehe die Liste unten). Diese sind für Sie durch
          entsprechende Symbole erkennbar. Wir haben sie so eingerichtet, dass sie standardmäßig deaktiviert sind. Sobald Sie sie aktivieren (indem Sie darauf klicken
          oder wenn Sie in Ihrem Social-Media-Konto eingeloggt sind), können die Betreiber dieser sozialen Netzwerke erkennen, dass Sie auf unserer Webseite sind, und
          diese Informationen für ihre Zwecke nutzen. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt dann unter Verantwortung des jeweiligen Betreibers gemäß
          seinen Datenschutzbestimmungen. Bossasto erhält von dem Betreiber keine Informationen über Sie.
        </p>
        <p>
          <h4>9.1. Facebook-Plugins</h4>
        </p>
        <p>
          Unsere Website beinhaltet Plugins des sozialen Netzwerkes Facebook, ansässig bei 1601 South California Avenue, Palo Alto, CA 94304, USA. Die Facebook-Plugins
          sind anhand des Facebook-Logos oder dem "Gefällt mir"- oder "Teilen"-Button auf unseren Seiten zu erkennen. Wenn Sie unsere Seiten besuchen und das
          Facebook-Plugin aktivieren, wird eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook übermittelt den Inhalt des Plugins
          direkt an Ihren Browser, der es dann in die Website einbindet. Durch diese Verbindung erhält Facebook Informationen darüber, dass Sie unsere Seite besucht
          haben, auch wenn Sie kein Facebook-Konto haben oder sich zum Zeitpunkt des Besuchs nicht bei Facebook angemeldet haben.
        </p>
        <p>
          Wenn Sie unsere Seiten besuchen und das Facebook-Plugin aktivieren, wird eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
          Durch diese Verbindung wird Facebook automatisch über Ihren Besuch informiert und erhält dabei auch Ihre IP-Adresse. Wenn Sie bei Ihrem Facebook-Konto
          eingeloggt sind und den "Gefällt mir"-Button anklicken, können Sie den Inhalt unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den
          Besuch unserer Seiten mit Ihrem Benutzerkonto verknüpfen.
        </p>
        <p>
          Informationen über Ihr Engagement auf unseren Seiten werden ebenfalls direkt an Facebook übermittelt und dort gespeichert. Diese Informationen werden auf
          Facebook veröffentlicht und können von Ihren Freunden eingesehen werden. Facebook verwendet diese Informationen für Zwecke wie Werbung, Marktforschung und die
          Optimierung seiner Seiten. Hierfür erstellt Facebook Nutzungs- , Interessen- und Beziehungsprofile, um beispielsweise Ihre Nutzung unserer Webseite im Hinblick
          auf die angezeigten Facebook-Werbeanzeigen zu analysieren, andere Facebook-Nutzer über Ihre Aktivitäten auf unseren Seiten zu informieren und um weitere
          Dienstleistungen im Zusammenhang mit der Verwendung von Facebook bereitzustellen.
        </p>
        <p>
          Wir möchten darauf hinweisen, dass wir als Anbieter unserer Webseite keine Kenntnis über den Inhalt der von Ihnen an Facebook übermittelten Daten oder deren
          Verwendung haben. Informationen zur Datenerfassung (Zweck, Umfang, Verarbeitung und Verwendung) sowie Ihre Rechte und Optionen zur Datenverwaltung können Sie
          den Datenschutzrichtlinien von Facebook entnehmen, die unter https://www.facebook.com/about/privacy/ zu finden sind.
        </p>
        <p>
          Zur Vermeidung einer Zuordnung Ihres Besuchs unserer Webseite zu Ihrem Facebook-Nutzerkonto empfehlen wir Ihnen, sich vor der Nutzung unserer Seiten aus Ihrem
          Facebook-Account auszuloggen.
        </p>
        <p id="Facebook-Plugins">
          <h4>9.1. LinkedInPlugins</h4>
        </p>
        <p>
          Wenn Sie den "Empfehlen"-Button von LinkedIn betätigen, während Sie in Ihrem LinkedIn-Konto angemeldet sind, können Sie die Inhalte unserer Seiten auf Ihrem
          LinkedIn-Profil verlinken und dadurch andere Nutzer informieren. Dabei werden Informationen wie Ihre IP-Adresse direkt an einen Server von LinkedIn übermittelt
          und dort gespeichert.
        </p>
        <p>
          Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übertragenen Daten sowie deren Verwendung durch LinkedIn erhalten. Weitere
          Informationen zu Zweck, Umfang, weiterer Verarbeitung und Nutzung der Daten finden Sie in den Datenschutzrichtlinien von LinkedIn. Diese finden Sie unter
          https://www.linkedin.com/legal/privacy-policy. Wenn Sie nicht wünschen, dass LinkedIn den Besuch unserer Seiten Ihrem LinkedIn-Konto zuordnen kann, loggen Sie
          sich bitte vor dem Besuch unserer Seite aus Ihrem LinkedIn-Konto aus.
        </p>
        <p>
          Es ist wichtig zu beachten, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten und deren Nutzung durch LinkedIn haben. Weitere
          Informationen zur Datenerhebung (Zweck, Umfang, Verarbeitung, Nutzung) und Ihre Rechte und Einstellungsmöglichkeiten können in den Datenschutzbestimmungen von
          LinkedIn eingesehen werden, die unter folgender Adresse verfügbar sind: https://www.linkedin.com/legal/privacy-policy.
        </p>
        <p id="Instagram">
          <h4>9.3 Instagram</h4>
        </p>
        <p>
          Wenn Sie bei Instagram eingeloggt sind, kann Instagram den Besuch unserer Seiten Ihrem Instagram- Konto direkt zuordnen. Wenn Sie mit den Plugins interagieren,
          zum Beispiel den Like-Button betätigen oder einen Kommentar abgeben, wird diese Information ebenfalls direkt an einen Server von Instagram übermittelt und dort
          gespeichert. Diese Informationen werden auch auf Ihrem Instagram- Konto veröffentlicht und dort Ihren Kontakten angezeigt.
        </p>
        <p>
          Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten. Details
          zur Datenerhebung (Zweck, Umfang, weitere Bearbeitung, Nutzung) sowie zu Ihren Rechten und Einstellungsmöglichkeiten können Sie den Datenschutzhinweisen von
          Instagram entnehmen. Diese finden Sie unter https://help.instagram.com/519522125107875.
        </p>
        <p>
          Wenn Sie nicht wünschen, dass Instagram den Besuch unserer Seiten Ihrem Instagram-Konto zuordnen kann, loggen Sie sich bitte aus Ihrem Instagram-Konto aus,
          bevor Sie unsere Seite besuchen.
        </p>
        <p>
          Wenn Sie nicht wünschen, dass Instagram den Besuch unserer Seiten Ihrem Instagram-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
          Instagram-Benutzerkonto aus, bevor Sie unsere Webseite nutzen.
        </p>
        <p>
          Wir empfehlen Ihnen, sorgfältig die Datenschutzhinweise von Instagram zu lesen, um ein besseres Verständnis dafür zu erhalten, wie Ihre Daten verwendet werden.
        </p>
        <p>
          <h3>10. Änderungen</h3>
        </p>
        <p>
          Wir empfehlen Ihnen, diese Datenschutzerklärung regelmäßig zu überprüfen, um auf dem aktuellen Stand zu bleiben und über eventuelle Änderungen informiert zu
          sein. Wenn Sie weitere Fragen zum Datenschutz haben, können Sie sich gerne an uns wenden. Wir stehen Ihnen jederzeit zur Verfügung, um Ihnen weitere
          Informationen und Auskünfte zu geben.
        </p>
      </p>
     
    </> </div>
    </>
  );

  return registerPrivacyPolicyOpened ? (
    <React.Fragment>
      <RegisterDialogWrapper onClick={closeDialogPrivacyPolicy}></RegisterDialogWrapper>
      <RegisterDialogContainer>
        <StyledCloseIcon onClick={closeDialogPrivacyPolicy} />
        <PageHeaderTitle title={t('LANDING_PAGE_FOOTER.dataPrivacy')} />
        <StyledPricingPlanWrapper>{renderPrivacyPolicy()}</StyledPricingPlanWrapper>

        <Footer style={{ justifyContent: 'center', paddingTop: '15px', paddingBottom: '15px' }}>
          <CustomButton text={t('BUTTON.cancel')} customHeight="50px" customTextColor={WHITE} handleOnClick={closeDialogPrivacyPolicy} />
        </Footer>
      </RegisterDialogContainer>
    </React.Fragment>
  ) : null;
};

export default RegisterPrivacyPolicy;
