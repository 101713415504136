import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { WHITE, LIGHT_GRAY_BACKGROUND, LIGHT_BLUE_PAGE_BG, APP_RED, BLACK, DARK_BLUE_FOOTER } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

const colorsOn = 'solid 0px';

 const StyledPreviewDialogFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${LIGHT_BLUE_PAGE_BG};
  /* padding: 0 30px 20px 30px; */
  flex-wrap: no-wrap;
  /* column-gap: 20px; */
  border: ${colorsOn} blue;
  @media (max-width: ${breakpoints.lg}) {
    margin-top: 10px;
  }
`;

 const StyledPreviewDialogFooterInfo = styled('div')`
  display: flex;
  flex-wrap: wrap;
  /* padding: 13px; */
  background-color: ${LIGHT_BLUE_PAGE_BG};
  width: 100%;
  font-size: 16px;
  border: ${colorsOn} orange;

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 10px;
  }

  a,
  a:visited,
  a:hover {
    color: ${APP_RED};
    margin-right: 20px;
    text-decoration: none;
  }
`;


const StyledTabs = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow: auto;
  position: relative;


  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledPreviewDetails = styled('div')`
  padding: 20px 30px 0;

`;

const StyledTab = styled('div')`
  background: ${({ active }) => (active ? APP_RED : LIGHT_GRAY_BACKGROUND)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  padding: ${({ active }) => (active ? '5px 10px' : '5px 10px')};
  color: ${({ active }) => (active ? WHITE : BLACK)};
  font-size: 18px;
  font-weight: 700;
  border-radius: 6px 6px 0 0;
  border-top: 1px solid #cbced7;
  border-left: 1px solid #cbced7;
  border-right: 1px solid #cbced7;
  border: ${({ active }) => (active ? `1px solid ${APP_RED}` : '')};
  line-height: 1.2;
  min-width: 200px;
  cursor: pointer;

`;

const StyledActions = styled('div')`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  justify-content: flex-end;
  button:nth-of-type(1) {
    margin-right: 14px;

  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;

    button {
      width: 100%;
    }
    button:nth-of-type(1) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const StyledProjectInfoDetails = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 350px;


  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;

const StyledProjectInfo = styled('div')`
  display: flex;
  width: 100%;
  padding: 16px 27px;


  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const StyledProjectInfoLeftSide = styled('div')`
  display: flex;
  width: 100%;


  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
  }
`;

const StyledProjectRate = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100px;


  h3 {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    line-height: 22px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    color: #282e36;
    opacity: 0.8;
    margin-bottom: 0;
  }
`;

const StyledMover = styled('div')`
  height: 100%;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const StyledTabsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;


  @media (min-width: 1400) {
    width: calc((100% - 200px) / 1.2);
    height: 50px;
  }
`;

const StyledProjectsHeader = styled('div')`
  text-align: left;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${DARK_BLUE_FOOTER};
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 4px solid ${APP_RED};
  position: relative;

`;

const StyledProjectsCardsWrapper = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 0;
  height: 70%;
  width: 100%;
  overflow: auto;
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;


  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledVacanciesContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px 30px;
  overflow: hidden;
  height: 100%;
  border: ${colorsOn} yellow;
`;

const StyledVacanciesInfoWrapper = styled(Row)`
  margin-top: 12px;
  height: 100%;
  overflow: hidden;
  border: ${colorsOn} yellow;
`;

const StyledCompletedVacanciesColWrapper = styled(Col)`
  padding-left: 7px;
  padding-right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: ${colorsOn} orange;
`;

const StyledTitleBoxWrapper = styled('div')`
  border-bottom: 2px solid red;
  display: flex;
  border: ${colorsOn}x blue;

  @media (max-width: ${breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

const StyledTitleBox = styled('div')`
  display: flex;
  background: ${({ active }) => (active ? '#f95050 0% 0% no-repeat padding-box' : '#F5F5F5')};
  border-radius: 6px 6px 0 0;
  text-align: center;
  font: normal normal bold 14px/48px Lato;
  letter-spacing: 0px;
  color: ${({ active }) => (active ? WHITE : '#474A4F')};
  border-top: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-left: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  border-right: ${({ active }) => (active ? 'none' : '1px solid #CBCED7')};
  padding: 0 20px;
  cursor: pointer;
  margin-right: 6px;
  border: ${colorsOn} pink;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 5px;
    width: 100%;
    justify-content: center;
    padding-bottom: -20px;
    height: 60px;
  }
`;

const StyledTitleColumn = styled('div')`
  display: flex;
  border: ${colorsOn} green;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 5px;
    min-width: 220px;
  }
`;

const StyledVacanciesCardWrapper = styled('div')`
  height: 100%;
  width: 100%;
  background: ${LIGHT_GRAY_BACKGROUND} 0% 0% no-repeat padding-box;
  border-radius: 0 6px 6px 6px;
  padding: 0 20px 0px 16px;
  overflow-y: auto;
`;

const StyledOngoingInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSearchResultContent = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${LIGHT_GRAY_BACKGROUND};
  border-radius: 6px 6px 0 0;
  padding: 0;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${APP_RED};
    border-radius: 10px;
    height: 120px;
  }
`;

const StyledCardWrapper = styled(Col)`
  padding: 10px 9px 10px 9px !important;
`;

export {
  StyledVacanciesContentWrapper,
  StyledVacanciesInfoWrapper,
  StyledCompletedVacanciesColWrapper,
  StyledTitleBoxWrapper,
  StyledTitleBox,
  StyledVacanciesCardWrapper,
  StyledOngoingInfoWrapper,
  StyledSearchResultContent,
  StyledCardWrapper,
  StyledTabs,
  StyledTab,
  StyledActions,
  StyledProjectInfoDetails,
  StyledProjectInfo,
  StyledProjectInfoLeftSide,
  StyledProjectRate,
  StyledMover,
  StyledTabsWrapper,
  StyledProjectsHeader,
  StyledProjectsCardsWrapper,
  StyledPreviewDetails,
  StyledTitleColumn,
  StyledPreviewDialogFooterInfo,
  StyledPreviewDialogFooter
};
