import styled from '@emotion/styled';
import { Dropdown } from 'react-bootstrap';

import { APP_RED, APP_RED_HOVER, INPUT_TEXT_DARK_BLUE, WHITE } from 'constants/colors';

const StyledDropdown = styled(Dropdown)`
  .btn-primary.dropdown-toggle {
    color: ${INPUT_TEXT_DARK_BLUE};
    border: ${APP_RED};
    background-color: transparent;
    outline: none;

    &:hover {
      color: ${WHITE};
      background-color: ${APP_RED_HOVER};
    }
    &:focus {
      color: ${INPUT_TEXT_DARK_BLUE};
      background-color: transparent;
      box-shadow: none;
    }
  }
  .dropdown-menu {
    .dropdown-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      text-decoration: none;
      width: 100%;

      .flag-icon {
        margin-right: 10px;
      }

      &:hover,
      :focus {
        color: ${WHITE};
        background-color: ${APP_RED_HOVER};
      }
    }
  }
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  background-color: transparent;
  border: none;
  color: ${INPUT_TEXT_DARK_BLUE};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  span {
    text-transform: capitalize;
  }
  ::after {
    margin-top: 2px;
  }
`;

export { StyledDropdown, DropdownToggle };
