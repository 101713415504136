import { createSlice } from '@reduxjs/toolkit';

import ASYNC_STATUS from 'constants/asyncStatus';
import {
  login,
  getWorkerProfile,
  updateUser,
  updateWorker,
  updateFavorites,
  getCompanyProfile,
  updateFavoritesCompany,
  updateCompany,
  deleteSingleFile,
  updateBlacklist,
  updateWorkerApplication
} from 'redux/modules/auth/asyncThunks';
import { AUTH, TOKEN } from 'utils/http';

const browserToken = JSON.parse(localStorage.getItem(TOKEN)) || null;
const browserUser = JSON.parse(localStorage.getItem(AUTH)) || {};
const profileData = JSON.parse(localStorage.getItem('profile')) || '';

const initialState = {
  token: browserToken || null,
  status: ASYNC_STATUS.IDLE,
  user: browserUser || {},
  profile: profileData || ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: state => {
      // sessionStorage.removeItem(AUTH);
      localStorage.removeItem(AUTH);
      localStorage.removeItem(TOKEN);
      localStorage.setItem('profile', '');
      // localStorage.clear();
      state.token = null;
    },
    loginUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.jwt;
      state.status = ASYNC_STATUS.IDLE;
    }
  },
  extraReducers: {
    [login.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.jwt;
      state.status = ASYNC_STATUS.IDLE;
    },
    [getWorkerProfile.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [getWorkerProfile.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [getCompanyProfile.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [getCompanyProfile.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateUser.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateWorker.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateWorker.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },

    [updateWorkerApplication.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateWorkerApplication.fulfilled]: (state, { payload }) => {
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateCompany.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateCompany.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateFavorites.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateFavorites.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateFavoritesCompany.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateFavoritesCompany.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.status = ASYNC_STATUS.IDLE;
    },
    [updateBlacklist.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [updateBlacklist.fulfilled]: (state, { payload }) => {
      state.profile = {
        ...state.profile,
        blacklists: payload
      };
      state.status = ASYNC_STATUS.IDLE;
    },
    
    // [updateBlacklist.fulfilled]: (state, { payload }) => {
    //   state.profile.blacklists = payload.blacklists;
    //   state.status = ASYNC_STATUS.IDLE;
    // },
    [deleteSingleFile.pending]: state => {
      state.status = ASYNC_STATUS.LOADING;
    },
    [deleteSingleFile.fulfilled]: (state, { payload }) => {
      state.profile.files = state.profile.files.filter(doc => doc.id !== payload.id);
      state.status = ASYNC_STATUS.IDLE;
    }
  }
});

export const { clearAuth, loginUser } = authSlice.actions;

export default authSlice.reducer;
