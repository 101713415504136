import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { StyledButton, StyledButtonIcon } from 'components/CustomButton/styles';
import { APP_RED, WHITE } from 'constants/colors';

const CustomButton = ({
  text,
  title,
  link,
  custombuttoncolor,
  customWidth,
  customHeight,
  custombordercolor,
  customFontSize,
  customTextColor,
  customradiustopleft,
  customradiustopright,
  handleOnClick,
  hasIcon,
  icon,
  btnIconText,
  iconColor,
  iconFontSize,
  disabled,
  type,
  className,
  customMarginLeft
}) => (
  <>
    <StyledButton
      title={title}
      customradiustopleft={customradiustopleft}
      customradiustopright={customradiustopright}
      custombuttoncolor={custombuttoncolor}
      customfontsize={customFontSize}
      customwidth={customWidth}
      customheight={customHeight}
      custombordercolor={custombordercolor}
      customtextcolor={customTextColor}
      onClick={handleOnClick}
      type={type}
      disabled={disabled}
      className={className}
    >
      {hasIcon ? (
        <StyledButtonIcon
        customMarginLeft={customMarginLeft}
        >
          {/* <FontAwesomeIcon icon={icon} color={iconColor} fontSize={iconFontSize} /> */}
          <FontAwesomeIcon icon={icon === 'arrowsUpDown' ? faArrowsAltV : icon} color={iconColor} fontSize={iconFontSize} />
          <span>{btnIconText}</span>
          <span>{text}</span>
        </StyledButtonIcon>
      ) : (
        <span>{text}</span>
      )}
    </StyledButton>
  </>
);

CustomButton.defaultProps = {
  customButtonColor: APP_RED,
  text: null,
  customWidth: null,
  customMarginLeft: null,
  customHeight: null,
  customTextColor: null,
  customBorderColor: null,
  iconColor: null,
  icon: null,
  iconFontSize: '14px',
  hasIcon: false,
  btnIconText: null,
  type: 'button',
  disabled: false,
  handleOnClick: () => {}
};

CustomButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  btnIconText: PropTypes.string,
  hasIcon: PropTypes.bool,
  handleOnClick: PropTypes.func,
  customButtonColor: PropTypes.string,
  customWidth: PropTypes.string,
  customMarginLeft: PropTypes.string,
  customHeight: PropTypes.string,
  customBorderColor: PropTypes.string,
  customTextColor: PropTypes.string,
  iconFontSize: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool
};

export default CustomButton;
