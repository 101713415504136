import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  StyledSideNavigation,
  StyledLogoWrapper,
  StyledUser,
  LinksSignOutWrapper,
  StyledLinks,
  StyledLink,
  StyledSignOut,
  StyledLinkArrow
} from 'components/SideNavigation/styles';
import LightHorizontalLogo from 'assets/logo/png/light-horizontal-stripped.png';
import { WHITE } from 'constants/colors';
import DefaultProfileImage from 'assets/images/default-profile-image.svg';

import { authProfileSelector, authUserSelector } from '../../redux/modules/auth/selectors';
import { USER_TYPE } from '../../constants/userTypes';

const SideNavigation = ({ links, user, handleLogout }) => {
  const { t } = useTranslation();

  const authUser = useSelector(authUserSelector);
  const authProfile = useSelector(authProfileSelector);
  // const BE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}`;
  const BE_URL = ``;

  return (
    <StyledSideNavigation className="sidebar">
      <StyledLogoWrapper>
        <img alt="rounded" src={LightHorizontalLogo} />
      </StyledLogoWrapper>
      <StyledUser>
        <img
          style={{ padding: '4px', maxWidth: '3.5rem', height: '3.5rem' }}
          alt="full user "
          src={authProfile?.image?.url ? `${BE_URL}${authProfile.image.url}` : DefaultProfileImage}
        />
        <div>
          <p>{user?.name}</p>
          {authUser?.role?.type === USER_TYPE.COMPANY && <p>{authProfile?.companyName}</p>}
          {authUser?.role?.type === USER_TYPE.COMPANY && authProfile?.companyCategory?.name && <p>{authProfile.companyCategory.name}</p>}
        </div>
      </StyledUser>
      <LinksSignOutWrapper>
        <StyledLinks>
          {links.map(link => (
            <StyledLink exact className="links" key={link.name} activeClassName="selected-item" to={link.link}>
              <span />
              {t(link.name)}
              <StyledLinkArrow icon="arrow-right" />
            </StyledLink>
          ))}
        </StyledLinks>
        <StyledSignOut>
          <div onClick={handleLogout}>
            <FontAwesomeIcon icon="arrow-right" color={WHITE} size="sm" />
            {t('BUTTON.signOut')}
          </div>
        </StyledSignOut>
      </LinksSignOutWrapper>
    </StyledSideNavigation>
  );
};

SideNavigation.propTypes = {
  links: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired
};

export default SideNavigation;
