/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FarStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as FaStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { useWindowDimensions } from 'utils/helpers';

import { APP_RED, WHITE } from 'constants/colors';
import CustomButton from 'components/CustomButton';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeaderImageWrapper,
  StyledCardHeaderImage,
  StyledCardHeaderImageDescription,
  StyledCardHeaderBottomImageDescription,
  StyledCardHeaderImageDescriptionFirst,
  StyledCardHeaderBellowImage,
  StyledIconWrapper,
  StyledImageDescriptionWrapper,
  StyledCardFooter,
  CardName,
  StyledCardBodyRight,
  StyledCardHeaderImageDescriptionRight,
  StyledCardFrontPage,
  StyledCardHeaderCol,
  StyledCardFrontBottom,
  StyledBottomCardDescription,
  StyledFooterBottom,
  StyledCardRowWrapperCol,
  StyledProjectsButtonRow,
  StyledCardAppFooter,
  StyledCardHeaderImageDeRight,
  StyledRowButtons,
  StyledColButtonLeft,
  StyledColButtonRight,
  StyledProjectsButtonTerm
} from 'components/CustomCarProjects/styles';

// eslint-disable-next-line complexity
const CustomCarProjects = ({
  headerDescriptionEmail,
  headerDescriptionPhone,
  favCompanyDescription,
  profileImage,
  sectionTitle,
  sectionSubtitle,
  handleActiveDeactivateJob,
  upperButtonText,
  lowerButtonText,
  handleDeleteJob,
  handleEditJob,
  editButtonText,
  headerDescriptionFirst,
  headerDescriptionSecond,
  headerDescriptionThird,
  headerDescriptionFourth,
  headerDescriptionFifth,
  headerDescriptionExtra,
  headerDescriptionFirstRight,
  headerDescriptionSecondRight,
  headerDescriptionThirdRight,
  footerDescription,
  profileClick,
  favoriteIcon,
  isFavorite,
  onFavoriteClick,
  isActive,
  click,
  hasActions,
  handleOnItemClick,
  handleOnItemClickReject,
  acceptText,
  rejectText,
  onApplyClick,
  onAddRemoveClick,
  plusMinusSign,
  footerMinHeight,
  deadline,
  headerDescriptionPayrollMonthly,
  headerDescriptionFourthFrontPage,
  openPopUpTerminateJob,
  handleTerminateClick,
  handleTerminateOfferClick,
  headerJobType,
  clickThis,
  worker,
  sectionTitleOffers,
  isOffer,
  isNotOffer
  // onApplyButton
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  // console.log(footerDescription);

  // const footerMinHeight = {
  //   minHeight: '6rem'
  // };

  // console.log("here is sectionTitle====>>>", sectionTitle)

  return (
    <StyledCard onClick={click}>
      {/* put above active={isActive}   */}
      <StyledCardHeader>
        <StyledIconWrapper>
          {favoriteIcon &&
            (isFavorite ? (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FaStar} color={APP_RED} fontSize="19px" />
            ) : (
              <FontAwesomeIcon onClick={onFavoriteClick} icon={FarStar} color={APP_RED} fontSize="19px" />
            ))}
        </StyledIconWrapper>

        <StyledCardHeaderImageWrapper>
          <StyledCardHeaderImageDescription>
            <StyledCardHeaderImage onClick={profileClick} src={profileImage} />{' '}
          </StyledCardHeaderImageDescription>
          <StyledCardRowWrapperCol>
            <StyledCardHeaderImageDeRight>
              {/* 
              /// yellow */}
              <StyledCardHeaderImageDescriptionFirst>
                {/* <CardName> */}
                {width > 1700 && sectionTitle ? sectionTitle.slice(0, 33): ""}
                  {width > 1700 && sectionTitle && sectionTitle.length > 32 ? '...' : ''}
                  {width > 900 && width < 1700 && sectionTitle ? sectionTitle.slice(0, 24): ""}
                  {width > 900 && width < 1700 && sectionTitle && sectionTitle.length > 23? '...' : ''}
                  {width <= 900 && sectionTitle ? sectionTitle.slice(0, 23): ""}
                  {width <= 900 && sectionTitle && sectionTitle.length > 22 ? '...' : ''}
                {/* </CardName> */}
              </StyledCardHeaderImageDescriptionFirst>
              <span onClick={profileClick}>
              {width > 1700 && sectionSubtitle ? sectionSubtitle.slice(0, 50): ""}
                  {width > 1700 && sectionSubtitle && sectionSubtitle.length > 49 ? '...' : ''}
                  {width > 900 && width < 1700 && sectionSubtitle ? sectionSubtitle.slice(0, 34): ""}
                  {width > 900 && width < 1700 && sectionSubtitle && sectionSubtitle.length > 33? '...' : ''}
                  {width <= 900 && sectionSubtitle ? sectionSubtitle.slice(0, 31): ""}
                  {width <= 900 && sectionSubtitle && sectionSubtitle.length > 30 ? '...' : ''}
                {sectionTitleOffers && sectionTitleOffers}
              </span>
              <span>
                {headerDescriptionFifth && headerDescriptionFifth.toString().slice(0, 6)}
                {headerDescriptionFifth && headerDescriptionFifth.toString().length > 6 ? '...' : ''}
                {headerDescriptionFirst && headerDescriptionFirst.slice(0, 31)}
                {headerDescriptionFirst && headerDescriptionFirst.length > 31 ? '...' : ''}
                
              </span>
              {headerDescriptionSecond && <span></span>}
                {headerDescriptionSecond && headerDescriptionSecond.slice(0, 15)}, {headerDescriptionSecond && headerDescriptionSecond.length > 15 ? '...' : ''} &nbsp;
              {headerDescriptionThird && headerDescriptionThird.slice(0, 20) + `\u0020`}
              {headerDescriptionThird && headerDescriptionThird.toString().length > 20 ? '...' : ''}
              <br></br>
              {/* Arbeitsstelle:  */}
              {headerJobType && headerJobType.slice(0, 34)}
              {headerJobType && headerJobType.length > 33 ? '...' : ''}
              <p>{headerDescriptionExtra && headerDescriptionExtra}</p>
              {/* {headerDescriptionThird && headerDescriptionThird.slice(0, 10)} {headerDescriptionThird && headerDescriptionThird.length > 10 ? '...' : ''} */}
              {headerDescriptionPhone && <span>Phone: {headerDescriptionPhone}, &nbsp;</span>}
              {headerDescriptionEmail && <span>Email: {headerDescriptionEmail}</span>}
            </StyledCardHeaderImageDeRight>
            {(headerDescriptionFirstRight || headerDescriptionSecondRight || headerDescriptionThirdRight) && (
              <StyledCardHeaderBottomImageDescription>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionFirstRight}</span>
                <span style={{ whiteSpace: 'nowrap' }}>{headerDescriptionSecondRight}</span>
                {headerDescriptionThirdRight && <span style={{ whiteSpace: 'nowrap' }}>Kat. {headerDescriptionThirdRight}</span>}
              </StyledCardHeaderBottomImageDescription>
            )}
            {headerDescriptionPayrollMonthly && (
              <StyledCardHeaderImageDescription>
                <StyledCardFrontPage>
                  <span>
                    {t('OFFERCARD.salaryDisplay.perMonth')}: CHF {headerDescriptionPayrollMonthly}
                  </span>
                  {deadline && (
                    <p style={footerMinHeight}>
                      {t('OFFERCARD.startDate')}: {deadline}
                    </p>
                  )}
                </StyledCardFrontPage>
              </StyledCardHeaderImageDescription>
            )}
          </StyledCardRowWrapperCol>
        </StyledCardHeaderImageWrapper>
      </StyledCardHeader>
      {headerDescriptionFourthFrontPage && (
        <StyledCardFrontBottom>
          <span>{headerDescriptionFourthFrontPage}</span>
        </StyledCardFrontBottom>
      )}
      {headerDescriptionFourth && (
        <StyledCardHeaderBellowImage>
          <span>{headerDescriptionFourth}</span>
        </StyledCardHeaderBellowImage>
      )}
      {/* <StyledCardFooter style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {favCompanyDescription && <p style={footerMinHeight}>{favCompanyDescription}</p>}
      </StyledCardFooter> */}

      {/* // took this out 28 09 2023 */}
      {/* <StyledFooterBottom style={{ justifyContent: 'space-between', alignItems: 'center' }}> */}
      {/* {footerDescription && <p style={footerMinHeight}>{footerDescription}</p>} */}
      {/* </StyledFooterBottom> */}
      {/* <StyledRowButtons> */}
        <StyledColButtonLeft>
          {' '}
          {hasActions && (
            <>
              {/* <StyledProjectsButtonRow>
            {onApplyClick && <CustomButton text={t('BUTTON.applyNow')} customTextColor={WHITE} customWidth="120px" handleOnClick={onApplyClick} />}
          </StyledProjectsButtonRow> */}
              <StyledProjectsButtonRow>
                {onAddRemoveClick && <CustomButton text={plusMinusSign} customTextColor={WHITE} customWidth="120px" handleOnClick={onAddRemoveClick} />}
              </StyledProjectsButtonRow>

              {/* <StyledCardAppFooter
                style={{ display: onApplyClick && 'flex', flexDirection: onApplyClick && 'row', justifyContent: 'space-between', alignItems: 'center' }}
              > */}
                {/* {(handleOnItemClick || handleOnItemClickReject) && (
              <div className="actions-wrapper">
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClickReject}>
                    {rejectText || 'Close'}
                  </button>
                )}
                {handleOnItemClick && (
                  <button type="button" onClick={handleOnItemClick}>
                    {acceptText || 'Open'}
                  </button>
                )}
              </div>
            )} */}
              {/* </StyledCardAppFooter> */}
            </>
          )}
       
        {upperButtonText ||
          lowerButtonText ||
          (editButtonText && (
            // <Col>
              <StyledCardBodyRight>
                {upperButtonText && (
                  <button className="edit-button" type="button" onClick={handleActiveDeactivateJob}>
                    {upperButtonText}
                  </button>
                )}
                {lowerButtonText && (
                  <button className="delete-button" type="button" onClick={handleDeleteJob}>
                    {lowerButtonText}
                  </button>
                )}
                {editButtonText && (
                  <button className="edit-button" type="button" onClick={handleEditJob}>
                    {editButtonText}
                  </button>
                )}
              </StyledCardBodyRight>
            // </Col>
          ))}
        {/* <Col> */}
          <StyledProjectsButtonTerm>
            {isNotOffer && (
              <CustomButton
                custombuttoncolor={WHITE}
                textColor={APP_RED}
                custombordercolor={APP_RED}
                text={t('BUTTON.terminate')}
                customWidth="120px"
                handleOnClick={handleTerminateClick}
              />
            )}
            {isOffer && (
              <CustomButton
                custombuttoncolor={WHITE}
                textColor={APP_RED}
                custombordercolor={APP_RED}
                text={t('BUTTON.terminate')}
                customWidth="120px"
                handleOnClick={handleTerminateOfferClick}
              />
            )}
          </StyledProjectsButtonTerm>
        {/* </Col> */}
        </StyledColButtonLeft>
      {/* </StyledRowButtons> */}
    </StyledCard>
  );
};

CustomCarProjects.defaultProps = {
  profileImage: null,
  sectionTitle: '',
  headerDescriptionFirst: null,
  headerDescriptionSecond: null,
  hasActions: false,
  sectionSubtitle: null,
  isActive: false
};

CustomCarProjects.propTypes = {
  profileImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionSubtitle: PropTypes.string,
  headerDescriptionFirst: PropTypes.string,
  headerDescriptionSecond: PropTypes.string,
  hasActions: PropTypes.bool,
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CustomCarProjects;
