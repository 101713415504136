import styled from '@emotion/styled';

import LoginBackground from 'assets/images/login-bg.jpg';
import BossastoTextLogo from 'assets/logo/png/light-horizontal.png';
import { ReactComponent as CloseIcon } from 'assets/icons/x_icon.svg';
import { APP_RED, BLACK, INPUT_BORDER, WHITE } from 'constants/colors';
import breakpoints from 'constants/breakpoints';

export const StyledCustomDialog = styled('div')`
  display: flex;
  background-color: ${BLACK}; /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 9998; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
`;

export const StyledCustomDialogWrapper = styled('div')`
  display: flex;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000039;
  border: 1px solid ${INPUT_BORDER};
  opacity: 1;
  max-width: 100%; /* Max width where it stops expanding */
  margin: auto; /* Auto margin according to the element width */
  padding: 10px;
  border-radius: 5px;
`;

export const StyledCustomDialogLeftSide = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 475px;
  height: 500px;
`;

export const StyledDialogTitle = styled('div')`
  margin-top: 70px;
  font-size: 36px;
`;

export const StyledDialogContent = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const StyledDialogButtonActions = styled('div')`
  display: flex;
  flex-direction: column;

  button {
    margin: 10px auto;
    :active {
      background-color: ${APP_RED};
    }
    :focus {
      background-color: ${APP_RED};
    }
  }
`;

export const StyledDialogForgetPassLable = styled('div')`
  margin-top: 45px;
  display: flex;
  width: 300px;

  span {
    font-style: italic;
    font-size: 14px;
    margin-left: 5px;
    font-weight: bold;
    font-style: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledDialogCreateAccountLabel = styled('div')`
  display: flex;
  width: 300px;

  span {
    font-style: italic;
    font-size: 14px;
    :nth-of-type(2) {
      margin-left: 5px;
      font-weight: bold;
      font-style: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const StyledInputField = styled('input')`
  font-size: 16px;
  padding: 15px 10px;
  width: 300px;
  border: 1px solid ${INPUT_BORDER};
  border-radius: 5px;
  margin-top: 20px;
`;

export const StyledCustomDialogRightSide = styled('div')`
  display: flex;
  position: relative;
  width: 475px;
  height: 540px;
  background: url(${LoginBackground});
  background-size: cover;
  background-position: left;
  background-position: center;

  .login-logo-title {
    position: absolute;
    background: url(${BossastoTextLogo});
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
